import {useI18n} from "common/hooks/useI18n";

import {FleetCarService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

interface VehicleItemProps {
    vehicle: FleetCarService.Car;
}

function VehicleItem({vehicle}: VehicleItemProps) {
    const {i18n} = useI18n();
    return (
        <div className="flex w-full flex-col">
            <div className="flex items-center gap-2">
                <Typography variant="body-primary">
                    {vehicle.model}, {vehicle.year}
                </Typography>
            </div>
            <div className="flex items-center gap-2">
                <Typography variant="label-primary">
                    <span className="text-secondary">
                        {vehicle.reg_number} • {vehicle.color}
                    </span>
                </Typography>
            </div>
            {vehicle.status === FleetCarService.CarStatus.BLOCKED && (
                <div className="flex items-center gap-2">
                    <Typography variant="label-primary">
                        <span className="text-danger-secondary">{i18n("vehicle.status.blocked")}</span>
                    </Typography>
                </div>
            )}
        </div>
    );
}

export default VehicleItem;
