import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton, Tooltip} from "@bolteu/kalep-react";
import {Call, Mail} from "@bolteu/kalep-react-icons";
import DriverRegistrationByCompanyIdAndSource = FleetOwnerPortalService.DriverRegistrationByCompanyIdAndSource;

interface DriverDetailsProps {
    driverRegistration: DriverRegistrationByCompanyIdAndSource;
}

const getCallFieldAction = (phone: string, label: string) => (
    <Tooltip content={label} placement="top">
        <a href={`tel:${phone}`}>
            <IconButton aria-label="Call the driver" size="sm" icon={<Call />} />
        </a>
    </Tooltip>
);

const getMailFieldAction = (mail: string, label: string) => (
    <Tooltip content={label} placement="top">
        <a href={`mailto:${mail}`}>
            <IconButton aria-label="Mail the driver" size="sm" icon={<Mail />} />
        </a>
    </Tooltip>
);

const DriverRegistrationDetails: FC<DriverDetailsProps> = ({driverRegistration}) => {
    const {i18n} = useI18n();

    const getProfileField = (title: string, value: string | null, fieldAction?: React.ReactNode) => (
        <div className="border-separator flex h-20 items-center justify-between border-b py-4">
            <div>
                <h4 className="text-secondary">{title}</h4>
                <p>{value ?? ""}</p>
            </div>
            {fieldAction}
        </div>
    );

    return (
        <div className="flex flex-col leading-6">
            {getProfileField(i18n("auth.app.fleet.driver.first_name"), driverRegistration.first_name)}
            {getProfileField(i18n("auth.app.fleet.driver.last_name"), driverRegistration.last_name)}
            {getProfileField(i18n("auth.app.fleet.driver.username"), driverRegistration.username)}
            {getProfileField(
                i18n("auth.app.fleet.driver.email"),
                driverRegistration.email,
                driverRegistration.email
                    ? getMailFieldAction(driverRegistration.email, i18n("common.email"))
                    : undefined,
            )}
            {getProfileField(
                i18n("auth.app.fleet.driver.phone"),
                driverRegistration.phone,
                getCallFieldAction(driverRegistration.phone, i18n("common.call")),
            )}
        </div>
    );
};

export default DriverRegistrationDetails;
