import {useI18n} from "common/hooks/useI18n";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";

import {Alert, Button, Dialog, Typography} from "@bolteu/kalep-react";

interface VehicleAccessBaseDialogProps {
    isOpen: boolean;
    children: React.ReactNode;
    title: string;
    subtitle: string;
    onSubmitted?: () => void;
    onRequestClose: () => void;
}
export default function VehicleAccessBaseDialog({
    isOpen,
    children,
    title,
    subtitle,
    onSubmitted,
    onRequestClose,
}: VehicleAccessBaseDialogProps) {
    const {i18n} = useI18n();
    return (
        <ResponsiveDialog isOpen={isOpen} title={title} onRequestClose={onRequestClose}>
            <Dialog.Content>
                <div className="flex h-full flex-col gap-4 overflow-hidden">
                    <Typography variant="body-m-regular">
                        <span className="text-secondary">{subtitle}</span>
                    </Typography>
                    {children}
                </div>
            </Dialog.Content>
            <div className="flex w-full flex-wrap items-center justify-between gap-4 px-6 py-2">
                <Alert severity="info">{i18n("auth.app.fleet.vehicle_assignment.driver_notification")}</Alert>
                <Button variant="secondary" onClick={onRequestClose}>
                    {i18n("auth.app.fleet.vehicle_assignment.dialogs.cancel")}
                </Button>
                <Button variant="primary" onClick={onSubmitted}>
                    {i18n("auth.app.fleet.vehicle_assignment.dialogs.submit")}
                </Button>
            </div>
        </ResponsiveDialog>
    );
}
