import Header, {HeaderSize} from "common/components/header/Header";
import LanguageSelector from "common/components/languageSelector";
import {useI18n} from "common/hooks/useI18n";

interface Props {
    hasHeader?: boolean;
}

export const PortalLanguage = ({hasHeader}: Props) => {
    const {i18n} = useI18n();

    return (
        <>
            {hasHeader && <Header size={HeaderSize.Small} text={i18n("common.language")} className="mt-6" />}
            <p className="text-secondary mt-2 mb-4">{i18n("auth.app.account.preferred_language")}</p>
            <LanguageSelector />
        </>
    );
};
