import {FC} from "react";

import HighlightedText from "common/components/util/HighlightedText";
import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";
import {I18nFn as I18nFnGeneric} from "@fleet/common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";
import {ChevronRight} from "@bolteu/kalep-react-icons";

import {Avatar} from "./Avatar";

interface DriverBasicInfoContainerProps {
    driver: FleetOwnerPortalService.DriverForFleetOwner;
    nameOnly?: boolean;
    onMap?: boolean;
    isMobileView?: boolean;
    highlightedText?: string;
}

const DriverBasicInfoContainer: FC<DriverBasicInfoContainerProps> = ({
    driver,
    nameOnly,
    onMap,
    isMobileView,
    highlightedText,
}) => {
    const {i18n} = useI18n();

    function getDriverSubInfo() {
        let driverSubInfo = `${driver.car_reg_number} • ${driver.car_manufacturer} ${driver.car_model} ${driver.car_year}`;
        if (driver.car_color) {
            driverSubInfo += `, ${i18n(`vehicle.color.${driver.car_color}`)}`;
        }
        return driverSubInfo;
    }

    const OnTripChip = driver.state === FleetOwnerPortalService.DriverState.HAS_ORDER && (
        <div className="shrink-0">
            <Chip color="promo" label={i18n("auth.app.fleet.live-map.on-trip")} size="sm" variant="filled" />
        </div>
    );

    const waitingStateTs =
        driver.state === FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS
            ? driver.state_change_timestamp
            : undefined;
    const OnWaitingChip = waitingStateTs && (
        <div className="shrink-0">
            <Chip label={timeFromNow(waitingStateTs, i18n)} size="sm" variant="filled" />
        </div>
    );

    if (onMap) {
        return (
            <div className="flex flex-row flex-nowrap items-center gap-3">
                <Avatar picture={driver.picture} driverState={driver.state} />
                <div className="flex grow flex-col overflow-hidden">
                    <div className="truncate text-base">{driver.name}</div>
                    <div className="text-secondary mb-1 truncate text-sm">{getDriverSubInfo()}</div>
                    {OnTripChip}
                    {OnWaitingChip}
                </div>
                {isMobileView && <ChevronRight className="text-tertiary shrink-0" />}
            </div>
        );
    }

    if (nameOnly) {
        return (
            <div className="flex flex-row items-start gap-4">
                <Avatar picture={driver.picture} driverState={driver.state} />
                <div className="flex flex-col items-start gap-2">
                    <div className="text-primary text-xl font-semibold">{driver.name}</div>
                    {OnTripChip}
                    {OnWaitingChip}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-row flex-nowrap items-center gap-3">
            <Avatar picture={driver.picture} driverState={driver.state} />
            <div className="flex grow flex-col overflow-hidden">
                <div className="truncate text-base">
                    <HighlightedText text={driver.name} highlightedText={highlightedText} />
                </div>
                <div className="text-secondary truncate text-sm">
                    <HighlightedText text={getDriverSubInfo()} highlightedText={highlightedText} />
                </div>
            </div>
            {OnTripChip}
            {OnWaitingChip}
        </div>
    );
};

export default DriverBasicInfoContainer;

export function timeFromNow(unixTimestamp: number, i18n: I18nFnGeneric<TranslationKeys>): string {
    const date = new Date(unixTimestamp * 1000);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const minutes = Math.floor(diff / 1000 / 60);
    if (minutes >= 60) {
        const hours = Math.floor(minutes / 60);
        return `${hours} ${i18n("common.hour_abbreviation")} ${minutes - hours * 60} ${i18n(
            "common.minute_abbreviation",
        )}`;
    }
    return `${minutes} min`;
}
