import {FC, useCallback, useMemo} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {DRIVER_POLL_INTERVAL_MS} from "../../hooks/useLiveMap";
import DriverBasicInfoContainer from "../driver/DriverBasicInfoContainer";
import {DriverStats} from "../types";
import EmptyListPlaceholder from "./EmptyListPlaceholder";
import Filter, {DriverFilter} from "./Filter";

type EmptyListViewTitle = FleetOwnerPortalService.DriverState | "search";

interface DriverListProps {
    filteredDrivers: FleetOwnerPortalService.DriverForFleetOwner[];
    filter: DriverFilter;
    onFilterChange: (driverFilter: DriverFilter) => void;
    onDriverSelected: (driver: FleetOwnerPortalService.DriverForFleetOwner) => void;
    driverStats: DriverStats;
}

const DriverList: FC<DriverListProps> = ({filteredDrivers, filter, onFilterChange, onDriverSelected, driverStats}) => {
    const {i18n} = useI18n();

    const getOnClickHandler = useCallback(
        (driver: FleetOwnerPortalService.DriverForFleetOwner) => () => {
            onDriverSelected(driver);
        },
        [onDriverSelected],
    );

    const rows = useMemo(
        () =>
            filteredDrivers.map((driver) => (
                <div
                    key={driver.id}
                    onClick={getOnClickHandler(driver)}
                    onKeyDown={getOnClickHandler(driver)}
                    role="button"
                    tabIndex={0}
                    className="py-3"
                >
                    <DriverBasicInfoContainer driver={driver} highlightedText={filter.term} />
                </div>
            )),
        [filter.term, filteredDrivers, getOnClickHandler],
    );

    function getEmptyListViewTitle() {
        const key: EmptyListViewTitle = filter.term ? "search" : filter.status;
        return i18n(`auth.app.fleet.live-map.empty-list.title.${key}`);
    }
    function getEmptyListViewSubtext() {
        const key = filter.term ? "search" : "updated-automatically";
        return i18n(`auth.app.fleet.live-map.empty-list.subtext.${key}`, {
            num: DRIVER_POLL_INTERVAL_MS / 1000,
        });
    }

    // TODO wait for chip fix then make backstop update!
    return (
        <div className="flex h-full shrink flex-col gap-2 py-4 md:gap-4 md:py-6">
            <Filter filter={filter} onFilterChange={onFilterChange} driverStats={driverStats} />
            {rows.length > 0 ? (
                <div className="divide-neutral-secondary grow divide-y overflow-y-auto px-6">{rows}</div>
            ) : (
                <EmptyListPlaceholder title={getEmptyListViewTitle()} subtext={getEmptyListViewSubtext()} />
            )}
        </div>
    );
};

export default DriverList;
