import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";

export function useBreadcrumbs() {
    const {i18n} = useI18n();
    const {getCampaignsPath} = useAbsolutePath();

    return [
        {
            href: getCampaignsPath(),
            title: i18n("auth.app.sidebar.campaigns"),
        },
        {
            href: "",
            title: i18n("auth.app.fleet.campaigns.details.title"),
        },
    ];
}
