import imageCompression from "browser-image-compression";

import {COMPRESSED_FILE_SIZE_MB} from "../document/DocumentUpload/constants";

const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result as ArrayBuffer);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });

export const getFileContentAsText = async (file: File): Promise<string | null> => {
    let arrayBuffer: ArrayBuffer;
    try {
        arrayBuffer = await readFileAsArrayBuffer(file);
    } catch {
        return null;
    }
    const decoder = new TextDecoder("utf-8");
    return decoder.decode(arrayBuffer);
};

export const getFileUrl = async (file: File): Promise<string> => {
    const compressedImage = await imageCompression(file, {maxSizeMB: COMPRESSED_FILE_SIZE_MB});
    return imageCompression.getDataUrlFromFile(compressedImage);
};
