import {ChangeEventHandler, useCallback, useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {OrderRequestsContextProvider} from "features/companies/orders/OrderRequestsContextProvider";

import {Switch, TextField, Typography} from "@bolteu/kalep-react";

interface Props {
    isManualPriceEnabled: boolean;
    onManualPriceSwitchChange: () => void;
    manualPrice: string;
    onManualPriceChange: (value: string) => void;
    validationError?: string;
    isLoadingCategories: boolean;
    manualPriceAvailable: boolean;
}

export const ManualPrice = ({
    isManualPriceEnabled,
    onManualPriceChange,
    onManualPriceSwitchChange,
    manualPrice,
    validationError,
    isLoadingCategories,
    manualPriceAvailable,
}: Props) => {
    const {i18n} = useI18n();
    const orderRequestsData = useContext(OrderRequestsContextProvider);

    const onManualPriceChangeWrapper = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            const {value} = event.target;
            const numValue = Number(value);
            if (!Number.isNaN(numValue) && String(numValue).split(".")?.[1]?.length > 2) {
                return;
            }
            onManualPriceChange(value);
        },
        [onManualPriceChange],
    );

    if (!orderRequestsData.getManualPricing().is_enabled) {
        return null;
    }

    const manualPriceInputOpacity = isManualPriceEnabled && manualPriceAvailable ? "opacity-100" : "opacity-0";

    return (
        <div className="mb-4 flex flex-col gap-2">
            <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                    <div>
                        <Switch
                            checked={isManualPriceEnabled}
                            onChange={onManualPriceSwitchChange}
                            disabled={isLoadingCategories || !manualPriceAvailable}
                        />
                    </div>
                    <div className="flex flex-col">
                        <div className="text-sm font-semibold">
                            {`${i18n("auth.app.orders.scheduled_rides.create-dialog.set-custom-price")}`}
                        </div>
                        <Typography color="secondary" fontSize="text-sm">
                            {i18n("auth.app.orders.scheduled_rides.optional")}
                        </Typography>
                    </div>
                </div>
                <div className={`max-w-[160px] ${manualPriceInputOpacity}`}>
                    <TextField
                        value={manualPrice}
                        onChange={onManualPriceChangeWrapper}
                        type="number"
                        prefix={orderRequestsData.getCurrency()}
                        fullWidth
                        error={Boolean(validationError)}
                        disabled={isLoadingCategories}
                    />
                </div>
            </div>
            {validationError && (
                <Typography color="danger-primary" fontSize="text-sm" align="end">
                    {validationError}
                </Typography>
            )}
            {!manualPriceAvailable && (
                <Typography color="warning-primary" fontSize="text-sm">
                    {`${i18n("auth.app.orders.scheduled_rides.create-dialog.manual_price_not_available")}`}
                </Typography>
            )}
        </div>
    );
};
