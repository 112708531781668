import {FC} from "react";

import {useTooltipClick} from "common/hooks/useTooltipClick";

import {Tooltip} from "@bolteu/kalep-react";
import {IconButton} from "@bolteu/kalep-react/build/components/IconButton/IconButton";
import {InfoCircle} from "@bolteu/kalep-react-icons";
import {KalepIconElement} from "@bolteu/kalep-react-icons/dist/types";

interface ProfileFieldAction {
    action: () => void;
    icon: KalepIconElement;
    label: string;
}

interface ProfileFieldProps {
    title: string;
    value: string | null;
    fieldAction?: ProfileFieldAction;
    tooltip?: string;
}

const ProfileField: FC<ProfileFieldProps> = ({title, value = "", fieldAction, tooltip}) => {
    const {isOpen, setIsOpen, onClick} = useTooltipClick({delay: 3000});

    return (
        <div className="min-h-20 border-separator flex flex-row justify-between border-b py-4">
            <div className="flex-col">
                <div className="flex items-center gap-1">
                    <h4 className="text-secondary">{title}</h4>
                    {!!tooltip && (
                        <Tooltip content={tooltip} placement="top" isOpen={isOpen} onOpenChange={setIsOpen}>
                            <div className="flex items-center">
                                <InfoCircle size="xs" onClick={onClick} />
                            </div>
                        </Tooltip>
                    )}
                </div>
                <p>{value}</p>
            </div>
            {fieldAction && (
                <Tooltip content={fieldAction.label} placement="top">
                    <IconButton
                        aria-label="Action button"
                        icon={fieldAction.icon}
                        shape="round"
                        size="sm"
                        type="button"
                        variant="filled"
                        onClick={fieldAction.action}
                    />
                </Tooltip>
            )}
        </div>
    );
};

export default ProfileField;
