import {Outlet} from "react-router-dom";

import {VerificationAuthProvider} from "./VerificationAuthProvider";

const VerificationAuthOutlet = () => (
    <VerificationAuthProvider>
        <Outlet />
    </VerificationAuthProvider>
);

export default VerificationAuthOutlet;
