import {useCallback, useMemo, useState} from "react";

import {debounce} from "lodash-es";

import {TextField} from "@bolteu/kalep-react";

interface Props {
    value?: string;
    onChange: (search: string) => void;
    placeholder: string;
}

const TableSearchField = ({value, onChange, placeholder}: Props) => {
    const [tableSearchValue, setTableSearchValue] = useState(value ?? "");

    const searchDebounce = useMemo(() => debounce((newSearch: string) => onChange(newSearch), 500), [onChange]);

    const onSearchChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setTableSearchValue(e.target.value);
            searchDebounce(e.target.value);
        },
        [searchDebounce],
    );

    return (
        <div className="w-full max-w-sm">
            <TextField
                fullWidth
                type="search"
                value={tableSearchValue}
                onChange={onSearchChange}
                placeholder={placeholder}
            />
        </div>
    );
};

export default TableSearchField;
