import {PortalLanguage} from "../PortalLanguage";
import NotificationPreferences from "./NotificationPreferences";

const GeneralSettings = () => (
    <div>
        <NotificationPreferences hasHeader />
        <PortalLanguage hasHeader />
    </div>
);

export default GeneralSettings;
