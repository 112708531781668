import {Fleet} from "common/constants/types";
import {CountryCode} from "common/geo/constants/countries";

export const countriesHaveTransportLicense = [
    CountryCode.Australia,
    CountryCode.Estonia,
    CountryCode.Lithuania,
    CountryCode.Norway,
    CountryCode.Poland,
    CountryCode.Slovakia,
    CountryCode.Spain,
    CountryCode.UnitedKingdom,
];

export const countriesHaveTransportLicenseExpiry = [
    CountryCode.Australia,
    CountryCode.Estonia,
    CountryCode.Slovakia,
    CountryCode.UnitedKingdom,
];

const citiesWhitelist = [
    333, // Moscow
];

export const isTransportLicenseVisible = (fleet: Fleet) =>
    countriesHaveTransportLicense.includes(fleet.country) || citiesWhitelist.includes(fleet.city_id);

export const isTransportLicenseExpiresVisible = (fleet: Fleet) =>
    countriesHaveTransportLicenseExpiry.includes(fleet.country) || citiesWhitelist.includes(fleet.city_id);
