import FilledCircle from "assets/icons/filled_circle.svg?react";
import {ApiDrivenTableTransforms} from "common/components/table/ApiDrivenTable/types";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import {CellRenderer} from "./cell-renderers";
import {ReferenceCell} from "./cell-renderers/ReferenceCell";
import {StateDisplayText} from "./cell-renderers/StateCell";
import {StatusChip} from "./cell-renderers/StatusCell";
import {isDateColumn, isReferenceColumn} from "./util";

import ApiColumn = FleetOwnerPortalService.ApiColumn;
import EntityState = FleetOwnerPortalService.EntityState;
import StateColumn = FleetOwnerPortalService.StateColumn;
import StatusColumn = FleetOwnerPortalService.StatusColumn;

interface MobileAccordionTitleProps {
    rowIdx: number;
    userDefinedVisibleColumns: FleetOwnerPortalService.ApiColumn[];
    stateColumns: StateColumn[] | undefined;
    statusColumn: StatusColumn | undefined;
    transforms?: ApiDrivenTableTransforms;
}

function isStateAlertOrInactive(rowIdx: number, stateColumn?: StateColumn) {
    return stateColumn && [EntityState.ALERT, EntityState.INACTIVE].includes(stateColumn.cells[rowIdx]);
}

function shouldRenderVisibleColumns(referenceColumns: ApiColumn[], visibleColumns: ApiColumn[]) {
    return referenceColumns.length <= 1 && visibleColumns[0];
}

function TierRenderer({
    transforms,
    column,
    rowIdx,
}: {
    transforms: ApiDrivenTableTransforms | undefined;
    column: ApiColumn;
    rowIdx: number;
}) {
    if (!transforms) {
        return null;
    }

    const renderTierIcon = transforms[column.key]?.renderCellInAccordionTitle;
    if (!renderTierIcon) {
        return null;
    }

    return (
        <>
            <div className="my-auto">{renderTierIcon({column, valueAtIdx: rowIdx})}</div>
        </>
    );
}

export default function MobileAccordionTitle({
    rowIdx,
    userDefinedVisibleColumns,
    stateColumns,
    statusColumn,
    transforms,
}: MobileAccordionTitleProps) {
    const visibleColumns = userDefinedVisibleColumns.filter((column) => !isReferenceColumn(column));
    const referenceColumns = userDefinedVisibleColumns.filter(
        (column) => isDateColumn(column) || isReferenceColumn(column),
    );

    const firstVisibleColumn = visibleColumns[0];
    const shouldRenderTier = transforms && firstVisibleColumn.key === "tier";

    return (
        <div className="flex justify-between">
            <div className="flex flex-row">
                {shouldRenderTier && (
                    <div className="my-auto mr-3">
                        <TierRenderer transforms={transforms} column={firstVisibleColumn} rowIdx={rowIdx} />
                    </div>
                )}
                <div className="flex-col items-center gap-3">
                    {referenceColumns.map((column, idx) => {
                        const matchingStateColumn = stateColumns?.find(
                            (stateColumn) => stateColumn.relation_key === column.key,
                        );

                        return (
                            <div key={column.key} className="flex items-center gap-x-1">
                                {isReferenceColumn(column) ? (
                                    <>
                                        {isStateAlertOrInactive(rowIdx, matchingStateColumn) && (
                                            <>
                                                <StateDisplayText
                                                    stateColumn={matchingStateColumn}
                                                    valueAtIdx={rowIdx}
                                                />
                                                <FilledCircle
                                                    width={4}
                                                    height={4}
                                                    className="bg-neutral-secondary-hard self-center rounded-full"
                                                />
                                            </>
                                        )}
                                        <ReferenceCell column={column} valueAtIdx={rowIdx} isSecondary={idx > 0} />
                                    </>
                                ) : (
                                    <CellRenderer valueAtIdx={rowIdx} column={column} />
                                )}
                            </div>
                        );
                    })}
                    {shouldRenderVisibleColumns(referenceColumns, visibleColumns) &&
                        (() => {
                            const columnToRender = shouldRenderTier ? visibleColumns[1] : firstVisibleColumn;
                            return (
                                <Typography variant="body-tabular-s-regular" color="secondary">
                                    <span className="mr-1">{`${columnToRender.title}:`}</span>
                                    <span className="inline-flex items-baseline">
                                        <CellRenderer column={columnToRender} valueAtIdx={rowIdx} isSubCell />
                                    </span>
                                </Typography>
                            );
                        })()}
                </div>
            </div>
            <div className="flex items-center justify-end">
                {statusColumn && <StatusChip value={statusColumn.cells[rowIdx]} valueMap={statusColumn.value_map} />}
            </div>
        </div>
    );
}
