import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import LoadingSpinner from "common/components/spinner";
import {useTabs} from "common/components/tab/useTabs";
import {ApiDriverTableSortedBy} from "common/components/table/ApiDrivenTable/types";
import {getDriverName} from "common/constants/driverName";
import {EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {
    ApiPeriod,
    apiPeriodToDates,
    getDatesStrFromDates,
    getPeriodName,
    OngoingDay,
    Period,
} from "common/services/period";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {AddDriver} from "./components/AddDriver";
import {DriverDialogProvider} from "./components/DriverDialogProvider";
import DriverRegistrationsTab from "./components/DriverRegistrationsTab";
import {defaultDriversFilters, DriverFilter} from "./components/DriversFilter";
import DriversTab from "./components/DriversTab";
import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";

import DriverRegistrationLogStatus = FleetOwnerPortalService.DriverRegistrationLogStatus;
import EngagementDataDriverKey = FleetOwnerPortalService.EngagementDataDriverKey;
import SortingDirection = FleetOwnerPortalService.SortingDirection;
import {Matches} from "./components/matches/Matches";

const MAX_LIMIT = 25;

export enum DriverTabs {
    Drivers = "drivers",
    Registrations = "registrations",
    ProposedCandidates = "proposed_candidates",
}

export enum DriverInvitationStatus {
    INVITED = "invited",
}

export type DriverRegistrationStatus = DriverRegistrationLogStatus | DriverInvitationStatus;

export interface DriverRegistration {
    id: string;
    hash: string;
    created: number;
    driver: string;
    status: DriverRegistrationStatus;
    email: string | null;
    phone: string;
    link: string | null;
}

const getEngagementDataFetchFunction = (
    api: Api,
    body: FleetOwnerPortalService.GetDriverEngagementDateRangePortalRequest,
) => api.fleetOwnerPortal.getDriverEngagementDataDateRange(body);
const getDriverRegistrationsDataFetchFunction = (api: Api) => api.fleetOwnerPortal.getDriverRegistrations();
const getDriverInvitationsDataFetchFunction = (api: Api) => api.fleetDriverInvitation.invitationsGet();

export interface OnlineHoursConfig {
    shouldShowOnlineHours: boolean;
    shouldShowWaitingOrders: boolean;
    shouldShowHasOrder: boolean;
    shouldShowUtilization: boolean;
}

const DriversListPage: FC = () => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const sorting = useRef<ApiDriverTableSortedBy | undefined>(undefined);
    const [tableSearchValue, setTableSearchValue] = useState("");
    const [driversFilters, setDriversFilters] = useState<DriverFilter>(defaultDriversFilters);
    const [pageNum, setPageNum] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>({period: Period.ONGOING_DAY} as OngoingDay);
    const [isLoadingRegistrations, setIsLoadingRegistrations] = useState(false);
    const {trackEvent} = useTracking();
    const {
        data: engagementData,
        fetch: engagementFetch,
        status: engagementStatus,
    } = useFetch(getEngagementDataFetchFunction);
    const {data: driverRegistrationData, fetch: driverRegistrationFetch} = useFetch(
        getDriverRegistrationsDataFetchFunction,
    );
    const {data: driverInvitationData, fetch: driverInvitationFetch} = useFetch(getDriverInvitationsDataFetchFunction);

    const driverCount = engagementData?.total_rows;
    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(engagementStatus);

    const driverApplications: DriverRegistration[] = [];

    if (driverRegistrationData) {
        driverApplications.push(
            ...driverRegistrationData.list.map((d) => ({
                id: String(d.id),
                hash: d.hash,
                created: d.created,
                driver: getDriverName(d.first_name, d.last_name, d.email),
                status: d.status,
                email: d.email,
                phone: d.phone,
                link: d.registration_link,
            })),
        );
    }

    if (driverInvitationData) {
        driverApplications.push(
            ...driverInvitationData.list.map((i) => ({
                id: i.hash,
                hash: i.hash,
                created: i.created,
                driver: i.email,
                status: DriverInvitationStatus.INVITED,
                email: i.email,
                phone: i.phone,
                link: i.invitation_link,
            })),
        );
    }

    driverApplications.sort((a, b) => b.created - a.created);

    const tabs = useTabSelectorOptions(driverCount, driverApplications.length);

    const isShowApplicationsTabByDefault = !driverCount && !tableSearchValue && !driversFilters.status;

    const {activeTab, TabSelector} = useTabs(
        tabs,
        isShowApplicationsTabByDefault ? DriverTabs.Registrations : DriverTabs.Drivers,
    );

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.drivers.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const getDashboardData = useCallback(
        async ({
            newPageNum,
            newSortingField,
            newSortingDirection,
        }: {
            newPageNum: number;
            newSortingField?: string;
            newSortingDirection?: SortingDirection;
        }) => {
            if (!engagementFetch) {
                return;
            }
            const dates =
                selectedPeriod.period === Period.CUSTOM
                    ? {start: selectedPeriod.startDate, end: selectedPeriod.endDate}
                    : apiPeriodToDates(selectedPeriod);

            const offset = newPageNum * MAX_LIMIT;
            engagementFetch({
                offset,
                limit: MAX_LIMIT,
                start_date: getIsoDate(dates.start),
                end_date: getIsoDate(dates.end),
                portal_status: driversFilters.status ?? undefined,
                sorting_field: newSortingField as EngagementDataDriverKey,
                sorting_direction: newSortingDirection,
                ...(tableSearchValue && {search: tableSearchValue}),
            });
        },
        [engagementFetch, selectedPeriod, tableSearchValue, driversFilters.status],
    );

    useEffect(() => {
        if (fleet) {
            setPageNum(0);
            getDashboardData({
                newPageNum: 0,
                newSortingField: sorting.current?.field,
                newSortingDirection: sorting.current?.direction,
            });
        }
    }, [getDashboardData, fleet]);

    useEffect(() => {
        if (fleet) {
            if (driverRegistrationFetch) {
                driverRegistrationFetch({});
            }
        }
    }, [fleet, driverRegistrationFetch]);

    useEffect(() => {
        if (fleet) {
            if (driverInvitationFetch) {
                driverInvitationFetch({});
            }
        }
    }, [fleet, driverInvitationFetch]);

    useEffect(() => {
        sorting.current = engagementData?.sorted_by;
    }, [engagementData?.sorted_by]);

    const onPageChange = useCallback(
        async (page: number) => {
            if (page === pageNum) {
                return;
            }
            getDashboardData({
                newPageNum: page,
                newSortingField: sorting.current?.field,
                newSortingDirection: sorting.current?.direction,
            });
            setPageNum(page);
        },
        [getDashboardData, pageNum],
    );

    const onPeriodChange = useCallback(
        (newPeriod: ApiPeriod) => {
            setSelectedPeriod(newPeriod);
            const dates = apiPeriodToDates(newPeriod);

            trackEvent(EventName.EngagementDashboardPeriodPicked, {
                periodName: getPeriodName(newPeriod),
                period: getDatesStrFromDates(dates.start, dates.end),
            });
        },
        [setSelectedPeriod, trackEvent],
    );

    const onSortingChange = useCallback(
        (newSorting?: ApiDriverTableSortedBy) => {
            sorting.current = newSorting;
            setPageNum(0);
            getDashboardData({
                newPageNum: 0,
                newSortingField: newSorting?.field,
                newSortingDirection: newSorting?.direction,
            });
        },
        [getDashboardData],
    );

    const onLeadAccepted = useCallback(() => {
        setIsLoadingRegistrations(true);
        if (driverRegistrationFetch) {
            setTimeout(() => {
                driverRegistrationFetch({}).then(() => setIsLoadingRegistrations(false));
            }, 3000);
        }
    }, [driverRegistrationFetch]);

    if (engagementStatus === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    return (
        <Page>
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
                    <ResponsiveHeader
                        type={ResponsiveHeaderType.MainPage}
                        text={i18n("auth.app.fleet.drivers.title")}
                    />
                    <AddDriver />
                </div>
                <TabSelector />
            </div>
            {activeTab === DriverTabs.Drivers && (
                <DriversTab
                    columns={engagementData?.columns}
                    sortedBy={sorting.current ?? engagementData?.sorted_by}
                    selectedPeriod={selectedPeriod}
                    onPeriodChange={onPeriodChange}
                    onSortingChange={onSortingChange}
                    driverCount={driverCount}
                    currentPage={pageNum}
                    pageSize={MAX_LIMIT}
                    onPageChange={onPageChange}
                    onSearchChange={setTableSearchValue}
                    isLoading={isLoading}
                    filters={driversFilters}
                    setFilters={setDriversFilters}
                />
            )}
            {activeTab === DriverTabs.Registrations && (
                <>
                    <LoadingSpinner show={isLoadingRegistrations} showLoadingText parentClassName="mt-9" />
                    {!isLoadingRegistrations && (
                        <>
                            <DriverRegistrationsTab
                                onLeadAccepted={onLeadAccepted}
                                driverRegistrations={driverApplications}
                            />
                        </>
                    )}
                </>
            )}
            {activeTab === DriverTabs.ProposedCandidates && <Matches onLeadAccepted={onLeadAccepted} />}
        </Page>
    );
};

const DriversListPageWithProvider = () => (
    <DriverDialogProvider>
        <DriversListPage />
    </DriverDialogProvider>
);

export default DriversListPageWithProvider;
