import {useCallback, useEffect, useRef, useState} from "react";

// Similarly is done in Kalep (accordion) table header tooltips
export const useTooltipClick = ({delay}: {delay: number} = {delay: 2000}) => {
    const [isOpen, setIsOpen] = useState(false);
    const timerRef = useRef<number | undefined>(undefined);

    useEffect(
        () => () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        },
        [],
    );

    const onClick = useCallback(
        (event: React.MouseEvent | React.KeyboardEvent) => {
            event.stopPropagation();

            setIsOpen((flag) => !flag);

            if (timerRef) {
                clearTimeout(timerRef.current);
            }

            timerRef.current = setTimeout(() => {
                setIsOpen(false);
            }, delay) as unknown as number;
        },
        [delay],
    );

    return {isOpen, onClick, setIsOpen};
};
