import {useEffect, useRef, useState} from "react";

import {FleetOwnerPortalService, FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip} from "@bolteu/kalep-react";

import {useGeneratedFileRow} from "../../hooks/useGeneratedFileRow";

interface Props {
    row: FleetOwnerReportingService.CompanyReportWithUrl;
    drivers: FleetOwnerPortalService.Driver[];
}

const FileRow = ({row, drivers}: Props) => {
    const titleRef = useRef<HTMLParagraphElement>(null);
    const [isTitleEllipsised, setIsTitleEllipsised] = useState(false);
    const {title, description, icon: Icon} = useGeneratedFileRow(row, drivers);

    useEffect(() => {
        const ref = titleRef.current;
        if (!ref?.offsetWidth || !ref.scrollWidth) {
            return;
        }
        setIsTitleEllipsised(ref.offsetWidth < ref.scrollWidth);
    }, [titleRef]);

    let titleElement = (
        <p
            ref={titleRef}
            className="truncate ltr:[direction:rtl] ltr:[text-align:left;] rtl:[direction:ltr] rtl:[text-align:right;]"
        >
            {title}
        </p>
    );
    if (isTitleEllipsised) {
        titleElement = (
            <Tooltip content={title} placement="top">
                {titleElement}
            </Tooltip>
        );
    }

    return (
        <div className="border-separator flex items-center justify-between gap-3 border-b">
            <div className="flex w-[280px] flex-col py-3">
                {titleElement}
                <span className="text-secondary text-sm">{description}</span>
            </div>
            {Icon}
        </div>
    );
};

export default FileRow;
