import {useCallback, useMemo} from "react";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Button, Chip, IconView, Island, IslandProps, Typography} from "@bolteu/kalep-react";
import {ChipAppearance} from "@bolteu/kalep-react/build/components/Chip/Chip.types";
import {Check} from "@bolteu/kalep-react-icons";
import {KalepIconElement} from "@bolteu/kalep-react-icons/dist/types";

export type OnboardingStepState = FleetOwnerRegistrationService.SectionStatus | "NONE";

export type Section = "details" | "documents" | "vehicles" | "drivers" | "full_experience";

export type OnboardingStepFooterType = "CHIP" | "BUTTON";

export interface OnboardingStepFooter {
    text: string;
    type: OnboardingStepFooterType;
    onActionClick?: () => void;
}

export interface OnboardingStepError {
    title: string;
    description: string;
}

export interface OnboardingStepProps {
    section: Section;
    title: string;
    subTitle: string;
    icon: KalepIconElement;
    state: OnboardingStepState;
    error?: OnboardingStepError;
    footer?: OnboardingStepFooter;
    elevation?: IslandProps["elevation"];
    hideLine?: boolean;
}

export interface FooterProps {
    footer: OnboardingStepFooter;
    state: OnboardingStepState;
}

const Footer = ({footer, state}: FooterProps): JSX.Element => {
    const {text, type, onActionClick} = footer;

    const chipAppearance = useMemo((): ChipAppearance | undefined => {
        if (state === FleetOwnerRegistrationService.SectionStatus.DECLINED) {
            return "danger";
        }
        if (state === FleetOwnerRegistrationService.SectionStatus.IN_REVIEW) {
            return "promo";
        }
        if (state === FleetOwnerRegistrationService.SectionStatus.COMPLETE) {
            return "action";
        }
        return undefined;
    }, [state]);

    const renderFooter = useCallback(() => {
        switch (type) {
            case "CHIP":
                return <Chip label={text} appearance={chipAppearance} size="sm" />;
            case "BUTTON":
                return (
                    <Button variant="secondary" size="sm" onClick={onActionClick}>
                        {text}
                    </Button>
                );
            default:
                return null;
        }
    }, [chipAppearance, onActionClick, text, type]);

    return <div>{renderFooter()}</div>;
};

export interface IconProps {
    icon: KalepIconElement;
    state: OnboardingStepState;
}

const Icon = ({icon, state}: IconProps): JSX.Element => {
    if (state === FleetOwnerRegistrationService.SectionStatus.COMPLETE) {
        return (
            <div>
                <IconView
                    icon={<Check />}
                    size="sm"
                    backgroundColor="bg-action-secondary"
                    color="text-action-primary"
                />
            </div>
        );
    }

    return (
        <div>
            <IconView icon={icon} size="sm" />
        </div>
    );
};

const OnboardingStep = ({
    section,
    title,
    subTitle,
    footer,
    error,
    elevation,
    icon,
    state,
    hideLine,
}: OnboardingStepProps) => (
    <div className="flex flex-row gap-6">
        <div className="flex flex-col">
            <Icon icon={icon} state={state} />
            {!hideLine && <div className="border-separator relative left-1/2 h-full border-l-[2px]" />}
        </div>
        <div className="w-full pb-6" key={section}>
            <Island elevation={elevation}>
                <div className="flex flex-col gap-4">
                    <Typography variant="title-secondary" as="h5" fontSize="text-xl" fontWeight="semibold">
                        {title}
                    </Typography>
                    <Typography variant="body-primary" fontSize="text-sm">
                        {subTitle}
                    </Typography>
                    {error && (
                        <div>
                            <Alert severity="error" title={error.title}>
                                {error.description}
                            </Alert>
                        </div>
                    )}
                    {footer && <Footer footer={footer} state={state} />}
                </div>
            </Island>
        </div>
    </div>
);

export {OnboardingStep};
