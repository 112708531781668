import {ChangeEventHandler, useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {Grid, TextField, Typography} from "@bolteu/kalep-react";

import {AreaCodeSelector} from "../areaCodeSelector";

interface Props {
    title?: string;
    phoneNumber: string;
    onAreaCodeChange: (areaCode: string) => void;
    onPhoneNumberChange: (phoneNumber: string) => void;
    errorMessage: string | null;
    defaultAreaCodeCountry?: string;
}

export const PhoneNumber = ({
    title,
    phoneNumber,
    onAreaCodeChange,
    onPhoneNumberChange,
    errorMessage,
    defaultAreaCodeCountry,
}: Props) => {
    const {i18n} = useI18n();
    const isMobile = useTailwindViewport() === "sm";

    const onPhoneAreaCodeChange = useCallback(
        (areaCode: string) => {
            onAreaCodeChange(areaCode);
        },
        [onAreaCodeChange],
    );

    const onPhoneChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            onPhoneNumberChange(event.target.value);
        },
        [onPhoneNumberChange],
    );

    const numberInputRemoveArrowsClasses =
        "[&>input]:[-moz-appearance:textfield] [&>input::-webkit-outer-spin-button]:appearance-none [&>input::-webkit-inner-spin-button]:appearance-none";

    return (
        <div className="flex w-full flex-col gap-2">
            {title && (
                <Typography fontWeight="semibold" fontSize="text-sm">
                    {title}
                </Typography>
            )}
            <Grid columns={12}>
                <Grid.Item colSpan={isMobile ? 5 : 4}>
                    <AreaCodeSelector
                        handlePhoneAreaCode={onPhoneAreaCodeChange}
                        defaultValue={defaultAreaCodeCountry}
                    />
                </Grid.Item>
                <Grid.Item colSpan={isMobile ? 7 : 8}>
                    <TextField
                        value={phoneNumber}
                        onChange={onPhoneChange}
                        type="number"
                        label=""
                        placeholder={i18n("login.phone_label")}
                        autoComplete="on"
                        fullWidth
                        error={Boolean(errorMessage)}
                        overrideClassName={numberInputRemoveArrowsClasses}
                    />
                </Grid.Item>
            </Grid>
            {!!errorMessage && (
                <Typography variant="body-primary" fontSize="text-sm">
                    <div className="text-danger-primary">{errorMessage}</div>
                </Typography>
            )}
        </div>
    );
};
