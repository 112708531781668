import {FC, useMemo} from "react";

import {useI18n} from "common/hooks/useI18n";
import {ApiPeriod} from "common/services/period";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Skeleton} from "@bolteu/kalep-react";

import {GRAPH_INCOME_SECTIONS_COLORS, GRAPH_OUTCOME_SECTIONS_COLORS} from "../../constants";
import {getGraphsData} from "./getGraphsData";
import NetEarningsTable from "./NetEarningsTable";
import NetEarningsTableHeader from "./NetEarningsTableHeader";
import TransactionCardGraph from "./TransactionCardGraph";
import TransactionCardLegend from "./TransactionCardLegend";
import TransactionsCardHeader from "./TransactionsCardHeader";
import Balance = FleetOwnerPortalService.Balance;

interface TransactionsCardProps {
    period: ApiPeriod;
    data: Balance | null;
    isLoading: boolean;
}

const TransactionsCard: FC<TransactionsCardProps> = ({period, data, isLoading}) => {
    const {i18n} = useI18n();
    const viewport = useTailwindViewport();

    const isMobileView = viewport === "sm";

    const Wrapper = ({children}: {children: React.ReactNode}) => (
        <div className="md:border-neutral-secondary flex flex-col gap-3 md:rounded-lg md:border md:p-4">{children}</div>
    );

    const graphsData = useMemo(() => {
        if (!data) {
            return null;
        }
        return getGraphsData(i18n, data);
    }, [i18n, data]);

    if (isLoading || !data || !graphsData) {
        return (
            <Wrapper>
                <TransactionsCardHeader isIncomeZero={false} isOutcomeZero={false} period={period} />
                <Skeleton variant="rounded" width={280} height={8} />
                <Skeleton variant="rounded" width={143} height={8} />
            </Wrapper>
        );
    }

    const isIncomeZero = graphsData.income.total === 0;
    const isOutcomeZero = graphsData.outcome.total === 0;

    if (isIncomeZero && isOutcomeZero) {
        return null;
    }

    return (
        <Wrapper>
            <TransactionsCardHeader isIncomeZero={isIncomeZero} isOutcomeZero={isOutcomeZero} period={period} />
            <div className="flex flex-col gap-1">
                {!isIncomeZero && (
                    <TransactionCardGraph
                        graphData={graphsData.income}
                        currencySymbol={data.currency}
                        colorClasses={GRAPH_INCOME_SECTIONS_COLORS}
                        isMobileView={isMobileView}
                    />
                )}
                {!isOutcomeZero && (
                    <TransactionCardGraph
                        graphData={graphsData.outcome}
                        currencySymbol={data.currency}
                        colorClasses={GRAPH_OUTCOME_SECTIONS_COLORS}
                        isMobileView={isMobileView}
                    />
                )}
            </div>
            <div className="border-separator my-3 border-t" />
            <div className="flex flex-col gap-3">
                {!isIncomeZero && (
                    <TransactionCardLegend
                        sections={graphsData.income.sections}
                        colorClasses={GRAPH_INCOME_SECTIONS_COLORS}
                        isMobileView={isMobileView}
                        currencySymbol={data.currency}
                    />
                )}
                {!isOutcomeZero && (
                    <TransactionCardLegend
                        sections={graphsData.outcome.sections}
                        colorClasses={GRAPH_OUTCOME_SECTIONS_COLORS}
                        isMobileView={isMobileView}
                        currencySymbol={data.currency}
                    />
                )}
            </div>
            <div className="border-separator my-3 border-t" />
            <NetEarningsTableHeader period={period} />
            <NetEarningsTable data={graphsData} cashInHand={data.cash_in_hand} currency={data.currency} />
        </Wrapper>
    );
};

export default TransactionsCard;
