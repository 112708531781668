import {useCallback, useEffect, useRef, useState} from "react";

import CloseButton from "common/components/CloseButton";

import {Typography} from "@bolteu/kalep-react";

interface TooltipProps {
    title: string;
    content: string;
    children: React.ReactElement;
    active: boolean;
    onClose: () => void;
}

function DiscoveryToolTip({title, content, children, active, onClose}: TooltipProps) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(active);
    }, [active]);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const closeTooltip = useCallback(() => {
        setIsVisible(false);
        onClose();
    }, [setIsVisible, onClose]);

    const targetRect = wrapperRef.current?.getBoundingClientRect() ?? null;

    let tooltipStyles = {};

    if (targetRect) {
        tooltipStyles = {
            top: `${targetRect.bottom + window.scrollY + 15}px`,
            left: `${targetRect.left + (targetRect.width * 0.5 - 25)}px`,
            boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.2)",
            opacity: isVisible ? 1 : 0,
            visibility: isVisible ? "visible" : "collapse",
        };
    }

    return (
        <div ref={wrapperRef} className="w-full">
            {isVisible && (
                <div
                    className="bg-layer-floor-1 absolute max-w-[300px] rounded-md p-4 opacity-0 transition-all duration-300"
                    style={tooltipStyles}
                >
                    <div className="bg-layer-floor-1 absolute top-[-6px] left-[20px] h-3 w-3 rotate-45" />
                    <div className="flex flex-row justify-between">
                        <div className="mt-1">
                            <Typography variant="body-primary" fontWeight="semibold" fontSize="text-lg">
                                {title}
                            </Typography>
                        </div>
                        <CloseButton onClick={closeTooltip} light />
                    </div>
                    <div className="flex flex-col gap-3">
                        <Typography variant="body-primary" fontSize="text-sm">
                            {content}
                        </Typography>
                    </div>
                </div>
            )}
            {children}
        </div>
    );
}

export default DiscoveryToolTip;
