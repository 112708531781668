import {useCallback, useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {ApiContextProvider} from "common/services/apiProvider";
import {getUnixTime} from "date-fns";
import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {OrderRequestsContextProvider} from "../../OrderRequestsContextProvider";
import {CategoriesContextProvider} from "../CategoriesContextProvider";
import {AddressWithLatLng, CreateDrawerType, CreateOrderData, Step, Step1Data, Step2Data, Step3Data} from "../types";
import {getApiAddressFromAddress} from "../util";

import ValidationError = FleetPortalOrderService.ValidationError;

export interface CreateOrderDataWithPickupLatLng extends CreateOrderData {
    address: AddressWithLatLng;
}

interface StepData extends Step {
    data: CreateOrderData;
}
type ValidateStepRequest =
    | FleetPortalOrderService.RouteDetailsStep
    | FleetPortalOrderService.RideDetailsStep
    | FleetPortalOrderService.AssignmentDetailsStep;

export const useCreateOrderValidations = (createDrawerType: CreateDrawerType) => {
    const {i18n} = useI18n();
    const config = useContext(OrderRequestsContextProvider);
    const api = useContext(ApiContextProvider);
    const {observability} = useContext(ThirdPartyContextProvider);
    const {findRideOptions, resetApiError} = useContext(CategoriesContextProvider);

    const getValidateApiRequest = useCallback(
        ({stepNumber, data}: StepData): ValidateStepRequest | undefined => {
            switch (stepNumber) {
                case 0: {
                    const pickup = getApiAddressFromAddress(data.pickup);
                    if (!pickup) {
                        // Shouldnt happen as this should be checked before
                        throw Error("Address is missing lat or lng");
                    }
                    let dropoffs: FleetPortalOrderService.Address[] | undefined = [];
                    if (dropoffs.length === 1 && !dropoffs[0].lat && !dropoffs[0].lng) {
                        dropoffs = undefined;
                    } else {
                        dropoffs = data.dropoffs
                            .map((address) => getApiAddressFromAddress(address))
                            .filter((address) => address !== null);
                    }
                    const creationType =
                        createDrawerType === CreateDrawerType.SCHEDULE
                            ? FleetPortalOrderService.OrderCreationType.SCHEDULED
                            : FleetPortalOrderService.OrderCreationType.INSTANT;
                    const scheduledFor =
                        createDrawerType === CreateDrawerType.SCHEDULE ? getUnixTime(data.scheduled_for) : undefined;
                    return {
                        type: FleetPortalOrderService.OrderCreationStep.ROUTE_DETAILS,
                        creation_type: creationType,
                        scheduled_for: scheduledFor,
                        pickup,
                        dropoffs,
                    };
                }
                case 1: {
                    if (!data.category_id) {
                        // Should be validated in the validateStep2 function
                        return undefined;
                    }
                    const pickup = getApiAddressFromAddress(data.pickup);
                    if (!pickup) {
                        // Shouldnt happen as this should be checked before
                        throw Error("Address is missing lat or lng");
                    }
                    const manualPrice = data.manual_price ? Number(data.manual_price) : undefined;
                    const creationType =
                        createDrawerType === CreateDrawerType.SCHEDULE
                            ? FleetPortalOrderService.OrderCreationType.SCHEDULED
                            : FleetPortalOrderService.OrderCreationType.INSTANT;
                    const scheduledFor =
                        createDrawerType === CreateDrawerType.SCHEDULE ? getUnixTime(data.scheduled_for) : undefined;
                    return {
                        type: FleetPortalOrderService.OrderCreationStep.RIDE_DETAILS,
                        payment_method: data.payment_method,
                        category_id: data.category_id,
                        manual_price: data.is_manual_price_enabled ? manualPrice : undefined,
                        scheduled_for: scheduledFor,
                        creation_type: creationType,
                        pickup,
                    };
                }
                case 2: {
                    const isAutomaticAssignment =
                        data.assignment_type === FleetPortalOrderService.AssignmentType.AUTOMATIC;
                    return {
                        type: FleetPortalOrderService.OrderCreationStep.ASSIGNMENT_DETAILS,
                        driver_id: isAutomaticAssignment ? undefined : data.driver_id,
                        passenger_phone: data.phone_area_code.trim() + data.phone_number.trim(),
                        passenger_name: data.passenger_name.trim(),
                        comment: data.comment.trim() || undefined,
                    };
                }
                default:
                    return UnreachableCode.never(stepNumber, undefined);
            }
        },
        [createDrawerType],
    );

    const validateStepWithApi = useCallback(
        async (step: StepData): Promise<ValidationError[]> => {
            if (!api) {
                return [];
            }
            const request = getValidateApiRequest(step);
            if (!request) {
                // Request should always exist for steps
                return [];
            }
            try {
                const response = await api.fleetPortalOrder.validateOrderCreationStep(request);
                return (
                    response.validation_errors?.map((validationError) => {
                        let errorMessage = validationError.error;
                        if (validationError.property === "passenger_phone" && validationError.error === "invalid") {
                            errorMessage = i18n("api.error.validation.phone_number.invalid");
                        } else if (validationError.error === "invalid") {
                            errorMessage = i18n("api.error.validation.invalid");
                        } else if (validationError.error === "required") {
                            errorMessage = i18n("api.error.validation.required");
                        }
                        return {...validationError, error: errorMessage};
                    }) ?? []
                );
            } catch {
                observability.reportError(new Error("API to validate order creation step failed"));
                return [];
            }
        },
        [api, observability, getValidateApiRequest, i18n],
    );

    const validateManualPricing = useCallback(
        (
            isManualPriceEnabled: boolean,
            categorySelected: boolean,
            fieldManualPrice?: string,
            categoryUpfrontPrice?: number,
        ): ValidationError | null => {
            if (
                !isManualPriceEnabled ||
                fieldManualPrice === undefined ||
                (!categorySelected && !categoryUpfrontPrice)
            ) {
                return null;
            }
            const manualPrice = Number(fieldManualPrice);
            if (Number.isNaN(manualPrice)) {
                return {
                    property: "manual_price",
                    error: i18n("api.error.validation.invalid"),
                };
            }
            if (!categoryUpfrontPrice || Number.isNaN(categoryUpfrontPrice)) {
                return {
                    property: "manual_price",
                    error: i18n("auth.app.orders.scheduled_rides.create-dialog.manual_price_not_available"),
                };
            }
            const manualPricingConfig = config.getManualPricing();
            if (manualPrice < manualPricingConfig.min_base_price_multiplier * categoryUpfrontPrice) {
                return {
                    property: "manual_price",
                    error: i18n("auth.app.orders.scheduled_rides.create-dialog.price_too_low"),
                };
            }
            if (manualPrice > manualPricingConfig.max_base_price_multiplier * categoryUpfrontPrice) {
                return {
                    property: "manual_price",
                    error: i18n("auth.app.orders.scheduled_rides.create-dialog.price_too_high"),
                };
            }
            return null;
        },
        [config, i18n],
    );

    const validateStep1 = useCallback(
        (data: Step1Data) => {
            const errors: ValidationError[] = [];
            if (!data.pickup.address_name) {
                errors.push({
                    property: "pickup",
                    error: i18n("api.error.validation.required"),
                });
            }
            let destinationAddressesErrors: ValidationError[] | null = null;
            data.dropoffs.forEach((address) => {
                if (!address.address_name) {
                    if (!destinationAddressesErrors) {
                        destinationAddressesErrors = [
                            {
                                property: "dropoffs",
                                error: i18n("api.error.validation.required"),
                                children: [],
                            },
                        ];
                    }
                    destinationAddressesErrors[0].children?.push({
                        property: address.key,
                        error: i18n("api.error.validation.required"),
                    });
                }
            });
            if (destinationAddressesErrors && config.isDropoffAddressRequired) {
                errors.push(destinationAddressesErrors[0]);
            }

            return errors;
        },
        [config.isDropoffAddressRequired, i18n],
    );

    const validateStep2 = useCallback(
        (data: Step2Data) => {
            const errors: ValidationError[] = [];
            if (!data.category_id || !data.category_price_str) {
                errors.push({
                    property: "category_id",
                    error: i18n("api.error.validation.required"),
                });
            }
            const manualPriceValidation = validateManualPricing(
                data.is_manual_price_enabled,
                !!data.category_id,
                data.manual_price,
                data.category_upfront_price,
            );
            if (manualPriceValidation) {
                errors.push(manualPriceValidation);
            }

            return errors;
        },
        [i18n, validateManualPricing],
    );

    const validateStep3 = useCallback(
        (data: Step3Data) => {
            const errors: ValidationError[] = [];
            if (data.assignment_type === FleetPortalOrderService.AssignmentType.MANUAL && !data.driver_id) {
                errors.push({
                    property: "driver_id",
                    error: i18n("api.error.validation.required"),
                });
            }
            if (!data.phone_area_code || !data.phone_number) {
                errors.push({
                    property: "passenger_phone",
                    error: i18n("api.error.validation.required"),
                });
            }

            return errors;
        },
        [i18n],
    );

    const validateStep = useCallback(
        async (step: StepData): Promise<ValidationError[]> => {
            let errors: ValidationError[] = [];
            const {stepNumber} = step;
            switch (stepNumber) {
                case 0:
                    errors = validateStep1(step.data);
                    break;
                case 1:
                    errors = validateStep2(step.data);
                    break;
                case 2:
                    errors = validateStep3(step.data);
                    break;
                default:
                    UnreachableCode.never(stepNumber);
            }
            if (errors.length > 0) {
                return errors;
            }
            const apiErrors = await validateStepWithApi(step);
            if (apiErrors.length > 0) {
                resetApiError();
                return apiErrors;
            }
            if (stepNumber === 0) {
                if (!step.data.pickup.lat || !step.data.pickup.lng) {
                    // Validations above will catch this
                    return [];
                }
                const pickup = {...step.data.pickup, lat: step.data.pickup.lat, lng: step.data.pickup.lng};
                const result = await findRideOptions({...step.data, pickup}, createDrawerType);
                if (result?.type === "error") {
                    return result.validation_errors;
                }
            }

            return [];
        },
        [
            createDrawerType,
            findRideOptions,
            resetApiError,
            validateStep1,
            validateStep2,
            validateStep3,
            validateStepWithApi,
        ],
    );

    return {validateStep, validateManualPricing};
};
