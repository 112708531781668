import {useCallback, useEffect} from "react";

import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {TextButton} from "@fleet/common/components/TextButton";

import {Button} from "@bolteu/kalep-react";

const fetchFunction = (api: Api) => api.leadToFleet.leadmatcherFleetOptIn();

interface Props {
    showInfoPage: () => void;
    onOptedIn: () => void;
}

export const OptIn = ({showInfoPage, onOptedIn}: Props) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const {fetch, status, error} = useFetch(fetchFunction);

    const optIn = useCallback(async () => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            onOptedIn();
            trackEvent(EventName.L2FOptInClicked);
        } else if (status === FetchStatus.Error) {
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.OptIn,
                errorMessage: error.message,
            });
        }
    }, [status, error.message, trackEvent, onOptedIn]);

    return (
        <>
            <div className="text-secondary max-w-3xl">
                {i18n("auth.app.fleet.opt_in.info")}{" "}
                <TextButton onClick={showInfoPage} text={i18n("auth.app.fleet.driver-registrations.learn-more")} />
            </div>
            <Button overrideClassName="w-[160px]" loading={status === FetchStatus.Loading} onClick={optIn} size="lg">
                {i18n("auth.app.fleet.opt_in.opt_in")}
            </Button>
        </>
    );
};
