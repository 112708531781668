import {useCallback, useMemo, useRef} from "react";

import {DraggableListItem} from "common/components/dragAndDrop/DraggableListItem";
import {DragPayload} from "common/components/dragAndDrop/types";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton, Skeleton} from "@bolteu/kalep-react";
import {AddressDestination, AddressPickup, Dragging, PinDestination} from "@bolteu/kalep-react-icons";

import {Address, AddressType} from "../../types";
import {AddressSuggestion} from "./AddressSuggestion";

interface Props {
    isPickup?: boolean;
    address: Address;
    isLastAddressField?: boolean;
    hasMultipleStops: boolean;
    onChange: (address: Address, deleteAddress?: boolean) => void;
    validationError?: string;
    geoLocation: GeolocationPosition | null;
    defaultSuggestions: FleetPortalOrderService.RidehailingSuggestionsResponse | null;
}

export const Stop = ({
    isPickup,
    address,
    isLastAddressField,
    onChange,
    validationError,
    geoLocation,
    hasMultipleStops,
    defaultSuggestions,
}: Props) => {
    const dragHandleRef = useRef(null);

    const getDragItemData = useCallback(() => address, [address]);

    const isDragItemDataSame = useCallback(
        (draggedItem: DragPayload<Address>) => draggedItem.data.key === address.key,
        [address.key],
    );

    const stub = useCallback(() => {}, []);

    const dragPreviewElement = useMemo(
        () => (
            <div className="px-5">
                <Skeleton variant="rounded" height={44} width={280} />
            </div>
        ),
        [],
    );

    let Icon = AddressPickup;
    let addressType = AddressType.Pickup;
    if (!isPickup) {
        Icon = isLastAddressField ? PinDestination : AddressDestination;
        addressType = isLastAddressField ? AddressType.Dropoff : AddressType.Stop;
    }

    const dragButtonMargins = validationError ? "mb-10" : "mb-3";
    const dragButtonDisplay = hasMultipleStops ? "" : "hidden";

    const addressFieldPadding = !isLastAddressField ? "pb-3" : "";

    return (
        <div className="flex gap-3">
            <div className="pt-[40px]">
                <Icon className="mb-[5px]" />
                {!isLastAddressField && (
                    <div className="border-promo-secondary relative left-[calc(50%-1px)] h-[calc(100%+6px)] border-l-[2px]" />
                )}
            </div>
            <DraggableListItem
                getItemData={getDragItemData}
                isItemDataSame={isDragItemDataSame}
                className={`flex w-full items-end gap-2 ${addressFieldPadding}`}
                dragPreviewElement={dragPreviewElement}
                dragHandleRef={dragHandleRef}
            >
                <div className="w-full">
                    <AddressSuggestion
                        type={addressType}
                        address={address}
                        onChange={onChange}
                        validationError={validationError}
                        geoLocation={geoLocation}
                        isDeletable={hasMultipleStops && addressType !== AddressType.Pickup}
                        defaultSuggestions={defaultSuggestions}
                    />
                </div>
                {/* Drag button needs to be in DOM always else when this button is shown conditionally dragging wont work */}
                <GhostButton
                    aria-label="Drag stop"
                    overrideClassName={`!cursor-grab ${dragButtonMargins} ${dragButtonDisplay}`}
                    onClick={stub}
                    as="button"
                    ref={dragHandleRef}
                >
                    <Dragging />
                </GhostButton>
            </DraggableListItem>
        </div>
    );
};
