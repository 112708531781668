import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";

import {TabName} from "..";

export function useTabSelectorOptions(): Array<Tab<TabName>> {
    const {i18n} = useI18n();

    const tabs: Array<Tab<TabName>> = [
        {
            id: TabName.ActiveAndUpcoming,
            title: i18n("auth.app.fleet.campaigns.tabs.active_and_upcoming"),
            trackingEventTabName: TabNameEvent.CampaignsListPageActiveAndUpcoming,
        },
        {
            id: TabName.Finished,
            title: i18n("auth.app.fleet.campaigns.tabs.finished"),
            trackingEventTabName: TabNameEvent.CampaignsListPageFinished,
        },
    ];

    return tabs;
}
