import {useCallback, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {restrictNumberInputToTime, TimeType} from "common/util/timeUtil";
import {v4} from "uuid";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormChange, FormState, useControlledForm} from "@bolteu/kalep-form-react";
import {Alert, Dialog, Grid, Typography} from "@bolteu/kalep-react";

import {BreakRowsComponent, isBreakFilled, ShiftBreakState} from "./BreakRows";

import BreakType = FleetShiftManagementService.BreakType;

export enum ShiftFormType {
    EDIT = "edit",
    ADD = "add",
}

export interface Props {
    type: ShiftFormType;
    closeDialog: () => void;
    onSubmit: (shift: FleetShiftManagementService.ShiftAttributes) => void;
    initialState?: ShiftFormState;
    isLoading: boolean;
}

export interface ShiftFormState {
    shiftName: string;
    shiftStartHour: string;
    shiftStartMinute: string;
    shiftEndHour: string;
    shiftEndMinute: string;
    breaks: ShiftBreakState[];
}

const DEFAULT_INITIAL_STATE: ShiftFormState = {
    shiftName: "",
    shiftStartHour: "",
    shiftStartMinute: "",
    shiftEndHour: "",
    shiftEndMinute: "",
    breaks: [{breakStartHour: "", breakStartMinute: "", breakDuration: "", key: v4()}],
};

const formatTime = (hours: string, minutes: string) => `${hours}:${minutes}`;

const isShiftFormValid = (formState: Partial<ShiftFormState>): formState is ShiftFormState =>
    Boolean(
        formState.shiftName &&
            formState.shiftStartHour &&
            formState.shiftStartMinute &&
            formState.shiftEndHour &&
            formState.shiftEndMinute &&
            formState.breaks?.every(isBreakFilled),
    );

export const ShiftActionDialog = ({
    type,
    closeDialog,
    onSubmit,
    isLoading,
    initialState = DEFAULT_INITIAL_STATE,
}: Props) => {
    const {i18n} = useI18n();
    const [shiftFormState, setShiftFormState] = useState(initialState);
    const isMobileView = useTailwindViewport() === "sm";

    const onFormChange = useCallback(
        (change: FormChange<Partial<ShiftFormState>>) => {
            let newValue = change.event.value;

            if (typeof change.event.value === "string" && change.event.name.endsWith("Hour")) {
                newValue = restrictNumberInputToTime(change.event.value, TimeType.HOUR);
            } else if (typeof change.event.value === "string" && change.event.name.endsWith("Minute")) {
                newValue = restrictNumberInputToTime(change.event.value, TimeType.MINUTE);
            }

            setShiftFormState((prev) => ({...prev, [change.event.name]: newValue}));
        },
        [setShiftFormState],
    );

    const onSubmitWrapper = useCallback(
        async (formState: FormState<Partial<ShiftFormState>>) => {
            if (isShiftFormValid(formState.data)) {
                const formattedBreaks = formState.data.breaks.filter(isBreakFilled).map((breakDetail) => ({
                    type: BreakType.START_DURATION,
                    start_time: formatTime(breakDetail.breakStartHour, breakDetail.breakStartMinute),
                    duration_min: Number(breakDetail.breakDuration),
                }));
                onSubmit({
                    name: formState.data.shiftName,
                    start_time: formatTime(formState.data.shiftStartHour, formState.data.shiftStartMinute),
                    end_time: formatTime(formState.data.shiftEndHour, formState.data.shiftEndMinute),
                    config: {
                        capacity: null,
                        breaks: formattedBreaks,
                    },
                });
            }
        },
        [onSubmit],
    );

    const {Form, TextField, Button, BreakRows} = useControlledForm({BreakRows: BreakRowsComponent});
    const isEditForm = type === ShiftFormType.EDIT;
    const title = isEditForm
        ? i18n("auth.app.fleet.shifts.edit_shift.title")
        : i18n("auth.app.fleet.shifts.add_shift.title");

    return (
        <Form value={shiftFormState} onChange={onFormChange} onSubmit={onSubmitWrapper}>
            <ResponsiveDialog title={title} isOpen onRequestClose={closeDialog}>
                {isEditForm && (
                    <Alert severity="info" overrideClassName="mb-4">
                        <Typography fontWeight="semibold">
                            {i18n("auth.app.fleet.shifts.edit_shift.info_alert")}
                        </Typography>
                    </Alert>
                )}
                <Dialog.Content>
                    <Grid columns={12}>
                        <Grid.Item colSpan={12}>
                            <TextField
                                name="shiftName"
                                type="text"
                                label={i18n("common.name")}
                                placeholder={i18n("common.name")}
                                required
                                fullWidth
                            />
                        </Grid.Item>
                        <Grid.Item colSpan={6}>
                            <div className="flex h-full items-end gap-1 pb-2">
                                <TextField
                                    name="shiftStartHour"
                                    type="number"
                                    max={23}
                                    min={0}
                                    label={i18n("auth.app.fleet.shifts.shift_form.shift_start")}
                                    placeholder={i18n("common.hour_placeholder")}
                                    required
                                    fullWidth
                                />
                                <div className="mb-3">:</div>
                                <TextField
                                    name="shiftStartMinute"
                                    type="number"
                                    label=""
                                    placeholder={i18n("common.minute_placeholder")}
                                    required
                                    fullWidth
                                />
                            </div>
                        </Grid.Item>
                        <Grid.Item colSpan={6}>
                            <div className="flex h-full items-end gap-1 pb-2">
                                <TextField
                                    name="shiftEndHour"
                                    type="number"
                                    label={i18n("auth.app.fleet.shifts.shift_form.shift_end")}
                                    placeholder={i18n("common.hour_placeholder")}
                                    overrideClassName="min-w-[90px] max-w-[90px] w-[90px]"
                                    required
                                    fullWidth
                                />
                                <div className="mb-3">:</div>
                                <TextField
                                    name="shiftEndMinute"
                                    type="number"
                                    label=""
                                    placeholder={i18n("common.minute_placeholder")}
                                    required
                                    fullWidth
                                />
                            </div>
                        </Grid.Item>
                        <Grid.Item colSpan={12}>
                            <hr className="border-separator pb-2" />
                        </Grid.Item>
                        <BreakRows name="breaks" initialBreaks={initialState.breaks} />
                    </Grid>
                </Dialog.Content>
                <Dialog.Footer>
                    <Button submit loading={isLoading} fullWidth={isMobileView}>
                        {i18n("common.submit")}
                    </Button>
                </Dialog.Footer>
            </ResponsiveDialog>
        </Form>
    );
};
