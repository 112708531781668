import {useCallback, useEffect, useMemo, useState} from "react";

import {EventName} from "common/constants/events";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {debounce} from "lodash-es";

import {FleetCarService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import MultiSelectListview, {
    MultiSelectListviewItemKey,
} from "../../../../common/components/listView/MultiSelectListview";
import VehicleAccessBaseDialog from "../components/VehicleAccessBaseDialog";
import DriverItem from "../DriverItem";
import {useVehicleDriverAccesses} from "./useVehicleDriverAccesses";

const NUM_RECORDS_PER_PAGE = 20;

interface ManageDriversDialogProps {
    onRequestClose: () => void;
    vehicleId: number;
}

const updateDriversFn = (api: Api, body: FleetCarService.CarUpdateAssignedDriversRequest) =>
    api.fleetCar.carUpdateAssignedDrivers(body);

export default function ManageDriversDialog({onRequestClose, vehicleId}: ManageDriversDialogProps) {
    const {i18n} = useI18n();

    const [availableDrivers, setAvailableDrivers] = useState<FleetCarService.CarDriver[]>([]);
    const [addedDriverIds, setAddedDriverIds] = useState<number[]>([]);
    const [removedDriverIds, setRemovedDriverIds] = useState<number[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [currentPage, setCurrentPage] = useState(1);

    const {driversData, driverIdsWithAccess, driversWithAccess, driversWithNoAccess} = useVehicleDriverAccesses(
        vehicleId,
        currentPage,
        20,
        searchQuery,
    );
    const {trackEvent} = useTracking();

    const {fetch: updateVehicleDriverAsses} = useFetch(updateDriversFn);

    useEffect(() => {
        if (driversData) {
            setAvailableDrivers([...availableDrivers, ...driversWithNoAccess]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps -- We need to append in this set scenario and not replace
    }, [driversWithNoAccess]);

    const debouncedSearch = useMemo(
        () =>
            debounce((query: string) => {
                setSearchQuery(query);
            }, 500),
        [],
    );

    const debouncedCurrentPageSet = useMemo(
        () => debounce((currentPageNum: number) => setCurrentPage(currentPageNum), 200),
        [],
    );

    const onSearchChange = useCallback(
        (query: string) => {
            setCurrentPage(1);
            setAvailableDrivers([]);
            debouncedSearch(query);
            trackEvent(EventName.VehicleAssignmentDriversSearched);
        },
        [debouncedSearch, trackEvent],
    );

    const onItemSelected = useCallback(
        (driverId: MultiSelectListviewItemKey) => {
            const isPresentInAssignedDrivers = driverIdsWithAccess.includes(driverId as number);
            if (!isPresentInAssignedDrivers) {
                setAddedDriverIds([...addedDriverIds, driverId as number]);
            }

            setRemovedDriverIds(removedDriverIds.filter((id) => id !== driverId));
        },
        [setAddedDriverIds, setRemovedDriverIds, addedDriverIds, removedDriverIds, driverIdsWithAccess],
    );
    const onItemDeselected = useCallback(
        (driverId: MultiSelectListviewItemKey) => {
            const isAddedInThisSession = addedDriverIds.includes(driverId as number);

            if (isAddedInThisSession) {
                setAddedDriverIds(addedDriverIds.filter((id) => id !== driverId));
                return;
            }

            setRemovedDriverIds([...removedDriverIds, driverId as number]);
        },
        [setRemovedDriverIds, setAddedDriverIds, removedDriverIds, addedDriverIds],
    );

    const onSubmitted = useCallback(() => {
        const addedDriverStatuses = availableDrivers
            .filter((driver) => addedDriverIds.includes(driver.driver_id))
            .reduce(
                (acc, driver) => {
                    if (driver.status === FleetCarService.EntityPortalStatus.Blocked) {
                        return {...acc, blocked: acc.blocked + 1};
                    }
                    if (driver.status === FleetCarService.EntityPortalStatus.Deactivated) {
                        return {...acc, deactivated: acc.deactivated + 1};
                    }
                    return {...acc, active: acc.active + 1};
                },
                {active: 0, deactivated: 0, blocked: 0},
            );

        trackEvent(EventName.VehicleAssigmentDriversAssigned, {
            numDriversAssigned: addedDriverIds.length,
            numDriversUnAssigned: removedDriverIds.length,
            numActiveDriversAssigned: addedDriverStatuses.active,
            numBlockedDriversAssigned: addedDriverStatuses.blocked,
            numDeactivatedDriversAssigned: addedDriverStatuses.deactivated,
        });

        if (updateVehicleDriverAsses) {
            updateVehicleDriverAsses({
                car_id: vehicleId,
                added_drivers: addedDriverIds,
                removed_drivers: removedDriverIds,
            }).then(onRequestClose);
        }
    }, [
        addedDriverIds,
        removedDriverIds,
        trackEvent,
        updateVehicleDriverAsses,
        vehicleId,
        onRequestClose,
        availableDrivers,
    ]);

    const onListEndReached = useCallback(() => {
        debouncedCurrentPageSet(currentPage + 1);
        trackEvent(EventName.VehicleAssignmentDriversEndOfListReached);
    }, [debouncedCurrentPageSet, currentPage, trackEvent]);

    const isMoreDataAvailable = useMemo(() => {
        if (!driversData) {
            return false;
        }

        return currentPage * NUM_RECORDS_PER_PAGE < driversData.total_rows;
    }, [driversData, currentPage]);

    return (
        <VehicleAccessBaseDialog
            title={i18n("auth.app.fleet.vehicle_assignment.vehicle.manage_drivers_dialog.manage_drivers_title")}
            subtitle={i18n("auth.app.fleet.vehicle_assignment.vehicle.manage_drivers_dialog.manage_drivers_subtitle")}
            isOpen
            onRequestClose={onRequestClose}
            onSubmitted={onSubmitted}
        >
            <div className="border-separator h-full overflow-hidden border-b-2 md:h-[500px]">
                <MultiSelectListview
                    initialSelectedItems={driverIdsWithAccess}
                    allItemsCount={driversData?.total_rows ?? 0}
                    searchPlaceholder={i18n("auth.app.fleet.vehicle_assignment.search.drivers_selected", {
                        num: driverIdsWithAccess.length + (addedDriverIds.length - removedDriverIds.length),
                    })}
                    isMoreDataAvailable={isMoreDataAvailable}
                    searchLabel={i18n("auth.app.sidebar.drivers")}
                    onSearch={onSearchChange}
                    onItemSelected={onItemSelected}
                    onItemDeselected={onItemDeselected}
                    onListEndReached={onListEndReached}
                >
                    {driversWithAccess?.map((driver) => (
                        <MultiSelectListview.Item
                            key={driver.driver_id}
                            value={driver.driver_id}
                            disabled={driver.access_to_car === FleetCarService.DriverCarAccess.HAS_ACCESS_TO_CAR_GROUP}
                        >
                            <DriverItem driver={driver} />
                        </MultiSelectListview.Item>
                    ))}
                    <MultiSelectListview.Divider />
                    {availableDrivers.map((driver) => (
                        <MultiSelectListview.Item key={driver.driver_id} value={driver.driver_id}>
                            <DriverItem driver={driver} />
                        </MultiSelectListview.Item>
                    ))}
                </MultiSelectListview>
            </div>
        </VehicleAccessBaseDialog>
    );
}
