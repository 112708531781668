import {useRef} from "react";

import {useTooltipClick} from "common/hooks/useTooltipClick";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip} from "@bolteu/kalep-react";
import {InfoCircleOutlined} from "@bolteu/kalep-react-icons";

import {CellRenderer, MobileSubContentRenderer} from "./cell-renderers";
import {CellProps, Transform} from "./types";

export function AccordionTableCell(
    cellProps: CellProps & {
        subColumns: FleetOwnerPortalService.ApiColumn[];
        transform?: Transform;
    },
) {
    const ref = useRef<HTMLElement | undefined>(undefined);
    function addRef() {
        return (r: HTMLElement | null) => {
            if (r) {
                ref.current = r;
            }
        };
    }

    const {isOpen, setIsOpen, onClick} = useTooltipClick({delay: 3000});

    const RenderCell = cellProps.transform ? cellProps.transform.renderCellOnMobile : CellRenderer;

    return (
        <>
            <div className="flex gap-y-1 gap-x-2">
                <span className="text-secondary m-0 flex items-center gap-2 text-sm font-normal" ref={addRef()}>
                    {cellProps.column.title}
                    {cellProps.column.tooltip && (
                        <Tooltip
                            placement="top"
                            isOpen={isOpen}
                            onOpenChange={setIsOpen}
                            boundaryElement={ref.current}
                            content={cellProps.column.tooltip}
                        >
                            <div>
                                <InfoCircleOutlined width={16} height={16} onClick={onClick} />
                            </div>
                        </Tooltip>
                    )}
                </span>
                <div className="border-separator min-w-[8px] flex-1 grow border-0 border-b" />
                <span>
                    <RenderCell {...cellProps} isMobileView />
                </span>
            </div>
            <MobileSubContentRenderer {...cellProps} />
        </>
    );
}
