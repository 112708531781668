import {useContext, useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Page} from "common/components/page/Page";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import ApiSettings from "features/account/pages/Settings/components/ApiSettings/ApiSettings";
import {FeaturesContextProvider} from "FeaturesProvider";

export const ApiIntegrationPage = () => {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);

    useEffect(() => {
        const pageTitle = i18n("auth.app.api_credentials.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    if (!features?.enterprise_api) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    return (
        <Page>
            <ApiSettings />
        </Page>
    );
};
