import {useEffect} from "react";

import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import DriversListPage from "features/companies/drivers/list-view";

export const DriversPage = () => {
    const {i18n} = useI18n();

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.drivers.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    return <DriversListPage />;
};
