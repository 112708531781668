import {FC, useCallback, useState} from "react";
import {useIntl} from "react-intl";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";
import {format} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Chip, Drawer, Island} from "@bolteu/kalep-react";
import {ChevronRight, Star} from "@bolteu/kalep-react-icons";

import {DRIVER_BLOCKED_BY_FLEET_OWNER_REASON} from "../../constants";
import BlockTranslationMarkdownOverride from "./BlockTranslationMarkdownOverride";

interface DriverTilesProps {
    driver: FleetOwnerPortalService.GetDriverResponse;
    blocks: FleetOwnerPortalService.DriverBlock[];
}

const DriverTiles: FC<DriverTilesProps> = ({driver, blocks}) => {
    const {i18n} = useI18n();
    const intl = useIntl();

    const unixMax = 4102437600;

    const [isBlockHistoryOpen, setIsBlockHistoryOpen] = useState(false);

    const closeBlockHistory = useCallback(() => {
        setIsBlockHistoryOpen(false);
    }, []);

    const openBlockHistory = useCallback(() => {
        setIsBlockHistoryOpen(true);
    }, []);

    const orderedBlocks = blocks.sort((a, b) =>
        !b.end && !a.end ? (b.start || 0) - (a.start || 0) : (b.end || unixMax) - (a.end || unixMax),
    );

    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];

    const formatDatesInterval = useCallback(
        (start: number | undefined, end: number | undefined) => {
            if (start && end) {
                return `${format(start * 1000, "PP", {locale: dateFnsLocale})} - ${format(end * 1000, "PP", {
                    locale: dateFnsLocale,
                })}`;
            }
            if (start) {
                return `${i18n("auth.app.orders.history.from")} ${format(start * 1000, "PP", {locale: dateFnsLocale})}`;
            }

            if (end) {
                return `${i18n("auth.app.orders.history.to")} ${format(end * 1000, "PP", {locale: dateFnsLocale})}`;
            }

            return "";
        },
        [dateFnsLocale, i18n],
    );

    const BlockOngoingChip = useCallback(
        () => <Chip label={i18n(`driver.ongoing_block`)} size="sm" appearance="danger" variant="filled" />,
        [i18n],
    );
    const DeactivatedChip = useCallback(
        () => <Chip label={i18n(`driver.status.deactivated`)} size="sm" appearance="neutral" variant="filled" />,
        [i18n],
    );

    return (
        <>
            <div className="flex flex-nowrap gap-4 overflow-x-auto [&>div]:py-[12px]">
                {driver.driver_score !== null && (
                    <Island>
                        <div className="min-w-[100px] max-w-[150px] flex-col">
                            <div className="text-secondary">
                                {i18n("auth.app.fleet.engagement-dashboard.header.driver_score")}
                            </div>
                            <div className="text-lg font-semibold">{driver.driver_score}%</div>
                        </div>
                    </Island>
                )}
                {driver.driver_rating && (
                    <Island>
                        <div className="min-w-[100px] max-w-[150px] flex-col">
                            <div className="text-secondary">{i18n("auth.app.orders.history.driver_rating")}</div>
                            <div className="flex items-center text-lg font-semibold">
                                <span className="mr-1">{Number(driver.driver_rating).toFixed(1)}</span>
                                <Star width={16} height={16} className="text-tertiary" />
                            </div>
                        </div>
                    </Island>
                )}
                {blocks.length > 0 ? (
                    <Island>
                        <div
                            className="min-w-[100px] max-w-[150px] flex-col hover:cursor-pointer"
                            onClick={openBlockHistory}
                            onKeyDown={openBlockHistory}
                            role="button"
                            tabIndex={0}
                        >
                            <div className="text-secondary flex flex-row justify-between">
                                <span>{i18n("driver.num_of_blocks")}</span>
                                <span>
                                    <ChevronRight width={20} height={20} />
                                </span>
                            </div>
                            <div className="font-semibold">{blocks.length}</div>
                        </div>
                    </Island>
                ) : null}
            </div>
            <ResponsiveDrawer
                isOpen={isBlockHistoryOpen}
                title={i18n("driver.blocking_history")}
                onRequestClose={closeBlockHistory}
            >
                <Drawer.Content>
                    <p className="mb-4">{i18n("driver.blocking_history_description")}</p>
                    <div className="divide-neutral-secondary flex flex-col divide-y">
                        {orderedBlocks.map((block) => (
                            <div
                                key={`${block.start || 0}-${block.end || 0}-${block.reason}`}
                                className="flex justify-between py-4"
                            >
                                <div>
                                    <BlockTranslationMarkdownOverride translation={block.translation} />
                                    <div className="text-secondary text-sm">
                                        {formatDatesInterval(block.start, block.end)}
                                    </div>
                                </div>
                                {(!block.end || block.end * 1000 > new Date().getTime()) && (
                                    <div className="ml-3 flex items-center justify-center">
                                        {block.reason === DRIVER_BLOCKED_BY_FLEET_OWNER_REASON ? (
                                            <DeactivatedChip />
                                        ) : (
                                            <BlockOngoingChip />
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </Drawer.Content>
                <Drawer.Footer>
                    <Button variant="secondary" fullWidth onClick={closeBlockHistory}>
                        {i18n("common.close")}
                    </Button>
                </Drawer.Footer>
            </ResponsiveDrawer>
        </>
    );
};

export default DriverTiles;
