import {useContext, useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {useI18n} from "common/hooks/useI18n";
import {useTopBarHeightTailwindConfig} from "common/hooks/useTopBarHeightTailwindConfig";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {ResponsiveLiveMap} from "features/companies/live-map/components/ResponsiveLiveMap";
import {FeaturesContextProvider} from "FeaturesProvider";

export const LiveMapPage = () => {
    const {i18n} = useI18n();
    const hasAccessToLiveMap = useContext(FeaturesContextProvider)?.live_map;
    const topbarConfig = useTopBarHeightTailwindConfig();

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.live-map.title_v2");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    if (!hasAccessToLiveMap) {
        return (
            <div
                className={`h-[calc(100dvh-${topbarConfig.topBarHeightPx}px)] w-full bg-[url("$assets/error-background.svg")] bg-repeat`}
            >
                <ErrorView
                    type={ErrorViewType.ServiceUnavailable}
                    customTitle={i18n("auth.app.fleet.live-map.not-available-title")}
                />
            </div>
        );
    }
    return <ResponsiveLiveMap />;
};
