import {FC, useContext, useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {FeaturesContextProvider} from "FeaturesProvider";

import {ResponsiveLiveMap} from "./ResponsiveLiveMap";
import styles from "./style.module.css";

const LiveMapPage: FC = () => {
    const {i18n} = useI18n();

    const hasAccessToLiveMap = useContext(FeaturesContextProvider)?.live_map;

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.live-map.title_v2");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    if (!hasAccessToLiveMap) {
        return (
            <div className={`h-full w-full ${styles.background}`}>
                <ErrorView
                    type={ErrorViewType.ServiceUnavailable}
                    customTitle={i18n("auth.app.fleet.live-map.not-available-title")}
                />
            </div>
        );
    }

    return <ResponsiveLiveMap />;
};

export default LiveMapPage;
