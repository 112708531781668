import {CountryCode} from "common/geo/constants/countries";

import {FleetOwnerAuthNoAuthService, FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import CompanyType = FleetOwnerAuthNoAuthService.CompanyIdentityType;
import ApiHoldingFleet = FleetOwnerService.HoldingFleet;
import CompanyStatus = FleetOwnerService.ExpandedCompanyStatus;

export interface Fleet extends Omit<FleetOwnerService.Company, "targeting"> {
    country: CountryCode;
}

export {CompanyType};

export type HoldingFleet = ApiHoldingFleet;
export type Company = HoldingFleet | Fleet;

// Bolt Rewards tier (bronze, silver, gold, platinum)
export type Tier = 0 | 1 | 2 | 3;

export function isHoldingFleet(company?: Company | null): company is HoldingFleet {
    if (!company) {
        return false;
    }
    return "sub_fleets" in company;
}

export function isFleet(company?: Company | null): company is Fleet {
    if (!company) {
        return false;
    }
    return !("sub_fleets" in company);
}

export function isActiveOrBlockedFleet(company?: Company | null): boolean {
    if (!isFleet(company)) {
        return false;
    }

    return [CompanyStatus.ACTIVE, CompanyStatus.BLOCKED].includes(company.status);
}

export function isBlockedFleet(company?: Company | null): boolean {
    if (!isFleet(company)) {
        return false;
    }

    return company.status === CompanyStatus.BLOCKED;
}

export function isDraftFleet(company?: Company | null): boolean {
    if (!isFleet(company)) {
        return false;
    }

    return [CompanyStatus.DRAFT, CompanyStatus.INCOMPLETE, CompanyStatus.IN_REVIEW].includes(company.status);
}
