import * as React from "react";

import config from "config";
import GoogleMapReact, {Props} from "google-map-react";

const GOOGLE_MAPS_JAVASCRIPT_GEO_TEAM_API_KEY = config.googleMapsApiKey;

export type GoogleMapsProps = Omit<Props, "bootstrapURLKeys">;

export const GoogleMaps: React.FC<GoogleMapsProps> = React.memo(
    ({...props}) => (
        <GoogleMapReact
            {...props}
            bootstrapURLKeys={{key: GOOGLE_MAPS_JAVASCRIPT_GEO_TEAM_API_KEY, libraries: "geometry,places"}}
        />
    ),
    // This component should rerender when the center or children changes.
    (prevProps, nextProps) => prevProps.center === nextProps.center && prevProps.children === nextProps.children,
);
