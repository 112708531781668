import {useMemo} from "react";

import {getDriverRegistrationStatus} from "common/constants/driverRegistrationStatus";
import {useI18n} from "common/hooks/useI18n";

import {Chip} from "@bolteu/kalep-react";
import {AccordionHeaders} from "@bolteu/kalep-table-react";

import {DriverRegistrationsTableData} from "../../components/DriverRegistrationsTable";

export function useAccordionTableColumns(): AccordionHeaders<DriverRegistrationsTableData> {
    const {i18n} = useI18n();

    return useMemo<AccordionHeaders<DriverRegistrationsTableData>>(
        () => ({
            status: {
                label: i18n("common.status"),
                direction: "horizontal",
                alignItems: "center",
                justify: "space-between",
                renderCell: (item: DriverRegistrationsTableData) => {
                    const statusData = getDriverRegistrationStatus(item.status);
                    return (
                        <Chip
                            label={i18n(`auth.app.fleet.driver-registrations.status.${statusData.label}`)}
                            size="sm"
                            appearance={statusData.color}
                            variant="outline"
                        />
                    );
                },
            },
            email: {label: i18n("common.email")},
            phone: {label: i18n("common.phone"), direction: "horizontal"},
        }),
        [i18n],
    );
}
