import {useCallback, useContext} from "react";
import {CircleFlag} from "react-circle-flags";
import {useIntl} from "react-intl";

import {ApiContextProvider} from "common/services/apiProvider";
import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";
import {SupportWidgetContextProvider} from "SupportWidgetProvider";
import {languages} from "@fleet/common/services/translations";

import {Button, Menu, MenuItem, MenuItemProps} from "@bolteu/kalep-react";
import {MenuItemClickEventHandler} from "@bolteu/kalep-react/build/components/Menu/Menu.types";
import {Globe} from "@bolteu/kalep-react-icons";

const LanguageSelector = () => {
    const api = useContext(ApiContextProvider);
    const language = useIntl().locale;
    const {updateSupportWidgetLocale} = useContext(SupportWidgetContextProvider);
    const {changeLocale} = useContext(ThirdPartyContextProvider);

    const languageSelectorButton = useCallback(() => {
        const name = languages.find((l) => l.key === language)?.name;
        return (
            <Button size="sm" variant="secondary" startIcon={<Globe />}>
                {name}
            </Button>
        );
    }, [language]);

    const handleClick = useCallback<MenuItemClickEventHandler>(
        async (e) => {
            const value = e.value as string;
            await changeLocale(value);
            updateSupportWidgetLocale(value);
            api?.fleetOwner.portalUpdateLanguage({language: value}).catch(() => {}); // fire & forget
        },
        [updateSupportWidgetLocale, changeLocale, api],
    );

    const renderStartSlot = useCallback(
        (region: string) => () => <CircleFlag countryCode={region} height={24} width={24} />,
        [],
    );

    const renderLabel = useCallback(
        (props: MenuItemProps) => {
            const selectedLabel = languages.find((l) => l.key === language)?.name;
            const selectedLanguageClass = selectedLabel === props.label ? "font-semibold" : "";
            return <div className={`${selectedLanguageClass} text-primary text-base`}>{props.label}</div>;
        },
        [language],
    );

    return (
        <Menu menuButton={languageSelectorButton} overrideClassName="w-72 max-h-[296px] overflow-auto">
            {languages.map((l) => (
                <MenuItem
                    key={l.key}
                    value={l.key}
                    onClick={handleClick}
                    label={l.name}
                    renderStartSlot={renderStartSlot(l.regions[0])}
                    renderLabel={renderLabel}
                />
            ))}
        </Menu>
    );
};

export default LanguageSelector;
