import {FC, RefObject, useCallback, useRef, useState} from "react";

import DriverWaiting from "assets/icons/live-map/marker-driver-car.svg?react";
import DriverWithOrder from "assets/icons/live-map/marker-selected-driver-busy.svg?react";
import {useTopBarHeightTailwindConfig} from "common/hooks/useTopBarHeightTailwindConfig";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import DriverBasicInfoContainer from "../../driver/DriverBasicInfoContainer";
import styles from "../../style.module.css";

export interface DriverMapMarkerProps {
    driver: FleetOwnerPortalService.DriverForFleetOwner;
    selectDriver: (driver: FleetOwnerPortalService.DriverForFleetOwner) => void;
    selectDriverPreview: (driverId: number | null) => void;
    driverDirection: number | undefined;
    isSelected: boolean;
    isSelectedForPreview: boolean;
    isMobileView: boolean;
}

const DRIVER_INFO_HEIGHT = 72;
const DRIVER_INFO_WIDTH = 312;
const DRIVER_LIST_WIDTH = 490;
const Y_DELTA = 20;
const X_DELTA = 10;

const getDriverInfoTranslations = (
    ref: RefObject<HTMLSpanElement>,
    isMobileView: boolean,
    hasOrder: boolean,
    navBarHeight: number,
) => {
    let yTranslate = hasOrder ? "-translate-y-[115%]" : "-translate-y-[120%]";
    let xTranslate = "-translate-x-1/2";
    const adjustedLeftXDelta = isMobileView ? X_DELTA : DRIVER_LIST_WIDTH;

    if (!ref.current) {
        return {yTranslate, xTranslate};
    }

    const bounds = ref.current.getBoundingClientRect();
    if (bounds.top < DRIVER_INFO_HEIGHT + navBarHeight + Y_DELTA) {
        yTranslate = hasOrder ? "translate-y-[15%]" : "translate-y-[20%]";
    }
    if (bounds.left < DRIVER_INFO_WIDTH / 4 + adjustedLeftXDelta) {
        xTranslate = "";
    } else if (bounds.left < DRIVER_INFO_WIDTH / 2 + adjustedLeftXDelta) {
        xTranslate = "-translate-x-1/4";
    } else if (Math.abs(bounds.right - window.innerWidth) < DRIVER_INFO_WIDTH / 4 + X_DELTA) {
        xTranslate = "-translate-x-full";
    } else if (Math.abs(bounds.right - window.innerWidth) < DRIVER_INFO_WIDTH / 2 + X_DELTA) {
        xTranslate = "-translate-x-3/4";
    }

    return {yTranslate, xTranslate};
};

export const DriverMapMarker: FC<DriverMapMarkerProps> = ({
    driver,
    selectDriver,
    selectDriverPreview,
    isSelected,
    isSelectedForPreview,
    isMobileView,
    driverDirection,
}) => {
    const [isInfoContainerVisible, setInfoContainerVisible] = useState(false);
    const driverMarkerRef = useRef<HTMLSpanElement>(null);

    const hasOrder = driver.state === FleetOwnerPortalService.DriverState.HAS_ORDER;
    const markerClass = hasOrder ? "bg-promo-primary" : "bg-action-primary";

    const NAVBAR_HEIGHT = useTopBarHeightTailwindConfig().topBarHeightPx;

    const showInfoContainer = useCallback(() => {
        setInfoContainerVisible(true);
        selectDriverPreview(driver.id);
    }, [driver.id, selectDriverPreview]);

    const hideInfoContainer = useCallback(() => {
        setInfoContainerVisible(false);
        selectDriverPreview(null);
    }, [selectDriverPreview]);

    const onClickWrapped = useCallback(() => {
        if ((isMobileView && isSelectedForPreview) || !isMobileView) {
            selectDriver(driver);
            selectDriverPreview(null);
            setInfoContainerVisible(false);
        } else {
            selectDriverPreview(driver.id);
        }
    }, [driver, isMobileView, isSelectedForPreview, selectDriver, selectDriverPreview]);

    const shouldShowInfoOnMap = isInfoContainerVisible || isSelectedForPreview;
    const {yTranslate, xTranslate} = getDriverInfoTranslations(driverMarkerRef, isMobileView, hasOrder, NAVBAR_HEIGHT);

    const DriverIcon = hasOrder ? DriverWithOrder : DriverWaiting;

    return (
        <>
            {isSelected && !isSelectedForPreview ? (
                <div className="-mt-5 -ml-5 flex h-10 w-10 items-center justify-center">
                    <DriverIcon
                        className="duration-600 absolute transition-transform"
                        style={{transform: `rotate(${driverDirection}deg)`}}
                    />
                </div>
            ) : (
                <span
                    onClick={onClickWrapped}
                    onKeyDown={onClickWrapped}
                    onMouseEnter={showInfoContainer}
                    onMouseLeave={hideInfoContainer}
                    role="button"
                    tabIndex={0}
                >
                    <span
                        ref={driverMarkerRef}
                        id={`driver-marker-${driver.id}`}
                        className={`border-primary-inverted absolute block h-5 w-5 -translate-x-1/2 -translate-y-1/2 rounded-full border-[3px] ${styles["marker-shadow"]} ${markerClass}`}
                    />
                    {shouldShowInfoOnMap && (
                        <span
                            className={`absolute ${yTranslate} ${xTranslate} bg-layer-floor-1 z-10 w-[312px] rounded-md px-4 py-3 ${styles["driver-info-shadow"]}`}
                        >
                            <DriverBasicInfoContainer driver={driver} onMap isMobileView={isMobileView} />
                        </span>
                    )}
                </span>
            )}
        </>
    );
};
