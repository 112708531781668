import CopyIconButton from "common/components/copyIconButton/CopyIconButton";

import {IconButton} from "@bolteu/kalep-react";
import {Edit} from "@bolteu/kalep-react-icons";

import {VehicleDataRowValues} from "../hooks/useVehicleDataRow";

interface Props {
    fieldData: VehicleDataRowValues;
    onEditClick: () => void;
}

const DataRow = ({fieldData, onEditClick}: Props) => (
    <>
        <div>
            <h4 className="text-secondary">{fieldData.title}</h4>
            <p className="">{fieldData.value}</p>
            {fieldData.description && (
                <p className={`text-secondary mt-2 text-sm ${fieldData.descriptionClasses}`}>{fieldData.description}</p>
            )}
        </div>
        {fieldData.editable && <IconButton aria-label="Edit" icon={<Edit />} size="sm" onClick={onEditClick} />}
        {fieldData.isCopyable && <CopyIconButton value={fieldData.value} />}
    </>
);

export default DataRow;
