import {useEffect} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {PortalLanguage} from "features/account/pages/Settings/components/PortalLanguage";

export const HoldingFleetLanguageSelection = () => {
    const {i18n} = useI18n();

    useEffect(() => {
        // TODO translate, here and below
        const pageTitle = "User preferences";
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    return (
        <Page className="gap-4">
            <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text="User preferences" />
            <PortalLanguage />
        </Page>
    );
};
