import {useCallback, useEffect, useMemo, useRef} from "react";

import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {v4} from "uuid";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {useSnackbar} from "@bolteu/kalep-react";

import {ShiftActionDialog, ShiftFormState, ShiftFormType} from "../../components/ShiftActionDialog";

export interface Props {
    closeDialog: () => void;
    onShiftEdited: () => void;
    shift: FleetShiftManagementService.ShiftWithAllocationSummary;
}

const editShiftFetchFunction = (api: Api, body: FleetShiftManagementService.UpdateShiftRequest) =>
    api.fleetShiftManagement.updateShift(body);

export const EditShiftDialog = ({shift, closeDialog, onShiftEdited}: Props) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const snackbar = useRef(useSnackbar());
    const {fetch: editShift, status, error} = useFetch(editShiftFetchFunction);
    const isLoading = status === FetchStatus.Loading;

    const onEditSubmit = useCallback(
        async (editedShift: FleetShiftManagementService.ShiftAttributes) => {
            if (editShift && shift) {
                await editShift({shift: editedShift, shift_id: shift.id});
            }
        },
        [editShift, shift],
    );

    useEffect(() => {
        if (status === FetchStatus.Success) {
            onShiftEdited();
            snackbar.current.add(
                {
                    description: i18n("auth.app.fleet.shifts.edit_shift.shift_edited"),
                },
                {timeout: 3000},
            );
            closeDialog();
            trackEvent(EventName.EditShiftSubmitSuccessful);
        } else if (status === FetchStatus.Error && error) {
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.EditShift,
                errorMessage: error.message,
            });
        }
    }, [status, trackEvent, i18n, error, onShiftEdited, snackbar, closeDialog]);

    const initialFormState: ShiftFormState | undefined = useMemo(() => {
        if (!shift) {
            return undefined;
        }
        const [shiftStartHour, shiftStartMinute] = shift.start_time.split(":");
        const [shiftEndHour, shiftEndMinute] = shift.end_time.split(":");
        const breaks = shift.breaks
            .filter((breakDetails) => breakDetails.type === FleetShiftManagementService.BreakType.START_DURATION)
            .map((breakDetails) => {
                const [breakStartHour, breakStartMinute] = breakDetails.start_time.split(":");
                return {
                    breakStartHour,
                    breakStartMinute,
                    breakDuration: breakDetails.duration_min.toString(),
                    key: v4(),
                };
            });
        return {
            shiftName: shift.name,
            shiftStartHour,
            shiftStartMinute,
            shiftEndHour,
            shiftEndMinute,
            breaks,
        };
    }, [shift]);

    return (
        <ShiftActionDialog
            type={ShiftFormType.EDIT}
            closeDialog={closeDialog}
            onSubmit={onEditSubmit}
            isLoading={isLoading}
            initialState={initialFormState}
        />
    );
};
