export enum EventName {
    LoginCompleted = "Login Completed",
    LogoutCompleted = "Logout Completed",

    ForgotPasswordEmailSent = "Forgot Password Email Sent",
    PasswordResetFinished = "Password Reset Finished",

    LiveMapDriverClicked = "Live Map Driver Clicked",
    LiveMapDriverMapClicked = "Live Map Driver Map Clicked",
    LiveMapSearchedDriverClicked = "Live Map Searched Driver Clicked",
    LiveMapSearchedDriverMapClicked = "Live Map Searched Driver Map Clicked",
    LiveMapDriverSearched = "Live Map Driver Searched",
    LiveMapShowRouteClicked = "Live Map Show Route Clicked",
    LiveMapDriverCoordinatesCopyClicked = "Live Map Driver Coordinates Copy Clicked",
    LiveMapCallDriverClicked = "Live Map Call Driver Clicked",
    LiveMapDriverProfileLinkClicked = "Live Map Driver Profile Link Clicked",
    LiveMapDriverPreviewClicked = "Live Map Driver Preview Clicked",
    LiveMapFabDriverListClicked = "Live Map FAB Driver List Clicked",
    LiveMapFabMapClicked = "Live Map FAB Map Clicked",
    LiveMapTakeDriverOfflineClicked = "Live Map Take Driver Offline Clicked",
    LiveMapTakeDriverOfflineConfirmed = "Live Map Take Driver Offline Confirmed",
    LiveMapShowDriverRoutesToggle = "Live Map Show Driver Routes Toggle",

    L2FShowMoreClicked = "L2F Show More Clicked",
    L2FLeadInfoClicked = "L2F Lead Info Clicked",
    L2FLeadAccepted = "L2F Lead Accepted",
    L2FLeadRejected = "L2F Lead Rejected",
    L2FApplicationLinkClicked = "L2F Application Link Clicked",
    L2FOptInClicked = "L2F Opt-In Clicked",

    EngagementDashboardPeriodPicked = "Engagement Dashboard Period Picked",
    EngagementDashboardDriverClicked = "Engagement Dashboard Driver Clicked",

    ExpiringDocumentsSorted = "Expiring Documents Sorted",
    ExpiringDocumentClicked = "Expiring Document Clicked",

    FormSubmissionFailed = "Form Submission Failed",

    DriverCashEnabled = "Driver Cash Enabled",
    DriverCashDisabled = "Driver Cash Disabled",

    InvitedDriversApplicationClicked = "Invited Drivers Application Clicked",
    InvitedDriversInvitationClicked = "Invited Drivers Invitation Clicked",

    VehicleFieldChanged = "Vehicle Field Changed",
    FileDownloaded = "File Downloaded",

    DocumentUploadButtonClicked = "Document Upload Button Clicked",
    DocumentUploadSuccessful = "Document Upload Successful",
    DocumentUploadFailed = "Document Upload Failed",

    AddNewVehicleButtonClicked = "Add New Vehicle Button Clicked",
    AddNewVehicleSubmitSuccessful = "Add New Vehicle Submit Successful",
    AddNewVehicleSubmissionFailed = "Add New Vehicle Submission Failed",

    FleetToRiderInvoicePdfClicked = "Fleet to Rider Invoice Pdf Clicked",
    FleetToRiderFileGenerationQueued = "Fleet to Rider File Generation Queued",
    FleetToRiderLoadMoreClicked = "Fleet to Rider Load More Clicked",
    FleetToRiderGeneratedFilesOpened = "Fleet to Rider Generated Files Opened",

    CompensationLoadMoreClicked = "Compensations Load More Clicked",

    AddNewDriverButtonClicked = "Add New Driver Button Clicked",
    InviteDriverButtonClicked = "Invite Driver Button Clicked",
    AddNewDriverSubmitSuccessful = "Add New Driver Successful",
    InviteDriverSubmitSuccessful = "Invite Driver Successful",
    CompleteDriverRegistrationButtonClicked = "Complete Driver Registration Button Clicked",
    SendRegistrationLinkToDriverClicked = "Send Registration Link To Driver Clicked",

    OrderAutoAcceptEnabled = "Order Auto Accept Enabled",
    OrderAutoAcceptDisabled = "Order Auto Accept Disabled",

    CampaignsListFilterDrawerOpened = "Campaigns List Filter Drawer Opened",
    CampaignsListPeriodPicked = "Campaigns List Period Picked",
    CampaignsListReportDownloadSuccessful = "Campaigns List Report Download Successful",
    CampaignsListReportDownloadFailed = "Campaigns List Report Download Failed",

    NotificationsBellClicked = "Notifications Bell Clicked",
    NotificationCallToActionClicked = "Notification Call To Action Clicked",

    SupportWidgetOpened = "Support Widget Opened",

    TermsAndConditionsAccepted = "Company Terms And Conditions Accepted",

    AddCompanyButtonClicked = "Add Company Button Clicked",

    AddNewShiftSubmitSuccessful = "Add New Shift Submit Successful",
    EditShiftSubmitSuccessful = "Edit Shift Submit Successful",
    ManageShiftAssignmentsButtonClicked = "Manage Shift Assignments Button Clicked",
    AssignShiftsSuccessful = "Assign shifts successful",

    AddNewVehicleListingButtonClicked = "Add New Vehicle Listing Button Clicked",
    AddNewVehicleListingSubmitSuccessful = "Add New Vehicle Listing Submit Successful",
    AddNewVehicleListingSubmitFailed = "Add New Vehicle Listing Submit Failed",

    PerformanceDashboardChartHover = "Performance Dashboard Chart Hover",

    VehicleAssigmentVehiclesAssigned = "Vehicle Assignment Vehicles Selected",
    VehicleAssigmentDriversAssigned = "Vehicle Assignment Drivers Selected",
    VehicleAssignmentAllVehiclesAssigned = "Vehicle Assignment All Vehicles Assigned",
    VehcicleAssignemtVehiclesSearched = "Vehicle Assignment Vehicles Searched",
    VehicleAssignmentDriversSearched = "Vehicle Assignment Drivers Searched",
    VehicleAssignmentVehiclesEndOfListReached = "Vehicle Assignment Vehicles End Of List Reached",
    VehicleAssignmentDriversEndOfListReached = "Vehicle Assignment Drivers End Of List Reached",
    DriverMandatoryCategoriesUpdated = "Driver Mandatory Categories Updated",

    /* Generic events */

    ButtonClicked = "Button Clicked",
    CheckboxClicked = "Checkbox Clicked",
    TabClicked = "Tab Clicked",
    PeriodChanged = "Period Changed",
}

export enum ButtonActionEvent {
    // API settings
    GenerateCredentials = "Generate Credentials",
    RenewCredentials = "Renew Credentials",
    RevokeCredentials = "Revoke Credentials",
    NotificationGroupExpanded = "Notification Group Expanded",
}

export enum CheckboxActionEvent {
    // Notification preferences
    TogglePortalNotifications = "Toggle Portal Notifications",
}

export enum TabNameEvent {
    // Company settings page
    CompanySettingsPageCompanyDetails = "Company Details",
    CompanySettingsPageBankingDetails = "Banking Details",
    CompanySettingsPageTaxInfo = "Tax Info",

    // Vehicles page
    VehiclesPageVehicles = "Vehicles",
    VehiclesPageApplications = "Vehicle Applications",
    VehiclesPageRentingOut = "Renting Out",
    VehiclesPageRentalPayments = "Rental Payments",

    // Orders
    OrdersPageOrderHistory = "Order History",
    OrdersPageIncomingOrders = "Incoming Orders",
    OrdersPageOrderRequests = "Order Requests",

    // Live map
    LiveMapPageHasOrder = "Has Order",
    LiveMapPageWaitingForOrder = "Waiting For Order",

    // Invoices
    InvoicesPageFleetToRider = "Fleet to Rider",
    InvoicesPageBoltToFleet = "Bolt to Fleet",
    InvoicesPageCompensations = "Compensations",
    InvoicesPagePayouts = "Payouts",

    // Campaigns list
    CampaignsListPageActiveAndUpcoming = "Active and Upcoming",
    CampaignsListPageFinished = "Finished",

    // Campaign details
    CampaignDetailsPageRequirements = "Campaign Requirements",
    CampaignDetailsPageParticipants = "Campaign Participants",

    // Drivers
    DriversPageDashboard = "Dashboard",
    DriversPageApplications = "Driver Applications",
    DriversPageProposedCandidates = "Proposed Candidates",

    // Settings
    SettingsPageGeneral = "General",
    SettingsPageApi = "API",

    // Login
    LoginPageEmail = "Email",
    LoginPagePhone = "Phone",

    // Shifts page
    ShiftPlanning = "Shift Planning",
    ShiftLog = "Shift Log",

    // Company performance page
    DriversPerformance = "Drivers Performance",
    VehiclesPerformance = "Vehicles Performance",

    // Listing view
    ListingPreview = "Listing Preview",
    ListingRentalRequests = "Listing Rental Requests",

    // User preferences page
    UserPreferencesPageNotificationSettings = "Notification Settings",
    UserPreferencesPageLanguageSelection = "Language Selection",
}

export type EventAdditionalProperties =
    | EventPropertiesL2FShowMore
    | EventPropertiesL2FReject
    | EventPropertiesFormSubmissionFailed
    | EventPropertiesExpiredDocumentsSorted
    | EventPropertiesVehicleFieldChanged
    | EventPropertiesFileDownloaded
    | EventPropertiesEarningsCsvDownloaded
    | EventPropertiesDocumentUpload
    | EventPropertiesSearchPeriodChanged
    | EventPropertiesFileDownloadedWithPeriodAndStatus
    | EventPropertiesButtonClicked
    | EventPropertiesCheckboxClicked
    | EventPropertiesTabClicked
    | EventPropertiesSwitcherToggled
    | EventPropertiesDateSelectorChanged
    | EventPropertiesPerformanceDashboardChartHover
    | EventPropertiesDriversAssigned
    | EventPropertiesVehiclesAssigned
    | EventPropertiesDriverMandatoryCategoriesChanged;

export enum Status {
    Ok = "Ok",
    Error = "Error",
}

interface EventPropertiesL2FShowMore {
    calledFrom: EventPropertiesL2FShowMoreFrom;
}
export enum EventPropertiesL2FShowMoreFrom {
    OptIn = "Opt In",
    DriverRegistrations = "Driver Registrations",
}

interface EventPropertiesL2FReject {
    rejectReasonId: number;
}

interface EventPropertiesFormSubmissionFailed {
    formName: EventPropertiesFormSubmissionFailedName;
    errorMessage: string;
    errors?: Record<string, string>;
}
export enum EventPropertiesFormSubmissionFailedName {
    AcceptLead = "L2F Accept Lead",
    RejectLead = "L2F Reject Lead",
    OptIn = "L2F Opt-in",
    VehicleField = "Vehicle Field",
    GenerateReport = "Generate Report",
    DownloadEnterpriseInvoice = "Download Enterprise Invoice",
    ForgotPassword = "Forgot Password",
    ResetPassword = "Reset Password",
    InviteDriver = "Invite Driver",
    AddDriver = "Add Driver",
    AddShift = "Add Shift",
    EditShift = "Edit Shift",
    AssingShifts = "Assign Shifts",
}

interface EventPropertiesExpiredDocumentsSorted {
    sortedBy: EventPropertiesExpiredDocumentsSortedBy;
}
export enum EventPropertiesExpiredDocumentsSortedBy {
    Deadline = "Deadline",
    Document = "Document",
    NameCarPlate = "Name Car Plate",
}

interface EventPropertiesVehicleFieldChanged {
    field: string;
}

interface EventPropertiesFileDownloaded {
    downloadType: EventPropertiesDownloadType;
}
interface EventPropertiesEarningsCsvDownloaded extends EventPropertiesFileDownloaded {
    periodName: string;
    period: string;
}

interface EventPropertiesFileDownloadedWithPeriodAndStatus extends EventPropertiesFileDownloaded {
    periodName: string;
    period: string;
    status: Status;
    errorMessage?: string;
}

export enum EventPropertiesDownloadType {
    EarningsReportCsv = "Earnings Report CSV",
    FleetToRiderGeneratedFile = "Fleet to Rider Generated File",
    BoltToFleetPdf = "Bolt to Fleet PDF",
    CompensationsPdf = "Compensations PDF",
    EnterpriseInvoicePdf = "Enterprise Invoice PDF",
    EngagementDashboardCsv = "Engagement Dashboard CSV",
    BalancePdf = "Balance PDF",
    ShiftActivityLogCsv = "Shift Activity Log CSV",
    OrderHistoryCsv = "Order History CSV",
}

interface EventPropertiesDocumentUpload {
    documentType?: string;
}

interface EventPropertiesSearchPeriodChanged {
    periodName: string;
    period: string;
}

interface EventPropertiesPerformanceDashboardChartHover {
    chartName: string;
    periodName: string;
    period: string;
}

interface EventPropertiesDriversAssigned {
    numDriversAssigned: number;
    numDriversUnAssigned: number;
    numActiveDriversAssigned: number;
    numDeactivatedDriversAssigned: number;
    numBlockedDriversAssigned: number;
}

interface EventPropertiesVehiclesAssigned {
    numVehiclesAssigned: number;
    numVehiclesUnAssigned: number;
}

interface EventPropertiesDriverMandatoryCategoriesChanged {
    categories: string[];
    driver_id: number;
}

/* Generic event properties */

interface EventPropertiesButtonClicked {
    action: ButtonActionEvent;
}

interface EventPropertiesCheckboxClicked {
    action: CheckboxActionEvent;
}

interface EventPropertiesTabClicked {
    tabName: TabNameEvent;
}

interface EventPropertiesSwitcherToggled {
    toggledOn: boolean;
}

interface EventPropertiesDateSelectorChanged {
    periodName: string;
    period: string;
}
