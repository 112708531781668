import {useContext} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {OrderRequestsContextProvider} from "features/companies/orders/OrderRequestsContextProvider";
import {formatNumber} from "@fleet/common/utils/numberFormatUtils";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Skeleton} from "@bolteu/kalep-react";
import {PaymentOutlined, Route} from "@bolteu/kalep-react-icons";

import {getPaymentMethodLabel} from "../../../util";

interface Props {
    selectedCategory: FleetPortalOrderService.Category;
    isLoading: boolean;
}

export const RideInfo = ({selectedCategory, isLoading}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const paymentMethods = useContext(OrderRequestsContextProvider).getOrderCreationPaymentMethods();
    const orderDurationInMinutes = selectedCategory?.order_duration_seconds
        ? Math.round(selectedCategory?.order_duration_seconds / 60)
        : undefined;

    const distanceAndTime =
        selectedCategory?.order_distance && orderDurationInMinutes ? (
            <>
                <span className="flex items-center gap-1">
                    <Route size="xs" /> {formatNumber(intl, selectedCategory.order_distance, {maxFractionDigits: 1})}
                    <span>{selectedCategory.order_distance_unit}</span>
                </span>
                <span>•</span>
                <span>
                    {orderDurationInMinutes} {i18n("common.minute_abbreviation")}
                </span>
            </>
        ) : null;
    const paymentMethodBorder = distanceAndTime ? (
        <div className="border-tertiary absolute bottom-1/4 inline-block h-1/2 border-l" />
    ) : null;
    const paymentMethodPadding = distanceAndTime ? "pl-[6px]" : "";
    const paymentMethod =
        paymentMethods.length === 1 ? (
            <div className="relative">
                {paymentMethodBorder}
                <span className={`flex items-center justify-center gap-1 ${paymentMethodPadding}`}>
                    <PaymentOutlined size="xs" />
                    {getPaymentMethodLabel(paymentMethods[0], i18n)}
                </span>
            </div>
        ) : null;
    if (!paymentMethod && !distanceAndTime) {
        return null;
    }
    return (
        <div className="text-secondary mb-2 flex items-center justify-start gap-1">
            {isLoading && <Skeleton variant="text" width={130} textSize="text-xl" />}
            {!isLoading && (
                <>
                    {distanceAndTime}
                    {paymentMethod}
                </>
            )}
        </div>
    );
};
