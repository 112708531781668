import {useContext, useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import CampaignsPageComponent from "features/companies/campaigns/list";
import {FeaturesContextProvider} from "FeaturesProvider";

export const CampaignsPage = () => {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.campaigns.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    if (!features?.campaigns) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    return <CampaignsPageComponent />;
};
