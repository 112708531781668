import {useCallback, useContext, useEffect, useMemo, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import LoadingSpinner from "common/components/spinner";
import {EventName, EventPropertiesL2FShowMoreFrom} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import useMediaQuery from "common/hooks/useMediaQuery";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {FeaturesContextProvider} from "FeaturesProvider";
import {TextButton} from "@fleet/common/components/TextButton";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {LeadToFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert} from "@bolteu/kalep-react";

import DriverRegistrationsInfo from "../DriverRegistrationsInfo";
import AcceptDialog from "./AcceptDialog";
import MatchCard from "./match-card";
import {OptIn} from "./OptIn";
import RejectDialog from "./RejectDialog";

const fetchFunction = (api: Api) => api.leadToFleet.leadmatcherAvailableMatches({});

interface Props {
    shouldShowTitle?: boolean;
    onLeadAccepted: () => void;
}

export const Matches = ({onLeadAccepted, shouldShowTitle}: Props) => {
    const {i18n} = useI18n();
    const isMatchesDisabled = !useContext(FeaturesContextProvider)?.lead_to_fleet;
    const {setNotification} = useContext(NotificationContext);
    const isNewNavigationEnabled = useContext(AccountContextProvider).profile?.isNewNavigationEnabled;
    const [isRegistrationsInfoShown, setIRegistrationsInfoShown] = useState(false);
    const [rejectedMatch, setRejectedMatch] = useState<LeadToFleetService.ActiveMatch | null>(null);
    const [acceptedMatch, setAcceptedMatch] = useState<LeadToFleetService.ActiveMatch | null>(null);
    const {trackEvent} = useTracking();
    const isMdBreakpointSurpassed = useMediaQuery("(min-width: 768px)");
    const {data, fetch, status} = useFetch(fetchFunction);

    const isLoading = status === FetchStatus.Loading;

    const getMatches = useCallback(() => {
        if (!fetch) {
            return;
        }
        fetch({});
    }, [fetch]);

    useEffect(() => {
        getMatches();
    }, [getMatches]);

    const matchesList = useMemo(() => {
        const matchCard = (match: LeadToFleetService.ActiveMatch) => (
            <MatchCard
                key={match.matcher_lead_id}
                match={match}
                setRejectedMatch={setRejectedMatch}
                setAcceptedMatch={setAcceptedMatch}
            />
        );

        if (!data?.list) {
            return null;
        }

        if (!isMdBreakpointSurpassed) {
            return data.list.map((match) => matchCard(match));
        }

        return (
            <>
                <div className="flex flex-col gap-4">
                    {data.list.map((match, i) => (i % 2 === 0 ? matchCard(match) : null))}
                </div>
                <div className="flex flex-col gap-4">
                    {data.list?.map((match, i) => (i % 2 !== 0 ? matchCard(match) : null))}
                </div>
            </>
        );
    }, [isMdBreakpointSurpassed, data?.list]);

    const closeModal = useCallback(() => {
        setAcceptedMatch(null);
        setRejectedMatch(null);
    }, [setRejectedMatch, setAcceptedMatch]);

    const removeMatch = useCallback(
        ({accepted}: {accepted: boolean}) => {
            const selectedMatch = acceptedMatch ?? rejectedMatch;
            if (!selectedMatch) {
                return;
            }
            getMatches();
            setRejectedMatch(null);
            setAcceptedMatch(null);
            setNotification({
                type: accepted ? NotificationType.SUCCESS : NotificationType.ERROR,
                text: i18n(`auth.app.fleet.matches.lead_${accepted ? "accepted" : "rejected"}`, {
                    name: selectedMatch.name,
                }),
            });
        },
        [acceptedMatch, rejectedMatch, getMatches, setNotification, i18n],
    );

    const closeRegistrationInfo = useCallback(() => setIRegistrationsInfoShown(false), []);

    const openRegistrationInfo = useCallback(() => {
        setIRegistrationsInfoShown(true);
        trackEvent(EventName.L2FShowMoreClicked, {calledFrom: EventPropertiesL2FShowMoreFrom.DriverRegistrations});
    }, [trackEvent]);

    if (isMatchesDisabled) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    const title = shouldShowTitle ? (
        <h2 className="text-lg font-semibold">
            {i18n("auth.app.fleet.driver-registrations.proposed-candidates.title")}
        </h2>
    ) : null;
    if (data?.can_opt_in) {
        return (
            <>
                <DriverRegistrationsInfo closePage={closeRegistrationInfo} isOpen={isRegistrationsInfoShown} />
                {title}
                <OptIn showInfoPage={openRegistrationInfo} onOptedIn={getMatches} />
            </>
        );
    }

    const isMatchesListEmpty = !isLoading && data?.list && !data.list.length;
    const hasMatches = !isLoading && data?.list && data.list.length !== 0;

    return (
        <div>
            <RejectDialog
                key={rejectedMatch?.matcher_lead_id}
                show={!!rejectedMatch}
                closeModal={closeModal}
                removeMatch={removeMatch}
                matcherLeadId={rejectedMatch?.matcher_lead_id}
            />
            <AcceptDialog
                key={acceptedMatch?.matcher_lead_id}
                show={!!acceptedMatch}
                closeModal={closeModal}
                removeMatch={removeMatch}
                matcherLeadId={acceptedMatch?.matcher_lead_id}
                onLeadAccepted={onLeadAccepted}
            />
            <DriverRegistrationsInfo closePage={closeRegistrationInfo} isOpen={isRegistrationsInfoShown} />
            {title}
            <LoadingSpinner show={isLoading} showLoadingText parentClassName="mt-9" />
            {isMatchesListEmpty && (
                <p className={isNewNavigationEnabled ? "" : "mt-4 p-5"}>{i18n("auth.app.fleet.matches.no_matches")}</p>
            )}
            {hasMatches && (
                <>
                    <div className="mb-6 flex max-w-3xl flex-col gap-6">
                        <div className="text-secondary">
                            <span>{`${i18n("auth.app.fleet.driver-registrations.proposed-candidates.info")} `}</span>
                            <TextButton
                                onClick={openRegistrationInfo}
                                text={i18n("auth.app.fleet.driver-registrations.learn-more")}
                            />
                        </div>
                        {data?.is_inactive_with_matches && (
                            <Alert
                                severity="warning"
                                title={i18n("auth.app.fleet.matching_removed_banner.title")}
                                overrideClassName="text-base"
                            >
                                {i18n("auth.app.fleet.matching_removed_banner.description")}
                            </Alert>
                        )}
                    </div>
                    <div className="grid grid-cols-1 content-start items-start gap-4 overflow-y-auto pb-7 md:max-w-screen-2xl md:grid-cols-2">
                        {matchesList}
                    </div>
                </>
            )}
        </div>
    );
};
