import {useContext} from "react";

import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {FeaturesContextProvider} from "FeaturesProvider";

import {DriverTabs} from "..";

export function useTabSelectorOptions(driverCount?: number, driverRegistrationCount?: number): Array<Tab<DriverTabs>> {
    const {i18n} = useI18n();
    const isNewNavigationEnabled = useContext(AccountContextProvider).profile?.isNewNavigationEnabled;
    const isProposedCandidatesEnabled = useContext(FeaturesContextProvider)?.lead_to_fleet;

    return [
        {
            id: DriverTabs.Drivers,
            title: `${i18n("auth.app.fleet.engagement-dashboard.fleet_drivers")}${
                driverCount !== undefined ? `: ${driverCount}` : ""
            }`,
            trackingEventTabName: TabNameEvent.DriversPageDashboard,
        },
        {
            id: DriverTabs.Registrations,
            title: `${i18n("auth.app.fleet.engagement-dashboard.applications")}${
                driverCount !== undefined ? `: ${driverRegistrationCount}` : ""
            }`,
            trackingEventTabName: TabNameEvent.DriversPageApplications,
        },
        ...(isNewNavigationEnabled && isProposedCandidatesEnabled
            ? [
                  {
                      id: DriverTabs.ProposedCandidates,
                      title: i18n("auth.app.fleet.driver-registrations.proposed-candidates.title"),
                      trackingEventTabName: TabNameEvent.DriversPageProposedCandidates,
                  },
              ]
            : []),
    ];
}
