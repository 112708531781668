import {useCallback, useContext, useEffect, useMemo, useState} from "react";

import {EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {debounce} from "lodash-es";

import {FleetCarService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import MultiSelectListview, {
    MultiSelectListviewItemKey,
} from "../../../../common/components/listView/MultiSelectListview";
import VehicleAccessBaseDialog from "../components/VehicleAccessBaseDialog";
import VehicleItem from "../VehicleItem";
import {useDriverVehicleAccesses} from "./useDriverVehicleAccesses";

const NUM_RECORDS_PER_PAGE = 20;

interface ManageVehicleDialogProps {
    onRequestClose: () => void;
    driverId: number;
}

const updateVehiclesFn = (api: Api, body: FleetCarService.DriverUpdateAssignedCarsRequest) =>
    api.fleetCar.driverUpdateAssignedCars(body);

export default function ManageVehicleDialog({onRequestClose, driverId}: ManageVehicleDialogProps) {
    const {i18n} = useI18n();

    const accountState = useContext(AccountContextProvider);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [availableCars, setAvailableCars] = useState<FleetCarService.Car[]>([]);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [addedVehicleIds, setAddedVehicleIds] = useState<number[]>([]);
    const [removedVehicleIds, setRemovedVehicleIds] = useState<number[]>([]);
    const {trackEvent} = useTracking();

    const {
        assignedVehicleIds,
        assignedVehicles,
        canAssignAllCars,
        availableCarsWithoutAssigned,
        totalRows,
        hasAccessToAllVehicles,
    } = useDriverVehicleAccesses(accountState, driverId, currentPage, searchQuery);

    const {fetch: updateVehicles, status} = useFetch(updateVehiclesFn);

    useEffect(() => {
        if (!availableCarsWithoutAssigned) {
            return;
        }

        setAvailableCars([...availableCars, ...availableCarsWithoutAssigned]);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- We need to append in this set scenario and not replace
    }, [availableCarsWithoutAssigned]);

    useEffect(() => {
        if (status === FetchStatus.Error) {
            setCurrentPage(currentPage - 1);
        }
    }, [status, currentPage]);

    const isMoreDataAvailable = useMemo(() => {
        if (!totalRows) {
            return false;
        }

        return currentPage * NUM_RECORDS_PER_PAGE < totalRows;
    }, [totalRows, currentPage]);

    const onSelectAllChange = useCallback(
        (selectAll: boolean) => {
            if (!availableCars) {
                return;
            }
            setAddedVehicleIds(selectAll ? [...availableCars.map((vehicle) => vehicle.id)] : []);
            setRemovedVehicleIds(selectAll ? [] : [...assignedVehicleIds]);
            setIsSelectAllSelected(selectAll);
        },
        [availableCars, assignedVehicleIds],
    );

    const debouncedSearch = useMemo(
        () =>
            debounce((currentSearchQuery: string) => {
                setAvailableCars([]);
                setSearchQuery(currentSearchQuery);
                setCurrentPage(1);
            }, 500),
        [],
    );

    const debouncedCurrentPageSet = useMemo(
        () => debounce((currentPageNum: number) => setCurrentPage(currentPageNum), 200),
        [],
    );

    const onSearchChange = useCallback(
        (currentSearchQuery: string) => {
            debouncedSearch(currentSearchQuery);
            trackEvent(EventName.VehcicleAssignemtVehiclesSearched);
        },
        [debouncedSearch, trackEvent],
    );

    const onListEndReached = useCallback(() => {
        debouncedCurrentPageSet(currentPage + 1);
        trackEvent(EventName.VehicleAssignmentVehiclesEndOfListReached);
    }, [debouncedCurrentPageSet, currentPage, trackEvent]);

    const onSubmitted = useCallback(() => {
        const event = isSelectAllSelected
            ? EventName.VehicleAssignmentAllVehiclesAssigned
            : EventName.VehicleAssigmentVehiclesAssigned;

        trackEvent(event, {
            numVehiclesAssigned: addedVehicleIds.length,
            numVehiclesUnAssigned: removedVehicleIds.length,
        });

        if (!updateVehicles) {
            return;
        }

        const accessPolicy: FleetCarService.DriverAssignCarsPolicy = isSelectAllSelected
            ? {
                  type: FleetCarService.DriverAccessCarsPolicyType.ALL,
              }
            : {
                  type: FleetCarService.DriverAccessCarsPolicyType.BY_ID,
                  added_cars: addedVehicleIds,
                  removed_cars: removedVehicleIds,
              };

        updateVehicles({
            driver_id: driverId,
            access_policy: accessPolicy,
        }).then(onRequestClose);
    }, [isSelectAllSelected, addedVehicleIds, removedVehicleIds, trackEvent, updateVehicles, driverId, onRequestClose]);

    const onItemSelected = useCallback(
        (vehicleId: MultiSelectListviewItemKey) => {
            const isPresentInAssignedVehicles = assignedVehicleIds.includes(vehicleId as number);
            if (!isPresentInAssignedVehicles) {
                setAddedVehicleIds([...addedVehicleIds, vehicleId as number]);
            }

            setRemovedVehicleIds(removedVehicleIds.filter((id) => id !== vehicleId));
        },
        [addedVehicleIds, removedVehicleIds, assignedVehicleIds],
    );

    const onItemDeselected = useCallback(
        (vehicleId: MultiSelectListviewItemKey) => {
            const isAddedInThisSession = addedVehicleIds.includes(vehicleId as number);
            if (isAddedInThisSession) {
                setAddedVehicleIds(addedVehicleIds.filter((id) => id !== vehicleId));
                return;
            }

            setRemovedVehicleIds([...removedVehicleIds, vehicleId as number]);
        },
        [addedVehicleIds, removedVehicleIds],
    );

    const assignedVehiclesWithoutRemoved = useMemo(() => {
        if (!assignedVehicles) {
            return [];
        }

        return assignedVehicles
            .filter((vehicle) => !removedVehicleIds.includes(vehicle.id))
            .map((vehicle) => vehicle.id);
    }, [assignedVehicles, removedVehicleIds]);

    return (
        <VehicleAccessBaseDialog
            title={i18n("auth.app.fleet.vehicle_assignment.driver.manage_vehicles_dialog.manage_vehicles_title")}
            subtitle={i18n("auth.app.fleet.vehicle_assignment.driver.manage_vehicles_dialog.manage_vehicles_subtitle")}
            isOpen
            onRequestClose={onRequestClose}
            onSubmitted={onSubmitted}
        >
            <div className="border-separator h-full overflow-hidden border-b-2 md:h-[500px]">
                <MultiSelectListview
                    initialSelectedItems={[...assignedVehiclesWithoutRemoved, ...addedVehicleIds]}
                    searchLabel={i18n("auth.app.sidebar.vehicles")}
                    searchPlaceholder={i18n("auth.app.fleet.vehicle_assignment.search.vehicles_selected", {
                        num: assignedVehicles.length + (addedVehicleIds.length - removedVehicleIds.length),
                    })}
                    onListEndReached={onListEndReached}
                    isMoreDataAvailable={isMoreDataAvailable}
                    isAllSelected={hasAccessToAllVehicles}
                    onSearch={onSearchChange}
                    onItemSelected={onItemSelected}
                    onItemDeselected={onItemDeselected}
                    allowSelectAll={canAssignAllCars}
                    onSelectAll={onSelectAllChange}
                    selectAllLabel={i18n(
                        "auth.app.fleet.vehicle_assignment.driver.manage_vehicles_dialog.all_vehicles",
                    )}
                    allItemsCount={totalRows || 0}
                >
                    {assignedVehicles?.map((vehicle) => (
                        <MultiSelectListview.Item key={vehicle.id} value={vehicle.id}>
                            <VehicleItem vehicle={vehicle} />
                        </MultiSelectListview.Item>
                    ))}
                    <MultiSelectListview.Divider />
                    {availableCars.map((vehicle) => (
                        <MultiSelectListview.Item key={vehicle.id} value={vehicle.id}>
                            <VehicleItem vehicle={vehicle} />
                        </MultiSelectListview.Item>
                    ))}
                </MultiSelectListview>
            </div>
        </VehicleAccessBaseDialog>
    );
}
