import {useCallback, useEffect, useMemo} from "react";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {AccountState} from "features/account/accountStateProvider";

import {FleetCarService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const NUM_RECORDS_PER_PAGE = 100;

const getVehiclesFn = (api: Api, body: FleetCarService.SearchCarsRequest) => api.fleetCar.driverGetCars(body);
export function useDriverVehicleAccesses(
    accountState: AccountState,
    driverId: number,
    currentPage = 1,
    searchQuery = "",
) {
    const {data, fetch: searchCars} = useFetch(getVehiclesFn);

    const doSearchCars = useCallback(() => {
        if (searchCars && accountState?.selectedCompany?.company) {
            searchCars({
                driver_id: driverId,
                limit: NUM_RECORDS_PER_PAGE,
                page: currentPage,
                search: searchQuery && searchQuery !== "" ? searchQuery : undefined,
            });
        }
    }, [searchCars, accountState, driverId, currentPage, searchQuery]);

    useEffect(doSearchCars, [doSearchCars]);

    const assignedVehicleIds = useMemo(() => {
        if (data && data.assigned_cars.type === FleetCarService.DriverAccessCarsPolicyType.BY_ID) {
            return data.assigned_cars.cars.map((c) => c.id);
        }
        return [];
    }, [data]);

    const availableCarsWithoutAssigned = useMemo(
        () => data?.available_cars.filter((c) => !assignedVehicleIds.includes(c.id)),
        [assignedVehicleIds, data],
    );

    const assignedVehicles = useMemo(
        () => data?.available_cars.filter((vehicle) => assignedVehicleIds.includes(vehicle.id)) || [],
        [assignedVehicleIds, data],
    );

    return {
        availableCarsWithoutAssigned,
        totalRows: data?.total_rows,
        assignedVehicleIds,
        assignedVehicles,
        canAssignAllCars: data?.can_assign_all_cars,
        hasAccessToAllVehicles: data?.assigned_cars.type === FleetCarService.DriverAccessCarsPolicyType.ALL,
        refetch: doSearchCars,
    };
}
