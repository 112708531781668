import {useCallback} from "react";

import {DocumentData} from "common/components/document/useDocumentsList";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const fetchFunction = async (
    api: Api,
    body: FleetOwnerPortalService.GetCarDocumentsSetRequest,
): Promise<DocumentData[]> => {
    const resp = await api.fleetOwnerPortal.getCarDocumentsSet(body);
    return resp.list.map((doc) => {
        const document = doc.uploaded_document ?? doc.document;
        return {
            ...(document
                ? {
                      document: {
                          id: document.id,
                          expires: document.expires,
                          status: document.status,
                      },
                  }
                : {document: null}),
            type: {
                ...doc.document_type,
                is_expiry_required: !!doc.document_type.is_expiry_required,
                is_required: !!doc.document_type.is_required,
            },
        };
    });
};

const uploadFunction = (
    api: Api,
    body: FleetOwnerPortalService.UploadCarDocumentRequest,
    files: FleetOwnerPortalService.Files,
) => api.fleetOwnerPortal.uploadCarDocument(body, files);

export const useVehicleDocumentFunctions = (vehicleId: number) => {
    const documentsFetchFunction = useCallback((api: Api) => fetchFunction(api, {car_id: vehicleId}), [vehicleId]);

    const documentUploadFunction = useCallback(
        async (api: Api, expiresIsoDate: string | null, files: FleetOwnerPortalService.Files) => {
            await uploadFunction(api, {car_id: vehicleId, ...(expiresIsoDate && {expires: expiresIsoDate})}, files);
        },
        [vehicleId],
    );

    return {
        documentsFetchFunction,
        documentUploadFunction,
    };
};
