import * as React from "react";
import {IntlShape, useIntl} from "react-intl";

import {AppBrand} from "../constants/appConstants";

export type PrimitiveValues = Record<string, string | number | boolean | null | undefined | Date>;
type ComplexValues = Record<string, React.ReactNode>;

export interface I18nFn<TranslationKeys extends string> {
    (id: TranslationKeys, values?: PrimitiveValues, defaultMessageId?: TranslationKeys): string;
    (id: TranslationKeys, values: ComplexValues, defaultMessageId?: TranslationKeys): React.ReactNode;
}

function getResolvedBrandKey<TranslationKeys extends string>(
    brand: AppBrand,
    intl: IntlShape,
    translationKey: TranslationKeys,
): string {
    const keyWithBrand = `${translationKey}__${brand}`.toLowerCase();

    const shouldAddBrandToKey = brand !== AppBrand.BOLT && keyWithBrand in intl.messages;
    return shouldAddBrandToKey ? keyWithBrand : translationKey;
}

export function useI18nBranded<TranslationKeys extends string>(brand: AppBrand) {
    const intl = useIntl();

    const i18n = React.useCallback(
        (
            id: TranslationKeys,
            values?: PrimitiveValues | ComplexValues,
            defaultMessageId?: TranslationKeys,
        ): typeof values extends undefined | PrimitiveValues ? string : React.ReactNode => {
            let defaultMessage: string | undefined;
            if (defaultMessageId) {
                const defaultMessageKey = getResolvedBrandKey(brand, intl, defaultMessageId);
                defaultMessage = intl.formatMessage({id: defaultMessageKey});
            }

            const stringId = getResolvedBrandKey(brand, intl, id);
            const i18nValue = intl.formatMessage(
                {
                    id: stringId,
                    defaultMessage,
                },
                values,
            );

            // If intl.formatMessage result contains complex values (is array), google translate can trigger errors. Eslint can't detect this as markup is generated by code. Wrapping inner string values in span helps to avoid that.
            if (Array.isArray(i18nValue)) {
                const wrappedSpanI18nValue = i18nValue.map((value): React.ReactNode => {
                    if (typeof value === "string") {
                        return <span key={value}>{value}</span>;
                    }
                    return value;
                });

                return wrappedSpanI18nValue;
            }

            return i18nValue;
        },
        [intl, brand],
    ) as I18nFn<TranslationKeys>;

    return {i18n};
}
