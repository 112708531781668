/* eslint-disable simple-import-sort/imports  -- polyfill should be first */
// https://stackoverflow.com/questions/73194573/why-does-babel-in-create-react-app-not-polyfill-array-prototype-at-properly
import R from "core-js";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";

import {createRoot} from "react-dom/client";
import App from "./AppEntry";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
/* eslint-enable simple-import-sort/imports */

// eslint-disable-next-line @typescript-eslint/no-unused-expressions -- valid statement
R;

const root = createRoot(document.getElementById("root")!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
