import {useI18n} from "common/hooks/useI18n";

import {Island, Switch, Tooltip} from "@bolteu/kalep-react";
import {InfoCircleOutlined} from "@bolteu/kalep-react-icons";

interface Props {
    handleAutoAcceptanceToggle: () => void;
    isAutoAcceptanceEnabled: boolean;
}

const AutoAcceptSetting = ({isAutoAcceptanceEnabled, handleAutoAcceptanceToggle}: Props) => {
    const {i18n} = useI18n();

    return (
        <div className="w-fit">
            <Island>
                <div className="flex items-center gap-9">
                    <div className="flex flex-col">
                        <div className="text-secondary flex items-center text-base font-normal">
                            {i18n("auth.app.orders.incoming.auto-accept")}
                            <Tooltip
                                content={i18n("auth.app.orders.incoming.auto-accept-button-message")}
                                placement="top"
                            >
                                <div className="inline-flex pl-1">
                                    <InfoCircleOutlined size="xs" />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="text-base font-normal">
                            {isAutoAcceptanceEnabled ? i18n("common.enabled") : i18n("common.disabled")}
                        </div>
                    </div>

                    <div className="my-auto">
                        <Switch checked={isAutoAcceptanceEnabled} onChange={handleAutoAcceptanceToggle} />
                    </div>
                </div>
            </Island>
        </div>
    );
};

export default AutoAcceptSetting;
