import {useCallback, useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";

import {EventName} from "common/constants/events";
import {CommonRoute, FleetRoute} from "common/constants/routes";
import {Fleet, HoldingFleet, isDraftFleet, isFleet} from "common/constants/types";
import {useAuthentication} from "common/hooks/useAuthentication";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider, SelectedCompany} from "features/account/accountStateProvider";

import {Button, IconButton, ListItemLayout, Typography} from "@bolteu/kalep-react";
import {Settings} from "@bolteu/kalep-react-icons";

import {CompanyMenu} from "./CompanyMenu";
import {useCompanySelector} from "./hooks/useCompanySelector";

const CompanyDropdown = ({
    fleets,
    selectedCompany,
    holdingFleets,
}: {
    selectedCompany: SelectedCompany | null;
    fleets: Fleet[];
    holdingFleets: HoldingFleet[];
}) => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {trackEvent} = useTracking();
    const {makeLogout} = useAuthentication();
    const {
        getDescription,
        getStatusChip,
        areCompanySettingsShown,
        isChipShownInHeader,
        renderCompanySelectionItems,
        getCompanyName,
    } = useCompanySelector();

    const selectedCompanyName = getCompanyName(selectedCompany?.company);
    const shouldShowCompanySettings = areCompanySettingsShown(selectedCompany?.company);
    const description = getDescription(selectedCompany?.company, false);
    const isNewNavigationEnabled = useContext(AccountContextProvider).profile?.isNewNavigationEnabled;

    const navigateToCompanySettings = useCallback(() => {
        navigate(FleetRoute.COMPANY_SETTINGS);
    }, [navigate]);

    const navigateToAddCompany = useCallback(() => {
        trackEvent(EventName.AddCompanyButtonClicked);
        navigate(CommonRoute.ADD_COMPANY);
    }, [navigate, trackEvent]);

    const onLogoutClick = useCallback(() => {
        makeLogout(true);
    }, [makeLogout]);

    const renderCompanySettings = useCallback(
        () => (
            <IconButton
                icon={<Settings />}
                size="sm"
                variant="ghost"
                onClick={navigateToCompanySettings}
                aria-label="Go to company settings"
            />
        ),
        [navigateToCompanySettings],
    );

    const renderStatusChip = useCallback(() => {
        if (selectedCompany && isFleet(selectedCompany.company)) {
            return getStatusChip(selectedCompany.company.status);
        }
        return null;
    }, [getStatusChip, selectedCompany]);

    const headerChip = useMemo(() => {
        if (selectedCompany && isChipShownInHeader(selectedCompany.company) && isFleet(selectedCompany.company)) {
            return getStatusChip(selectedCompany.company.status, "xs");
        }
        return null;
    }, [isChipShownInHeader, getStatusChip, selectedCompany]);

    const shouldHideAddCompanyButton = fleets.length && fleets.every((fleet) => isDraftFleet(fleet));

    return (
        <CompanyMenu selectedCompanyName={selectedCompanyName} chip={headerChip ?? undefined}>
            <div className="flex max-h-[400px] flex-col overflow-hidden">
                <div className="bg-neutral-secondary m-1 rounded-sm p-1">
                    <ListItemLayout
                        variant="base"
                        primary={
                            <Typography fontSize="text-lg" fontWeight="semibold" variant="body-primary">
                                {selectedCompanyName}
                            </Typography>
                        }
                        secondary={description}
                        renderEndSlot={shouldShowCompanySettings ? renderCompanySettings : renderStatusChip}
                    />
                </div>
                <div className="flex-1 overflow-auto px-2">
                    {renderCompanySelectionItems(selectedCompany, fleets, holdingFleets)}
                </div>
                <div className="flex flex-row align-middle p-6 justify-between">
                    {!shouldHideAddCompanyButton && (
                        <Button variant="secondary" size="md" onClick={navigateToAddCompany}>
                            {i18n("company-selector.add_company")}
                        </Button>
                    )}
                    {isNewNavigationEnabled && (
                        <Button variant="static-light" size="md" onClick={onLogoutClick}>
                            <Typography color="danger-primary" fontWeight="semibold">
                                {i18n("common.log_out")}
                            </Typography>
                        </Button>
                    )}
                </div>
            </div>
        </CompanyMenu>
    );
};

export {CompanyDropdown};
