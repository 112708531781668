import {FC, useCallback, useContext, useEffect, useMemo} from "react";

import CloseButton from "common/components/CloseButton";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {CarRentalPaymentService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Button} from "@bolteu/kalep-react";
import {Alert as AlertIcon} from "@bolteu/kalep-react-icons";

import PaymentsTable from "./PaymentsTable";

const fetchFunction = (api: Api, body: CarRentalPaymentService.ConfirmCarRentalPaymentUploadRequest) =>
    api.carRentalPayment.carRentalPaymentConfirmUpload(body);

export interface ConfirmPaymentsProps {
    payments: CarRentalPaymentService.CarRentalPaymentUploadData[];
    fileContent: string;
    close: () => void;
    paymentsConfirmed: () => Promise<void>;
}

const ConfirmPayments: FC<ConfirmPaymentsProps> = ({payments, close, fileContent, paymentsConfirmed}) => {
    const {i18n} = useI18n();
    const isNewNavigationEnabled = useContext(AccountContextProvider).profile?.isNewNavigationEnabled;
    const {status, fetch} = useFetch(fetchFunction);

    const confirmPayments = useCallback(async () => {
        if (fetch) {
            const confirmedPaymentsData = payments.map<CarRentalPaymentService.CarRentalPaymentData>((payment) => ({
                amount: payment.amount,
                driver_uuid: payment.driver_uuid,
                driver_name: payment.driver_name,
                driver_phone: payment.phone,
            }));
            fetch({csv: fileContent, list: confirmedPaymentsData});
        }
    }, [fetch, fileContent, payments]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            // Parent component closes this component, by setting payments and csv to null
            paymentsConfirmed();
        }
    }, [status, paymentsConfirmed]);

    const hasErrors = useMemo<boolean>(() => {
        const payment = payments.find((p) => p.error !== undefined);
        return !!payment;
    }, [payments]);

    return (
        <div
            className={`mb-4 flex max-w-6xl flex-col gap-2 ${
                isNewNavigationEnabled ? "px-6" : "container mx-auto p-6"
            }`}
        >
            <div className="flex justify-end">
                <CloseButton onClick={close} disabled={status === FetchStatus.Loading} />
            </div>
            <ResponsiveHeader
                type={ResponsiveHeaderType.MainPage}
                text={i18n("auth.app.fleet.car-rentals.confirm_payment.title")}
            />
            <p className="text-secondary mb-6">{i18n("auth.app.fleet.car-rentals.confirm_payment.description")}</p>
            {hasErrors && (
                <Alert
                    severity="warning"
                    action={{
                        onClick: close,
                        label: i18n("auth.app.fleet.car-rentals.confirm_payment.alert.action"),
                    }}
                    title={i18n("auth.app.fleet.car-rentals.confirm_payment.alert.title")}
                    icon={<AlertIcon size="lg" />}
                >
                    {i18n("auth.app.fleet.car-rentals.confirm_payment.alert.description")}
                </Alert>
            )}
            <PaymentsTable payments={payments} hasErrors={hasErrors} />
            {!hasErrors && (
                <div className="mt-10">
                    <Button size="lg" onClick={confirmPayments} loading={status === FetchStatus.Loading}>
                        {i18n("auth.app.fleet.car-rentals.confirm_payment.confirm")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ConfirmPayments;
