import {ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {restrictNumberInputToTime, TimeType} from "common/util/timeUtil";
import {v4} from "uuid";

import {Button, GhostButton, Grid, TextField} from "@bolteu/kalep-react";
import {BinOutlined, Plus, TimeOutlined} from "@bolteu/kalep-react-icons";

export interface Props {
    index: number;
    data: ShiftBreakState;
    isOnlyBreak: boolean;
    handleTextFieldChange: ChangeEventHandler<HTMLInputElement>;
    handleBreakHourChange: (index: number, newValue: string) => void;
    onDeleteClick: (index: number) => void;
    onChange: (obj: {value: string}) => void;
}

export interface ShiftBreakState {
    breakStartHour: string;
    breakStartMinute: string;
    breakDuration: string;
    key: string;
}

const renderTimeIcon = () => <TimeOutlined aria-hidden="true" className="text-secondary flex-none" />;

export const isBreakFilled = (breakDetail: ShiftBreakState) =>
    breakDetail.breakDuration !== "" && breakDetail.breakStartHour !== "" && breakDetail.breakStartMinute !== "";

export interface BreakRowsProps {
    value: ShiftBreakState[];
    onChange: (change: {value: ShiftBreakState[]}) => void;
    initialBreaks: ShiftBreakState[];
}

export const BreakRowsComponent = ({value, onChange, initialBreaks}: BreakRowsProps) => {
    const {i18n} = useI18n();
    const [breaks, setBreaks] = useState(initialBreaks);

    useEffect(() => {
        if (value) {
            setBreaks(value);
        }
    }, [value]);

    const isAllBreaksFilled = breaks.every(isBreakFilled);

    const onChangeHandler = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            let newValue = e.target.value;
            if (e.target.name.endsWith("Hour")) {
                newValue = restrictNumberInputToTime(e.target.value, TimeType.HOUR);
            } else if (e.target.name.endsWith("Minute")) {
                newValue = restrictNumberInputToTime(e.target.value, TimeType.MINUTE);
            }
            const index = Number(e.target.dataset.index);
            const newBreaks = breaks.map((breakData, i) =>
                i === index ? {...breakData, [e.target.name]: newValue} : breakData,
            );
            onChange({value: newBreaks});
        },
        [breaks, onChange],
    );

    const addBreak = useCallback(() => {
        const newBreaks = [...breaks, {breakStartHour: "", breakStartMinute: "", breakDuration: "", key: v4()}];
        onChange({value: newBreaks});
    }, [breaks, onChange]);

    const deleteBreak = (index: number) => {
        breaks.splice(index, 1);
        onChange({value: breaks});
    };

    return (
        <>
            {breaks.map((breakData, index) => (
                <Grid.Item colSpan={12} key={breakData.key}>
                    <Grid columns={12}>
                        <Grid.Item colSpan={6}>
                            <div className="flex items-end gap-1">
                                <TextField
                                    name="breakStartHour"
                                    type="number"
                                    data-index={index}
                                    label={i18n("auth.app.fleet.shifts.shift_form.break_start")}
                                    placeholder={i18n("common.hour_placeholder")}
                                    onChange={onChangeHandler}
                                    value={breakData.breakStartHour}
                                    required
                                    fullWidth
                                />
                                <div className="mb-3">:</div>
                                <TextField
                                    name="breakStartMinute"
                                    type="number"
                                    data-index={index}
                                    placeholder={i18n("common.minute_placeholder")}
                                    onChange={onChangeHandler}
                                    value={breakData.breakStartMinute}
                                    required
                                    fullWidth
                                />
                            </div>
                        </Grid.Item>
                        <Grid.Item colSpan={6}>
                            <div className="flex h-full items-end">
                                <TextField
                                    name="breakDuration"
                                    type="number"
                                    data-index={index}
                                    label={i18n("auth.app.fleet.shifts.shift_form.break_duration")}
                                    placeholder="mm"
                                    onChange={onChangeHandler}
                                    value={breakData.breakDuration}
                                    required
                                    fullWidth
                                    renderStartSlot={renderTimeIcon}
                                />
                                <GhostButton
                                    id={`shift-break-delete-button_${index}`}
                                    aria-label="Delete break"
                                    // onClick on GhostButton often returns one of the child components as the target of
                                    // the event, so instead of adding a data attribute for the index, we have to use an
                                    // arrow function.
                                    // eslint-disable-next-line react/jsx-no-bind -- GhostButton limitation
                                    onClick={() => deleteBreak(index)}
                                    overrideClassName="mb-[13px] ml-3"
                                    disabled={breaks.length < 2}
                                >
                                    <BinOutlined className={breaks.length < 2 ? "text-tertiary" : ""} />
                                </GhostButton>
                            </div>
                        </Grid.Item>
                    </Grid>
                </Grid.Item>
            ))}
            <Grid.Item colSpan={12}>
                <Button
                    type="button"
                    size="sm"
                    startIcon={<Plus />}
                    variant={isAllBreaksFilled ? "tertiary" : "static-light"}
                    onClick={addBreak}
                    disabled={!isAllBreaksFilled}
                    overrideClassName="mt-1"
                >
                    {i18n("auth.app.fleet.shifts.shift_form.add_break")}
                </Button>
            </Grid.Item>
        </>
    );
};
