import {useEffect, useRef} from "react";

import {Point} from "common/geo/components/google-maps/types";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {DriverInfo} from "../../types";

export interface DriverLocationHistoryItem {
    point: Point;
    timestamp: Date;
    driverId: number;
}

const createNewLocations = (currentDrivers: FleetOwnerPortalService.DriverForFleetOwner[]) =>
    currentDrivers
        .filter((driver) => driver.state === FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS)
        .map((driver) => ({
            point: {lat: driver.lat, lng: driver.lng},
            timestamp: new Date(),
            driverId: driver.id,
        }));

const removeOldLocations = (
    locations: DriverLocationHistoryItem[],
    driverTrailPeriodMs: number,
    currentDriverIds: number[],
) =>
    locations.filter(
        (item) =>
            item.timestamp.getTime() > new Date().getTime() - driverTrailPeriodMs &&
            currentDriverIds.includes(item.driverId),
    );

export function useDriverLocationHistory(
    drivers: FleetOwnerPortalService.DriverForFleetOwner[],
    selectedDriver: DriverInfo | null,
    isShowDriverRoutesEnabled: boolean,
    driverTrailPeriodMs: number,
) {
    const driverLocationHistory = useRef<DriverLocationHistoryItem[]>([]);

    useEffect(() => {
        if (!isShowDriverRoutesEnabled) {
            return;
        }

        const currentDriverIds = drivers.map((driver) => driver.id);
        const withoutOldLocations = removeOldLocations(
            driverLocationHistory.current,
            driverTrailPeriodMs,
            currentDriverIds,
        );
        const newLocations = createNewLocations(drivers);

        driverLocationHistory.current = [...withoutOldLocations, ...newLocations];
    }, [isShowDriverRoutesEnabled, drivers, driverTrailPeriodMs]);

    if (selectedDriver) {
        return driverLocationHistory.current.filter((item) => item.driverId === selectedDriver.driver.id);
    }

    return driverLocationHistory.current;
}
