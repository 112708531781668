import {useCallback, useEffect, useRef} from "react";

import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {useSnackbar} from "@bolteu/kalep-react";

import {ShiftActionDialog, ShiftFormType} from "./ShiftActionDialog";

interface Props {
    closeDialog: () => void;
    onShiftCreated: () => void;
}

const createShiftFetchFunction = (api: Api, body: FleetShiftManagementService.ShiftAttributes) =>
    api.fleetShiftManagement.createShift(body);

const AddShiftDialog = ({closeDialog, onShiftCreated}: Props) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const snackbar = useRef(useSnackbar());
    const {fetch: createShift, status, error} = useFetch(createShiftFetchFunction);
    const isLoading = status === FetchStatus.Loading;

    const onAddSubmit = useCallback(
        async (shift: FleetShiftManagementService.ShiftAttributes) => {
            if (createShift) {
                await createShift(shift);
            }
        },
        [createShift],
    );

    useEffect(() => {
        if (status === FetchStatus.Success) {
            onShiftCreated();
            snackbar.current.add(
                {
                    description: i18n("auth.app.fleet.shifts.add_shift.new_shift_created"),
                },
                {timeout: 3000},
            );
            closeDialog();
            trackEvent(EventName.AddNewShiftSubmitSuccessful);
        } else if (status === FetchStatus.Error && error) {
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.AddShift,
                errorMessage: error.message,
            });
        }
    }, [status, trackEvent, i18n, error, onShiftCreated, snackbar, closeDialog]);

    return (
        <ShiftActionDialog
            type={ShiftFormType.ADD}
            closeDialog={closeDialog}
            onSubmit={onAddSubmit}
            isLoading={isLoading}
        />
    );
};

export default AddShiftDialog;
