import {useCallback, useContext} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {ApiContextProvider} from "common/services/apiProvider";
import {logout, redirectToLogin, requestNewRefreshToken} from "common/services/authService";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {ApplicationContextProvider} from "features/app/appStateProvider";
import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";

export const useAuthentication = () => {
    const {observability} = useContext(ThirdPartyContextProvider);
    const appState = useContext(ApplicationContextProvider);
    const api = useContext(ApiContextProvider);
    const accountState = useContext(AccountContextProvider);

    const navigate = useNavigate();
    const location = useLocation();

    const {setIsAuthError, setIsAppReady, setIsAuthenticated, setRefreshToken, refreshToken} = appState;

    const makeLogout = useCallback(
        async (sendEvents: boolean) => {
            if (!accountState) {
                return;
            }

            if (api && refreshToken) {
                try {
                    await api.fleetOwnerAuth.logout({refresh_token: refreshToken});
                } catch (e) {
                    observability.reportError(e as Error);
                }
            }

            await logout(accountState, sendEvents);

            setRefreshToken(null);
            setIsAuthError(false);
            accountState.setProfile(null);
            accountState.setSelectedCompany(null);

            // This should be the last of setStates to not send double page opened events
            setIsAuthenticated(false);

            redirectToLogin(navigate, location);
        },
        [
            accountState,
            api,
            refreshToken,
            setIsAuthError,
            setIsAuthenticated,
            setRefreshToken,
            navigate,
            location,
            observability,
        ],
    );

    const makeLogin = useCallback(
        async (username: string, password: string) => {
            setIsAuthError(false);
            try {
                const newRefreshToken = await requestNewRefreshToken(username, password);

                setRefreshToken(newRefreshToken);

                return true;
            } catch {
                setIsAuthError(true);
                setIsAuthenticated(false);
            } finally {
                setIsAppReady(true);
            }

            return false;
        },
        [setIsAuthError, setIsAppReady, setRefreshToken, setIsAuthenticated],
    );

    return {makeLogout, makeLogin};
};
