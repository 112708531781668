import {useCallback, useEffect} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Dialog, Typography} from "@bolteu/kalep-react";

const fetchFunction = (api: Api, body: FleetPortalOrderService.CancelScheduledRideRequest) =>
    api.fleetPortalOrder.cancelScheduledRide(body);

interface Props {
    onClose: () => void;
    onCancel: () => void;
    orderdId: number | null;
}

export const CancelScheduledRideDialog = ({onClose, onCancel, orderdId}: Props) => {
    const {i18n} = useI18n();
    const {fetch, status} = useFetch(fetchFunction);

    const onCancelClick = useCallback(async () => {
        if (fetch && orderdId) {
            fetch({order_id: orderdId});
        }
    }, [fetch, orderdId]);

    useEffect(() => {
        if ([FetchStatus.Success, FetchStatus.Error].includes(status)) {
            // On error most likely the order is not cancelable anymore
            onCancel();
        }
    }, [status, onCancel]);

    const isApiLoading = status === FetchStatus.Loading;

    return (
        <Dialog
            title={i18n("auth.app.orders.scheduled_rides.cancel-dialog.title")}
            isOpen
            onRequestClose={onClose}
            variant="alert"
        >
            <Dialog.Content>
                <Typography align="center">
                    {i18n("auth.app.orders.scheduled_rides.cancel-dialog.description")}
                </Typography>
            </Dialog.Content>
            <Dialog.Footer>
                <Button
                    variant="danger"
                    onClick={onCancelClick}
                    loading={isApiLoading}
                    fullWidth
                    overrideClassName="sm:max-w-[192px]"
                >
                    {i18n("auth.app.orders.scheduled_rides.cancel-dialog.cancel-order")}
                </Button>
                <Button
                    variant="secondary"
                    onClick={onClose}
                    disabled={isApiLoading}
                    fullWidth
                    overrideClassName="sm:max-w-[192px]"
                >
                    {i18n("auth.app.orders.scheduled_rides.cancel-dialog.dont-cancel")}
                </Button>
            </Dialog.Footer>
        </Dialog>
    );
};
