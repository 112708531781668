import {useId} from "@react-aria/utils";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {Icon} from "./Icon";

interface InfoTextProps {
    fieldConfig: FleetOwnerRegistrationService.InfoTextGroupField;
}

const InfoGroupText = ({fieldConfig}: InfoTextProps) => {
    const {label, values} = fieldConfig;

    const prefix = (icon?: FleetOwnerRegistrationService.IconConfig) =>
        icon ? (
            <span>
                <Icon config={icon} />
            </span>
        ) : null;

    const id = useId();
    return (
        <>
            <label htmlFor={id} className="bolt-font-body-s-accent text-primary w-fit">
                {label}
            </label>
            <ul className="list-disc pl-6" id={id}>
                {values?.map((value) => (
                    <li key={value.value} className="pt-1">
                        {prefix(value.icon)} {value.value}
                    </li>
                ))}
            </ul>
        </>
    );
};

export {InfoGroupText};
