import config from "config/index";

import {
    FleetCompanyNoAuthService,
    FleetOwnerAuthNoAuthService,
    FleetOwnerNoAuthService,
    FleetOwnerVerificationNoAuthService,
    HttpClientLibrary,
} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {localStorageService} from "../LocalStorageService";

const httpClientConfig = {
    baseUrl: config.baseUrl,
};

const httpClientInitConfig = {
    standardRequestStrategy: {
        getStandardRequest: () => ({
            language: localStorageService.getLanguageWithDefaultLocale(),
            version: config.version,
            brand: config.appBrand as unknown as FleetCompanyNoAuthService.BrandEnum,
        }),
    },
};

const authApiHttpClientInitConfig = {
    standardRequestStrategy: {
        getStandardRequest: () => ({
            language: localStorageService.getLanguageWithDefaultLocale(),
            version: config.version.substring(0, 8),
            brand: config.appBrand as unknown as FleetOwnerNoAuthService.BrandEnum,
        }),
    },
};

const fleetCompanyHttpClient =
    new HttpClientLibrary.HttpClientBoltServer<FleetCompanyNoAuthService.HttpClientConstraints>(httpClientConfig);
fleetCompanyHttpClient.init(httpClientInitConfig);
const fleetCompanyNoAuthApiClient: FleetCompanyNoAuthService.ApiClient = new FleetCompanyNoAuthService.ApiClient({
    httpClient: fleetCompanyHttpClient,
});

const fleetOwnerAuthNoAuthHttpClient =
    new HttpClientLibrary.HttpClientBoltServer<FleetOwnerAuthNoAuthService.HttpClientConstraints>(httpClientConfig);
fleetOwnerAuthNoAuthHttpClient.init(authApiHttpClientInitConfig);
const fleetOwnerAuthNoAuthApiClient: FleetOwnerAuthNoAuthService.ApiClient = new FleetOwnerAuthNoAuthService.ApiClient({
    httpClient: fleetOwnerAuthNoAuthHttpClient,
});

const fleetOwnerVerificationNoAuthHttpClient =
    new HttpClientLibrary.HttpClientBoltServer<FleetOwnerVerificationNoAuthService.HttpClientConstraints>(
        httpClientConfig,
    );
fleetOwnerVerificationNoAuthHttpClient.init(httpClientInitConfig);
const fleetOwnerVerificationNoAuthApiClient: FleetOwnerVerificationNoAuthService.ApiClient =
    new FleetOwnerVerificationNoAuthService.ApiClient({
        httpClient: fleetOwnerVerificationNoAuthHttpClient,
    });

export {
    fleetCompanyNoAuthApiClient as FleetCompanyNoAuthApiClient,
    fleetOwnerAuthNoAuthApiClient as FleetOwnerAuthNoAuthApiClient,
    fleetOwnerVerificationNoAuthApiClient as FleetOwnerVerificationNoAuthApiClient,
};
