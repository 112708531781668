import {FC, useCallback, useState} from "react";

import Header, {HeaderSize} from "common/components/header/Header";
import {EventName} from "common/constants/events";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Island, Switch, Typography} from "@bolteu/kalep-react";

import DisableCashRidesDialog from "./DisableCashRidesDialog";

interface PermissionsProps {
    driverId: number;
    setHasCashPayments: (value: boolean) => void;
    isCashPaymentsToggleButtonEnabled: boolean;
    cashPaymentsEnabled: boolean;
}

const toggleCashFetchFunction = (api: Api, body: FleetOwnerPortalService.ToggleCashRequest) =>
    api.fleetOwnerPortal.driverToggleCash(body);

const Permissions: FC<PermissionsProps> = ({
    driverId,
    setHasCashPayments,
    isCashPaymentsToggleButtonEnabled,
    cashPaymentsEnabled,
}) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const [isOpen, setIsOpen] = useState(false);

    const {fetch: toggleCashFetch} = useFetch(toggleCashFetchFunction);

    const handleCashPaymentsToggle = useCallback(() => {
        if (!toggleCashFetch) {
            return;
        }

        toggleCashFetch({driver_id: driverId, has_cash_payment: !cashPaymentsEnabled});
        const eventName = cashPaymentsEnabled ? EventName.DriverCashDisabled : EventName.DriverCashEnabled;
        trackEvent(eventName);
        setHasCashPayments(!cashPaymentsEnabled);
    }, [toggleCashFetch, driverId, cashPaymentsEnabled, trackEvent, setHasCashPayments]);

    const handleChange = useCallback(() => {
        if (!cashPaymentsEnabled) {
            handleCashPaymentsToggle();
        } else {
            setIsOpen(true);
        }
    }, [cashPaymentsEnabled, handleCashPaymentsToggle]);

    const confirmDialog = useCallback(() => {
        handleCashPaymentsToggle();
        setIsOpen(false);
    }, [handleCashPaymentsToggle]);

    return (
        <div>
            <Island>
                <Header
                    size={HeaderSize.ExtraSmall}
                    text={i18n("auth.app.fleet.driver-profile.permissions")}
                    className="pb-4"
                />
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col">
                        <p className="text-secondary text-base font-normal">
                            {i18n("auth.app.fleet.driver-profile.cash_rides")}
                        </p>
                        <p className="text-base font-normal">
                            {cashPaymentsEnabled ? i18n("common.enabled") : i18n("common.disabled")}
                        </p>
                    </div>
                    <div className="my-auto">
                        <Switch
                            checked={cashPaymentsEnabled}
                            onChange={handleChange}
                            disabled={!isCashPaymentsToggleButtonEnabled}
                        />
                    </div>
                </div>
                {!isCashPaymentsToggleButtonEnabled && (
                    <>
                        <div className="border-separator my-3 h-0 border-b-[1px]">&nbsp;</div>
                        <Typography variant="body-secondary" color="secondary">
                            {i18n("auth.app.fleet.driver-profile.modify_driver_permissions")}
                        </Typography>
                    </>
                )}
            </Island>
            <DisableCashRidesDialog confirmDialog={confirmDialog} isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
};

export default Permissions;
