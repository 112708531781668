import {useContext, useMemo} from "react";

import {CommonRoute, FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {CompanyManagementLayoutVariant} from "common/layouts/CompanyManagementLayout";
import {FeaturesContextProvider} from "FeaturesProvider";

export interface NavigationLink {
    title: string;
    route: CommonRoute | FleetRoute; // | HoldingFleetRoute;
}

export const useNavigationLinks = (variant: CompanyManagementLayoutVariant) => {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);

    const navigationLinks = useMemo(() => {
        const holdingFleetLinksGroup: NavigationLink[] = [
            {
                title: i18n("common.invoices"),
                route: CommonRoute.INVOICES,
            },
        ];

        const inactiveFleetLinksGroup: NavigationLink[] = [
            ...(features?.company_under_review?.add_driver
                ? [
                      {
                          title: i18n("auth.app.fleet.drivers.title"),
                          route: FleetRoute.DRIVERS,
                      },
                  ]
                : []),
            ...(features?.company_under_review?.add_vehicle
                ? [
                      {
                          title: i18n("auth.app.sidebar.vehicles"),
                          route: FleetRoute.VEHICLES,
                      },
                  ]
                : []),
        ];

        const fleetLinksMainGroup: NavigationLink[] = [
            {
                title: i18n("auth.app.fleet.drivers.title"),
                route: FleetRoute.DRIVERS,
            },
            {
                title: i18n("auth.app.sidebar.vehicles"),
                route: FleetRoute.VEHICLES,
            },
            ...(features?.earning_reports
                ? [
                      {
                          title: i18n("auth.app.fleet.reports.title"),
                          route: FleetRoute.REPORTS,
                      },
                  ]
                : []),
            {
                title: i18n("common.invoices"),
                route: CommonRoute.INVOICES,
            },
            {
                title: i18n("auth.app.orders.title"),
                route: FleetRoute.ORDERS,
            },
            ...(features?.live_map
                ? [
                      {
                          title: i18n("auth.app.sidebar.live-map_v2"),
                          route: FleetRoute.LIVE_MAP,
                      },
                  ]
                : []),
        ];

        const fleetLinksCompanyGroup: NavigationLink[] = [
            {
                title: i18n("auth.app.company-settings.title_v2"),
                route: FleetRoute.COMPANY_SETTINGS,
            },
        ];

        const fleetLinksOthersGroup: NavigationLink[] = [
            {
                title: i18n("auth.app.sidebar.expiring-documents_v2"),
                route: FleetRoute.EXPIRING_DOCUMENTS,
            },
            ...(features?.shifts
                ? [
                      {
                          title: i18n("auth.app.sidebar.shifts"),
                          route: FleetRoute.SHIFTS,
                      },
                  ]
                : []),
            ...(features?.campaigns
                ? [
                      {
                          title: i18n("auth.app.sidebar.campaigns"),
                          route: FleetRoute.CAMPAIGNS,
                      },
                  ]
                : []),
            ...(features?.balance
                ? [
                      {
                          title: i18n("auth.app.sidebar.balance"),
                          route: FleetRoute.BALANCE,
                      },
                  ]
                : []),
            ...(features?.company_metrics
                ? [
                      {
                          title: i18n("auth.app.sidebar.performance"),
                          route: FleetRoute.DASHBOARD,
                      },
                  ]
                : []),
        ];

        const holdingFleetLinks = [holdingFleetLinksGroup];
        const inactiveFleetLinks = [inactiveFleetLinksGroup];
        const fleetLinks = [fleetLinksMainGroup, fleetLinksCompanyGroup, fleetLinksOthersGroup];

        if (variant === "FLEET") {
            return fleetLinks;
        }
        if (variant === "HOLDING_FLEET") {
            return holdingFleetLinks;
        }
        if (variant === "INACTIVE_COMPANY") {
            return inactiveFleetLinks;
        }
        return [];
    }, [
        i18n,
        features?.company_under_review,
        features?.company_metrics,
        features?.earning_reports,
        features?.shifts,
        features?.campaigns,
        features?.balance,
        features?.live_map,
        variant,
    ]);

    return navigationLinks;
};
