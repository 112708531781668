import {useCallback, useEffect, useState} from "react";

import DiscoveryToolTip from "common/components/fixedTooltip/DiscoveryToolTip";
import {useI18n} from "common/hooks/useI18n";
import {localStorageService} from "common/services/LocalStorageService";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, IconButton, Tooltip, Typography} from "@bolteu/kalep-react";
import {Edit, InfoCircleOutlined} from "@bolteu/kalep-react-icons";

import DriverCategoryItem from "./DriverCateforyItem";

interface DriverCategoriesRendererProps {
    title: string;
    buttonText: string;
    tooltipText?: string;
    onButtonClicked: () => void;
    discoveryTooltipContent?: string;
    driverCategories: FleetOwnerPortalService.InternalSearchCategoriesResponse;
}

interface DriverCategoriesSettings {
    discoveryTooltipDismissed: boolean;
}

export default function DriverCategoriesRenderer({
    title,
    buttonText,
    tooltipText,
    onButtonClicked,
    discoveryTooltipContent,
    driverCategories,
}: DriverCategoriesRendererProps) {
    const size = useTailwindViewport();
    const {i18n} = useI18n();

    const [isDiscoveryTooltipActivated, setIsDiscoveryTooltipActivated] = useState(false);

    const [settings, setSettings] = useLocalStorage<DriverCategoriesSettings>(
        localStorageService.getVehicleAccessSettingsKey(),
        {
            discoveryTooltipDismissed: false,
        },
    );

    useEffect(() => {
        if (settings.discoveryTooltipDismissed) {
            return () => {};
        }

        const timeOutId = setTimeout(() => {
            setIsDiscoveryTooltipActivated(true);
        }, 1000);

        return () => {
            clearTimeout(timeOutId);
        };
    });

    const onDiscoveryTooltipClosed = useCallback(() => {
        setSettings({...settings, discoveryTooltipDismissed: true});
    }, [settings, setSettings]);

    const activeCategories = driverCategories.list.filter((category) => category.selected);
    const inactiveCategories = driverCategories.list.filter((category) => !category.selected);

    const getCategoriesText = (categories: FleetOwnerPortalService.InternalSearchCategory[]) => {
        if (!categories.length) {
            return "-";
        }
        const categoriesMap = new Map(categories.map((category) => [category.group, category]));
        const makeCategoryString = (category: FleetOwnerPortalService.InternalSearchCategory) =>
            `${category.name}${
                category.opt_out_disabled
                    ? ` (${i18n("auth.app.fleet.driver-profile.driver_categories.required")})`
                    : ""
            }`;
        return Array.from(categoriesMap.values()).map(makeCategoryString).join(", ");
    };

    return (
        <div className="flex w-full flex-col">
            <div className="flex items-center gap-2">
                <Typography variant="body-l-accent">{title}</Typography>
                {tooltipText && (
                    <Tooltip content={tooltipText}>
                        <IconButton
                            variant="ghost"
                            icon={<InfoCircleOutlined />}
                            aria-label="manage-driver-categories-info"
                        />
                    </Tooltip>
                )}
            </div>
            <div className="md:border-separator flex flex-col justify-between gap-2 md:max-w-[480px] md:flex-row md:items-center md:border-b md:py-2 ">
                <div className="flex w-full flex-col gap-2">
                    <div className="flex w-full items-center gap-12">
                        <span className="text-secondary">
                            <DriverCategoryItem
                                title={i18n("auth.app.fleet.driver-profile.driver_categories.active_categories")}
                                subtitle={getCategoriesText(activeCategories)}
                            />
                        </span>
                    </div>
                </div>
                <div className="py-2">
                    <DiscoveryToolTip
                        title={i18n("auth.app.fleet.driver-profile.driver_categories.discovery_tooltip.title")}
                        content={discoveryTooltipContent ?? ""}
                        active={isDiscoveryTooltipActivated}
                        onClose={onDiscoveryTooltipClosed}
                    >
                        <>
                            {size === "sm" && (
                                <Button variant="secondary" onClick={onButtonClicked}>
                                    {buttonText}
                                </Button>
                            )}
                            {size !== "sm" && (
                                <IconButton
                                    onClick={onButtonClicked}
                                    variant="secondary"
                                    icon={<Edit />}
                                    aria-label="edit-vehicle-info"
                                />
                            )}
                        </>
                    </DiscoveryToolTip>
                </div>
            </div>
            <div className="md:border-separator justify-between gap-2 md:max-w-[480px] md:flex-row md:items-center md:border-b md:py-2">
                <DriverCategoryItem
                    title={i18n("auth.app.fleet.driver-profile.driver_categories.inactive_categories")}
                    subtitle={getCategoriesText(inactiveCategories)}
                />
            </div>
        </div>
    );
}
