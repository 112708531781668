import {useCallback, useEffect, useMemo, useState} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {Button, Dialog, Select, SelectOption, TextArea, Typography} from "@bolteu/kalep-react";

interface Props {
    isOpen: boolean;
    onCancel: () => void;
    onDecline: (reason: string, comment?: string) => void;
}

const getDelineReasonsFn = (api: Api) => api.vehicleMarketplace.vehicleApplicationGetDeclineReasons();

export default function DeclineApplicationDialog({isOpen, onCancel, onDecline}: Props) {
    const {i18n} = useI18n();
    const [reason, setReason] = useState<string | undefined>(undefined);
    const [comment, setComment] = useState<string | undefined>(undefined);

    const {fetch: getDelineReasons, data, status: getDelineReasonsStatus} = useFetch(getDelineReasonsFn);

    const declineReasons = useMemo(
        () =>
            data?.reasons.map((r) => ({
                title: r.reason,
                value: r.reason_key,
            })) ?? [],
        [data],
    );

    const handleReasonChange = useCallback((option: SelectOption | SelectOption[] | null) => {
        setReason((option as SelectOption)?.value as string);
    }, []);

    const handleCommentChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    }, []);

    const onDeclineClicked = useCallback(() => {
        if (reason) {
            onDecline(reason, comment);
        }
    }, [onDecline, reason, comment]);

    useEffect(() => {
        if (getDelineReasons) {
            getDelineReasons({});
        }
    }, [getDelineReasons]);

    return (
        <Dialog
            title={i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.decline.title")}
            isOpen={isOpen}
            onRequestClose={onCancel}
        >
            <Dialog.Content>
                <div className="flex flex-col gap-6">
                    <div className="text-secondary">
                        {i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.decline.description")}
                    </div>
                    <div className="flex flex-col gap-1">
                        <Typography variant="body-s-accent">
                            {i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.decline.select_reason")}
                        </Typography>
                        <Select
                            options={declineReasons}
                            placeholder={i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.select")}
                            onChange={handleReasonChange}
                            loading={getDelineReasonsStatus === FetchStatus.Loading}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="flex w-full gap-2">
                            <Typography variant="body-s-accent">
                                {i18n(
                                    "auth.app.fleet.vehicle_marketplace.applications.view.dialog.decline.add_comment",
                                )}
                            </Typography>
                            <Typography variant="body-s-regular">
                                {`(${i18n(
                                    "auth.app.fleet.vehicle_marketplace.applications.view.dialog.decline.optional",
                                )})`}
                            </Typography>
                        </div>
                        <TextArea
                            placeholder={i18n(
                                "auth.app.fleet.vehicle_marketplace.applications.view.dialog.decline.add_comment",
                            )}
                            onChange={handleCommentChange}
                            maxLength={255}
                        />
                    </div>
                </div>
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button variant="secondary" onClick={onCancel}>
                        {i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.cancel")}
                    </Button>
                    <Button onClick={onDeclineClicked} variant="danger" disabled={!reason}>
                        {i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.decline.decline_request")}
                    </Button>
                </div>
            </Dialog.Footer>
        </Dialog>
    );
}
