import {Typography} from "@bolteu/kalep-react";

interface DriverCategoryItemProps {
    title: string;
    subtitle: string;
}

function DriverCategoryItem(driverCategory: DriverCategoryItemProps) {
    return (
        <div className="flex w-full flex-col">
            <div className="flex items-center gap-2">
                <Typography variant="label-primary">
                    <span className="text-secondary">{driverCategory.title}</span>
                </Typography>
            </div>
            <div className="flex items-center gap-2">
                <Typography variant="body-primary">{driverCategory.subtitle}</Typography>
            </div>
        </div>
    );
}

export default DriverCategoryItem;
