import {useContext, useEffect, useMemo} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {SelectOption} from "@bolteu/kalep-react";

const getCarGroupsFetchFunction = (api: Api) => api.fleetRegistration.getCarGroupsList({});

export function useCarGroups() {
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState?.getFleet();

    const {data, fetch, status} = useFetch(getCarGroupsFetchFunction);

    useEffect(() => {
        if (fetch && fleet) {
            fetch({});
        }
    }, [fetch, fleet]);

    const carGroups = useMemo(() => {
        if (FetchStatus.Success === status) {
            return data.list.map(
                (g) =>
                    ({
                        value: g.id,
                        title: g.name,
                    } as SelectOption),
            );
        }
        return [];
    }, [data, status]);

    return {carGroups, fetchStatus: status};
}
