import {useCallback, useContext, useMemo, useState} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";

import Popover, {PopoverTrigger} from "common/components/popover/Popover";
import {CommonRoute, CompanyOnboardingRoutePrefix} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {getIsShowSmsAnnouncement, setIsShownSmsAnnouncement} from "common/services/announcementsService";
import {checkIfCompanyOnboardingRoute, checkIfHoldingFleetRoute} from "common/util/routeUtils";
import {AccountContextProvider} from "features/account/accountStateProvider";
import CompanySelectorWithApplication from "features/account/components/CompanySelectorWithApplication";

import {IconButton} from "@bolteu/kalep-react";
import {HelpCircle} from "@bolteu/kalep-react-icons";

import NotificationQuickView from "../../../features/app/notifications/NotificationQuickView";
import {NavigationBarVisbilityContextProvider} from "../../../NavigationBarVisibilityProvider";
import {NavigationLink} from "../hooks/useNavigationLinks";
import {LogoLink} from "./LogoLink";
import {NavWrapper} from "./NavWrapper";
import {OthersMenu} from "./OthersMenu";
import {SidebarButton} from "./Sidebar";
import {TopBarProfileMenu} from "./TopBarProfileMenu";

const TopbarLink = ({title, route}: NavigationLink) => {
    const linkClassname: (props: {isActive: boolean; isPending: boolean}) => string | undefined = useCallback(
        ({isActive}) =>
            `flex justify-center items-center text-center h-full border-box px-3 py-2 border-b-[3px] hover:text-action-secondary ${
                isActive ? "font-semibold border-action-primary" : "border-transparent"
            }`,
        [],
    );

    return (
        <NavLink to={route} className={linkClassname}>
            {title}
        </NavLink>
    );
};

interface TopbarProps {
    navigationLinks: NavigationLink[][];
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    toggleSupportWidget: () => void;
    isSupportWidgetEnabled: boolean;
    hasUnreadSupportCases: boolean;
}

export const Topbar = ({
    navigationLinks,
    isSidebarOpen,
    toggleSidebar,
    toggleSupportWidget,
    isSupportWidgetEnabled,
    hasUnreadSupportCases,
}: TopbarProps) => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const location = useLocation();
    const {isNavigationBarVisible} = useContext(NavigationBarVisbilityContextProvider);

    const [isPopoverOpen, setIsPopoverOpen] = useState(getIsShowSmsAnnouncement());
    const onPopoverClose = useCallback(() => {
        setIsShownSmsAnnouncement();
        setIsPopoverOpen(false);
    }, []);

    const onNavigateToSettings = useCallback(() => {
        navigate(CommonRoute.SETTINGS);
        onPopoverClose();
    }, [navigate, onPopoverClose]);

    const selectedCompany = useContext(AccountContextProvider)?.selectedCompany?.company;

    const isCompanyOnboardingRoute = checkIfCompanyOnboardingRoute(location.pathname);
    const isHoldingFleetRoute = checkIfHoldingFleetRoute(location.pathname);
    const isFleetRoute = !isCompanyOnboardingRoute && !isHoldingFleetRoute;

    const [fleetLinksFirstGroup = []] = navigationLinks;
    const fleetLinksOthersGroup = [...navigationLinks].pop() || [];

    const SupportButton = isSupportWidgetEnabled ? (
        <IconButton
            icon={
                <div className="relative leading-none">
                    <HelpCircle className="h-6 w-6 md:h-5 md:w-5" />
                    {hasUnreadSupportCases && (
                        <span className="bg-warning-primary border-primary-inverted absolute -top-1 -right-1 z-50 h-3 w-3 rounded-full border-2" />
                    )}
                </div>
            }
            variant="ghost"
            size="sm"
            aria-label="Support"
            onClick={toggleSupportWidget}
            value="csBtn"
        />
    ) : null;

    const homePath: string = useMemo(() => {
        if (isCompanyOnboardingRoute) {
            return `/${CompanyOnboardingRoutePrefix}/${selectedCompany?.id}/`;
        }
        return CommonRoute.HOME;
    }, [isCompanyOnboardingRoute, selectedCompany?.id]);

    if (!isNavigationBarVisible) {
        return null;
    }

    return (
        <NavWrapper>
            <div className="flex items-center" data-testid="navbar">
                <SidebarButton isSidebarOpen={isSidebarOpen} onClick={toggleSidebar} />
                <LogoLink to={homePath} />
                <div className="ml-12 hidden h-full items-center gap-4 xl:flex">
                    {!isFleetRoute &&
                        fleetLinksFirstGroup.map((link) => (
                            <TopbarLink title={link.title} route={link.route} key={link.route} />
                        ))}
                    {isFleetRoute && (
                        <>
                            {[fleetLinksFirstGroup].map((linkGroup, i) => (
                                // We have no better key than the index here
                                <div key={`linkgroup-${i + 1}`} className="flex h-full items-center gap-4">
                                    {linkGroup.map((link) => (
                                        <TopbarLink title={link.title} route={link.route} key={link.route} />
                                    ))}
                                </div>
                            ))}
                            <OthersMenu navigationLinks={fleetLinksOthersGroup} />
                        </>
                    )}
                </div>
            </div>
            <div className="ml-8 flex min-w-0 items-center gap-4">
                <NotificationQuickView />
                {SupportButton}
                <div className="hidden md:block">
                    <CompanySelectorWithApplication />
                </div>
                <PopoverTrigger isOpen={isPopoverOpen} onClose={onPopoverClose}>
                    <Popover
                        header={i18n("announcements.sms_activated.title")}
                        text={i18n("announcements.sms_activated.body")}
                        actionText={i18n("announcements.sms_activated.button")}
                        placement="bottom"
                        onAction={onNavigateToSettings}
                        offset={11}
                    />
                    <div className="hidden md:block">
                        <TopBarProfileMenu />
                    </div>
                </PopoverTrigger>
            </div>
        </NavWrapper>
    );
};
