import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";

import OrangeBadge from "assets/icons/badge-orange-s.svg?react";
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {FleetRoute} from "common/constants/routes";
import {isActiveOrBlockedFleet} from "common/constants/types";
import {useAuthentication} from "common/hooks/useAuthentication";
import {useI18n} from "common/hooks/useI18n";
import {useTopBarHeightTailwindConfig} from "common/hooks/useTopBarHeightTailwindConfig";
import {NavGroupSeparator} from "common/navigation/components/NavGroupSeparator";
import SubMenu, {NavBarVariant} from "common/navigation/components/SubMenu";
import {MenuContent} from "common/navigation/hooks/useNavigationLinksV2";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {useCompanySelector} from "features/account/components/CompanySelectorWithApplication/hooks/useCompanySelector";
import MobileCompanySelector from "features/account/components/MobileCompanySelector";
import {useNotificationApi} from "features/app/notifications/hooks/useNotificationApi";
import {SupportWidgetContextProvider} from "SupportWidgetProvider";

import {Accordion, AccordionItem, GhostButton, ListItemLayout, Typography} from "@bolteu/kalep-react";
import {ChevronRight, LogOut} from "@bolteu/kalep-react-icons";

interface SidebarProps {
    isOpen: boolean;
    navigationLinks: MenuContent[];
    setOpen: (isOpen: boolean) => void;
}

export const SidebarV2 = ({isOpen, navigationLinks, setOpen}: SidebarProps) => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {makeLogout} = useAuthentication();
    const {getNotifications, data} = useNotificationApi();
    const {getDescription, getCompanyName} = useCompanySelector();
    const {sideBarTopClass: SIDE_BAR_TOP_CLASS} = useTopBarHeightTailwindConfig();

    const accountState = useContext(AccountContextProvider);
    const {isSupportWidgetEnabled, hasUnreadCases, toggleSupportWidget} = useContext(SupportWidgetContextProvider);

    const [isCompanySelectorShown, setIsCompanySelectorShown] = useState(false);

    const selectedCompany = accountState.selectedCompany?.company;
    const selectedCompanyName = getCompanyName(selectedCompany);
    const description = getDescription(selectedCompany, false, true);
    const hasCompanies = Boolean(accountState.profile?.fleets.length || accountState.profile?.holdingFleets.length);

    useEffect(
        () => () => {
            clearAllBodyScrollLocks();
        },
        [],
    );

    useEffect(() => {
        // TODO getting notifications here and in the TopBar NotificationQuickView, 2 API calls made for same API!
        // Should have some context for the red dot?
        if (isActiveOrBlockedFleet(selectedCompany)) {
            getNotifications();
        }
    }, [getNotifications, selectedCompany]);

    const renderRightArrow = useCallback(() => <ChevronRight className="text-tertiary" />, []);

    const ref = useRef<HTMLDivElement>(null);

    const onCompanyClick = useCallback(() => {
        setIsCompanySelectorShown(true);
        if (ref.current) {
            disableBodyScroll(ref.current, {
                allowTouchMove: (el) => {
                    while (el && el !== document.body) {
                        if (el.id === "body-scroll-lock-ignore") {
                            return true;
                        }

                        // eslint-disable-next-line no-param-reassign -- invalid
                        el = el.parentElement!;
                    }
                    return false;
                },
            });
        }
    }, []);

    const onCompanySelectorClose = useCallback(() => {
        setIsCompanySelectorShown(false);
        if (ref.current) {
            enableBodyScroll(ref.current);
        }
    }, []);

    const hasUnreadNotifications = useMemo(
        () => data?.list?.some((notification) => !notification.read_timestamp) ?? false,
        [data?.list],
    );

    const onNotificationsButtonClick = useCallback(() => {
        setOpen(false);
        navigate(FleetRoute.NOTIFICATIONS, {state: {exitToPreviousPage: true}});
    }, [navigate, setOpen]);

    const onSupportButtonClick = useCallback(() => {
        setOpen(false);
        toggleSupportWidget();
    }, [setOpen, toggleSupportWidget]);

    const closeSideBar = useCallback(() => setOpen(false), [setOpen]);

    const onLogoutClick = useCallback(() => {
        makeLogout(true);
    }, [makeLogout]);

    const NotificationsButton = isActiveOrBlockedFleet(selectedCompany) ? (
        <div className="px-7 pt-3 pb-4">
            <GhostButton onClick={onNotificationsButtonClick} overrideClassName="w-full">
                <div className="flex flex-row items-center justify-between">
                    <Typography>{i18n("auth.app.notifications.title")}</Typography>
                    {hasUnreadNotifications && <OrangeBadge />}
                </div>
            </GhostButton>
        </div>
    ) : null;

    const SupportButton = isSupportWidgetEnabled ? (
        <div className="px-7 pt-3 pb-4">
            <GhostButton onClick={onSupportButtonClick} overrideClassName="w-full">
                <div className="flex flex-row items-center justify-between">
                    <Typography>Support {/* TODO translation */}</Typography>
                    {hasUnreadCases && <OrangeBadge />}
                </div>
            </GhostButton>
        </div>
    ) : null;

    const LogOutButton = (
        <div className="px-7 pt-3 pb-4">
            <GhostButton onClick={onLogoutClick}>
                <Typography color="danger-primary">
                    <div className="flex flex-row gap-2 align-middle">
                        <LogOut />
                        {i18n("common.log_out")}
                    </div>
                </Typography>
            </GhostButton>
        </div>
    );

    return (
        <div
            className={`scrolling-touch fixed ${SIDE_BAR_TOP_CLASS} bg-layer-floor-1 bottom-0 left-0 z-30 box-border w-10/12 overflow-auto shadow transition-transform duration-300 ease-in-out sm:w-[330px] xl:hidden ${
                !isOpen ? "-translate-x-full" : ""
            }`}
        >
            <div className="relative flex h-full min-h-full flex-col justify-between">
                <div className="flex flex-col pt-2">
                    {hasCompanies && (
                        <div className="grow px-3 md:hidden">
                            <GhostButton
                                onClick={onCompanyClick}
                                overrideClassName="block w-full leading-snug truncate hover:bg-neutral-secondary focus:bg-neutral-secondary rounded-md"
                            >
                                <ListItemLayout
                                    variant="base"
                                    primary={selectedCompanyName}
                                    secondary={description}
                                    renderEndSlot={renderRightArrow}
                                />
                            </GhostButton>
                        </div>
                    )}
                    <div className="md:hidden">{navigationLinks.length > 0 && <NavGroupSeparator />}</div>
                    <div className="grow">
                        {navigationLinks.map((item) => {
                            if (item.subMenuItems.length === 0 && item.route) {
                                return (
                                    <NavLink
                                        to={item.route}
                                        className="hover:border-action-secondary min-h-12 flex w-full items-center justify-start py-3 px-6"
                                        onClick={closeSideBar}
                                    >
                                        {({isActive}) => (
                                            <Typography color="primary" fontWeight={isActive ? "semibold" : "regular"}>
                                                {item.title}
                                            </Typography>
                                        )}
                                    </NavLink>
                                );
                            }
                            if (item.subMenuItems.length === 0) {
                                return null;
                            }
                            return (
                                <Accordion key={item.title} showSeparator={false}>
                                    <AccordionItem id={item.title} title={{primary: item.title}}>
                                        {item.subMenuItems.map((section) => (
                                            <SubMenu
                                                key={section.title}
                                                section={section}
                                                variant={NavBarVariant.SIDEBAR}
                                                onLinkClick={closeSideBar}
                                            />
                                        ))}
                                    </AccordionItem>
                                </Accordion>
                            );
                        })}
                    </div>
                    <NavGroupSeparator />
                    {NotificationsButton}
                    {SupportButton}
                    {(!!NotificationsButton || !!SupportButton) && <NavGroupSeparator />}
                    {LogOutButton}
                </div>
            </div>
            <MobileCompanySelector isOpen={isCompanySelectorShown} onRequestClose={onCompanySelectorClose} ref={ref} />
        </div>
    );
};
