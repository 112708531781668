import {ChangeEventHandler, useCallback, useEffect, useState} from "react";

import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";

import {FleetCarService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, TextField} from "@bolteu/kalep-react";
import {Clear} from "@bolteu/kalep-react-icons";

import {VisibleVehicleField} from "./VehicleData";

interface Props {
    value: string;
    title: string;
    fieldName: VisibleVehicleField;
    id: number;
    closeEdit: (newValue?: string) => void;
}

const updateCarFetch = (api: Api, body: FleetCarService.UpdateCarRequest) => api.fleetCar.carUpdate(body);

const EditRow = ({value, title, fieldName, id, closeEdit}: Props) => {
    const {i18n} = useI18n();
    const [fieldValue, setFieldValue] = useState(value);
    const {trackEvent} = useTracking();
    const {fetch: update, status: updateStatus, error} = useFetch(updateCarFetch);

    useEffect(() => {
        if (updateStatus === FetchStatus.Success) {
            trackEvent(EventName.VehicleFieldChanged, {field: fieldName});
            closeEdit(fieldValue);
        }
    }, [closeEdit, fieldName, fieldValue, trackEvent, updateStatus]);

    useEffect(() => {
        if (updateStatus === FetchStatus.Error && error.message !== "") {
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.VehicleField,
                errorMessage: `${error.message},${fieldName}`,
            });
        }
    }, [error.message, fieldName, trackEvent, updateStatus]);

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => setFieldValue(e.target.value), []);

    const handleCancelClick = useCallback(() => {
        closeEdit();
    }, [closeEdit]);

    const handleSave = useCallback(() => {
        if (!update) {
            return;
        }
        const body: FleetCarService.UpdateCarRequest = {id};
        if (fieldName === "car_transport_licence_number") {
            body[fieldName] = fieldValue;
        }
        update(body);
    }, [fieldName, id, update, fieldValue]);

    const handleClearClick = useCallback(() => {
        setFieldValue("");
    }, []);

    const renderClearIcon = useCallback(
        () => (
            <Clear
                className="text-tertiary hover:text-secondary hover:cursor-pointer active:scale-[.95] active:duration-100 active:ease-in-out"
                onClick={handleClearClick}
            />
        ),
        [handleClearClick],
    );

    const isLoading = updateStatus === FetchStatus.Loading;

    return (
        <div className="w-full">
            <TextField
                label={title}
                value={fieldValue}
                onChange={handleChange}
                fullWidth
                renderEndSlot={renderClearIcon}
                disabled={isLoading}
                required
            />
            <div className="mt-3 flex gap-3">
                <Button
                    size="sm"
                    variant="secondary"
                    overrideClassName="text-sm px-8"
                    onClick={handleCancelClick}
                    loading={isLoading}
                >
                    {i18n("common.cancel")}
                </Button>
                <Button
                    size="sm"
                    overrideClassName="text-sm px-8"
                    loading={isLoading}
                    onClick={handleSave}
                    disabled={fieldValue === ""}
                >
                    {i18n("common.save")}
                </Button>
            </div>
        </div>
    );
};

export default EditRow;
