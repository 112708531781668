import {useIntl} from "react-intl";

import {apiTimestampToDate} from "common/util/apiTimestampToDate";
import {Dash} from "@fleet/common/constants/dash";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";
import {getDayMonthTimeDate, getDayMonthYearDate, getTime} from "@fleet/common/utils/dateFormatUtils";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography, TypographyColor} from "@bolteu/kalep-react";

import DateType = FleetOwnerPortalService.DateType;

interface Props {
    value: number;
    isSubCell?: boolean;
    type: DateType;
}

export const DateCell = ({value, isSubCell, type}: Props) => {
    const intl = useIntl();
    const isMobile = useTailwindViewport() === "sm";
    const fontSize = isSubCell ? "text-xs" : "text-sm";
    let color: TypographyColor | undefined = isSubCell ? "secondary" : undefined;

    let dateStr: string | undefined;
    let timeStr: string | undefined;
    if (value === 0) {
        dateStr = Dash.EM;
        color = "tertiary";
    } else {
        switch (type) {
            case DateType.DATETIME:
                dateStr = getDayMonthYearDate(apiTimestampToDate(value), intl);
                timeStr = getTime(apiTimestampToDate(value));
                break;
            case DateType.SHORT_DATETIME: {
                const unix = Math.floor(apiTimestampToDate(value).getTime() / 1000);
                dateStr = getDayMonthTimeDate(unix, intl);
                break;
            }
            default:
                UnreachableCode.never(type);
                dateStr = getDayMonthYearDate(apiTimestampToDate(value), intl);
                timeStr = getTime(apiTimestampToDate(value));
        }
    }
    if (!isSubCell && !isMobile) {
        return (
            <div className="flex flex-col justify-start whitespace-nowrap">
                <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                    {dateStr}
                </Typography>
                {timeStr && (
                    <Typography variant="body-tabular-xs-regular" color="secondary">
                        {timeStr}
                    </Typography>
                )}
            </div>
        );
    }

    const dateText = timeStr ? `${dateStr}, ${timeStr}` : dateStr;
    return (
        <div className="flex justify-start whitespace-nowrap">
            <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                {dateText}
            </Typography>
        </div>
    );
};
