import {useCallback, useEffect, useRef, useState} from "react";

import {useI18n} from "common/hooks/useI18n";

interface InfiniteScrollviewProps {
    children: React.ReactNode;
    onListEndReached?: () => void;
    isMoreDataAvailable?: boolean;
}

export default function InfiniteScrollview({children, isMoreDataAvailable, onListEndReached}: InfiniteScrollviewProps) {
    const scrollViewRef = useRef<HTMLDivElement>(null);
    const {i18n} = useI18n();
    const [isScrollable, setIsScrollable] = useState(false);

    const handleScroll = useCallback(() => {
        const el = scrollViewRef.current;
        if (!el || !isMoreDataAvailable) {
            return;
        }

        setIsScrollable(el.scrollHeight > el.clientHeight);

        const isAtBottom = el.scrollTop + el.clientHeight >= el.scrollHeight - 40;
        if (isAtBottom) {
            onListEndReached?.();
        }
    }, [onListEndReached, isMoreDataAvailable]);

    useEffect(() => {
        const el = scrollViewRef.current;

        el?.addEventListener("scroll", handleScroll);
        return () => {
            el?.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    return (
        <div className="hover:scrollbar-thin no-scrollbar h-full overflow-scroll" ref={scrollViewRef}>
            {children}
            {isMoreDataAvailable && isScrollable && (
                <div className="text-secondary flex h-12 items-center justify-center">{i18n("api.loading")}</div>
            )}
        </div>
    );
}
