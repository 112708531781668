import {useMemo} from "react";

import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, Period} from "common/services/period";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {DriverEarningsService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface DowloadBody {
    format: DriverEarningsService.FileFormat;
}

export function useDriverEarningsDownloadApi(
    period: ApiPeriod,
): (api: Api, {format}: DowloadBody) => Promise<DriverEarningsService.GetEarningsDownloadResponse> {
    return useMemo(() => {
        const dates =
            period.period === Period.CUSTOM ? {start: period.startDate, end: period.endDate} : apiPeriodToDates(period);
        return (api: Api, {format}: DowloadBody) =>
            api.driverEarnings.getEarningsDownload({
                start_date: getIsoDate(dates.start),
                end_date: getIsoDate(dates.end),
                format,
            });
    }, [period]);
}
