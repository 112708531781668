import {Link} from "react-router-dom";

import Logo from "assets/icons/bw-logo.svg?react";

import {NavWrapper} from "./NavWrapper";
import {SidebarButton} from "./Sidebar";
import {TopBarProfileMenu} from "./TopBarProfileMenu";

interface NonAccountTopBarProps {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
}

export const NonAccountTopBarV2 = ({isSidebarOpen, toggleSidebar}: NonAccountTopBarProps) => (
    <NavWrapper>
        <div className="flex items-center" data-testid="navbar">
            <div className="md:hidden">
                <SidebarButton isSidebarOpen={isSidebarOpen} onClick={toggleSidebar} />
            </div>
            <div className="flex items-center" data-testid="empty-navbar">
                <Link to="/" className="">
                    <Logo className="" />
                </Link>
            </div>
        </div>
        <div className="ml-8 flex min-w-0 items-center gap-4">
            <div className="hidden sm:block">
                <TopBarProfileMenu />
            </div>
        </div>
    </NavWrapper>
);
