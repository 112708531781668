import {createContext, useCallback, useContext, useEffect, useState} from "react";

import {ApiContextProvider} from "common/services/apiProvider";

import {AccountContextProvider} from "./features/account/accountStateProvider";

interface FeatureFlags {
    earning_reports: boolean;
    driver_invitations: boolean;
    campaigns: boolean;
    support_widget: boolean;
    driver_blocks: boolean;
    balance: boolean;
    enterprise_api: boolean;
    vehicle_engagement_data: boolean;
    company_under_review: {
        add_driver: boolean;
        add_vehicle: boolean;
    };
    company_documents: boolean;
    vehicle_marketplace: boolean;
    shifts: boolean;
    company_metrics: boolean;
    vehicle_assignment: boolean;
    live_map: boolean;
    car_rental_payments: boolean;
    lead_to_fleet: boolean;
    order_dispatching: boolean;
    incoming_orders: boolean;
    rider_invoices: boolean;
    compensation_invoices: boolean;
}

export interface Features extends Partial<FeatureFlags> {
    is_loading_features: boolean;
}

const FeaturesContextProvider = createContext<Features>({is_loading_features: true});
FeaturesContextProvider.displayName = "FeaturesContextProvider";

const defaultFeatures = {
    isEarningReportsEnabled: false,
    isDriverInvitationEnabled: false,
    isCampaignsEnabled: false,
    isSupportWidgetEnabled: false,
    isDriverBlocksEnabled: false,
    isBalanceEnabled: false,
    isEnterpriseApiEnabled: false,
    isVehicleEngagementDataEnabled: false,
    companyUnderReview: {
        isAddVehicleEnabled: false,
        isAddDriverEnabled: false,
    },
    isCompanyDocumentsEnabled: false,
    isVehicleMarketplaceEnabled: false,
    shifts: false,
    isCompanyMetricsEnabled: false,
    isVehicleAssignmentEnabled: false,
    isLiveMapEnabled: false,
    isCarRentalPaymentsEnabled: false,
    isLeadToFleetEnabled: false,
    isOrderDispatchingEnabled: false,
    isIncomingOrdersEnabled: false,
    isRiderInvoicesEnabled: false,
    isCompensationInvoicesEnabled: false,
};

const FeaturesProvider = ({children}: {children: React.ReactNode}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [features, setFeatures] = useState<typeof defaultFeatures | null>(null);
    const api = useContext(ApiContextProvider);
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState.getFleet();
    const holdingFleet = accountState.getHoldingFleet();

    const getIsAvailableData = useCallback(async () => {
        setIsLoading(true);
        if (!api || !fleet) {
            setFeatures(null);
            if (holdingFleet) {
                setIsLoading(false);
            }
            return;
        }
        try {
            const response = await api.fleetOwnerPortal.getFeatures();
            setFeatures({
                isEarningReportsEnabled: response.earnings_dashboard_enabled,
                isDriverInvitationEnabled: response.driver_invitation_enabled,
                isCampaignsEnabled: response.incentives_dashboard_enabled,
                isSupportWidgetEnabled: response.support_widget_enabled,
                isDriverBlocksEnabled: response.driver_blocks_enabled,
                isBalanceEnabled: response.balance_page_enabled,
                isEnterpriseApiEnabled: response.enterprise_api_page_enabled,
                isVehicleEngagementDataEnabled: response.vehicle_engagement_data_enabled,
                companyUnderReview: {
                    isAddDriverEnabled: response.company_under_review.add_driver_enabled,
                    isAddVehicleEnabled: response.company_under_review.add_vehicle_enabled,
                },
                isCompanyDocumentsEnabled: response.company_documents_enabled,
                isVehicleMarketplaceEnabled: response.vehicle_marketplace_enabled,
                shifts: response.shifts_enabled,
                isCompanyMetricsEnabled: response.company_metrics_enabled,
                isVehicleAssignmentEnabled: response.vehicle_assignment_enabled,
                isLiveMapEnabled: response.live_map_enabled,
                isCarRentalPaymentsEnabled: response.car_rental_payments_enabled,
                isLeadToFleetEnabled: response.lead_to_fleet_enabled,
                isOrderDispatchingEnabled: response.order_dispatching_enabled,
                isIncomingOrdersEnabled: response.incoming_orders_enabled,
                isRiderInvoicesEnabled: response.rider_invoices_enabled,
                isCompensationInvoicesEnabled: response.compensation_invoices_enabled,
            });
        } catch {
            setFeatures(defaultFeatures);
        } finally {
            setIsLoading(false);
        }
    }, [api, fleet, holdingFleet]);

    useEffect(() => {
        getIsAvailableData();
    }, [getIsAvailableData]);

    const contextValue: Features = features
        ? {
              earning_reports: features.isEarningReportsEnabled,
              driver_invitations: features.isDriverInvitationEnabled,
              campaigns: features.isCampaignsEnabled,
              support_widget: features.isSupportWidgetEnabled,
              driver_blocks: features.isDriverBlocksEnabled,
              balance: features.isBalanceEnabled,
              enterprise_api: features.isEnterpriseApiEnabled,
              vehicle_engagement_data: features.isVehicleEngagementDataEnabled,
              company_under_review: {
                  add_driver: features.companyUnderReview.isAddDriverEnabled,
                  add_vehicle: features.companyUnderReview.isAddVehicleEnabled,
              },
              company_documents: features.isCompanyDocumentsEnabled,
              vehicle_marketplace: features.isVehicleMarketplaceEnabled,
              shifts: features.shifts,
              company_metrics: features.isCompanyMetricsEnabled,
              vehicle_assignment: features.isVehicleAssignmentEnabled,
              live_map: features.isLiveMapEnabled,
              car_rental_payments: features.isCarRentalPaymentsEnabled,
              lead_to_fleet: features.isLeadToFleetEnabled,
              order_dispatching: features.isOrderDispatchingEnabled,
              incoming_orders: features.isIncomingOrdersEnabled,
              rider_invoices: features.isRiderInvoicesEnabled,
              compensation_invoices: features.isCompensationInvoicesEnabled,
              is_loading_features: isLoading,
          }
        : {is_loading_features: isLoading};

    return <FeaturesContextProvider.Provider value={contextValue}>{children}</FeaturesContextProvider.Provider>;
};

export {FeaturesProvider, FeaturesContextProvider};
