import {useEffect} from "react";

import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {Link} from "@bolteu/kalep-react";

const getVehicleBrandingSignupUrlFetchFunction = (api: Api) => api.fleetOwnerPortal.getVehicleBrandingSignupUrl();

const useCallForBranding = () => {
    const {i18n} = useI18n();
    const {data, fetch} = useFetch(getVehicleBrandingSignupUrlFetchFunction);

    useEffect(() => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    if (!data?.url) {
        return null;
    }

    const brandingLink = (url: string) => (
        <Link target="_blank" href={url}>
            {i18n("auth.app.fleet.vehicles.call_for_vehicle_branding.link_text")}
        </Link>
    );
    return (
        <div className="text-secondary whitespace-pre-wrap font-normal">
            {i18n("auth.app.fleet.vehicles.call_for_vehicle_branding.description", {
                link: brandingLink(data.url),
            })}
        </div>
    );
};

export default useCallForBranding;
