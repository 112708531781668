import {useCallback, useContext, useEffect, useMemo, useState} from "react";

import {debounce} from "lodash-es";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {CategoriesContextProvider} from "../../../CategoriesContextProvider";
import {useCreateOrderValidations} from "../../../hooks/useCreateOrderValidations";
import {CreateDrawerType, Step1Data, Step2Data} from "../../../types";
import {Categories} from "../categories";
import {ManualPrice} from "../ManualPrice";
import {PaymentMethods} from "../PaymentMethods";

interface Props {
    data: Step1Data & Step2Data;
    onChange: (data: Partial<Step2Data>) => void;
    validationErrors?: FleetPortalOrderService.ValidationError[];
    drawerType: CreateDrawerType;
}

const FIND_SCHEDULED_RIDE_OPTIONS_INTERVAL_MS = 30_000;
const FIND_INSTANT_RIDE_OPTIONS_INTERVAL_MS = 15_000;

export const RideDetails = ({drawerType, data, onChange, validationErrors}: Props) => {
    const {
        rideOptions,
        findRideOptions,
        isLoading: isLoadingCategories,
        apiError,
    } = useContext(CategoriesContextProvider);
    const [internalManualPrice, setInternalManualPrice] = useState<string | undefined>(data.manual_price);

    const {validateManualPricing} = useCreateOrderValidations(drawerType);

    useEffect(() => {
        // Switch manual price off if its on and there is no upfront pricing
        const manualPriceNotAvailable = !rideOptions?.categories.every((category) => category.price.upfront_price);
        if (manualPriceNotAvailable && data.is_manual_price_enabled) {
            onChange({is_manual_price_enabled: false});
        }
    }, [data.is_manual_price_enabled, onChange, rideOptions?.categories]);

    const getCategories = useCallback(() => {
        if (!data.pickup.lat || !data.pickup.lng) {
            // Should exist from previous step
            return;
        }
        const pickup = {...data.pickup, lat: data.pickup.lat, lng: data.pickup.lng};
        findRideOptions(
            {
                payment_method: data.payment_method,
                scheduled_for: data.scheduled_for,
                pickup,
                dropoffs: data.dropoffs,
                create_order_uuid: data.create_order_uuid,
                manual_price: data.manual_price,
            },
            drawerType,
        );
    }, [
        data.create_order_uuid,
        data.dropoffs,
        data.manual_price,
        data.payment_method,
        data.pickup,
        data.scheduled_for,
        drawerType,
        findRideOptions,
    ]);

    useEffect(() => {
        getCategories();
        const intervalMs =
            drawerType === CreateDrawerType.SCHEDULE
                ? FIND_SCHEDULED_RIDE_OPTIONS_INTERVAL_MS
                : FIND_INSTANT_RIDE_OPTIONS_INTERVAL_MS;
        const interval = setInterval(() => getCategories(), intervalMs);
        return () => clearInterval(interval);
    }, [drawerType, getCategories]);

    useEffect(() => {
        if (data.category_id && rideOptions) {
            const selectedCategory = rideOptions.categories.find((category) => category.id === data.category_id);
            if (!selectedCategory) {
                onChange({
                    category_id: undefined,
                    category_price_str: undefined,
                    category_upfront_price: undefined,
                    category_price_lock_hash: undefined,
                    category_search_token: undefined,
                    category_price_lock_hash_manual_price: undefined,
                    category_search_token_manual_price: undefined,
                });
                return;
            }
            // To keep track of the latest price lock for order creation
            if (
                selectedCategory.price.price_lock !== data.category_price_lock_hash ||
                selectedCategory.price.manual_price_lock !== data.category_price_lock_hash_manual_price ||
                data.category_search_token !== rideOptions.search_token ||
                data.category_search_token_manual_price !== rideOptions.manual_price_search_token
            ) {
                onChange({
                    category_price_str: selectedCategory.price.price_str,
                    category_upfront_price: selectedCategory.price.upfront_price,
                    category_price_lock_hash: selectedCategory.price.price_lock,
                    category_ride_duration_seconds: selectedCategory.order_duration_seconds,
                    category_search_token: rideOptions.search_token,
                    category_price_lock_hash_manual_price: selectedCategory.price.manual_price_lock,
                    category_search_token_manual_price: rideOptions.manual_price_search_token,
                });
            }
        }
    }, [
        data.category_id,
        data.category_price_lock_hash,
        data.category_price_lock_hash_manual_price,
        data.category_search_token,
        data.category_search_token_manual_price,
        onChange,
        rideOptions,
    ]);

    const onPaymentMethodChange = useCallback(
        (newPaymentMethod: FleetPortalOrderService.CreateOrderPaymentMethod) => {
            onChange({payment_method: newPaymentMethod});
        },
        [onChange],
    );

    const onCategoryChange = useCallback(
        (category?: FleetPortalOrderService.Category) => {
            onChange({
                category_id: category?.id,
                category_price_str: category?.price.price_str,
                category_upfront_price: category?.price.upfront_price,
                category_price_lock_hash: category?.price.price_lock,
                category_ride_duration_seconds: category?.order_duration_seconds,
                category_search_token: rideOptions?.search_token,
                category_price_lock_hash_manual_price: category?.price.manual_price_lock,
                category_search_token_manual_price: rideOptions?.manual_price_search_token,
            });
        },
        [onChange, rideOptions?.manual_price_search_token, rideOptions?.search_token],
    );

    // Debounce, as changing manual_price triggers findRideOptions called in above useEffect on every character change
    const debounceManualPriceChange = useMemo(
        () => debounce((newPrice?: string) => onChange({manual_price: newPrice}), 500),
        [onChange],
    );

    const onManualPriceChange = useCallback(
        (newPriceInput: string) => {
            const newPrice = newPriceInput === "" ? undefined : newPriceInput;
            setInternalManualPrice(newPriceInput);
            // If manual price invalid, lets not debounce change what in turn causes findRideOptions API call
            if (!validateManualPricing(true, !!data.category_id, newPrice, data.category_upfront_price)) {
                debounceManualPriceChange(newPrice);
            }
        },
        [data.category_id, data.category_upfront_price, debounceManualPriceChange, validateManualPricing],
    );

    const onManualPriceSwitchChange = useCallback(() => {
        onChange({is_manual_price_enabled: !data.is_manual_price_enabled});
    }, [data.is_manual_price_enabled, onChange]);

    const manualPrice =
        internalManualPrice !== undefined ? internalManualPrice : String(data.category_upfront_price || "");
    const isManualPriceEnabledAndEntered = data.is_manual_price_enabled && !!internalManualPrice;
    const categoryError = validationErrors?.find((error) => error.property === "category_id")?.error ?? apiError?.error;
    const manualPriceError =
        validationErrors?.find((error) => error.property === "manual_price")?.error ??
        validateManualPricing(
            data.is_manual_price_enabled,
            !!data.category_id,
            internalManualPrice,
            data.category_upfront_price,
        )?.error;
    const manualPriceAvailable = !!rideOptions?.categories.every((category) => category.price.upfront_price);

    return (
        <div className="flex flex-col gap-4">
            <PaymentMethods selectedPaymentMethod={data.payment_method} onChange={onPaymentMethodChange} />
            <Categories
                selectedCategoryId={data.category_id}
                onChange={onCategoryChange}
                categories={rideOptions?.categories}
                isLoading={isLoadingCategories}
                categoryError={categoryError}
                isManualPriceEnabledAndEntered={isManualPriceEnabledAndEntered}
            />
            <ManualPrice
                isManualPriceEnabled={data.is_manual_price_enabled}
                onManualPriceChange={onManualPriceChange}
                onManualPriceSwitchChange={onManualPriceSwitchChange}
                manualPrice={manualPrice}
                validationError={isManualPriceEnabledAndEntered ? manualPriceError : undefined}
                isLoadingCategories={isLoadingCategories}
                manualPriceAvailable={manualPriceAvailable}
            />
        </div>
    );
};
