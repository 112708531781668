import {useCallback, useState} from "react";

import {Glossary, GlossaryItemProp} from "common/components/glossary/Glossary";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {useI18n} from "common/hooks/useI18n";

const BalancePageHeader = () => {
    const {i18n} = useI18n();

    const EARNINGS_GLOSSARY_ITEMS: GlossaryItemProp[] = [
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.payment.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.payment.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.earning_in_app.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.earning_in_app.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.campaign_bonuses.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.campaign_bonuses.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.fee_bonuses.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.fee_bonuses.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.fleet_compensations.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.fleet_compensations.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.branding_bonuses.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.branding_bonuses.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.compensations_manual.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.compensations_manual.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.compensations.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.compensations.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.tips.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.tips.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.cancellation_fees.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.cancellation_fees.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.car_rental_fees.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.car_rental_fees.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.rider_cash_discount.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.rider_cash_discount.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.earnings.withholding_tax.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.earnings.withholding_tax.description"),
        },
    ];
    const EXPENSES_GLOSSARY_ITEMS: GlossaryItemProp[] = [
        {
            title: i18n("auth.app.fleet.balance.transactions_list.expenses.payouts.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.expenses.payouts.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.expenses.cashouts.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.expenses.cashouts.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.expenses.tax_authority_payouts.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.expenses.tax_authority_payouts.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.expenses.commissions_in_app.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.expenses.commissions_in_app.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.expenses.commissions_cash.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.expenses.commissions_cash.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.expenses.booking_fees.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.expenses.booking_fees.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.expenses.refunds.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.expenses.refunds.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.expenses.additional_services.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.expenses.additional_services.description"),
        },
        {
            title: i18n("auth.app.fleet.balance.transactions_list.expenses.other_fees.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.expenses.other_fees.description"),
        },
    ];

    const glossaryItems: GlossaryItemProp[] = [
        {
            title: i18n("auth.app.fleet.balance.transactions_list.starting_balance.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.starting_balance.description"),
        },
        ...EARNINGS_GLOSSARY_ITEMS,
        ...EXPENSES_GLOSSARY_ITEMS,
        {
            title: i18n("auth.app.fleet.balance.transactions_list.ending_balance.title"),
            text: i18n("auth.app.fleet.balance.transactions_list.ending_balance.description"),
        },
    ];

    const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);

    const handleGlossaryOpen = useCallback(() => {
        setIsGlossaryOpen(true);
    }, []);

    const handleGlossaryClose = useCallback(() => {
        setIsGlossaryOpen(false);
    }, []);

    const SeeGlossaryLink = () => (
        <span
            className="text-action-primary"
            onClick={handleGlossaryOpen}
            onKeyDown={handleGlossaryOpen}
            role="button"
            tabIndex={0}
        >
            {i18n("auth.app.fleet.balance.description.link")}
        </span>
    );

    return (
        <div>
            <div className="flex flex-col gap-4">
                <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("auth.app.fleet.balance.title")} />
                <div className="text-secondary font-normal">
                    {i18n("auth.app.fleet.balance.description.text", {
                        glossary_link: <SeeGlossaryLink />,
                    })}
                </div>
            </div>
            <Glossary isOpen={isGlossaryOpen} handleClose={handleGlossaryClose} items={glossaryItems} />
        </div>
    );
};

export default BalancePageHeader;
