import {FC, useCallback, useContext, useEffect, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Glossary} from "common/components/glossary/Glossary";
import Header, {HeaderSize} from "common/components/header/Header";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import ApiDrivenMetricCard from "common/components/table/ApiDrivenMetricCard";
import {EventName} from "common/constants/events";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {SelectPeriodOption} from "common/hooks/usePeriodSelectorOptions";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, getDatesStrFromDates, getPeriodName, Period} from "common/services/period";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import PeriodSelect from "../../../common/components/periodDatePicker/PeriodSelect";
import {FeaturesContextProvider} from "../../../FeaturesProvider";
import DashboardCharts from "./components/DashboardCharts";
import DashboardTables from "./components/DashboardTables";
import {MetricsTab, useTabSelectorOptions} from "./hooks/useTabSelectorOptions";

import GetCompanyPerformanceMetricsRequest = FleetOwnerPortalService.GetCompanyPerformanceMetricsRequest;

const SeeGlossaryLink = ({handleGlossaryOpen}: {handleGlossaryOpen: () => void}) => {
    const {i18n} = useI18n();

    return (
        <span
            className="text-action-primary"
            onClick={handleGlossaryOpen}
            onKeyDown={handleGlossaryOpen}
            role="button"
            tabIndex={0}
        >
            {i18n("auth.app.fleet.dashboard.description_block.link")}
        </span>
    );
};

const getCompanyPerformanceFetchFunction = (api: Api, query: GetCompanyPerformanceMetricsRequest) =>
    api.fleetOwnerPortal.getCompanyPerformance(query);

const DashboardPage: FC = () => {
    const {i18n} = useI18n();
    const tabs = useTabSelectorOptions();
    const features = useContext(FeaturesContextProvider);
    const {trackEvent} = useTracking();

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>({period: Period.PREVIOUS_7_DAYS});

    const {activeTab, TabSelector} = useTabs(tabs, MetricsTab.Drivers);

    const {fetch: companyPerformanceDataFetch, data: companyPerformanceData} = useFetch(
        getCompanyPerformanceFetchFunction,
    );

    const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);

    const handleGlossaryOpen = useCallback(() => {
        setIsGlossaryOpen(true);
    }, []);

    const handleGlossaryClose = useCallback(() => {
        setIsGlossaryOpen(false);
    }, []);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.dashboard.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        if (companyPerformanceDataFetch) {
            companyPerformanceDataFetch({
                cutoff_date: getIsoDate(apiPeriodToDates(selectedPeriod).end),
            });
        }
    }, [companyPerformanceDataFetch, selectedPeriod]);

    const onPeriodChange = useCallback(
        (apiPeriod: ApiPeriod | undefined) => {
            if (apiPeriod) {
                setSelectedPeriod(apiPeriod);

                const dates = apiPeriodToDates(apiPeriod);
                trackEvent(EventName.PeriodChanged, {
                    periodName: getPeriodName(apiPeriod),
                    period: getDatesStrFromDates(dates.start, dates.end),
                });
            }
        },
        [trackEvent],
    );

    if (!features?.company_metrics) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    if (!companyPerformanceData) {
        return null;
    }

    let MetricsTables = null;
    switch (activeTab) {
        case MetricsTab.Vehicles:
            MetricsTables = (
                <DashboardTables
                    topEntities={companyPerformanceData?.tables.top_vehicles}
                    bottomEntities={companyPerformanceData?.tables.bottom_vehicles}
                />
            );
            break;
        case MetricsTab.Drivers:
        default:
            MetricsTables = (
                <DashboardTables
                    topEntities={companyPerformanceData?.tables.top_drivers}
                    bottomEntities={companyPerformanceData?.tables.bottom_drivers}
                />
            );
    }

    return (
        <Page>
            <div className="flex flex-row flex-wrap items-baseline justify-between">
                <div className="flex flex-col gap-4">
                    <ResponsiveHeader
                        type={ResponsiveHeaderType.MainPage}
                        text={i18n("auth.app.fleet.dashboard.title")}
                    />
                    <div className="text-secondary font-normal">
                        {i18n("auth.app.fleet.dashboard.description_block.text", {
                            glossary_link: <SeeGlossaryLink handleGlossaryOpen={handleGlossaryOpen} />,
                        })}
                    </div>
                </div>
                <div className="flex-col">
                    <PeriodSelect
                        period={selectedPeriod}
                        selectPeriodOptions={[
                            SelectPeriodOption.Previous7Days,
                            SelectPeriodOption.ThisWeek,
                            SelectPeriodOption.LastWeek,
                        ]}
                        onSelectionChange={onPeriodChange}
                        excludeCustomRange
                    />
                </div>
            </div>
            <div className="flex flex-nowrap gap-4 overflow-x-auto">
                {companyPerformanceData.metrics.map((metric) => (
                    <ApiDrivenMetricCard key={metric.name} metric={metric} />
                ))}
            </div>
            <DashboardCharts charts={companyPerformanceData?.charts} selectedPeriod={selectedPeriod} />
            <div className="mt-4 flex flex-col gap-2">
                <Header size={HeaderSize.Small} text={i18n("auth.app.fleet.dashboard.weekly_performers.title")} />
                <div className="text-secondary font-normal">
                    {i18n("auth.app.fleet.dashboard.weekly_performers.description")}
                </div>
            </div>
            <TabSelector />
            {MetricsTables}
            <Glossary
                isOpen={isGlossaryOpen}
                handleClose={handleGlossaryClose}
                items={companyPerformanceData.glossary.items}
            />
        </Page>
    );
};

export default DashboardPage;
