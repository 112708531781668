import {NavGroupSeparator} from "common/navigation/components/NavGroupSeparator";
import {SubMenuLink} from "common/navigation/components/SubMenuLink";
import {SubMenuItem} from "common/navigation/hooks/useNavigationLinksV2";

import {Typography} from "@bolteu/kalep-react";

export enum NavBarVariant {
    TOPBAR = "TOPBAR",
    SIDEBAR = "SIDEBAR",
}

interface SubMenuProps {
    section: SubMenuItem;
    variant: NavBarVariant;
    onLinkClick?: () => void;
}

export const SubMenu = ({section, variant, onLinkClick}: SubMenuProps) => {
    if (section.links.length === 0) {
        return null;
    }

    return (
        <div className={variant === NavBarVariant.TOPBAR ? "min-w-[250px]" : ""}>
            <div className={variant === NavBarVariant.TOPBAR ? "px-2" : "my-3"}>
                <Typography fontWeight="semibold" fontSize="text-xs" color="secondary">
                    {section.title}
                </Typography>
                {section.subTitle && (
                    <Typography fontSize="text-xs" color="secondary" paddingTop={2}>
                        {section.subTitle}
                    </Typography>
                )}
            </div>
            <div className={`flex flex-col space-y-1 ${variant === NavBarVariant.TOPBAR ? "mt-2" : ""}`}>
                {section.links.map((link) => (
                    <div key={link.title} className="flex flex-col">
                        {link.renderDividerAbove && <NavGroupSeparator isNavLinkSeparator />}
                        <SubMenuLink title={link.title} route={link.route} onClick={onLinkClick} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SubMenu;
