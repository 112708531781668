import * as React from "react";

import {Dash} from "@fleet/common/constants/dash";

interface Props {
    title: string;
    mainValue: string;
    isLoading: boolean;
}

export const StatCard: React.FC<Props> = ({title, mainValue, isLoading}) => {
    let valueStr = mainValue;
    let valueColor = "text-primary";

    if (valueStr === Dash.EN) {
        valueColor = "text-tertiary";
    } else if (Number(valueStr) < 0) {
        valueColor = "text-danger-primary";
        valueStr = valueStr.replace(Dash.HYPHEN, Dash.EN);
    }

    let content = <div className={`h-10 text-[28px] font-semibold ${valueColor}`}>{valueStr}</div>;
    if (isLoading) {
        content = <div className="bg-neutral-secondary block h-[42px] w-20 rounded-sm" />;
    }

    return (
        <div className="border-neutral-secondary flex min-w-[160px] max-w-[240px] grow flex-col justify-between gap-2 rounded-lg border p-4">
            <div className="flex items-center gap-2">
                <div className="text-secondary text-xs">{title}</div>
            </div>
            {content}
        </div>
    );
};
