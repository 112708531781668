import React, {useContext, useEffect, useState} from "react";

import {ThirdPartyContextProvider} from "./appThirdPartyProvider";

export interface ApplicationState {
    isAppReady: boolean;
    setIsAppReady: (isError: boolean) => void;
    refreshToken: string | null;
    setRefreshToken: (token: string | null) => void;
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    isAuthError: boolean;
    setIsAuthError: (isAuthError: boolean) => void;
}

const ApplicationContextProvider = React.createContext<ApplicationState>({
    isAppReady: false,
    setIsAppReady: () => {},
    refreshToken: null,
    setRefreshToken: () => {},
    isAuthenticated: false,
    setIsAuthenticated: () => {},
    isAuthError: false,
    setIsAuthError: () => {},
});
ApplicationContextProvider.displayName = "ApplicationContextProvider";

const ApplicationProvider = ({children}: {children: React.ReactNode}) => {
    const {observability} = useContext(ThirdPartyContextProvider);

    const [isAppReady, setIsAppReady] = useState<boolean>(false);
    const [refreshToken, setRefreshToken] = useState<string | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAuthError, setIsAuthError] = useState<boolean>(false);

    useEffect(() => {
        observability.addBreadcrumb("app ready", {isAppReady});
    }, [isAppReady, observability]);

    useEffect(() => {
        observability.addBreadcrumb("user is authenticated", {isAuthenticated});
    }, [isAuthenticated, observability]);

    return (
        <ApplicationContextProvider.Provider
            value={{
                isAppReady,
                setIsAppReady,
                refreshToken,
                setRefreshToken,
                isAuthenticated,
                setIsAuthenticated,
                isAuthError,
                setIsAuthError,
            }}
        >
            {children}
        </ApplicationContextProvider.Provider>
    );
};

export {ApplicationContextProvider, ApplicationProvider};
