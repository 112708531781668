import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {FullScreenLoadingSpinner} from "common/components/spinner";
import {useTabs} from "common/components/tab/useTabs";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {FeaturesContextProvider} from "FeaturesProvider";
import {useReactRouterLinkForBreadcrumb} from "@fleet/common/hooks/useBreadcrumbRenderItem";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Breadcrumb} from "@bolteu/kalep-react";

import CampaignHiddenIsland from "./components/CampaignHiddenIsland";
import DetailsHeader from "./components/DetailsHeader";
import {useBreadcrumbs} from "./hooks/useBreadcrumbs";
import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";
import CampaignParticipants from "./participants";
import CampaignRequirements from "./requirements";
import GetCampaignParticipantsRequest = FleetOwnerPortalService.GetCampaignParticipantsRequest;
import GetCampaignDetailsRequest = FleetOwnerPortalService.GetCampaignDetailsRequest;
import CampaignStatus = FleetOwnerPortalService.CampaignStatus;
import CampaignType = FleetOwnerPortalService.CampaignType;
import BonusIcon = FleetOwnerPortalService.BonusIcon;
import ApiChip = FleetOwnerPortalService.Chip;

export enum TabName {
    Requirements = "requirements",
    Participants = "participants",
}

const fetchRequirementsFunction = (api: Api, body: GetCampaignDetailsRequest) =>
    api.fleetOwnerPortal.fleetIncentivesGetCampaignDetails(body);
const fetchParticipantsFunction = (api: Api, body: GetCampaignParticipantsRequest) =>
    api.fleetOwnerPortal.fleetIncentivesGetCampaignParticipants(body);

const CampaignDetails = () => {
    const {id, type} = useParams();

    const features = useContext(FeaturesContextProvider);

    const breadcrumbs = useBreadcrumbs();
    const tabs = useTabSelectorOptions();

    const {i18n} = useI18n();
    const intl = useIntl();

    const {
        data: requirementsData,
        status: requirementsStatus,
        fetch: requirementsFetch,
    } = useFetch(fetchRequirementsFunction);
    const {
        data: participantsData,
        status: participantsStatus,
        fetch: participantsFetch,
    } = useFetch(fetchParticipantsFunction);

    const [isFaultyUrl, setIsFaultyUrl] = useState(false);

    const [headerIcon, setHeaderIcon] = useState<BonusIcon>();
    const [headerName, setHeaderName] = useState<string>();
    const [headerChips, setHeaderChips] = useState<ApiChip[]>([]);
    const [isCampaignHidden, setIsCampaignHidden] = useState<boolean>(false);
    const [campaignStatus, setCampaignStatus] = useState<CampaignStatus>(CampaignStatus.UPCOMING);

    const {activeTab, TabSelector} = useTabs(tabs, TabName.Requirements);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.campaigns.details.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        if (!Number(id) || !Object.values(CampaignType).includes(type as CampaignType)) {
            setIsFaultyUrl(true);
            return;
        }

        const body: GetCampaignDetailsRequest | GetCampaignParticipantsRequest = {
            language: intl.locale,
            campaign_id: Number(id),
            campaign_type: type as CampaignType,
        };

        if (activeTab === TabName.Requirements && requirementsFetch) {
            requirementsFetch(body);
        } else if (activeTab === TabName.Participants && participantsFetch) {
            participantsFetch(body);
        }
    }, [activeTab, id, intl.locale, participantsFetch, requirementsFetch, type]);

    useEffect(() => {
        const campaignsData = requirementsData || participantsData;

        if (campaignsData) {
            setHeaderIcon(campaignsData.bonus_icon);
            setHeaderName(campaignsData.name);
            setHeaderChips(campaignsData.chips);
            setIsCampaignHidden(campaignsData.hidden);
            setCampaignStatus(campaignsData.status);
        }
    }, [requirementsData, participantsData]);

    if (!features?.campaigns) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    if (isFaultyUrl || requirementsStatus === FetchStatus.Error || participantsStatus === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    if (!headerName || !headerIcon) {
        return <FullScreenLoadingSpinner />;
    }

    return (
        <div className="h-full w-full">
            <div className="leading-14 container mx-auto mb-14 flex flex-col gap-6 px-6 pt-4 lg:pt-8">
                <Breadcrumb items={breadcrumbs} renderItem={useReactRouterLinkForBreadcrumb} />
                <DetailsHeader icon={headerIcon} name={headerName} chips={headerChips} />
                {isCampaignHidden && <CampaignHiddenIsland />}
                {campaignStatus !== CampaignStatus.UPCOMING && <TabSelector />}
                {activeTab === TabName.Requirements && <CampaignRequirements data={requirementsData} />}
                {activeTab === TabName.Participants && (
                    <CampaignParticipants
                        campaignId={Number(id)}
                        campaignType={type as CampaignType}
                        data={participantsData}
                    />
                )}
            </div>
        </div>
    );
};

export default CampaignDetails;
