import {useContext, useEffect, useMemo} from "react";
import {useIntl} from "react-intl";

import {PaymentMethod} from "common/constants/PaymentMethod";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {Dash} from "@fleet/common/constants/dash";
import {getYearMonthDate} from "@fleet/common/utils/dateFormatUtils";
import {formatNumber} from "@fleet/common/utils/numberFormatUtils";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {TABLE_ROWS_PER_PAGE} from "../../contants";

import GetRiderInvoicesRequest = FleetOwnerReportingService.GetRiderInvoicesRequest;

export interface FleetToRiderInvoiceData {
    id: string;
    createdDate: Date;
    driverName: string;
    pickupAddress: string;
    sum: string;
    currency: string;
    paymentMethod: string;
    uuid: string;
}

interface Props {
    date: Date;
    paymentMethod: PaymentMethod | null;
    driverId: number | null;
    offset: number;
}

const fetchRiderInvoices = (api: Api, body: GetRiderInvoicesRequest) => api.fleetOwnerReporting.getRiderInvoices(body);

const getInvoicesPaymentMethod = (
    paymentMethod: PaymentMethod,
): FleetOwnerReportingService.UserInvoicePaymentMethodType => {
    switch (paymentMethod) {
        case PaymentMethod.CASH:
            return FleetOwnerReportingService.UserInvoicePaymentMethodType.CASH;
        case PaymentMethod.APP_PAYMENT:
            return FleetOwnerReportingService.UserInvoicePaymentMethodType.APP_PAYMENT;
        case PaymentMethod.BUSINESS:
            return FleetOwnerReportingService.UserInvoicePaymentMethodType.BUSINESS;
        case PaymentMethod.CARD:
            return FleetOwnerReportingService.UserInvoicePaymentMethodType.CARD;
        default:
            return UnreachableCode.never(
                paymentMethod,
                FleetOwnerReportingService.UserInvoicePaymentMethodType.APP_PAYMENT,
            );
    }
};

export const useInvoices = ({date, paymentMethod, driverId, offset}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {data, fetch, status} = useFetch(fetchRiderInvoices);

    useEffect(() => {
        if (!fetch || !fleet) {
            return;
        }
        const body: GetRiderInvoicesRequest = {
            date: getYearMonthDate(date),
            limit: String(TABLE_ROWS_PER_PAGE),
            offset: String(offset),
        };
        if (paymentMethod) {
            body.payment_method = getInvoicesPaymentMethod(paymentMethod);
        }
        if (driverId) {
            body.driver_id = driverId;
        }
        fetch(body);
    }, [date, driverId, fetch, fleet, offset, paymentMethod]);

    const invoices = useMemo<FleetToRiderInvoiceData[]>(() => {
        if (status !== FetchStatus.Success) {
            return [];
        }
        return data.list.map((d) => ({
            id: d.id,
            createdDate: new Date(d.created_at * 1000),
            driverName: d.driver_name,
            pickupAddress: d.pickup_address ?? Dash.EM,
            sum: formatNumber(intl, d.sum_with_vat, {minFractionDigits: 2, maxFractionDigits: 2}),
            currency: d.invoice_currency,
            paymentMethod: i18n(`payment-methods.${d.payment_method as PaymentMethod}`),
            uuid: d.uuid,
        }));
    }, [data, i18n, intl, status]);

    return {invoices, status};
};
