import {useCallback, useState} from "react";
import {useParams} from "react-router-dom";

import {useI18n} from "common/hooks/useI18n";
import {restoreRefreshToken} from "common/services/authService";

import {FleetOwnerVerificationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Typography} from "@bolteu/kalep-react";

import {useFleetOwnerForSignup} from "./hooks/useFleetOwnerForSignup";
import {useFleetOwnerForSignupResponse} from "./hooks/useFleetOwnerForSignupResponse";

interface ConfirmationProps {
    username: string;
}

export const Confirmation = ({username}: ConfirmationProps) => {
    const refreshToken = restoreRefreshToken();
    const {i18n} = useI18n();
    const {fetchUseFleetOwnerForSignup} = useFleetOwnerForSignup();
    const {handleUseFleetOwnerForSignupResponse} = useFleetOwnerForSignupResponse();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const params = useParams();
    const attemptKey = params.key ?? "";

    const UserName = () => <span className="font-semibold">{username}</span>;

    const onContinueWithExisting = useCallback(async () => {
        setErrorMessage("");
        setIsLoading(true);
        try {
            const res = await fetchUseFleetOwnerForSignup({
                type: FleetOwnerVerificationService.UseFleetOwnerForSignupRequestType.CONTINUE_WITH_FLEET_OWNER,
                attempt_key: attemptKey,
            });
            const result = handleUseFleetOwnerForSignupResponse(res);
            if (result.type === "error") {
                setErrorMessage(result.errorMessage);
                setIsLoading(false);
            }
        } catch (e) {
            setIsLoading(false);
            throw e;
        }
    }, [attemptKey, fetchUseFleetOwnerForSignup, handleUseFleetOwnerForSignupResponse]);

    const onCreateNewAccount = useCallback(async () => {
        setErrorMessage("");
        setIsLoading(true);
        try {
            if (!refreshToken) {
                setErrorMessage(i18n("api.default_error"));
                return;
            }
            const res = await fetchUseFleetOwnerForSignup({
                type: FleetOwnerVerificationService.UseFleetOwnerForSignupRequestType.CREATE_NEW_ACCOUNT,
                attempt_key: attemptKey,
                refresh_token: refreshToken,
            });
            const result = handleUseFleetOwnerForSignupResponse(res);
            if (result.type === "error") {
                setErrorMessage(result.errorMessage);
                setIsLoading(false);
            }
        } catch (e) {
            setIsLoading(false);
            throw e;
        }
    }, [attemptKey, fetchUseFleetOwnerForSignup, handleUseFleetOwnerForSignupResponse, i18n, refreshToken]);

    return (
        <div className="w-full">
            <div className="mb-8 flex flex-col items-center gap-2">
                {errorMessage && <h1 className="text-2xl font-semibold">{errorMessage}</h1>}
                {!errorMessage && (
                    <>
                        <div className="flex flex-col items-center gap-3 pb-6 text-center">
                            <Typography variant="title-primary" fontSize="text-2xl">
                                {i18n("verification.confirmation.title")}
                            </Typography>
                            <Typography variant="body-secondary">
                                <div className="text-secondary text-center text-base">
                                    {i18n("verification.confirmation.description", {username: <UserName />})}
                                </div>
                            </Typography>
                        </div>
                        <div className="flex w-full flex-col gap-4 sm:px-16">
                            <Button fullWidth variant="primary" onClick={onContinueWithExisting} loading={isLoading}>
                                {i18n("verification.confirmation.continue_with_existing")}
                            </Button>
                            <Button fullWidth variant="secondary" onClick={onCreateNewAccount} loading={isLoading}>
                                {i18n("verification.confirmation.create_new_account")}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
