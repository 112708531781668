import {useCallback, useContext, useEffect} from "react";

import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import NoOrders from "../../components/NoOrders";
import {IncomingOrdersType, PollingInterval} from "..";
import {useTableActions, useTableColumns} from "../hooks/useManualAcceptOrderTableColumns";

interface Props {
    setIncomingOrdersType: (type: IncomingOrdersType) => void;
}

export enum OrderType {
    ORDER_TRY = "order_try",
    ORDER_OFFER = "order_offer",
}

export interface ManualAcceptedOrdersTableData {
    id: number;
    order_type: OrderType;
    driver_id: number;
    driver_name: string;
    address: string | null;
    incoming_time: number;
    client_name: string | null;
}

const getOrdersFetchFunction = (api: Api) => api.fleetPortalOrder.getPendingOrdersForFleet();
const acceptOrderFetchFunction = (api: Api, body: FleetPortalOrderService.AcceptOrderRequest) =>
    api.fleetPortalOrder.acceptPendingOrder(body);
const rejectOrderFetchFunction = (api: Api, body: FleetPortalOrderService.AcceptOrderRequest) =>
    api.fleetPortalOrder.rejectPendingOrder(body);

const ManualAcceptIncomingOrdersTable = ({setIncomingOrdersType}: Props) => {
    const {i18n} = useI18n();
    const columns = useTableColumns();
    const {setNotification} = useContext(NotificationContext);

    const fleet = useContext(AccountContextProvider)?.getFleet();

    const {data, fetch} = useFetch(getOrdersFetchFunction);
    const {fetch: acceptOrder} = useFetch(acceptOrderFetchFunction);
    const {fetch: rejectOrder} = useFetch(rejectOrderFetchFunction);

    useEffect(() => {
        let interval: number | undefined;
        if (fleet && fetch) {
            fetch({});
            interval = window.setInterval(() => {
                fetch({});
            }, PollingInterval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [fetch, fleet]);

    useEffect(() => {
        if (data) {
            setIncomingOrdersType(
                data?.is_auto_accept ? IncomingOrdersType.AUTO_ACCEPTED_ORDERS : IncomingOrdersType.MANUAL_ORDERS,
            );
        }
    }, [data, setIncomingOrdersType]);

    const renderEmptyPlaceholder = useCallback(() => <NoOrders />, []);

    const getFields = (order: FleetPortalOrderService.FleetPendingOrder): ManualAcceptedOrdersTableData => ({
        // It is guaranteed that pending approval has either offer id or try id
        id: order.order_offer_id || order.order_try_id!,
        order_type: order.order_offer_id ? OrderType.ORDER_OFFER : OrderType.ORDER_TRY,
        incoming_time: order.created,
        address: order.address,
        driver_id: order.driver_id,
        driver_name: order.driver_name,
        client_name: order.client_name,
    });

    const items = data?.pending_orders.map(getFields) || [];

    const onApprove = useCallback(
        (id: number, orderType: OrderType) => () => {
            async function checkAutoAccept() {
                if (fleet && acceptOrder) {
                    const orderStatusChange =
                        orderType === OrderType.ORDER_OFFER ? {order_offer_id: id} : {order_try_id: id};
                    await acceptOrder(orderStatusChange);
                    setNotification({
                        type: NotificationType.SUCCESS,
                        text: i18n("auth.app.orders.incoming.order-accepted"),
                        timeout: 6000,
                    });
                    if (fetch) {
                        await fetch({});
                    }
                }
            }

            checkAutoAccept();
        },
        [fleet, fetch, acceptOrder, setNotification, i18n],
    );

    const onDecline = useCallback(
        (id: number, orderType: OrderType) => () => {
            async function checkAutoAccept() {
                if (fleet && rejectOrder) {
                    const orderStatusChange =
                        orderType === OrderType.ORDER_OFFER ? {order_offer_id: id} : {order_try_id: id};
                    await rejectOrder(orderStatusChange);
                    setNotification({
                        type: NotificationType.SUCCESS,
                        text: i18n("auth.app.orders.incoming.order-rejected"),
                        timeout: 6000,
                    });
                    if (fetch) {
                        await fetch({});
                    }
                }
            }

            checkAutoAccept();
        },
        [fleet, fetch, rejectOrder, setNotification, i18n],
    );

    const inlineActions = useTableActions(onApprove, onDecline);

    return (
        <FixedHeaderTable
            columns={columns}
            items={items}
            aria-label="Manually accepted orders table"
            renderEmptyPlaceholder={renderEmptyPlaceholder}
            inlineActions={inlineActions}
        />
    );
};

export default ManualAcceptIncomingOrdersTable;
