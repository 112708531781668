import {FC, useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {FeaturesContextProvider} from "FeaturesProvider";

import {DriverRegistration} from "../index";
import DriverRegistrationsTable from "./DriverRegistrationsTable";
import {Matches} from "./matches/Matches";

interface DriverRegistrationsTabProps {
    driverRegistrations?: DriverRegistration[];
    onLeadAccepted: () => void;
}

const DriverRegistrationsTab: FC<DriverRegistrationsTabProps> = ({driverRegistrations, onLeadAccepted}) => {
    const {i18n} = useI18n();
    const isLeadToFleetEnabled = useContext(FeaturesContextProvider)?.lead_to_fleet;
    const isNewNavigationEnabled = useContext(AccountContextProvider).profile?.isNewNavigationEnabled;
    const isMatchesEnabled = isLeadToFleetEnabled && !isNewNavigationEnabled;

    return (
        <>
            {!!driverRegistrations?.length && (
                <div className="mb-3 flex flex-col">
                    <h2 className="text-lg font-semibold">
                        {i18n("auth.app.fleet.driver-registrations.invited-drivers")}
                    </h2>
                    <DriverRegistrationsTable driverRegistrations={driverRegistrations} />
                </div>
            )}
            {isMatchesEnabled && <Matches onLeadAccepted={onLeadAccepted} shouldShowTitle />}
        </>
    );
};

export default DriverRegistrationsTab;
