import {useCallback, useEffect, useMemo} from "react";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

import {FleetCarService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const getDriversFn = (api: Api, body: FleetCarService.CarGetDriversRequest) => api.fleetCar.carGetDrivers(body);

export function useVehicleDriverAccesses(vehicleId: number, page = 1, limit = 100, search?: string) {
    const {fetch: fetchDrivers, data: driversData} = useFetch(getDriversFn);

    const offset = page * limit - limit;

    const doFetchDrivers = useCallback(() => {
        if (fetchDrivers) {
            fetchDrivers({car_id: vehicleId, limit, offset, search});
        }
    }, [fetchDrivers, vehicleId, search, limit, offset]);

    useEffect(() => {
        doFetchDrivers();
    }, [doFetchDrivers]);

    const driverIdsWithAccess = useMemo(
        () => driversData?.assigned_drivers.map((driver) => driver.driver_id) || [],
        [driversData],
    );

    const driversWithAccess = useMemo(() => driversData?.assigned_drivers ?? [], [driversData]);

    const driversWithNoAccess = useMemo(
        () => driversData?.drivers?.filter((driver) => !driverIdsWithAccess.includes(driver.driver_id)) || [],
        [driversData, driverIdsWithAccess],
    );

    return {
        driversData,
        driversWithNoAccess,
        driverIdsWithAccess,
        driversWithAccess,
        refetch: doFetchDrivers,
    };
}
