import {ReactNode, useCallback, useRef} from "react";

import {Radio, RadioProps, TypographyStack} from "@bolteu/kalep-react";

export const CustomRadio = ({
    label,
    labelFontWeight = "regular",
    labelIcon,
    helperText,
    iconUrl,
    defaultIconUrl,
    renderEnd = () => null,
    className,
    ...props
}: RadioProps & {
    helperText?: string;
    labelFontWeight?: "semibold" | "regular";
    labelIcon?: ReactNode;
    iconUrl?: string;
    defaultIconUrl?: string;
    renderEnd?: () => ReactNode;
    className?: string;
}) => {
    const imgRef = useRef<HTMLImageElement>(null);

    const onIconUrlError = useCallback(() => {
        if (imgRef.current && defaultIconUrl) {
            imgRef.current.src = defaultIconUrl;
        }
    }, [defaultIconUrl, imgRef]);

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control -- guaranteed to have control element
        <label className={`flex w-full items-center justify-between gap-4 ${className ?? ""}`}>
            <div className="flex items-center gap-2">
                <Radio {...props} />
                {iconUrl && (
                    <img
                        ref={imgRef}
                        src={iconUrl}
                        alt="Category"
                        className="min-h-[48px] w-[80px] object-contain"
                        onError={onIconUrlError}
                    />
                )}
                <TypographyStack secondary={helperText} primaryTypographyProps={{fontWeight: labelFontWeight}}>
                    <div className="flex items-center gap-1">
                        <span>{label}</span>
                        {labelIcon}
                    </div>
                </TypographyStack>
            </div>
            {renderEnd()}
        </label>
    );
};
