import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

import Pagination from "common/components/Pagination";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";
import TableSearchField from "@fleet/common/components/table/TableSearchField";
import {I18nFn} from "@fleet/common/hooks/useI18n";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton, Typography} from "@bolteu/kalep-react";
import {History as HistoryIcon} from "@bolteu/kalep-react-icons";

import RentingOutAccordionTable from "./RentingOutAccordionTable";
import RentingOutFilter from "./RentingOutFilter";
import RentingOutFullTable from "./RentingOutFullTable";

interface RentingOutTableProps {
    searchCompanyListingsData: VehicleMarketplaceService.SearchCompanyListingsResponse | null;
    searchValue: string;
    onSearchChange: (searchQuery: string) => void;
    currentPage: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    isLoading: boolean;
    totalCount: number;
    onFiltersApplied: (statuses: VehicleMarketplaceService.ListingStatus[] | undefined) => void;
}

export default function RentingOutTable({
    searchCompanyListingsData,
    searchValue,
    onSearchChange,
    currentPage,
    totalCount,
    pageSize,
    onPageChange,
    isLoading,
    onFiltersApplied,
}: RentingOutTableProps) {
    const {i18n} = useI18n();

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    return (
        <div className="flex w-full flex-col gap-6">
            <div className="flex gap-4 sm:max-w-full md:max-w-md">
                <TableSearchField
                    value={searchValue}
                    onChange={onSearchChange}
                    placeholder={i18n("auth.app.fleet.vehicle_marketplace.listings.table.search_by_model")}
                />
                <RentingOutFilter onApply={onFiltersApplied} />
            </div>
            {isMobileView ? (
                <RentingOutAccordionTable items={searchCompanyListingsData ?? {rows: [], count: 0}} />
            ) : (
                <RentingOutFullTable items={searchCompanyListingsData ?? {rows: [], count: 0}} isLoading={isLoading} />
            )}
            <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={totalCount}
                onPageChange={onPageChange}
                disabled={isLoading}
                showText
            />
        </div>
    );
}

export function offerTypeToReadable(
    status: VehicleMarketplaceService.OfferType | null,
    i18n: I18nFn<TranslationKeys>,
): string {
    switch (status) {
        case VehicleMarketplaceService.OfferType.RENT:
            return i18n("auth.app.fleet.vehicle_marketplace.listings.table.rent");
        case VehicleMarketplaceService.OfferType.RENT_TO_BUY:
            return i18n("auth.app.fleet.vehicle_marketplace.listings.table.rent_to_buy");
        default:
            return "-";
    }
}

export function RentingOutTableEmptyState() {
    const {i18n} = useI18n();
    const {getVehicleListingCreatePath} = useAbsolutePath();
    const navigate = useNavigate();

    const goToCreateListing = useCallback(
        () => navigate(getVehicleListingCreatePath()),
        [navigate, getVehicleListingCreatePath],
    );

    return (
        <div className="flex w-full flex-col items-center justify-center gap-8 py-10">
            <HistoryIcon className="h-24 w-24" color="gray" />
            <div className="flex flex-col items-center justify-center gap-1">
                <Typography variant="body-l-accent">
                    {i18n("auth.app.fleet.vehicle_marketplace.listings.view.no_vehicles_listed")}
                </Typography>
                <GhostButton onClick={goToCreateListing}>
                    <div className="text-action-primary">
                        {i18n("auth.app.fleet.vehicle_marketplace.listings.view.add_vehicle_listing")}
                    </div>
                </GhostButton>
            </div>
        </div>
    );
}
