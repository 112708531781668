import {BoundsPadding, GoogleMapApi, Point} from "./types";

export class MapHelper {
    public static createBounds = (googleMapsApi: GoogleMapApi, points: Point[]) => {
        const bounds = new googleMapsApi.maps.LatLngBounds();
        for (const point of points) {
            bounds.extend(new googleMapsApi.maps.LatLng(point.lat, point.lng));
        }
        return bounds;
    };

    public static fitBounds = (
        googleMapsApi: GoogleMapApi,
        points: Point[],
        padding?: BoundsPadding,
        panOnSinglePoint?: boolean,
    ) => {
        const bounds = MapHelper.createBounds(googleMapsApi, points);
        googleMapsApi.map.fitBounds(bounds, padding);
        // 1 point is not respecting padding, gets hidden behind drivers list/details in live map
        if (points.length === 1 && panOnSinglePoint) {
            const xDirection = typeof padding === "number" ? padding : padding?.left ?? 0;
            googleMapsApi.map.panBy(-xDirection / 2, 0);
        }
    };

    public static getZoom = (googleMapsApi: GoogleMapApi) => googleMapsApi.map.getZoom();

    public static setZoom = (googleMapsApi: GoogleMapApi, zoom?: number) => {
        googleMapsApi.map.setZoom(zoom);
    };

    public static setCenter = (googleMapsApi: GoogleMapApi, point: Point) => {
        googleMapsApi.map.setCenter(point);
    };
}
