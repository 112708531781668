import {useContext} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import ViewApplicationPage from "features/companies/vehicleListings/applications/view-application";
import {FeaturesContextProvider} from "FeaturesProvider";

export const VehicleListingApplicantPage = () => {
    const isVehicleMarketplaceDisabled = !useContext(FeaturesContextProvider)?.vehicle_marketplace;

    if (isVehicleMarketplaceDisabled) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }
    return <ViewApplicationPage />;
};
