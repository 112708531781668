import {useCallback, useEffect, useState} from "react";

import {useLocalStorage} from "./useLocalStorage";

interface IpLocationData {
    country: string;
    country_iso_code: string;
    city: string;
    location: {
        lat: number;
        lng: number;
    };
}

interface ApiResponse {
    code: number;
    message: string;
    data?: IpLocationData;
}

interface IpLocationWithTimestamp {
    data: IpLocationData;
    timestamp: number;
}

export enum Status {
    LOADING = "loading",
}

const FIVE_MIN_IN_MILLIS = 5 * 60 * 1000;

export function useIpLocationData(geoServiceUrl: string, localStorageKey: string) {
    const [locationData, setLocationData] = useState<IpLocationData | Status | null>(Status.LOADING);
    const [cache, setCache] = useLocalStorage<IpLocationWithTimestamp | null>(localStorageKey, null);

    const getIpLocation = useCallback(async () => {
        try {
            if (cache) {
                if (cache.timestamp && Date.now() - cache.timestamp < FIVE_MIN_IN_MILLIS) {
                    setLocationData(cache.data);
                    return;
                }
            }
        } catch {
            // Ignore all errors
        }

        try {
            const response = await fetch(`${geoServiceUrl}/ipLocation`);
            if (response.status === 200) {
                const res = (await response.json()) as ApiResponse;
                if (res.code === 0 && res.data) {
                    setLocationData(res.data);
                    setCache({data: res.data, timestamp: Date.now()});
                }
            }
        } catch {
            setLocationData(null);
        }
    }, [geoServiceUrl, cache, setCache]);

    useEffect(() => {
        getIpLocation();
    }, [getIpLocation]);

    return locationData;
}
