import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";

import {TabName} from "..";

export function useTabSelectorOptions(): Array<Tab<TabName>> {
    const {i18n} = useI18n();

    const tabs: Array<Tab<TabName>> = [
        {
            id: TabName.CompanyDetails,
            title: i18n("auth.app.company-settings.tabs.company_details"),
            trackingEventTabName: TabNameEvent.CompanySettingsPageCompanyDetails,
        },
        {
            id: TabName.BankingDetails,
            title: i18n("auth.app.company-settings.tabs.banking_details"),
            trackingEventTabName: TabNameEvent.CompanySettingsPageBankingDetails,
        },
        {
            id: TabName.TaxInfo,
            title: i18n("auth.app.company-settings.tabs.tax_info"),
            trackingEventTabName: TabNameEvent.CompanySettingsPageTaxInfo,
        },
    ];

    return tabs;
}
