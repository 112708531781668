import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {DriverStats} from "../../types";
import {DriverFilterStatus} from "../Filter";

export function useTabSelectorOptions(driverStats: DriverStats): Array<Tab<DriverFilterStatus>> {
    const {i18n} = useI18n();

    const tabs: Array<Tab<DriverFilterStatus>> = [
        {
            id: FleetOwnerPortalService.DriverState.HAS_ORDER,
            title: `${i18n("auth.app.fleet.live-map.has-order")}: ${
                driverStats[FleetOwnerPortalService.DriverState.HAS_ORDER] || "-"
            }`,
            trackingEventTabName: TabNameEvent.LiveMapPageHasOrder,
        },
        {
            id: FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS,
            title: `${i18n("auth.app.fleet.live-map.waiting-for-order")}: ${
                driverStats[FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS] || "-"
            }`,
            trackingEventTabName: TabNameEvent.LiveMapPageWaitingForOrder,
        },
    ];

    return tabs;
}
