import {FC, useCallback, useState} from "react";

import Header, {HeaderSize} from "common/components/header/Header";
import LoadingSpinner from "common/components/spinner";
import {EventName} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";

import {GhostButton, Island} from "@bolteu/kalep-react";

import {DocumentRow} from "./DocumentRow";
import {UploadDocumentWithDocumentType} from "./DocumentUpload/UploadDocumentWithDocumentType";
import {DocumentData, DocumentOwner, DocumentType} from "./useDocumentsList";

export interface DocumentsListProps {
    owner: DocumentOwner;
    documents: DocumentData[];
    onDocumentUpload: () => void;
    shouldShowDocumentsLoading: boolean;
    isUploadAllowed: boolean;
}

export const DocumentsList: FC<DocumentsListProps> = ({
    owner,
    documents,
    onDocumentUpload,
    shouldShowDocumentsLoading,
    isUploadAllowed,
}) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState<number | null>(null);

    const onDocumentUploadClose = useCallback(
        ({documentUploaded}: {documentUploaded: boolean}) => {
            setIsUploadDrawerOpen(false);
            setSelectedDocumentType(null);
            if (documentUploaded) {
                onDocumentUpload();
            }
        },
        [onDocumentUpload],
    );

    const handleUploadClick = useCallback(
        (documentType: DocumentType) => () => {
            trackEvent(EventName.DocumentUploadButtonClicked, {documentType: documentType.name});
            setIsUploadDrawerOpen(true);
            setSelectedDocumentType(documentType.id);
        },
        [trackEvent],
    );
    const uploadFile = useCallback(() => {
        trackEvent(EventName.DocumentUploadButtonClicked);
        setIsUploadDrawerOpen(true);
    }, [trackEvent]);

    if (shouldShowDocumentsLoading) {
        return (
            <Island>
                <LoadingSpinner show />
            </Island>
        );
    }

    if (documents.length === 0) {
        return <></>;
    }

    return (
        <>
            <Island>
                <Header
                    size={HeaderSize.ExtraSmall}
                    text={i18n("auth.app.fleet.driver-profile.documents.header.title")}
                    className="pb-4"
                />
                {documents.map((document) => (
                    <div
                        key={document.type.name}
                        className="border-separator mb-2 border-b pb-2 last:mb-0 last:border-none last:pb-0"
                    >
                        <DocumentRow
                            document={document}
                            isUploadAllowed={isUploadAllowed}
                            onUploadClick={handleUploadClick(document.type)}
                        />
                    </div>
                ))}
                {isUploadAllowed && (
                    <GhostButton
                        overrideClassName="font-semibold text-action-primary active:scale-975 active:ease-in-out active:duration-100 mt-3"
                        onClick={uploadFile}
                    >
                        {i18n("auth.app.fleet.driver-profile.documents.footer.upload_button")}
                    </GhostButton>
                )}
            </Island>
            {isUploadDrawerOpen && (
                <UploadDocumentWithDocumentType
                    owner={owner}
                    onClose={onDocumentUploadClose}
                    documentTypes={documents}
                    documentTypeId={selectedDocumentType}
                />
            )}
        </>
    );
};
