import {createContext, useCallback, useContext, useEffect, useState} from "react";

import {Checkbox, TextField, Typography} from "@bolteu/kalep-react";

import InfiniteScrollview from "../infiniteScroll/InfiniteScrollview";

export type MultiSelectListviewItemKey = string | number;

interface MultiSelectListviewContextType {
    selectedItems: MultiSelectListviewItemKey[];
    selectAll: boolean;
    addSelectedItem: (item: MultiSelectListviewItemKey) => void;
    removeSelectedItem: (item: MultiSelectListviewItemKey) => void;
}

const MultiSelectListviewContext = createContext<MultiSelectListviewContextType>({
    selectedItems: [],
    selectAll: false,
    addSelectedItem: () => {},
    removeSelectedItem: () => {},
});

export interface MultiSelectListviewSubmitArgs {
    isAllSelected: boolean;
}

interface MultiSelectListviewProps {
    children: React.ReactNode;
    searchLabel: string;
    searchPlaceholder?: string;
    allowSelectAll?: boolean;
    selectAllLabel?: string;
    allItemsCount: number;
    initialSelectedItems?: MultiSelectListviewItemKey[];
    isMoreDataAvailable?: boolean;
    isAllSelected?: boolean;
    onListEndReached?: () => void;
    onSelectAll?: (selectAll: boolean) => void;
    onSearch: (search: string) => void;
    onSelectedItemsChange?: (items: MultiSelectListviewItemKey[]) => void;
    onItemSelected?: (item: MultiSelectListviewItemKey) => void;
    onItemDeselected?: (item: MultiSelectListviewItemKey) => void;
}

function MultiSelectListview({
    children,
    searchLabel,
    searchPlaceholder,
    allowSelectAll,
    selectAllLabel,
    allItemsCount,
    initialSelectedItems,
    isMoreDataAvailable,
    isAllSelected,
    onSelectAll,
    onSearch,
    onListEndReached,
    onSelectedItemsChange,
    onItemSelected,
    onItemDeselected,
}: MultiSelectListviewProps) {
    const [selectedItems, setSelectedItems] = useState<Array<string | number>>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setSelectedItems(initialSelectedItems || []);
    }, [initialSelectedItems]);

    useEffect(() => {
        setSelectAll(isAllSelected || false);
    }, [isAllSelected]);

    const addSelectedItem = useCallback(
        (value: string | number) => {
            const newSelectedItems = [...selectedItems, value];
            setSelectedItems(newSelectedItems);
            onSelectedItemsChange?.(newSelectedItems);
            onItemSelected?.(value);
        },
        [selectedItems, onSelectedItemsChange, onItemSelected],
    );

    const removeSelectedItem = useCallback(
        (value: string | number) => {
            const newSelectedItems = selectedItems.filter((item) => item !== value);
            setSelectedItems(newSelectedItems);
            onSelectedItemsChange?.(newSelectedItems);
            onItemDeselected?.(value);
        },
        [selectedItems, onSelectedItemsChange, onItemDeselected],
    );

    const onSelectAllChange = useCallback(() => {
        setSelectAll(!selectAll);
        if (selectAll) {
            setSelectedItems([]);
        }

        onSelectAll?.(!selectAll);
    }, [selectAll, onSelectAll]);

    const handleSearchChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onSearch(e.target.value);
            setSearchQuery(e.target.value);
        },
        [onSearch],
    );

    return (
        <MultiSelectListviewContext.Provider value={{selectedItems, selectAll, addSelectedItem, removeSelectedItem}}>
            <div className="flex h-full w-full flex-col gap-6">
                <TextField
                    label={searchLabel}
                    placeholder={searchPlaceholder}
                    onChange={handleSearchChange}
                    fullWidth
                />
                <InfiniteScrollview onListEndReached={onListEndReached} isMoreDataAvailable={isMoreDataAvailable}>
                    <div className="flex flex-col gap-6 px-2">
                        {allowSelectAll && searchQuery.length === 0 && (
                            <div className="flex w-full items-center gap-4">
                                <Checkbox aria-label="Select all" checked={selectAll} onChange={onSelectAllChange} />
                                <Typography variant="body-m-regular">
                                    {selectAllLabel} ({allItemsCount})
                                </Typography>
                            </div>
                        )}
                        <div className="flex h-full flex-col gap-3">{children}</div>
                    </div>
                </InfiniteScrollview>
            </div>
        </MultiSelectListviewContext.Provider>
    );
}

interface MultiSelectListviewItemProps {
    children: React.ReactNode;
    value: string | number;
    disabled?: boolean;
}

MultiSelectListview.Item = function Item({children, value, disabled}: MultiSelectListviewItemProps) {
    const {selectedItems, addSelectedItem, removeSelectedItem, selectAll} = useContext(MultiSelectListviewContext);
    const isSelected = selectedItems.includes(value) || selectAll;

    const onCheckboxClicked = useCallback(() => {
        if (isSelected) {
            removeSelectedItem(value);
        } else {
            addSelectedItem(value);
        }
    }, [isSelected, addSelectedItem, removeSelectedItem, value]);

    return (
        <div className="flex w-full items-center gap-4">
            <Checkbox
                aria-label="Select item"
                checked={isSelected}
                onChange={onCheckboxClicked}
                disabled={disabled || selectAll}
            />
            <button type="button" className="flex-1" onClick={onCheckboxClicked}>
                {children}
            </button>
        </div>
    );
};

MultiSelectListview.Divider = function Divider() {
    return <div className="border-separator h-px border-b" />;
};

export default MultiSelectListview;
