import {ChangeEventHandler, useCallback, useEffect, useMemo} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getUnixTime} from "date-fns";
import {debounce} from "lodash-es";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ComboBox, SelectOption} from "@bolteu/kalep-react";

import {useAssigmentDrivers} from "../../hooks/useAssigmentDrivers";
import {CreateDrawerType, Step1Data, Step2Data, Step3Data} from "../../types";
import {getApiAddressFromAddress} from "../../util";

interface Props {
    data: Step1Data & Step2Data & Step3Data;
    onChange: (driverId?: number, newName?: string) => void;
    validationError?: string;
    drawerType: CreateDrawerType;
}

type GetDriversRequest =
    | FleetPortalOrderService.ScheduledOrderCreationDriverAssignment
    | FleetPortalOrderService.InstantOrderCreationDriverAssignment;

const getDriversForOrderCreationAssignmentFunction = (api: Api, body: GetDriversRequest) =>
    api.fleetPortalOrder.getDriversForOrderCreationAssignment(body);

export const DriverComboBoxForOrderCreationAssignment = ({data, onChange, validationError, drawerType}: Props) => {
    const {i18n} = useI18n();
    const {fetch: getDriversFetch, data: drivers, status} = useFetch(getDriversForOrderCreationAssignmentFunction);

    const {onDriverChange, driversOptions, searchValue, setSearchValue} = useAssigmentDrivers({
        fetchData: drivers,
        fetchStatus: status,
        selectedDriverName: data.driver_name,
        onChange,
    });

    const getDrivers = useCallback(
        (search: string) => {
            if (!getDriversFetch || !data.category_id) {
                return;
            }
            const pickup = getApiAddressFromAddress(data.pickup);
            if (!pickup) {
                // Shouldnt happen as this should be checked before
                throw Error("Address is missing lat or lng");
            }
            const hasNoDropoffs = data.dropoffs.every((dropoff) => !dropoff.address_name);
            const dropoffs = hasNoDropoffs
                ? undefined
                : data.dropoffs
                      .map((dropoff) => getApiAddressFromAddress(dropoff))
                      .filter((address) => address !== null);
            const bodyBase: Omit<FleetPortalOrderService.OrderCreationDriverAssignmentBase, "type"> = {
                pickup,
                dropoffs,
                search,
                payment_method: data.payment_method,
                category_id: data.category_id,
            };
            const body: GetDriversRequest =
                drawerType === CreateDrawerType.SCHEDULE
                    ? {
                          ...bodyBase,
                          type: FleetPortalOrderService.OrderCreationType.SCHEDULED,
                          scheduled_for: getUnixTime(data.scheduled_for),
                          ride_duration_seconds: data.category_ride_duration_seconds,
                      }
                    : {...bodyBase, type: FleetPortalOrderService.OrderCreationType.INSTANT};
            getDriversFetch(body);
        },
        [
            data.category_id,
            data.category_ride_duration_seconds,
            data.dropoffs,
            data.payment_method,
            data.pickup,
            data.scheduled_for,
            drawerType,
            getDriversFetch,
        ],
    );

    useEffect(() => {
        getDrivers("");
    }, [getDrivers]);

    const getDriversDebounce = useMemo(() => debounce((newSearch: string) => getDrivers(newSearch), 500), [getDrivers]);

    const onSearchValueChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setSearchValue(event.target.value);
            getDriversDebounce(event.target.value);
        },
        [getDriversDebounce, setSearchValue],
    );

    // No filtering needed as we are getting drivers by search from API
    const filterOptions = useCallback((options: SelectOption[]) => options, []);

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(status);
    const selectedDriverOption = driversOptions?.find((option) => option.value === data.driver_id);

    return (
        <ComboBox
            placeholder={i18n("auth.app.orders.scheduled_rides.select-driver-placeholder")}
            value={selectedDriverOption}
            options={driversOptions ?? []}
            onChange={onDriverChange}
            inputValue={searchValue}
            onInputChange={onSearchValueChange}
            fullWidth
            loading={isLoading}
            error={Boolean(validationError)}
            helperText={validationError}
            filterOptions={filterOptions}
        />
    );
};
