import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";

import {TabName} from "..";

export function useTabSelectorOptions(): Array<Tab<TabName>> {
    const {i18n} = useI18n();

    const tabs: Array<Tab<TabName>> = [
        {
            id: TabName.Requirements,
            title: i18n("auth.app.fleet.campaigns.details.tabs.requirements"),
            trackingEventTabName: TabNameEvent.CampaignDetailsPageRequirements,
        },
        {
            id: TabName.Participants,
            title: i18n("auth.app.fleet.campaigns.details.tabs.participants"),
            trackingEventTabName: TabNameEvent.CampaignDetailsPageParticipants,
        },
    ];

    return tabs;
}
