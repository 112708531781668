import {useMemo} from "react";

import DataAndMeasuresCell from "common/components/table/DataAndMeasuresCell";
import {useI18n} from "common/hooks/useI18n";

import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import DownloadPdf from "../components/DownloadPdf";
import {CompensationInvoiceData} from "./useCompensations";

export function useTableColumns() {
    const {i18n} = useI18n();

    return useMemo<TableHeaders<CompensationInvoiceData>>(
        () => ({
            name: {
                label: i18n("auth.app.invoices.compensations_v2.compensation"),
            },
            date: {
                label: i18n("common.date"),
            },
            sum: {
                label: i18n("common.total_sum"),
                renderCell: (item: CompensationInvoiceData) => (
                    <DataAndMeasuresCell data={item.sum} measure={item.currency} />
                ),
                align: "right",
            },
            id: {
                label: "",
                renderCell: (item: CompensationInvoiceData) => (
                    <DefaultCellContent className="justify-center">
                        <DownloadPdf item={item} />
                    </DefaultCellContent>
                ),
            },
        }),
        [i18n],
    );
}
