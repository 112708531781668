import {createContext, useCallback, useContext, useEffect, useState} from "react";

import {ApiContextProvider} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {FeaturesContextProvider} from "FeaturesProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

type ConfigEnabled = Omit<FleetPortalOrderService.PortalFleetDispatchingConfigEnabled, "is_enabled"> & {
    is_enabled: true;
};
type ConfigDisabled = Omit<FleetPortalOrderService.PortalFleetDispatchingConfigDisabled, "is_enabled"> & {
    is_enabled: false;
};

export type OrderRequestsConfig = ConfigEnabled | ConfigDisabled;
export interface OrderRequestsContext {
    isEnabled: boolean;
    isInstantRidesEnabled: boolean;
    isOrderCreationEnabled: boolean;
    isDropoffAddressRequired: boolean;
    setIsDropoffAddressRequired: (value: boolean) => void;
    getCurrency: () => string;
    getOrderCreationPaymentMethods: () => FleetPortalOrderService.CreateOrderPaymentMethod[];
    getManualPricing: () => FleetPortalOrderService.ManualPricing;
    getSchedulingLimits: () => FleetPortalOrderService.SchedulingLimits;
    getMaxDropoffsCount: () => number;
    isLoading: boolean;
}

// Default are just for the initial state, should be overwritten by the actual values from API
const defaultConfig: OrderRequestsConfig = {is_enabled: false};
const defaultManualPricing: FleetPortalOrderService.ManualPricing = {
    is_enabled: false,
    max_base_price_multiplier: 1,
    min_base_price_multiplier: 1,
};
const defaultSchedulingLimits: FleetPortalOrderService.SchedulingLimits = {
    maximum_date_in_future_days: 90,
    minimum_time_in_future_minutes: 30,
};
const DEFAULT_MAX_DROPOFFS_COUNT = 3;

const OrderRequestsContextProvider = createContext<OrderRequestsContext>({
    isEnabled: false,
    isInstantRidesEnabled: false,
    isOrderCreationEnabled: false,
    getCurrency: () => "",
    getOrderCreationPaymentMethods: () => [],
    getManualPricing: () => defaultManualPricing,
    getSchedulingLimits: () => defaultSchedulingLimits,
    getMaxDropoffsCount: () => DEFAULT_MAX_DROPOFFS_COUNT,
    isDropoffAddressRequired: true,
    setIsDropoffAddressRequired: () => {},
    isLoading: true,
});
OrderRequestsContextProvider.displayName = "OrderRequestsContextProvider";

const OrderRequestsProvider = ({children}: {children: React.ReactNode}) => {
    const [config, setConfig] = useState<OrderRequestsConfig>(defaultConfig);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropoffAddressRequired, setIsDropoffAddressRequired] = useState(true);
    const api = useContext(ApiContextProvider);
    const fleet = useContext(AccountContextProvider).getFleet();
    const isOrderDispatchingFeatureDisabled = !useContext(FeaturesContextProvider)?.order_dispatching;

    const fetchConfig = useCallback(async () => {
        setConfig(defaultConfig);
        setIsLoading(true);
        if (!api || !fleet || isOrderDispatchingFeatureDisabled) {
            setIsLoading(false);
            return;
        }
        try {
            const response = await api.fleetPortalOrder.getFleetDispatchingConfig();
            if (response.is_enabled === FleetPortalOrderService.Enabled.TRUE) {
                setConfig({
                    is_enabled: true,
                    is_order_creation_enabled: response.is_order_creation_enabled,
                    is_instant_ride_creation_enabled: response.is_instant_ride_creation_enabled,
                    manual_pricing: response.manual_pricing,
                    create_order_payment_methods: response.create_order_payment_methods,
                    currency: response.currency,
                    scheduling_limits: response.scheduling_limits,
                    max_dropoffs_count: response.max_dropoffs_count,
                });
            }
        } finally {
            setIsLoading(false);
        }
    }, [api, fleet, isOrderDispatchingFeatureDisabled]);

    useEffect(() => {
        fetchConfig();
    }, [fetchConfig]);

    const getOrderCreationPaymentMethods = useCallback(
        (): FleetPortalOrderService.CreateOrderPaymentMethod[] =>
            config.is_enabled ? config.create_order_payment_methods : [],
        [config],
    );

    const getCurrency = useCallback((): string => (config.is_enabled ? config.currency : ""), [config]);

    const getManualPricing = useCallback(
        (): FleetPortalOrderService.ManualPricing => (config.is_enabled ? config.manual_pricing : defaultManualPricing),
        [config],
    );

    const getSchedulingLimits = useCallback(
        (): FleetPortalOrderService.SchedulingLimits =>
            config.is_enabled ? config.scheduling_limits : defaultSchedulingLimits,
        [config],
    );

    const getMaxDropoffsCount = useCallback(
        (): number => (config.is_enabled ? config.max_dropoffs_count : DEFAULT_MAX_DROPOFFS_COUNT),
        [config],
    );

    const contextValue: OrderRequestsContext = {
        isEnabled: config.is_enabled,
        isInstantRidesEnabled: config.is_enabled ? config.is_instant_ride_creation_enabled : false,
        isOrderCreationEnabled: config.is_enabled ? config.is_order_creation_enabled : false,
        getCurrency,
        getOrderCreationPaymentMethods,
        getManualPricing,
        getSchedulingLimits,
        isDropoffAddressRequired,
        setIsDropoffAddressRequired,
        getMaxDropoffsCount,
        isLoading,
    };

    return (
        <OrderRequestsContextProvider.Provider value={contextValue}>{children}</OrderRequestsContextProvider.Provider>
    );
};

export {OrderRequestsProvider, OrderRequestsContextProvider};
