import {useContext} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerRegistrationService, VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Button, Link, Typography} from "@bolteu/kalep-react";

import WizardFileupload from "../../common/components/WizardFileUpload";
import {getVehicleMarketplaceListingUrl} from "../../utils/getVehicleMarketplaceListingUrl";
import FleetFeedback from "./FleetFeedback";
import {ListingContextProvider} from "./ListingInfoProvider";

import ListingStep = VehicleMarketplaceService.ListingStep;

interface WizardReviewContentProps {
    listingId: number;
    steps: ListingStep[];
    fleetSupportLink?: string;
    onPublishListing: () => void;
    onPrevStep: () => void;
    isPublishLoading: boolean;
}

const WizardReviewContent = ({
    listingId,
    steps,
    onPublishListing,
    onPrevStep,
    isPublishLoading,
    fleetSupportLink,
}: WizardReviewContentProps) => {
    const {i18n} = useI18n();
    const {listing} = useContext(ListingContextProvider);

    const getFieldContent = (field: FleetOwnerRegistrationService.FormField) => {
        const {type} = field;

        switch (type) {
            case FleetOwnerRegistrationService.FormFieldType.IMAGEUPLOAD:
                return <WizardFileupload isReadOnly />;
            case FleetOwnerRegistrationService.FormFieldType.RADIOGROUP:
            case FleetOwnerRegistrationService.FormFieldType.COMBOBOX: {
                if (!field.value?.length) {
                    return null;
                }
                if (field.type === FleetOwnerRegistrationService.FormFieldType.COMBOBOX && field.multi) {
                    const values = field.options
                        .filter((option) => field.values?.includes(option.value))
                        .map((option) => option.label);

                    return <li>{`${field.label}: ${values.join(", ")}`}</li>;
                }

                const value = field.options.find((option) => option.value === field.value)?.label || "";

                return <li>{`${field.label}: ${value}`}</li>;
            }
            case FleetOwnerRegistrationService.FormFieldType.TEXT: {
                const value = field.value || "";
                return <li>{`${field.label}: ${value}`}</li>;
            }
            case FleetOwnerRegistrationService.FormFieldType.NUMBER: {
                const unit = field.unit || "";
                const value = field.value || "";
                const valueToShow = value ? `${value}${unit}` : "-";
                return <li>{`${field.label}: ${valueToShow}`}</li>;
            }
            case FleetOwnerRegistrationService.FormFieldType.CHECKBOXGROUP:
                if (!field.values?.length) {
                    return null;
                }
                return (
                    <div className="pt-4">
                        <p className="font-semibold">{field.label}</p>
                        <ul className="mt-4 list-disc gap-3 pl-5">
                            {field.values?.map((value) => {
                                const label = field.options.find((option) => option.value === value)?.label || "";
                                return <li key={label}>{label}</li>;
                            })}
                        </ul>
                    </div>
                );
            default:
                return <></>;
        }
    };

    const getStepContent = (step: ListingStep) => {
        const {fields} = step;
        if (step.id === "driver_requirements" && fields.every((field) => !field.value)) {
            return null;
        }
        return (
            <div key={step.id}>
                <Typography variant="title-primary" fontSize="text-xl">
                    {step.title}
                </Typography>
                <ul className="mt-4 list-disc gap-3 pl-5">
                    {fields.map((field) => (
                        <div key={field.name}>{getFieldContent(field)}</div>
                    ))}
                </ul>
            </div>
        );
    };

    const title = listing
        ? `${listing.data.vehicle_manufacturer} ${listing.data.vehicle_model_name} (${listing.data.vehicle_years})`
        : "";

    const isListingInDraft = listing?.data.status === VehicleMarketplaceService.ListingStatus.DRAFT;

    return (
        <div className="mt-8">
            {isListingInDraft && (
                <Alert severity="info">
                    <Typography variant="title-primary" fontSize="text-xl">
                        {i18n("auth.app.fleet.vehicle_marketplace.listings.publish.title", {carModel: title})}
                    </Typography>

                    <p className="text-secondary mt-2">
                        {i18n("auth.app.fleet.vehicle_marketplace.listings.publish.description", {
                            marketplace_link: (
                                <Link
                                    href={getVehicleMarketplaceListingUrl(listing.data.city, listingId)}
                                    target="_blank"
                                >
                                    {i18n("auth.app.fleet.vehicle_marketplace.listings.publish.marketplace_link_text")}
                                </Link>
                            ),
                        })}
                    </p>
                </Alert>
            )}

            <div className="mt-8 flex w-full flex-col gap-8">{steps.map(getStepContent)}</div>
            <FleetFeedback fleetSupportLink={fleetSupportLink} />
            <div className="mt-8 flex justify-between">
                <Button variant="secondary" onClick={onPrevStep} disabled={isPublishLoading}>
                    {i18n("common.back")}
                </Button>
                {isListingInDraft && (
                    <Button loading={isPublishLoading} type="submit" onClick={onPublishListing}>
                        {i18n("auth.app.fleet.vehicle_marketplace.listings.add.publish")}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default WizardReviewContent;
