import {useContext} from "react";

import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {FeaturesContextProvider} from "FeaturesProvider";

export enum TabName {
    OrderHistory = "order_history",
    ActivityLog = "activity_log",
}

export function useTrackingTabSelectorOptions(): Array<Tab<TabName>> {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);

    const tabs: Array<Tab<TabName>> = [
        {
            id: TabName.OrderHistory,
            title: i18n("auth.app.sidebar.order-history"),
            trackingEventTabName: TabNameEvent.OrdersPageOrderHistory,
        },
    ];
    if (features?.shifts) {
        tabs.push({
            id: TabName.ActivityLog,
            title: i18n("auth.app.fleet.shifts.activity_log.title"),
            trackingEventTabName: TabNameEvent.ShiftLog,
        });
    }

    return tabs;
}
