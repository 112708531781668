import {useI18n} from "common/hooks/useI18n";

import {Button, Dialog} from "@bolteu/kalep-react";

interface Props {
    isOpen: boolean;
    onRequestClose: () => void;
    onTakeOfflineClicked: () => void;
}

export default function TakeOfflineDialog({isOpen, onRequestClose, onTakeOfflineClicked}: Props) {
    const {i18n} = useI18n();
    return (
        <Dialog title={i18n("common.take_driver_offline")} isOpen={isOpen} onRequestClose={onRequestClose}>
            <Dialog.Content>
                <div className="text-secondary text-base">
                    {i18n("auth.app.fleet.live-map.take-offline.confirmation.set-driver-offline-description")}
                </div>
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button variant="secondary" onClick={onRequestClose}>
                        {i18n("auth.app.fleet.live-map.take-offline.confirmation.cancel")}
                    </Button>
                    <Button onClick={onTakeOfflineClicked} variant="danger">
                        {i18n("auth.app.fleet.live-map.take-offline.confirmation.take-offline")}
                    </Button>
                </div>
            </Dialog.Footer>
        </Dialog>
    );
}
