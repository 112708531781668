import {FC} from "react";

import PolygonDown from "assets/icons/polygon-down-red.svg?react";
import PolygonUp from "assets/icons/polygon-up-green.svg?react";
import Header, {HeaderSize} from "common/components/header/Header";
import ApiDrivenTable from "common/components/table/ApiDrivenTable";
import {AlignVertical} from "common/components/table/ApiDrivenTable/types";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Island} from "@bolteu/kalep-react";
import TabularData = FleetOwnerPortalService.TabularData;

interface DashboardTablesProps {
    topEntities?: TabularData;
    bottomEntities?: TabularData;
}

const DashboardTables: FC<DashboardTablesProps> = ({topEntities, bottomEntities}) => {
    if (!topEntities && !bottomEntities) {
        return null;
    }

    return (
        <Island>
            {topEntities && (
                <>
                    <div className="flex flex-row">
                        <span className="mr-2">
                            <PolygonUp />
                        </span>
                        <Header
                            size={HeaderSize.ExtraSmall}
                            text={topEntities.name}
                            tooltip={topEntities.description}
                            className="mb-6"
                        />
                    </div>
                    <ApiDrivenTable
                        tableId="TOP_PERFORMERS"
                        columns={topEntities.columns}
                        alignVertical={AlignVertical.MIDDLE}
                        numberOfRecords={topEntities.columns[0].cells.length ?? 0}
                    />
                </>
            )}
            {bottomEntities && (
                <>
                    <div className="mt-10 flex flex-row">
                        <span className="mr-2">
                            <PolygonDown />
                        </span>
                        <Header
                            size={HeaderSize.ExtraSmall}
                            text={bottomEntities.name}
                            tooltip={bottomEntities.description}
                            className="mb-6"
                        />
                    </div>
                    <ApiDrivenTable
                        tableId="LOW_PERFORMERS"
                        columns={bottomEntities.columns}
                        alignVertical={AlignVertical.MIDDLE}
                        numberOfRecords={bottomEntities.columns[0].cells.length ?? 0}
                    />
                </>
            )}
        </Island>
    );
};

export default DashboardTables;
