import {
    AccordionColumnHeader,
    AccordionHeaderColumnKeys,
    AccordionTable,
    AccordionTableProps,
    TableItem,
} from "@bolteu/kalep-table-react";

import {useShownColumns} from "./hooks/useShownColumns";
import {CommonCustomizableTableHeaderProps} from "./TableWithCustomizableColumns";

export interface CustomizableAccordionTableHeader<Entity>
    extends AccordionColumnHeader<Entity>,
        CommonCustomizableTableHeaderProps {}

export type CustomizableAccordionTableHeaders<Entity> = Partial<
    Record<AccordionHeaderColumnKeys<Entity>, CustomizableAccordionTableHeader<Entity>>
>;

export interface CustomizableAccordionTableProps<Entity extends TableItem> extends AccordionTableProps<Entity> {
    id: string;
    columns: CustomizableAccordionTableHeaders<Entity>;
}

export default function AccordionTableWithCustomizableColumns<Entity extends TableItem>({
    id,
    ...props
}: CustomizableAccordionTableProps<Entity>) {
    const {columns} = props;
    const shownColumns = useShownColumns(id, columns);

    return <AccordionTable {...props} columns={shownColumns} />;
}
