export const MIX_GRAPH_SECTION_WIDTH = 0.04;
export const MAX_GRAPH_SECTIONS = 4;
export const GRAPH_INCOME_SECTIONS_COLORS = [
    "bg-active-action-primary",
    "bg-action-primary",
    "bg-active-action-secondary",
    "bg-action-secondary",
];
export const GRAPH_OUTCOME_SECTIONS_COLORS = [
    "bg-active-danger-primary",
    "bg-danger-primary",
    "bg-active-danger-secondary",
    "bg-danger-secondary",
];
