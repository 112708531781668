import {useCallback} from "react";

import {DocumentData} from "common/components/document/useDocumentsList";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const fetchFunction = async (
    api: Api,
    body: FleetOwnerPortalService.GetCompanyDocumentsSetRequest,
): Promise<DocumentData[]> => {
    const resp = await api.fleetOwnerPortal.getCompanyDocumentsSet(body);
    return resp.list.map((doc) => {
        const document = doc.uploaded_document ?? doc.document;
        return {
            ...(document
                ? {
                      document: {
                          id: document.id,
                          expires: document.expires,
                          status: document.status,
                      },
                  }
                : {document: null}),
            type: {
                ...doc.document_type,
                is_expiry_required: !!doc.document_type.is_expiry_required,
                is_required: !!doc.document_type.is_required,
            },
        };
    });
};

const uploadFunction = (
    api: Api,
    body: FleetOwnerPortalService.UploadCompanyDocumentRequest,
    files: FleetOwnerPortalService.Files,
) => api.fleetOwnerPortal.uploadCompanyDocument(body, files);

export const useCompanyDocumentFunctions = () => {
    const documentsFetchFunction = useCallback((api: Api) => fetchFunction(api, {}), []);

    const documentUploadFunction = useCallback(
        async (api: Api, expiresIsoDate: string | null, files: FleetOwnerPortalService.Files) => {
            await uploadFunction(api, {...(expiresIsoDate && {expires: expiresIsoDate})}, files);
        },
        [],
    );

    return {
        documentsFetchFunction,
        documentUploadFunction,
    };
};
