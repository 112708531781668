import {FC} from "react";

import Icon from "assets/icons/car-driver.svg?react";
import CloseButton from "common/components/CloseButton";
import {useI18n} from "common/hooks/useI18n";

import {Button} from "@bolteu/kalep-react";

export interface CarRentalPaymentsInfoProps {
    closePage: () => void;
}

const CarRentalPaymentsInfo: FC<CarRentalPaymentsInfoProps> = ({closePage}) => {
    const {i18n} = useI18n();

    return (
        <div className="m-auto mb-10 flex max-w-3xl flex-col justify-center">
            <div className="flex justify-end">
                <CloseButton onClick={closePage} />
            </div>
            <Icon />
            <div className="my-6 flex flex-col gap-3">
                <h1 className="text-3xl font-semibold">{i18n("auth.app.fleet.car-rentals.info_page.main_title")}</h1>
                <p className="text-secondary text-lg">{i18n("auth.app.fleet.car-rentals.info_page.main_body")} </p>
            </div>
            <div className="my-6 flex flex-col gap-2">
                <h2 className="text-lg font-semibold">{i18n("auth.app.fleet.car-rentals.info_page.sub_title1")}</h2>
                <p className="text-secondary text-lg">{i18n("auth.app.fleet.car-rentals.info_page.sub_body1")}</p>
            </div>
            <div className="my-6 flex flex-col gap-2">
                <h2 className="text-lg font-semibold">{i18n("auth.app.fleet.car-rentals.info_page.sub_title2")}</h2>
                <p className="text-secondary text-lg">{i18n("auth.app.fleet.car-rentals.info_page.sub_body2")}</p>
            </div>
            <div className="my-6 flex flex-col gap-2">
                <h2 className="text-lg font-semibold">{i18n("auth.app.fleet.car-rentals.info_page.sub_title3")}</h2>
                <p className="text-secondary text-lg">{i18n("auth.app.fleet.car-rentals.info_page.sub_body3")}</p>
                <ul className="text-secondary text-lg">
                    <li>{i18n("auth.app.fleet.car-rentals.info_page.sub_bullet1")}</li>
                    <li>{i18n("auth.app.fleet.car-rentals.info_page.sub_bullet2")}</li>
                    <li>{i18n("auth.app.fleet.car-rentals.info_page.sub_bullet3")}</li>
                </ul>
            </div>
            <div className="my-6 flex flex-col gap-2">
                <h2 className="text-lg font-semibold">{i18n("auth.app.fleet.car-rentals.info_page.sub_title4")}</h2>
                <p className="text-secondary text-lg">{i18n("auth.app.fleet.car-rentals.info_page.sub_body4")}</p>
            </div>
            <div className="mt-4">
                <Button variant="secondary" onClick={closePage}>
                    {i18n("auth.app.fleet.car-rentals.info_page.close")}
                </Button>
            </div>
        </div>
    );
};

export default CarRentalPaymentsInfo;
