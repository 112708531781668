import {createContext, useCallback, useContext, useRef, useState} from "react";

import {I18nFn, useI18n} from "common/hooks/useI18n";
import {ApiContextProvider} from "common/services/apiProvider";
import {isError} from "common/util/isErrorType";
import {TranslationKeys} from "config/translations";
import {getUnixTime} from "date-fns";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {isEqual} from "lodash-es";
import {PrimitiveValues} from "@fleet/common/hooks/useI18n";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {OrderRequestsContextProvider} from "../OrderRequestsContextProvider";
import {ServerError} from "./constants";
import {AddressWithLatLng, CreateDrawerType, Step1Data, Step2Data} from "./types";
import {getApiAddressFromAddress} from "./util";

type FindRideOptionsRequest =
    | FleetPortalOrderService.FindInstantRideOptions
    | FleetPortalOrderService.FindScheduledRideOptions;

type FindRideOptionsResult =
    | FleetPortalOrderService.RideOptionsSuccess
    | FleetPortalOrderService.RideOptionsError
    | null;

type Step1DataPickupExists = Step1Data & {pickup: Step1Data["pickup"] & AddressWithLatLng};
type FormData = Step1DataPickupExists & Omit<Step2Data, "is_manual_price_enabled">;

export interface CatetegoriesContext {
    rideOptions: FleetPortalOrderService.RideOptionsSuccess | null;
    resetRideOptions: () => void;
    resetApiError: () => void;
    findRideOptions: (formData: FormData, drawerType: CreateDrawerType) => Promise<FindRideOptionsResult>;
    isLoading: boolean;
    apiError: FleetPortalOrderService.ValidationError | null;
}

const getRequest = (formData: FormData, drawerType: CreateDrawerType) => {
    const hasNoDropoffs = formData.dropoffs.every((dropoff) => !dropoff.address_name);
    const dropoffs: FleetPortalOrderService.Address[] | undefined = hasNoDropoffs
        ? undefined
        : formData.dropoffs.map((dropoff) => getApiAddressFromAddress(dropoff)).filter((address) => address !== null);
    const pickup = getApiAddressFromAddress(formData.pickup);
    if (!pickup) {
        // Shouldnt happen as this should be checked before
        throw Error("Address is missing lat or lng");
    }
    let request: FindRideOptionsRequest = {
        type: FleetPortalOrderService.OrderCreationType.INSTANT,
        create_order_uuid: formData.create_order_uuid,
        pickup,
        dropoffs,
        payment_method: formData.payment_method,
        manual_price: formData.manual_price ? Number(formData.manual_price) || undefined : undefined,
    };
    if (drawerType === CreateDrawerType.SCHEDULE) {
        request = {
            ...request,
            type: FleetPortalOrderService.OrderCreationType.SCHEDULED,
            scheduled_for: getUnixTime(formData.scheduled_for),
        };
    }
    return request;
};

const getErrorTranslationKey = (error: Error, isInstantOrderCreation: boolean) => {
    if (error.message.includes(ServerError.GEO_PROVIDER_ERROR)) {
        return ServerError.UNABLE_TO_RESOLVE_ADDRESSES;
    }
    if (ServerError.NO_RESULTS === error.message) {
        return ServerError.AREA_NOT_SERVICED;
    }
    if (ServerError.NO_CATEGORY_MATCHING_DRIVERS && isInstantOrderCreation) {
        return "NO_CATEGORY_MATCHING_DRIVERS_INSTANT";
    }
    return error.message;
};

const getErrorProperty = (errorMessage: string) => {
    switch (errorMessage) {
        case ServerError.AREA_NOT_SERVICED:
            return "pickup";
        case ServerError.UNABLE_TO_RESOLVE_ADDRESSES:
        case ServerError.INVALID_REQUEST_DISTANCE_TOO_LONG:
            return "dropoffs";
        case ServerError.NO_CATEGORY_MATCHING_TIME:
            return "scheduled_for";
        case ServerError.NO_CATEGORY_MATCHING_DRIVERS:
        case ServerError.NO_CATEGORY_MATCHING_PAYMENT_METHOD:
        default:
            return "general";
    }
};

const getPaymentMethodTranslations = (
    i18n: I18nFn,
    paymentMethods: FleetPortalOrderService.CreateOrderPaymentMethod[],
): string => {
    const translatedPaymentMethods: string[] = [];
    for (const paymentMethod of paymentMethods) {
        switch (paymentMethod) {
            case FleetPortalOrderService.CreateOrderPaymentMethod.CASH:
                translatedPaymentMethods.push(i18n("payment-methods.cash").toLocaleLowerCase());
                break;
            case FleetPortalOrderService.CreateOrderPaymentMethod.CARD_TERMINAL:
                translatedPaymentMethods.push(i18n("payment-methods.card").toLocaleLowerCase());
                break;
            default:
                UnreachableCode.never(paymentMethod);
        }
    }
    return translatedPaymentMethods.join("/");
};

const MIN_MILLIS_BETWEEN_SAME_REQUEST_API_CALLS = 2_000;

const CategoriesContextProvider = createContext<CatetegoriesContext>({
    rideOptions: null,
    resetRideOptions: () => {},
    resetApiError: () => {},
    findRideOptions: () =>
        new Promise((resolve) => {
            resolve(null);
        }),
    isLoading: false,
    apiError: null,
});
CategoriesContextProvider.displayName = "CategoriesContextProvider";

const CategoriesProvider = ({children}: {children: React.ReactNode}) => {
    const {i18n} = useI18n();
    const {setNotification} = useContext(NotificationContext);
    const fleet = useContext(AccountContextProvider).getFleet();
    const availablePaymentMethods = useContext(OrderRequestsContextProvider).getOrderCreationPaymentMethods();
    const lastFetch = useRef<{ms: number; request?: FindRideOptionsRequest}>({ms: 0});
    const api = useContext(ApiContextProvider);
    const [data, setData] = useState<FleetPortalOrderService.RideOptionsSuccess | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState<FleetPortalOrderService.ValidationError | null>(null);

    const findRideOptions = useCallback(
        async (formData: FormData, drawerType: CreateDrawerType): Promise<FindRideOptionsResult> => {
            const request = getRequest(formData, drawerType);
            if (
                !api ||
                !fleet ||
                (isEqual(request, lastFetch.current.request) &&
                    Date.now() - lastFetch.current.ms < MIN_MILLIS_BETWEEN_SAME_REQUEST_API_CALLS)
            ) {
                return null;
            }

            setIsLoading(true);
            try {
                const response = await api.fleetPortalOrder.findRideOptions(request);
                if (response.type === FleetPortalOrderService.ResultType.ERROR) {
                    setIsLoading(false);
                    setApiError(response.validation_errors[0]);
                    return response;
                }
                setData(response);
                setApiError(null);
                setIsLoading(false);
                lastFetch.current.ms = Date.now();
                lastFetch.current.request = request;
                return response;
            } catch (e) {
                const error = isError(e) ? getErrorTranslationKey(e, drawerType === CreateDrawerType.INSTANT) : "";
                let translationVariables: PrimitiveValues | undefined;
                if (error === ServerError.NO_CATEGORY_MATCHING_PAYMENT_METHOD) {
                    translationVariables = {
                        paymentMethods: getPaymentMethodTranslations(i18n, availablePaymentMethods),
                    };
                }
                const errorMsg = i18n(
                    `api.error.${error}` as TranslationKeys,
                    translationVariables,
                    "api.default_error",
                );
                const errorPropery = getErrorProperty(error);
                setApiError({property: errorPropery, error: errorMsg});
                if (errorPropery === "general") {
                    setNotification({
                        type: NotificationType.ERROR,
                        text: errorMsg,
                        timeout: 6000,
                    });
                }
                setIsLoading(false);
                return {
                    type: FleetPortalOrderService.ResultType.ERROR,
                    validation_errors: [{property: errorPropery, error: errorMsg}],
                };
            }
        },
        [api, availablePaymentMethods, fleet, i18n, setNotification],
    );

    const resetRideOptions = useCallback(() => {
        setData(null);
        setApiError(null);
        setIsLoading(false);
        lastFetch.current = {ms: 0};
    }, []);

    const resetApiError = useCallback(() => {
        setApiError(null);
    }, []);

    const contextValue: CatetegoriesContext = {
        rideOptions: data,
        resetRideOptions,
        resetApiError,
        findRideOptions,
        apiError,
        isLoading,
    };

    return <CategoriesContextProvider.Provider value={contextValue}>{children}</CategoriesContextProvider.Provider>;
};

export {CategoriesProvider, CategoriesContextProvider};
