import {FC} from "react";

import LoadingSpinner from "common/components/spinner";

export interface FileInfoProps {
    file: File;
    isLoading?: boolean;
}

const getFileData = (file: File) => {
    const sizeKB = file.size / 1000;
    let sizeUnit = "B";
    let size = file.size.toString();
    if (sizeKB >= 1 && sizeKB < 1000) {
        size = sizeKB.toFixed();
        sizeUnit = "kB";
    } else if (sizeKB >= 1000) {
        size = (sizeKB / 1000).toFixed();
        sizeUnit = "MB";
    }

    return {
        name: file.name,
        type: file.type.split("/")[1],
        size,
        sizeUnit,
    };
};

const FileInfo: FC<FileInfoProps> = ({file, isLoading = true}) => {
    const fileData = getFileData(file);

    return (
        <div className="text-secondary flex w-full items-center justify-between gap-8 py-3">
            <div className="flex w-3/4 flex-col">
                <span className={`overflow-hidden text-ellipsis whitespace-nowrap ${isLoading ? "" : "text-primary"}`}>
                    {fileData.name}
                </span>
                <span>
                    {fileData.size} {fileData.sizeUnit} ・ {fileData.type}
                </span>
            </div>
            <LoadingSpinner show={isLoading} />
        </div>
    );
};

export default FileInfo;
