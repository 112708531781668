import {useCallback, useContext, useMemo} from "react";
import {generatePath, useNavigate} from "react-router-dom";

import {EventName} from "common/constants/events";
import {FleetRoute, FleetRouteV2} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetPortalDocumentService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton, IconView} from "@bolteu/kalep-react";
import {Company as CompanyIcon, User as DriverIcon, Vehicle as CarIcon} from "@bolteu/kalep-react-icons";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import {ExpiringDocumentTableItem} from "../ExpiringDocumentsTable";

function useGoToOwner() {
    const navigate = useNavigate();
    const {trackEvent} = useTracking();
    const accountState = useContext(AccountContextProvider);
    const isNewNavigationEnabled = accountState.profile?.isNewNavigationEnabled;
    const fleetId = accountState?.getFleet()?.id;

    return useCallback(
        (document: ExpiringDocumentTableItem) => () => {
            if (!fleetId) {
                return;
            }

            trackEvent(EventName.ExpiringDocumentClicked);

            const routes = isNewNavigationEnabled ? FleetRouteV2 : FleetRoute;

            switch (document.entity_type) {
                case FleetPortalDocumentService.OwnerType.DRIVER:
                    navigate(
                        generatePath(`/:fleetId/${routes.DRIVER}`, {
                            id: String(document.entity_id),
                            fleetId: String(fleetId),
                        }),
                    );
                    break;
                case FleetPortalDocumentService.OwnerType.CAR:
                    navigate(
                        generatePath(`/:fleetId/${routes.VEHICLE}`, {
                            id: String(document.entity_id),
                            fleetId: String(fleetId),
                        }),
                    );
                    break;
                case FleetPortalDocumentService.OwnerType.COMPANY:
                    navigate(
                        generatePath(`/:fleetId/${routes.COMPANY_SETTINGS}`, {
                            fleetId: String(fleetId),
                        }),
                    );
                    break;
                default:
                    break;
            }
        },
        [fleetId, trackEvent, navigate, isNewNavigationEnabled],
    );
}

export function useTableColumns() {
    const {i18n} = useI18n();
    const goToOwner = useGoToOwner();

    return useMemo<TableHeaders<ExpiringDocumentTableItem>>(
        () => ({
            entity_type: {
                label: i18n(`auth.app.fleet.expiring-documents.header.entity_type`),
                renderCell: (item: ExpiringDocumentTableItem) => renderEntityType(item.entity_type),
            },
            type_title: {
                label: i18n(`auth.app.fleet.expiring-documents.header.document-type`),
            },
            expires: {
                label: i18n(`auth.app.fleet.expiring-documents.header.expiration_date`),
                renderCell: (item: ExpiringDocumentTableItem) => renderExpirationDate(item.expires),
            },
            identifier: {
                label: i18n(`auth.app.fleet.expiring-documents.header.identifier_v2`),
                renderCell: (item: ExpiringDocumentTableItem) => renderIdentifier(item.identifier, goToOwner(item)),
            },
        }),
        [i18n, goToOwner],
    );
}

function renderExpirationDate(expires: string) {
    const now = new Date();
    const expiresDate = new Date(expires);
    return (
        <DefaultCellContent className={expiresDate < now ? "text-danger-primary font-semibold" : ""}>
            {expiresDate.toLocaleDateString()}
        </DefaultCellContent>
    );
}

function renderIdentifier(identifier: string | null, onClick: () => void) {
    return (
        <DefaultCellContent>
            <GhostButton overrideClassName="hover:underline" onClick={onClick}>
                {identifier}
            </GhostButton>
        </DefaultCellContent>
    );
}

function renderEntityType(entityType: FleetPortalDocumentService.OwnerType) {
    let icon;
    switch (entityType) {
        case FleetPortalDocumentService.OwnerType.DRIVER:
            icon = <DriverIcon />;
            break;
        case FleetPortalDocumentService.OwnerType.CAR:
            icon = <CarIcon />;
            break;
        case FleetPortalDocumentService.OwnerType.COMPANY:
            icon = <CompanyIcon />;
            break;
        default:
            break;
    }

    return (
        <DefaultCellContent>
            <IconView icon={icon} size="xs" backgroundColor="bg-layer-floor-0" color="text-secondary" />
        </DefaultCellContent>
    );
}
