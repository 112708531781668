import {useCallback, useEffect, useState} from "react";

const useMediaQuery = (query: string): boolean => {
    const getMatches = (queryString: string): boolean => window.matchMedia(queryString).matches;

    const [isMatch, setMatches] = useState<boolean>(getMatches(query));

    const handleChange = useCallback(() => setMatches(getMatches(query)), [query]);

    useEffect(() => {
        const matchMedia = window.matchMedia(query);
        handleChange();
        matchMedia.addEventListener("change", handleChange);

        return () => matchMedia.removeEventListener("change", handleChange);
    }, [handleChange, query]);

    return isMatch;
};

export default useMediaQuery;
