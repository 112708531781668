import {useContext} from "react";
import {useIntl} from "react-intl";

import {CountryCode} from "common/geo/constants/countries";
import {useI18n} from "common/hooks/useI18n";
import {apiTimestampToDate} from "common/util/apiTimestampToDate";
import {format} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {AccountContextProvider} from "../../../../account/accountStateProvider";
import {countriesHaveTransportLicense, countriesHaveTransportLicenseExpiry} from "../../config/transportLicenseConfig";
import Alerts from "./Alerts";

interface Props {
    application: CarApplicationService.GetCarApplicationResponse;
}

const ApplicationData = ({application}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const country = useContext(AccountContextProvider)?.getFleet()?.country ?? "";

    const getDataRow = (title: string, value?: string | number | null) => {
        if (value === null || value === 0) {
            return null;
        }
        return (
            <div className="border-separator items-center justify-between border-b py-3">
                <h4 className="text-secondary">{title}</h4>
                <p className="min-h-6">{value}</p>
            </div>
        );
    };

    const getTransportLicenceExpiry = (value: number | null) => {
        if (value === undefined || value === null) {
            return null;
        }
        const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
        const formattedDate = format(apiTimestampToDate(value), "P", {locale: dateFnsLocale});
        return getDataRow(i18n("auth.app.fleet.vehicle.transport_license_expires"), formattedDate);
    };

    const shouldShowTransportLicenseNumber = countriesHaveTransportLicense.includes(country as CountryCode);
    const shouldShowTransportLicenseExpires = countriesHaveTransportLicenseExpiry.includes(country as CountryCode);

    return (
        <div className="flex w-full max-w-[480px] flex-col">
            <Alerts application={application} />
            {getDataRow(i18n("auth.app.fleet.vehicle.model"), application.model)}
            {getDataRow(i18n("auth.app.fleet.vehicle.year"), application.year)}
            {getDataRow(i18n("auth.app.fleet.vehicle.license_plate"), application.reg_number)}
            {shouldShowTransportLicenseNumber &&
                getDataRow(
                    i18n("auth.app.fleet.vehicle.transport_license_number"),
                    application.car_transport_licence_number,
                )}
            {shouldShowTransportLicenseExpires && getTransportLicenceExpiry(application.car_transport_licence_expires)}
            {getDataRow(i18n("auth.app.fleet.vehicle.color"), application.color)}
            {getDataRow(i18n("auth.app.fleet.vehicle.seats"), application.seats)}
            {getDataRow(i18n("auth.app.fleet.vehicle-application.your_comment"), application.applicant_comment)}
        </div>
    );
};

export default ApplicationData;
