import {useCallback, useEffect, useState} from "react";

import {useAreaCodeOptions} from "common/geo/hooks/useAreaCodeOptions";
import {useI18n} from "common/hooks/useI18n";

import {ComboBox, SelectOption} from "@bolteu/kalep-react";

// TODO: This config type should be imported from Kalep when exported
interface FilterOptionsConfig {
    inputValue: string;
    getOptionLabel: (option: SelectOption) => string;
    freeSolo?: boolean;
}

export interface AreaCodeSelectorProps {
    handlePhoneAreaCode: (areaCode: string) => void;
    companyCountry?: string;
}

const AreaCodeSelector = ({handlePhoneAreaCode, companyCountry}: AreaCodeSelectorProps) => {
    const {i18n} = useI18n();
    const [selectedOption, setSelectedOption] = useState<SelectOption>({value: null});

    const {options, renderOption, countryData} = useAreaCodeOptions();

    const handleChange = useCallback<(option: SelectOption | SelectOption[] | null) => void>(
        (option) => {
            if (option === null || Array.isArray(option) || typeof option.title === "function") {
                return;
            }
            setSelectedOption(option);
            handlePhoneAreaCode(option.title || "");
        },
        [handlePhoneAreaCode],
    );

    const filterOptions = useCallback(
        (optionsToFilter: SelectOption[], config: FilterOptionsConfig) =>
            optionsToFilter.filter((option) => {
                const country = countryData[option.value as string];
                return `${country.countryName} ${country.areaCode}`
                    .toLowerCase()
                    .includes(config.inputValue.toLowerCase());
            }),
        [countryData],
    );

    useEffect(() => {
        if (!companyCountry) {
            return;
        }
        const {areaCode} = countryData[companyCountry];
        handlePhoneAreaCode(areaCode);
        setSelectedOption({title: areaCode, value: companyCountry});
    }, [countryData, handlePhoneAreaCode, companyCountry]);

    return (
        <ComboBox
            options={options}
            filterOptions={filterOptions}
            label={i18n("auth.app.fleet.add-driver.country_code")}
            renderOption={renderOption}
            value={selectedOption}
            onChange={handleChange}
            required
            fullWidth
        />
    );
};

export default AreaCodeSelector;
