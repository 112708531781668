import {FC} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {Tier} from "common/constants/types";
import {useI18n} from "common/hooks/useI18n";
import {formatContentHtml} from "common/util/formatContentHtml";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Accordion, AccordionItem, Button, Drawer, Typography} from "@bolteu/kalep-react";

import {useDriverRewardsIcons} from "../../../hooks/useDriverRewardsIcons";
import GetLevelsAndBenefitsDetailsResponse = FleetOwnerPortalService.GetLevelsAndBenefitsDetailsResponse;
import TierStatus = FleetOwnerPortalService.TierStatus;

interface GlossaryProps {
    isOpen: boolean;
    handleClose: () => void;
    content: GetLevelsAndBenefitsDetailsResponse;
}

const LevelItem = ({
    tier,
    title,
    subtitle,
    benefits,
}: {
    tier: Tier;
    title: string;
    subtitle: string;
    benefits: Array<{title: string; description: string}>;
}) => {
    const levelIcon = useDriverRewardsIcons(tier, TierStatus.ACTIVE);

    return (
        <div className="border-separator mb-4 rounded-md border p-4">
            <div className="mb-2 flex items-center gap-2">
                {levelIcon}
                <Typography fontWeight="semibold" fontSize="text-lg">
                    {title}
                </Typography>
            </div>

            <Typography color="secondary" variant="body-secondary" fontSize="text-xs">
                {subtitle}
            </Typography>

            <div className="mt-1 flex flex-col gap-3">
                {benefits.map((benefit, index) => (
                    <div className="flex flex-col gap-1">
                        <Accordion paddingStart={0} paddingEnd={0}>
                            <AccordionItem
                                title={{
                                    primary: (
                                        <div className="text-sm font-normal">{formatContentHtml(benefit.title)}</div>
                                    ),
                                }}
                                id={index}
                            >
                                <div className="text-sm font-normal">{formatContentHtml(benefit.description)}</div>
                            </AccordionItem>
                        </Accordion>
                    </div>
                ))}
            </div>
        </div>
    );
};

const LevelsAndBenefitsGlossary: FC<GlossaryProps> = ({isOpen, handleClose, content}) => {
    const {i18n} = useI18n();

    return (
        <ResponsiveDrawer isOpen={isOpen} title={content.title} onRequestClose={handleClose}>
            <Drawer.Content>
                <div className="flex flex-col gap-6">
                    {content.levels.map((level) => (
                        <LevelItem
                            tier={level.tier as Tier}
                            title={level.title}
                            subtitle={level.subtitle}
                            benefits={level.benefits}
                        />
                    ))}
                </div>
            </Drawer.Content>

            <Drawer.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {i18n("common.close")}
                </Button>
            </Drawer.Footer>
        </ResponsiveDrawer>
    );
};

export {LevelsAndBenefitsGlossary};
