import React, {useCallback, useContext, useEffect} from "react";

import {EventName} from "common/constants/events";
import {isActiveOrBlockedFleet} from "common/constants/types";
import {FetchStatus} from "common/hooks/useFetch";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider, SelectedCompany} from "features/account/accountStateProvider";

import {IconButton, Menu} from "@bolteu/kalep-react";
import {Bell} from "@bolteu/kalep-react-icons";

import EmptyNotificationList from "./components/EmptyNotificationList";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NotificationQuickViewList from "./components/NotificationQuickViewList";
import {useNotificationApi} from "./hooks/useNotificationApi";

interface NotificationsQuickViewProps {
    company: SelectedCompany | null;
}

const NotificationsQuickViewInternal = React.memo(
    ({company}: NotificationsQuickViewProps) => {
        const {trackEvent} = useTracking();
        const {getNotifications, data, status, setNotificationsReadAndReload, setAllNotificationsReadAndReload} =
            useNotificationApi();

        const notifications = data?.list;
        const hasUnreadMessages = notifications?.some((notification) => !notification.read_timestamp) ?? false;

        useEffect(() => {
            if (isActiveOrBlockedFleet(company?.company)) {
                getNotifications(31);
            }
        }, [getNotifications, company]);

        const onMenuChangeHandler = useCallback(
            ({open}: {open: boolean}) => {
                if (open) {
                    trackEvent(EventName.NotificationsBellClicked);
                }
            },
            [trackEvent],
        );

        const menuButton = useCallback(
            ({open}: {open: boolean}) => (
                <IconButton
                    variant="ghost"
                    size="sm"
                    shape="round"
                    aria-label="Notifications"
                    overrideClassName={open ? "[button>&]:bg-active-neutral-secondary" : undefined}
                    icon={
                        <div className="relative leading-none">
                            <Bell className="h-6 w-6 md:h-5 md:w-5" />
                            {hasUnreadMessages && (
                                <span className="bg-warning-primary border-primary-inverted absolute -top-0.5 -right-0.5 z-50 h-3 w-3 rounded-full border-2 md:-right-1 md:-top-1" />
                            )}
                        </div>
                    }
                    value="notificationQuickViewBtn"
                />
            ),
            [hasUnreadMessages],
        );

        if (!isActiveOrBlockedFleet(company?.company)) {
            return null;
        }

        const isEmptyList =
            [FetchStatus.Init, FetchStatus.Error].includes(status) || !notifications || notifications.length === 0;

        const view = isEmptyList ? (
            <EmptyNotificationList title="auth.app.notifications.no_recent_notifications" />
        ) : (
            <NotificationQuickViewList
                notifications={notifications ?? []}
                setNotificationsAsRead={setNotificationsReadAndReload}
                newUi={data.new_ui}
            />
        );

        return (
            <Menu
                overrideClassName="w-screen max-w-[420px]"
                direction="bottom"
                align="end"
                gap={4}
                menuButton={menuButton}
                onMenuChange={onMenuChangeHandler}
            >
                <div className="max-h-[calc(100dvh-140px)] overflow-y-auto">
                    <Header
                        hasUnreadMessages={hasUnreadMessages}
                        onAllNotificationsSetRead={setAllNotificationsReadAndReload}
                        isPartOfMenu
                    />
                    {view}
                </div>
                {data?.new_ui && <Footer />}
            </Menu>
        );
    },
    (prevProps, nextProps) =>
        prevProps.company?.company.id === nextProps.company?.company.id &&
        prevProps.company?.type === nextProps.company?.type,
);

const NotificationQuickView = () => {
    const accountState = useContext(AccountContextProvider);

    return <NotificationsQuickViewInternal company={accountState.selectedCompany} />;
};

export default NotificationQuickView;
