import {ChangeEventHandler, useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {RadioGroup, Tooltip, Typography} from "@bolteu/kalep-react";
import {Surge} from "@bolteu/kalep-react-icons";

import {DEFAULT_CATEGORY_ICON_URL} from "../../../constants";
import {CustomRadio} from "../../CustomRadio";
import {CategorySkeleton} from "./CategorySkeleton";
import {RideInfo} from "./RideInfo";

interface Props {
    selectedCategoryId?: number;
    onChange: (category?: FleetPortalOrderService.Category) => void;
    categories?: FleetPortalOrderService.Category[];
    isLoading: boolean;
    categoryError?: string;
    isManualPriceEnabledAndEntered: boolean;
}

export const Categories = ({
    selectedCategoryId,
    onChange,
    categories,
    isLoading,
    categoryError,
    isManualPriceEnabledAndEntered,
}: Props) => {
    const {i18n} = useI18n();

    const onCategoryChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            const categoryId = Number(event.target.value);
            const newCategory = categories?.find((category) => category.id === categoryId);
            onChange(newCategory);
        },
        [categories, onChange],
    );

    const renderCategoryEnd = useCallback(
        (category: FleetPortalOrderService.Category) => () =>
            (
                <Typography
                    fontWeight="semibold"
                    color={isManualPriceEnabledAndEntered ? "tertiary" : "primary"}
                    inlineStyle={isManualPriceEnabledAndEntered ? {textDecorationLine: "line-through"} : {}}
                >
                    {category.price.price_str}
                </Typography>
            ),
        [isManualPriceEnabledAndEntered],
    );

    const selectedCategory =
        categories?.find((category) => category.id === selectedCategoryId) ?? (categories ?? [])[0];
    const minAbbreviation = i18n("common.minute_abbreviation");
    const SurgeTooltip = (
        <Tooltip content={i18n("auth.app.orders.scheduled_rides.create-dialog.category_surge")}>
            <div>
                <Surge size="xs" />
            </div>
        </Tooltip>
    );

    return (
        <>
            <Typography fontWeight="semibold" fontSize="text-sm">
                {i18n("auth.app.orders.scheduled_rides.create-dialog.ride-category")}
            </Typography>
            {selectedCategory && <RideInfo selectedCategory={selectedCategory} isLoading={isLoading} />}
            <div>
                {categoryError && (
                    <Typography color="danger-primary" paddingTop={2}>
                        {categoryError}
                    </Typography>
                )}
                <RadioGroup
                    name="category"
                    value={String(selectedCategoryId)}
                    onChange={onCategoryChange}
                    error={Boolean(categoryError)}
                >
                    <div className="flex w-full flex-col">
                        {isLoading &&
                            Array.from({length: categories?.length ?? 2}).map((_, index) => (
                                // eslint-disable-next-line react/no-array-index-key -- no better key
                                <CategorySkeleton key={index} />
                            ))}
                        {!isLoading &&
                            (categories ?? []).map((category) => (
                                <CustomRadio
                                    key={category.id}
                                    label={category.name}
                                    labelFontWeight="semibold"
                                    helperText={
                                        category.eta_seconds !== undefined
                                            ? `${Math.round(
                                                  Math.max(category.eta_seconds, 60) / 60,
                                              )} ${minAbbreviation}`
                                            : undefined
                                    }
                                    labelIcon={category.price.surge_multiplier > 1 ? SurgeTooltip : undefined}
                                    value={String(category.id)}
                                    iconUrl={category.category_icon_url}
                                    defaultIconUrl={DEFAULT_CATEGORY_ICON_URL}
                                    className="border-separator border-b py-5 last:border-none"
                                    renderEnd={renderCategoryEnd(category)}
                                />
                            ))}
                    </div>
                </RadioGroup>
            </div>
        </>
    );
};
