import {useCallback, useMemo} from "react";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Table, Tooltip} from "@bolteu/kalep-react";
import {ArrowDown, ArrowUp, InfoCircle, ReorderVert} from "@bolteu/kalep-react-icons";

import DesktopTableBody from "./DesktopTableBody";
import {AlignHorizontal, AlignVertical, ApiDrivenTableTableProps} from "./types";
import {isDateColumn, isReferenceColumn, renderTitleOnTwoRows} from "./util";

const ACTION_HEADER_WIDTH = 50;

const SortingArrow = ({direction}: {direction?: FleetOwnerPortalService.SortingDirection}) => {
    switch (direction) {
        case FleetOwnerPortalService.SortingDirection.ASC:
            return <ArrowUp className="shrink-0" width={20} height={20} />;
        case FleetOwnerPortalService.SortingDirection.DESC:
            return <ArrowDown className="shrink-0" width={20} height={20} />;
        default:
            return <ReorderVert className="shrink-0" width={20} height={20} />;
    }
};

const getNextDirection = (direction?: FleetOwnerPortalService.SortingDirection) => {
    switch (direction) {
        case FleetOwnerPortalService.SortingDirection.ASC:
            return FleetOwnerPortalService.SortingDirection.DESC;
        case FleetOwnerPortalService.SortingDirection.DESC:
        default:
            return FleetOwnerPortalService.SortingDirection.ASC;
    }
};

export default function ApiDrivenTableDesktop(apiDrivenTableDesktopProps: ApiDrivenTableTableProps) {
    const {
        subColumns,
        stateColumns,
        alignVertical = AlignVertical.MIDDLE,
        alignHorizontal = AlignHorizontal.LEFT,
        userDefinedVisibleColumns,
        transforms,
        inlineActions,
        propActions,
        hiddenColumnTitleKeys,
        sortedBy,
    } = apiDrivenTableDesktopProps;

    const headerColumns = useMemo((): FleetOwnerPortalService.ApiColumn[] => {
        const filteredColumns = userDefinedVisibleColumns.map((column) => ({
            ...column,
            title: hiddenColumnTitleKeys.includes(column.key) ? "" : column.title,
        }));

        return inlineActions?.length
            ? [
                  ...filteredColumns,
                  {
                      key: "actions",
                      title: "",
                      type: FleetOwnerPortalService.ListColumnType.STRING,
                      cells: [],
                      accessibility: FleetOwnerPortalService.Accessibility.VISIBLE,
                  },
              ]
            : filteredColumns;
    }, [hiddenColumnTitleKeys, inlineActions, userDefinedVisibleColumns]);

    const onHeaderCellClick = useCallback(
        (column: FleetOwnerPortalService.ApiColumn) => () => {
            if (propActions?.changeSorting && column.is_sortable) {
                propActions.changeSorting({field: column.key, direction: getNextDirection(sortedBy?.direction)});
            }
        },
        [propActions, sortedBy],
    );

    return (
        <Table>
            <Table.Header>
                <Table.HeaderRow>
                    {headerColumns.map((column) => {
                        const isSortable = Boolean(column.is_sortable);
                        const isSortedBy = sortedBy?.field === column.key;

                        const headerCellAlignment =
                            !isReferenceColumn(column) &&
                            !isDateColumn(column) &&
                            alignHorizontal === AlignHorizontal.RIGHT
                                ? "justify-end text-right"
                                : "justify-start text-left";
                        const headerCellWidth =
                            column.key === "actions" && inlineActions
                                ? inlineActions.length * ACTION_HEADER_WIDTH
                                : undefined;
                        const headerCellHighlight = isSortable && isSortedBy ? "bg-neutral-secondary" : "";
                        const headerCellCursor = isSortable ? "cursor-pointer" : "";

                        return (
                            <Table.HeaderCell
                                onClick={onHeaderCellClick(column)}
                                align={alignHorizontal}
                                key={column.key}
                                width={headerCellWidth}
                                className={`group ${headerCellHighlight} ${headerCellCursor}`}
                            >
                                <div
                                    className={`align-center flex h-full w-full items-center gap-x-2 ${headerCellAlignment}`}
                                >
                                    {renderTitleOnTwoRows(column.title)}
                                    {column.tooltip && (
                                        <Tooltip placement="top" offset={15} content={column.tooltip}>
                                            <div>
                                                <InfoCircle width={15} height={15} />
                                            </div>
                                        </Tooltip>
                                    )}
                                    {isSortable && (
                                        <div
                                            className={`${isSortedBy ? "" : "opacity-0"} flex group-hover:opacity-100`}
                                        >
                                            <SortingArrow direction={isSortedBy ? sortedBy?.direction : undefined} />
                                        </div>
                                    )}
                                </div>
                            </Table.HeaderCell>
                        );
                    })}
                </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
                {apiDrivenTableDesktopProps.numberOfRecords !== 0 ? (
                    <DesktopTableBody
                        numberOfRecords={apiDrivenTableDesktopProps.numberOfRecords}
                        userDefinedVisibleColumns={userDefinedVisibleColumns}
                        stateColumns={stateColumns}
                        alignVertical={alignVertical}
                        alignHorizontal={alignHorizontal}
                        subColumns={subColumns}
                        transforms={transforms}
                        inlineActions={inlineActions}
                    />
                ) : (
                    <Table.Row>
                        <Table.Cell
                            colSpan={
                                apiDrivenTableDesktopProps.userDefinedVisibleColumns.length +
                                (inlineActions?.length ? 1 : 0)
                            }
                            overrideClassName="border-0"
                        >
                            {apiDrivenTableDesktopProps.emptyStateComponent}
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
}
