import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";

export function useBreadcrumbs() {
    const {i18n} = useI18n();
    const {getVehiclesPath} = useAbsolutePath();

    return [
        {
            href: getVehiclesPath(),
            title: i18n("auth.app.sidebar.vehicles"),
        },
        {
            href: "",
            title: i18n("auth.app.fleet.vehicle.details"),
        },
    ];
}
