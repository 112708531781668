import {useCallback, useEffect, useMemo} from "react";

import DriverFilter from "common/components/DriverFilter";
import {PeriodDatePicker} from "common/components/periodDatePicker";
import ApiDrivenTablesCustomizationDrawer from "common/components/table/ApiDrivenTable/ApiDrivenTableCustomizationDrawer";
import {ApiDrivenTableTransforms} from "common/components/table/ApiDrivenTable/types";
import {useDrivers} from "common/hooks/useDrivers";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {selectPeriodOptionsUntilNow} from "common/hooks/usePeriodSelectorOptions";
import {Api} from "common/services/apiProvider";
import {ApiPeriod} from "common/services/period";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {SelectOption} from "@bolteu/kalep-react";

import {DATE_RANGE_LIMIT_DAYS, ORDER_HISTORY_TABLE_ID} from "../../order-requests/constants";
import FilteredBy from "./FilteredBy";
import OrderHistoryDownload from "./OrderHistoryDownload";
import OrdersFilter, {OrderFilters} from "./OrdersFilter";

interface Props {
    columns: FleetPortalOrderService.ApiColumn[];
    columnsTransforms: ApiDrivenTableTransforms;
    isInvalidPeriod: boolean;
    selectedPeriod: ApiPeriod;
    selectedDriver: SelectOption | null;
    onPeriodChange: (period: ApiPeriod) => void;
    onPeriodErrorStateChange: (isError: boolean) => void;
    setDriver: (driver: SelectOption | null) => void;
    filters: OrderFilters;
    setFilters: (filters: OrderFilters) => void;
}

const citiesFetchFunction = (api: Api) => api.fleetOwner.portalGetCityListForFleet();
const getSearchCategoriesFunc = (api: Api) => api.fleetOwnerReporting.getOrderSearchCategories();

const OrderHistorySearch = ({
    columns,
    columnsTransforms,
    selectedPeriod,
    isInvalidPeriod,
    selectedDriver: driver,
    onPeriodChange,
    onPeriodErrorStateChange,
    setDriver,
    filters,
    setFilters,
}: Props) => {
    const {i18n} = useI18n();

    const {drivers, status} = useDrivers(selectedPeriod);

    const {fetch: citiesFetch, data: searchCities} = useFetch(citiesFetchFunction);

    useEffect(() => {
        if (citiesFetch) {
            citiesFetch({});
        }
    }, [citiesFetch]);

    const {fetch: categoriesFetch, data: searchCategories} = useFetch(getSearchCategoriesFunc);

    useEffect(() => {
        if (categoriesFetch) {
            categoriesFetch({});
        }
    }, [categoriesFetch]);

    const cities = useMemo(() => searchCities?.cities || [], [searchCities]);
    const categories = useMemo(() => searchCategories?.rows || [], [searchCategories]);

    const handleDriverIdChange = useCallback(
        (selectedDrivers: SelectOption[] | null) => {
            setDriver(selectedDrivers ? selectedDrivers[0] : null);
        },
        [setDriver],
    );

    return (
        <>
            <div className="flex flex-col items-start justify-between gap-4 lg:flex-row">
                <div className="flex gap-4">
                    <DriverFilter
                        driverIds={driver ? [driver.value as number] : null}
                        drivers={drivers}
                        onChange={handleDriverIdChange}
                        loading={status === FetchStatus.Loading}
                        placeholder={i18n("auth.app.orders.history.search-by-name")}
                    />
                    <OrdersFilter
                        filters={filters}
                        setFilters={setFilters}
                        cities={cities}
                        category_groups={categories}
                    />
                    <ApiDrivenTablesCustomizationDrawer
                        tableId={ORDER_HISTORY_TABLE_ID}
                        columns={columns}
                        transforms={columnsTransforms}
                    />
                </div>
                <div className="flex flex-wrap gap-4 md:flex-nowrap">
                    <PeriodDatePicker
                        period={selectedPeriod}
                        selectPeriodOptions={selectPeriodOptionsUntilNow}
                        onPeriodChange={onPeriodChange}
                        dateRangeLimitDays={DATE_RANGE_LIMIT_DAYS}
                        onErrorStateChange={onPeriodErrorStateChange}
                    />
                    <OrderHistoryDownload
                        driver={driver}
                        period={selectedPeriod}
                        filters={filters}
                        isError={isInvalidPeriod}
                    />
                </div>
            </div>
            <FilteredBy cities={cities} filters={filters} setFilters={setFilters} categories={categories} />
        </>
    );
};

export {OrderHistorySearch};
