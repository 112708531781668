import {useCallback, useContext, useState} from "react";

import {I18nFn, useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetCarService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip, Tooltip, Typography} from "@bolteu/kalep-react";

import AccessManagementRenderer from "../components/AccessManagementRenderer";
import VehicleItem from "../VehicleItem";
import ManageVehicleDialog from "./ManageVehiclesDialog";
import {useDriverVehicleAccesses} from "./useDriverVehicleAccesses";

interface VehicleAccessManagerProps {
    driverId: number;
}

export default function VehicleAccessManager({driverId}: VehicleAccessManagerProps) {
    const {i18n} = useI18n();
    const [isManageVehiclesDialogOpen, setIsManageVehiclesDialogOpen] = useState(false);

    const accountState = useContext(AccountContextProvider);

    const {assignedVehicles, hasAccessToAllVehicles, refetch} = useDriverVehicleAccesses(accountState, driverId);

    const onAccessManagementBtnClicked = useCallback(() => {
        setIsManageVehiclesDialogOpen(true);
    }, []);

    const onManageVehiclesDialogRequestClose = useCallback(() => {
        setIsManageVehiclesDialogOpen(false);
        refetch();
    }, [refetch]);

    return (
        <>
            <AccessManagementRenderer
                title={i18n("auth.app.sidebar.vehicles")}
                buttonText={i18n("auth.app.fleet.vehicle_assignment.driver.manage_vehicles")}
                tooltipText={i18n("auth.app.fleet.vehicle_assignment.driver.vehicle_info_tooltip")}
                onButtonClicked={onAccessManagementBtnClicked}
                editMode={assignedVehicles.length > 0 || hasAccessToAllVehicles}
                discoveryTooltipContent={i18n(
                    "auth.app.fleet.vehicle_assignment.discovery_tooltip.driver_access_description",
                )}
            >
                <div className="flex w-full flex-col gap-2">
                    {assignedVehicles.length === 0 && !hasAccessToAllVehicles && (
                        <span className="text-secondary">
                            {i18n("auth.app.fleet.vehicle_assignment.driver.no_vehicles_selected")}
                        </span>
                    )}
                    {(assignedVehicles.length > 0 || hasAccessToAllVehicles) && (
                        <div className="flex w-full items-center gap-12">
                            {hasAccessToAllVehicles && (
                                <span className="text-secondary">
                                    <Typography variant="body-m-regular">
                                        {i18n(
                                            "auth.app.fleet.vehicle_assignment.driver.manage_vehicles_dialog.all_vehicles",
                                        )}
                                    </Typography>
                                </span>
                            )}
                            {!hasAccessToAllVehicles && (
                                <span className="text-secondary">
                                    <VehicleItem vehicle={assignedVehicles[0]} />
                                </span>
                            )}
                            {assignedVehicles.length > 1 && (
                                <div>
                                    <Tooltip content={getTooltipText(assignedVehicles, 5, i18n)} placement="bottom">
                                        <Chip
                                            label={
                                                hasAccessToAllVehicles
                                                    ? `${assignedVehicles.length - 1}`
                                                    : `${i18n("common.more", {
                                                          num: assignedVehicles.length - 1,
                                                      })}`
                                            }
                                            size="sm"
                                            appearance="promo"
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </AccessManagementRenderer>
            {isManageVehiclesDialogOpen && (
                <ManageVehicleDialog onRequestClose={onManageVehiclesDialogRequestClose} driverId={driverId} />
            )}
        </>
    );
}

function getTooltipText(assignedVehicles: FleetCarService.Car[], numVehiclesToDisplay: number, i18n: I18nFn): string {
    let vehicles = assignedVehicles
        .slice(1, numVehiclesToDisplay + 1)
        .map(
            (vehicle, idx) =>
                `${idx + 1}. ${vehicle.model}, ${vehicle.year} • ${vehicle.reg_number} • ${vehicle.color}`,
        )
        .join(" ");

    if (assignedVehicles.length > numVehiclesToDisplay) {
        vehicles += ` ${i18n("common.more", {num: assignedVehicles.length - numVehiclesToDisplay})}`;
    }

    return vehicles;
}
