import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";

export enum MetricsTab {
    Drivers = "drivers",
    Vehicles = "vehicles",
}

export function useTabSelectorOptions(): Array<Tab<MetricsTab>> {
    const tabs: Array<Tab<MetricsTab>> = [
        {
            id: MetricsTab.Drivers,
            title: "Drivers",
            trackingEventTabName: TabNameEvent.DriversPerformance,
        },
        {
            id: MetricsTab.Vehicles,
            title: "Vehicles",
            trackingEventTabName: TabNameEvent.VehiclesPerformance,
        },
    ];

    return tabs;
}
