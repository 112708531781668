import Logo from "assets/icons/logo.svg?react";
import LanguageSelector from "common/components/languageSelector";
import {useI18n} from "common/hooks/useI18n";
import {getYear} from "date-fns";
import {twMerge} from "tailwind-merge";

interface Props {
    gap: "none" | "small" | "large";
    children: React.ReactNode;
}

interface NoAuthLayoutContainerProps {
    children: React.ReactNode;
    overrideClassName?: string;
}

export const NoAuthLayoutContainer = ({children, overrideClassName}: NoAuthLayoutContainerProps) => (
    <div
        className={twMerge(
            "mx-auto mt-[72px] flex h-[calc(100dvh-72px)] max-w-[484px] flex-col items-center justify-between gap-7 px-3 sm:px-0",
            overrideClassName,
        )}
    >
        {children}
    </div>
);

const NoAuthLayout = ({children, gap = "small"}: Props) => {
    const {i18n} = useI18n();

    const year = getYear(new Date());
    const flexGap = {
        none: "",
        small: "gap-3",
        large: "gap-7",
    }[gap];
    const logoMarginBottom = gap === "small" ? "mb-7" : "mb-3";

    return (
        <NoAuthLayoutContainer>
            <div className={`flex w-full flex-col items-center ${flexGap}`}>
                <Logo className={logoMarginBottom} width={170} height={40} />
                {children}
            </div>
            <div className="flex flex-col items-center pb-6">
                <LanguageSelector />
                <span className="text-secondary mt-3 text-xs">{i18n("common.copyright", {year})}</span>
            </div>
        </NoAuthLayoutContainer>
    );
};

export default NoAuthLayout;
