import * as Sentry from "@sentry/react";

import {NoopErrorBoundary} from "./NoopErrorBoundary";

export const sentryInit = (
    dataSourceName: string,
    isDevMode: boolean,
    app: string,
    version: string,
    environment: string,
    brand: string,
) => {
    let ErrorBoundary;
    if (!isDevMode && dataSourceName) {
        Sentry.init({
            dsn: dataSourceName,
            release: `${environment}-${app}-${brand}-${version}`,
            environment,
        });
        ErrorBoundary = Sentry.ErrorBoundary;
    } else {
        ErrorBoundary = NoopErrorBoundary;
    }

    const reportError = (error: Error) => Sentry.captureException(error);

    const setUser = (id: string) => Sentry.setUser({id});

    const addBreadcrumb = (message: string, metadata?: Record<string, unknown>) =>
        Sentry.addBreadcrumb({message, data: metadata});

    return {ErrorBoundary, reportError, setUser, addBreadcrumb};
};
