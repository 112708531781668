import {useCallback, useContext} from "react";

import {DocumentData} from "common/components/document/useDocumentsList";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {isError} from "common/util/isErrorType";
import {TranslationKeys} from "config/translations";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const driverDocFetchFunction = async (
    api: Api,
    body: FleetOwnerPortalService.GetDriverDocumentsSetRequest,
): Promise<DocumentData[]> => {
    const resp = await api.fleetOwnerPortal.driverDocumentsGetDriverDocumentsSet(body);
    return resp.list.map((doc) => ({
        ...(doc.document
            ? {
                  document: {
                      id: doc.document.id,
                      expires: doc.document.expires,
                      status: doc.document.status,
                  },
              }
            : {document: null}),
        type: {
            ...doc.document_type,
            is_expiry_required: !!doc.document_type.is_expiry_required,
            is_required: !!doc.document_type.is_required,
        },
    }));
};

const documentUploadFetchFunction = (
    api: Api,
    body: FleetOwnerPortalService.UploadDriverDocumentRequest,
    files: FleetOwnerPortalService.Files,
) => api.fleetOwnerPortal.driverDocumentsUploadDriverDocument(body, files);

export const useDriverDocumentFunctions = (driverId: number) => {
    const {i18n} = useI18n();
    const {setNotification} = useContext(NotificationContext);

    const documentsFetchFunction = useCallback(
        (api: Api) => driverDocFetchFunction(api, {driver_id: driverId}),
        [driverId],
    );

    const documentUploadFunction = useCallback(
        async (api: Api, expiresIsoDate: string | null, files: FleetOwnerPortalService.Files) => {
            try {
                await documentUploadFetchFunction(
                    api,
                    {driver_id: driverId, ...(expiresIsoDate && {expires: expiresIsoDate})},
                    files,
                );
            } catch (error) {
                if (isError(error)) {
                    setNotification({
                        type: NotificationType.ERROR,
                        text: i18n(`api.error.${error.message}` as TranslationKeys, undefined, "api.default_error"),
                        timeout: 6000,
                    });
                }

                throw error;
            }
        },
        [driverId, i18n, setNotification],
    );

    return {
        documentsFetchFunction,
        documentUploadFunction,
    };
};
