import React, {Fragment, useCallback} from "react";

import {useTooltipClick} from "common/hooks/useTooltipClick";
import {Dash} from "@fleet/common/constants/dash";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip, Typography} from "@bolteu/kalep-react";
import {ArrowRight, ArrowRightDouble} from "@bolteu/kalep-react-icons";

import OrderStop = FleetOwnerReportingService.OrderStop;

interface Props {
    stops?: OrderStop[];
}

export const OrderRouteCellApiDriven = ({stops}: Required<Props>) => {
    const isMobile = useTailwindViewport() === "sm";
    const tooltipData = useTooltipClick({delay: 3000});

    const orderStops = stops.sort((x, y) => x.id - y.id);

    const TooltipWrapper = useCallback(
        (data: {children: React.ReactElement}) => {
            const isOpen = isMobile ? tooltipData.isOpen : undefined;
            const setIsOpen = isMobile ? tooltipData.setIsOpen : undefined;

            return (
                <Tooltip
                    isOpen={isOpen}
                    onOpenChange={setIsOpen}
                    content={orderStops.map((x) => x.address).join(" → ")}
                    delay={400}
                >
                    {data.children}
                </Tooltip>
            );
        },
        [isMobile, orderStops, tooltipData],
    );

    if (!orderStops?.length) {
        return <span>{Dash.EM}</span>;
    }

    const routeStart = orderStops[0];
    const routeEnd = orderStops[orderStops.length - 1];
    const orderStopsCount = orderStops.length;
    const Wrapper = orderStopsCount > 2 ? TooltipWrapper : Fragment;

    const divRole = isMobile ? "button" : undefined;
    const onClick = isMobile ? tooltipData.onClick : undefined;
    const divTabIndex = isMobile ? 0 : undefined;

    return (
        <div>
            <Wrapper>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions -- if mobile then has all required fields */}
                <div onClick={onClick} onKeyDown={onClick} role={divRole} tabIndex={divTabIndex}>
                    <Typography fontSize="text-sm">
                        <span>{routeStart.address}</span> {orderStopsCount === 2 && <ArrowRight size="xs" />}
                        {orderStopsCount > 2 && <ArrowRightDouble size="xs" />}
                        {orderStopsCount > 1 && <span>{` ${routeEnd.address}`}</span>}
                    </Typography>
                </div>
            </Wrapper>
        </div>
    );
};
