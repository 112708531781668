import {useCallback, useEffect} from "react";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {AccountState} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const getDriverFn = (api: Api, body: FleetOwnerPortalService.GetDriverRequest) => api.fleetOwnerPortal.getDriver(body);
export function useDriverCategories(accountState: AccountState, driverId: number) {
    const {data, fetch: getDriver} = useFetch(getDriverFn);

    const doGetDriver = useCallback(() => {
        if (getDriver) {
            getDriver({
                id: driverId,
            });
        }
    }, [getDriver, accountState, driverId]);

    useEffect(doGetDriver, [doGetDriver]);

    return {
        data,
        refetch: doGetDriver,
    };
}
