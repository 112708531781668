import {useTopBarHeightTailwindConfig} from "common/hooks/useTopBarHeightTailwindConfig";

export const NavWrapper = ({children}: {children: React.ReactNode}) => {
    const {topBarHeightClass: TOPBAR_HEIGHT_CLASSNAME} = useTopBarHeightTailwindConfig();
    return (
        <>
            <nav className={`${TOPBAR_HEIGHT_CLASSNAME} bg-layer-floor-0 fixed inset-x-0 top-0 z-[25] shadow`}>
                <div className={`${TOPBAR_HEIGHT_CLASSNAME} flex justify-between px-3 xl:px-6`}>{children}</div>
            </nav>
            <div className={TOPBAR_HEIGHT_CLASSNAME} /> {/* Push page contents below Topbar  */}
        </>
    );
};
