import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";

export enum TabName {
    NotificationSettings = "notification_settings",
    LanguageSelection = "language_selection",
}

export function useUserPreferencesTabSelectorOptions(): Array<Tab<TabName>> {
    // TODO translate
    const tabs: Array<Tab<TabName>> = [
        {
            id: TabName.NotificationSettings,
            title: "Notification settings",
            trackingEventTabName: TabNameEvent.UserPreferencesPageNotificationSettings,
        },
        {
            id: TabName.LanguageSelection,
            title: "Language selection",
            trackingEventTabName: TabNameEvent.UserPreferencesPageLanguageSelection,
        },
    ];

    return tabs;
}
