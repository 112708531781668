import {useContext, useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import {CompanyOnboardingRoutePrefix} from "common/constants/routes";
import {Company, CompanyType, isActiveOrBlockedFleet} from "common/constants/types";
import {CompanySwitchType, useCompanySelection} from "common/hooks/useCompanySelection";
import {ViewLocationState} from "common/navigation/types/locationState";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {ApplicationContextProvider} from "features/app/appStateProvider";
import {getReturnPath} from "@fleet/common/utils/routeUtil";

import BaseLayout from "./BaseLayout";

export const REDIRECT_PARAM = "go";

export type CompanyManagementLayoutVariant = "FLEET" | "HOLDING_FLEET" | "INACTIVE_COMPANY";

interface CompanyManagementLayoutProps {
    variant: CompanyManagementLayoutVariant;
}

const CompanyManagementLayout = ({variant}: CompanyManagementLayoutProps) => {
    const appState = useContext(ApplicationContextProvider);
    const accountState = useContext(AccountContextProvider);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const selectCompany = useCompanySelection();

    useEffect(() => {
        async function checkCompanyChanged() {
            if (!appState.isAppReady || !accountState.profile) {
                return;
            }

            const {fleets, holdingFleets} = accountState.profile;
            const activeFleets = fleets.filter((f) => isActiveOrBlockedFleet(f));
            const inactiveFleets = fleets.filter((f) => !isActiveOrBlockedFleet(f));

            if (variant === "INACTIVE_COMPANY") {
                const companyIdInUrl = Number(params.companyId);
                const inactiveCompany = inactiveFleets.find((c: Company) => c.id === companyIdInUrl);
                const activeCompany = activeFleets.find((c: Company) => c.id === companyIdInUrl);
                if (inactiveCompany) {
                    selectCompany(inactiveCompany, CompanyType.FLEET_COMPANY, CompanySwitchType.STATE_SWITCH_ONLY);
                } else if (activeCompany) {
                    navigate(`/${companyIdInUrl}`, {replace: true});
                    return;
                } else {
                    navigate("/", {replace: true});
                    return;
                }

                return;
            }

            if (params.fleetId === REDIRECT_PARAM && activeFleets.length) {
                const pathWithFleetId = location.pathname.replace(REDIRECT_PARAM, activeFleets[0].id.toString());
                navigate(pathWithFleetId + location.search, {replace: true});
                return;
            }

            let companyIdInUrl = Number(params.fleetId);
            const inactiveCompany = inactiveFleets.find((c: Company) => c.id === companyIdInUrl);
            if (inactiveCompany) {
                navigate(`/${CompanyOnboardingRoutePrefix}/${String(companyIdInUrl)}`, {replace: true});
                return;
            }

            let companyType = CompanyType.FLEET_COMPANY;
            if (!companyIdInUrl || Number.isNaN(companyIdInUrl)) {
                companyIdInUrl = Number(params.holdingFleetId);
                companyType = CompanyType.HOLDING_FLEET;
            }

            const selectedCompany = accountState.selectedCompany?.company;
            const selectedCompanyType = accountState.selectedCompany?.type;

            if (companyIdInUrl === selectedCompany?.id && companyType === selectedCompanyType) {
                return;
            }

            const availableCompanies = (
                companyType === CompanyType.FLEET_COMPANY ? activeFleets : holdingFleets
            ) as Company[];

            const newCompany = availableCompanies.find((c: Company) => c.id === companyIdInUrl);
            if (newCompany) {
                selectCompany(newCompany, companyType, CompanySwitchType.STATE_SWITCH_ONLY);
            } else {
                const returnUrl = getReturnPath(location);
                if (returnUrl) {
                    navigate(returnUrl, {replace: true});
                } else {
                    const viewPath = params.fleetId ?? params.holdingFleetId;
                    const searchParams = new URLSearchParams(location.search);
                    const view = searchParams.toString() ? `${viewPath}${location.search}` : viewPath;

                    const state: ViewLocationState = {view};
                    navigate("/", {replace: true, state});
                }
            }
        }

        checkCompanyChanged();
    }, [
        params.fleetId,
        params.holdingFleetId,
        appState.isAppReady,
        location,
        navigate,
        accountState,
        selectCompany,
        variant,
        params.companyId,
    ]);

    if (!accountState.selectedCompany) {
        return <></>;
    }

    return <BaseLayout variant={variant} topbarVariant="ACCOUNT" />;
};

export default CompanyManagementLayout;
