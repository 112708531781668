import {FC} from "react";

import {IconButton, Tooltip} from "@bolteu/kalep-react";
import {InfoCircleOutlined} from "@bolteu/kalep-react-icons";

export enum HeaderSize {
    Large = "large",
    Medium = "medium",
    Small = "small",
    ExtraSmall = "extra-small",
}

export interface HeaderProps {
    size: HeaderSize;
    text: string;
    tooltip?: string;
    disabled?: boolean;
    className?: string;
}

function getHeaderSize(type: HeaderSize) {
    switch (type) {
        case HeaderSize.ExtraSmall:
            return "text-xl";
        case HeaderSize.Small:
            return "text-2xl";
        case HeaderSize.Medium:
            return "text-3xl";
        case HeaderSize.Large:
        default:
            return "text-6xl";
    }
}

const Header: FC<HeaderProps> = ({size, text, tooltip, disabled, className = ""}) => {
    const textSize = getHeaderSize(size);
    const textColor = disabled ? "text-tertiary" : "text-primary";

    return (
        <div className="flex flex-row gap-2">
            <h1
                className={`${textSize} leading-14 font-semibold tracking-normal ${textColor} ${className}`}
                data-testid="page-header"
            >
                {text}
            </h1>
            {tooltip && (
                <Tooltip content={tooltip} placement="bottom">
                    <IconButton
                        overrideClassName="h-[24px] w-[24px]"
                        aria-label="tooltip"
                        variant="ghost"
                        icon={<InfoCircleOutlined />}
                    />
                </Tooltip>
            )}
        </div>
    );
};

export default Header;
