import {useCallback, useMemo} from "react";
import {CircleFlag} from "react-circle-flags";

import countries, {CountryCode} from "common/geo/constants/countries";
import {useI18n} from "common/hooks/useI18n";

export interface AreaCodeOption {
    label: string;
    value: string;
    name: string;
}

export function useAreaCodeReactSelectOptions() {
    const {i18n} = useI18n();

    const countryData = useMemo(() => {
        const translateCountry = (code: CountryCode, name: string) => i18n(`countries.${code}`) || name;
        return Object.fromEntries(
            countries.map((c) => [
                c.code,
                {countryName: translateCountry(c.code, c.name), areaCode: `+${c.primaryAreaCode}`},
            ]),
        );
    }, [i18n]);

    const options = useMemo(
        () =>
            Object.entries(countryData).map(
                ([countryCode, data]) =>
                    ({
                        label: data.areaCode,
                        name: data.countryName,
                        value: countryCode,
                    } as AreaCodeOption),
            ),
        [countryData],
    );

    const renderOption = useCallback(
        ({label, value}: AreaCodeOption) => {
            const countryCode = value?.toString();
            return (
                <div>
                    {countryCode && (
                        <div className="flex max-w-[350px] flex-row items-center justify-between gap-2 p-2">
                            <div className="flex flex-row">
                                <CircleFlag countryCode={countryCode} height={20} width={20} />
                                <span className="country-name ml-3">{countryData[countryCode].countryName}</span>
                            </div>
                            <span className="text-base">{label}</span>
                        </div>
                    )}
                    {countryCode === null && <></>}
                </div>
            );
        },
        [countryData],
    );

    return {options, renderOption, countryData};
}
