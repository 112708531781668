import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ApiDrivenTableDesktop from "./ApiDrivenTableDesktop";
import {ApiDrivenTableMobile} from "./ApiDrivenTableMobile";
import {
    AlignHorizontal,
    AlignVertical,
    ApiDrivenTableTransforms,
    ApiDriverTableInlineAction,
    ApiDriverTableSortedBy,
    PropActions,
} from "./types";
import {useApiDrivenTableColumns} from "./useApiDrivenTableColumns";

interface ApiDrivenTableProps {
    columns: FleetOwnerPortalService.ApiColumn[];
    sortedBy?: ApiDriverTableSortedBy;
    numberOfRecords: number;
    alignHorizontal?: AlignHorizontal;
    alignVertical?: AlignVertical;
    tableId: string;
    children?: React.ReactNode;
    transforms?: ApiDrivenTableTransforms;
    propActions?: PropActions;
    inlineActions?: ApiDriverTableInlineAction[];
}

export default function ApiDrivenTable(apiDrivenTableProps: ApiDrivenTableProps) {
    const columns = useApiDrivenTableColumns(
        apiDrivenTableProps.tableId,
        apiDrivenTableProps.columns,
        apiDrivenTableProps.transforms,
    );

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    if (isMobileView) {
        return (
            <ApiDrivenTableMobile
                {...columns}
                numberOfRecords={apiDrivenTableProps.numberOfRecords}
                emptyStateComponent={apiDrivenTableProps.children}
                transforms={apiDrivenTableProps.transforms}
                inlineActions={apiDrivenTableProps.inlineActions}
            />
        );
    }

    return (
        <ApiDrivenTableDesktop
            {...columns}
            sortedBy={apiDrivenTableProps.sortedBy}
            alignVertical={apiDrivenTableProps.alignVertical}
            alignHorizontal={apiDrivenTableProps.alignHorizontal}
            numberOfRecords={apiDrivenTableProps.numberOfRecords}
            emptyStateComponent={apiDrivenTableProps.children}
            transforms={apiDrivenTableProps.transforms}
            propActions={apiDrivenTableProps.propActions}
            inlineActions={apiDrivenTableProps.inlineActions}
        />
    );
}
