import {Skeleton} from "@bolteu/kalep-react";

export const CategorySkeleton = () => (
    <div className="border-b-layer-surface flex items-center gap-2 border-b py-5 last:border-none">
        <div className="shrink-0">
            <Skeleton variant="circular" width={24} />
        </div>
        <div className="flex items-center justify-start gap-2">
            <Skeleton variant="text" width={80} textSize="text-6xl" />
            <Skeleton variant="text" width={100} textSize="text-xl" />
        </div>
        <div className="flex flex-1 justify-end">
            <Skeleton variant="text" width={60} textSize="text-xl" />
        </div>
    </div>
);
