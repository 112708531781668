import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useLocation} from "react-router-dom";

import {CommonRoute, FleetRoute} from "common/constants/routes";
import {ApiContextProvider} from "common/services/apiProvider";
import {ApplicationContextProvider} from "features/app/appStateProvider";
import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";
import jwtDecode from "jwt-decode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {AuthType, SupportWidget, UnreadCase} from "@bolteu/support-widget";

import {AccountContextProvider} from "../../features/account/accountStateProvider";
import {FeaturesContextProvider} from "../../FeaturesProvider";

interface ParsedJWTToken {
    exp: number;
}

export const useSupportWidget = (selectedCaseId?: number) => {
    const {observability} = useContext(ThirdPartyContextProvider);
    const appState = useContext(ApplicationContextProvider);
    const features = useContext(FeaturesContextProvider);
    const [isWidgetInitialised, setIsWidgetInitialised] = useState(false);
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState.getFleet();
    const intl = useIntl();
    const api = useContext(ApiContextProvider);
    const [unreadCases, setUnreadCases] = useState<UnreadCase[]>();
    const supportWidget = useMemo(() => new SupportWidget(), []);
    const location = useLocation();
    const [isSupportWidgetOffsetAdded, setIsSupportWidgetOffsetAdded] = useState(false);
    const [currentFleet, setCurrentFleet] = useState<number>();

    const isWidgetInactive =
        (fleet && isWidgetInitialised && currentFleet !== fleet.id) || !fleet?.id || !appState.isAuthenticated;

    const isSupportWidgetFeatureEnabled = useMemo(
        () => !isWidgetInactive && !!features?.support_widget,
        [isWidgetInactive, features?.support_widget],
    );

    const getAuthToken = useCallback(async () => {
        const willAccessTokenExpireSoon = (decodedJwtToken: ParsedJWTToken, validForSeconds: number): boolean =>
            !decodedJwtToken.exp || decodedJwtToken.exp * 1000 - Date.now() < validForSeconds * 1000;

        const authStrategy = api?.authenticationStrategy?.jwt;
        let token = authStrategy?.getAuthToken();
        const parsedAccessToken: ParsedJWTToken | undefined = jwtDecode(token || "");
        const validForSeconds = authStrategy?.getExtraConfig().accessTokenRefreshTimeoutBeforeExpiryInSeconds || 0;

        if (!parsedAccessToken) {
            throw new Error("Could not parse access token");
        }

        if (willAccessTokenExpireSoon(parsedAccessToken, validForSeconds)) {
            token = await authStrategy?.getExtraConfig().updateAuthToken();
        }

        if (!token) {
            throw new Error("Missing authentication token");
        }

        return token;
    }, [api]);

    const getSupportCaseUrl = useCallback(async (): Promise<string> => {
        if (api) {
            const {url} = await api.fleetOwnerPortal.getSupportCaseWidgetUrl();
            return url;
        }
        return "";
    }, [api]);

    const getUnreadCases = useCallback(async (): Promise<UnreadCase[]> => {
        if (api) {
            const {cases} = await api.fleetOwnerPortal.getUnreadCasesWithLastMessage();
            setUnreadCases(cases);
            return cases;
        }
        return [];
    }, [api]);

    const getUnreadCasesFop = useCallback(async (): Promise<UnreadCase[]> => {
        observability.addBreadcrumb("FOP getUnreadCases");
        return getUnreadCases();
    }, [getUnreadCases, observability]);

    const getUnreadCasesWidget = useCallback(async (): Promise<UnreadCase[]> => {
        observability.addBreadcrumb("CS widget getUnreadCases");
        return getUnreadCases();
    }, [getUnreadCases, observability]);

    const getWidgetUrl = useCallback(async (): Promise<string> => {
        if (api) {
            const {url} = await api.fleetOwnerPortal.getSupportWidgetUrl();
            return url;
        }
        return "";
    }, [api]);

    const getMqttConnectionInfo = useCallback(
        async (): Promise<FleetOwnerPortalService.GetMqttConnectionInfoResponse | undefined> =>
            api?.fleetOwnerPortal.getMqttConnectionInfo(),
        [api],
    );

    const destroy = useCallback(() => {
        setUnreadCases(undefined);
        setIsWidgetInitialised(false);
        supportWidget.destroy();
    }, [supportWidget]);

    const initialiseWidget = useCallback(async () => {
        const mqttConnectionInfo = await getMqttConnectionInfo();
        if (unreadCases && fleet && mqttConnectionInfo) {
            setCurrentFleet(fleet.id);
            setIsWidgetInitialised(true);
            supportWidget.init({
                url: await getWidgetUrl(),
                auth: {
                    type: AuthType.JWT,
                    requestToken: getAuthToken,
                },
                styleProps: {zIndex: 31},
                locale: intl.locale,
                supportCaseUrl: await getSupportCaseUrl(),
                unreadCases,
                selectedCaseId,
                mqttConnectionInfo,
                notificationsDisabled: false,
                getUnreadCases: getUnreadCasesWidget,
            });
        }
    }, [
        fleet,
        getAuthToken,
        getMqttConnectionInfo,
        getSupportCaseUrl,
        getUnreadCasesWidget,
        getWidgetUrl,
        intl.locale,
        selectedCaseId,
        supportWidget,
        unreadCases,
    ]);

    const toggle = useCallback(() => {
        if (isWidgetInitialised) {
            supportWidget.toggle();
        } else {
            initialiseWidget();
        }
    }, [initialiseWidget, isWidgetInitialised, supportWidget]);

    const open = useCallback(() => {
        if (isWidgetInitialised) {
            if (!supportWidget._widgetOpen) {
                supportWidget.toggle();
            }
        } else {
            initialiseWidget();
        }
    }, [initialiseWidget, isWidgetInitialised, supportWidget]);

    const close = useCallback(() => {
        if (isWidgetInitialised && supportWidget._widgetOpen) {
            supportWidget.toggle();
        }
    }, [isWidgetInitialised, supportWidget]);

    const updateSupportWidgetLocale = useCallback(
        (locale: string) => {
            if (isWidgetInitialised) {
                supportWidget.updateLocale(locale);
            }
        },
        [isWidgetInitialised, supportWidget],
    );

    useEffect(() => {
        if (isWidgetInactive) {
            destroy();
        }
    }, [isWidgetInactive, destroy]);

    useEffect(() => {
        if (isSupportWidgetFeatureEnabled) {
            if (!isWidgetInitialised && !unreadCases) {
                getUnreadCasesFop();
            } else if (!isWidgetInitialised && unreadCases) {
                initialiseWidget();
            }
        }
    }, [getUnreadCasesFop, initialiseWidget, isSupportWidgetFeatureEnabled, isWidgetInitialised, unreadCases]);

    useEffect(() => {
        if (!isWidgetInitialised) {
            return;
        }

        const isInvoicesPath = location.pathname.includes(CommonRoute.INVOICES);
        const isLiveMapMobile = location.pathname.includes(FleetRoute.LIVE_MAP) && supportWidget.isMobile;

        const shouldUpdateOffset = (isInvoicesPath || isLiveMapMobile) && !location.search;

        if (shouldUpdateOffset) {
            supportWidget.updateBottomOffset(95);
            setIsSupportWidgetOffsetAdded(true);
        } else if (isSupportWidgetOffsetAdded) {
            supportWidget.resetBottomOffset();
            setIsSupportWidgetOffsetAdded(false);
        }
    }, [isSupportWidgetOffsetAdded, isWidgetInitialised, location.pathname, location.search, supportWidget]);

    return {
        initialiseWidget,
        destroy,
        unreadCases,
        isWidgetInitialised,
        isSupportWidgetFeatureEnabled,
        toggle,
        open,
        close,
        updateSupportWidgetLocale,
    };
};
