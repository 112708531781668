import {useCallback, useState} from "react";

import {I18nFn, useI18n} from "common/hooks/useI18n";

import {FleetCarService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip, Tooltip} from "@bolteu/kalep-react";

import AccessManagementRenderer from "../components/AccessManagementRenderer";
import DriverItem from "../DriverItem";
import ManageDriversDialog from "./ManageDriversDialog";
import {useVehicleDriverAccesses} from "./useVehicleDriverAccesses";

interface DriverAccessManagerProps {
    vehicleId: number;
}
export default function DriverAccessManager({vehicleId}: DriverAccessManagerProps) {
    const {i18n} = useI18n();

    const [isManageDriversDialogOpen, setIsManageDriversDialogOpen] = useState(false);
    const onAccessManagementBtnClicked = useCallback(() => {
        setIsManageDriversDialogOpen(true);
    }, []);

    const {driversWithAccess: assignedDrivers, refetch} = useVehicleDriverAccesses(vehicleId);

    const onManageDriversDialogClose = useCallback(() => {
        setIsManageDriversDialogOpen(false);
        refetch();
    }, [refetch]);

    return (
        <>
            <AccessManagementRenderer
                title={i18n("auth.app.sidebar.drivers")}
                buttonText={i18n("auth.app.fleet.vehicle_assignment.vehicle.manage_drivers")}
                tooltipText={i18n("auth.app.fleet.vehicle_assignment.vehicle.driver_info_tooltip")}
                onButtonClicked={onAccessManagementBtnClicked}
                editMode={assignedDrivers.length > 0}
                discoveryTooltipContent={i18n(
                    "auth.app.fleet.vehicle_assignment.discovery_tooltip.vehicle_access_description",
                )}
            >
                <div>
                    {assignedDrivers.length === 0 && (
                        <span className="text-secondary">
                            {i18n("auth.app.fleet.vehicle_assignment.vehicle.no_drivers_added")}
                        </span>
                    )}
                    {assignedDrivers.length > 0 && (
                        <div className="flex w-full items-center gap-12">
                            <div>
                                <DriverItem driver={assignedDrivers[0]} listMode={false} />
                            </div>
                            {assignedDrivers.length > 1 && (
                                <div>
                                    <Tooltip content={getTooltipText(assignedDrivers, 5, i18n)} placement="bottom">
                                        <Chip
                                            label={i18n("auth.app.fleet.vehicle_assignment.more", {
                                                num: assignedDrivers.length - 1,
                                            })}
                                            size="sm"
                                            appearance="promo"
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </AccessManagementRenderer>
            {isManageDriversDialogOpen && (
                <ManageDriversDialog onRequestClose={onManageDriversDialogClose} vehicleId={vehicleId} />
            )}
        </>
    );
}

function getTooltipText(
    assignedDrivers: FleetCarService.CarDriver[],
    numDriversToDisplay: number,
    i18n: I18nFn,
): string {
    let drivers = assignedDrivers
        .slice(1, numDriversToDisplay + 1)
        .map((driver, idx) => `${idx + 1}. ${driver.name}`)
        .join("\n");

    if (assignedDrivers.length > numDriversToDisplay) {
        drivers += ` ${i18n("common.more", {num: assignedDrivers.length - numDriversToDisplay})}`;
    }

    return drivers;
}
