import {useCallback, useEffect, useState} from "react";

import DiscoveryToolTip from "common/components/fixedTooltip/DiscoveryToolTip";
import {useI18n} from "common/hooks/useI18n";
import {localStorageService} from "common/services/LocalStorageService";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {Button, IconButton, Tooltip, Typography} from "@bolteu/kalep-react";
import {Edit, InfoCircleOutlined} from "@bolteu/kalep-react-icons";

interface AccessManagementRendererProps {
    title: string;
    children: React.ReactNode;
    buttonText: string;
    tooltipText: string;
    onButtonClicked: () => void;
    editMode?: boolean;
    discoveryTooltipContent?: string;
}

interface VehicleAccessSettings {
    discoveryTooltipDismissed: boolean;
}

export default function AccessManagementRenderer({
    title,
    children,
    buttonText,
    tooltipText,
    onButtonClicked,
    editMode = false,
    discoveryTooltipContent,
}: AccessManagementRendererProps) {
    const size = useTailwindViewport();
    const {i18n} = useI18n();

    const [isDiscoveryTooltipActivated, setIsDiscoveryTooltipActivated] = useState(false);

    const [settings, setSettings] = useLocalStorage<VehicleAccessSettings>(
        localStorageService.getVehicleAccessSettingsKey(),
        {
            discoveryTooltipDismissed: false,
        },
    );

    useEffect(() => {
        if (settings.discoveryTooltipDismissed) {
            return () => {};
        }

        const timeOutId = setTimeout(() => {
            setIsDiscoveryTooltipActivated(true);
        }, 1000);

        return () => {
            clearTimeout(timeOutId);
        };
    });

    const onRenderedActionButtonClicked = useCallback(() => {
        if (!settings.discoveryTooltipDismissed) {
            setSettings({...settings, discoveryTooltipDismissed: true});
            setIsDiscoveryTooltipActivated(false);
        }

        onButtonClicked();
    }, [settings, setSettings, onButtonClicked]);

    const onDiscoveryTooltipClosed = useCallback(() => {
        setSettings({...settings, discoveryTooltipDismissed: true});
    }, [settings, setSettings]);

    return (
        <div className="flex w-full flex-col">
            <div className="flex items-center gap-2">
                <Typography variant="body-l-accent">{title}</Typography>
                <Tooltip content={tooltipText}>
                    <IconButton variant="ghost" icon={<InfoCircleOutlined />} aria-label="manage-vehicle-info" />
                </Tooltip>
            </div>
            <div className="md:border-separator flex flex-col justify-between gap-2 md:max-w-[480px] md:flex-row md:items-center md:border-b md:py-2 ">
                <div className="flex w-full flex-col gap-2 md:w-7/12">{children}</div>
                {!editMode && (
                    <div className="flex w-5/12">
                        <DiscoveryToolTip
                            title={i18n("auth.app.fleet.vehicle_assignment.discovery_tooltip.title")}
                            content={discoveryTooltipContent ?? ""}
                            active={isDiscoveryTooltipActivated}
                            onClose={onDiscoveryTooltipClosed}
                        >
                            <Button variant="primary" onClick={onButtonClicked} fullWidth>
                                {buttonText}
                            </Button>
                        </DiscoveryToolTip>
                    </div>
                )}
                {editMode && (
                    <div className="py-2">
                        <DiscoveryToolTip
                            title={i18n("auth.app.fleet.vehicle_assignment.discovery_tooltip.title")}
                            content={discoveryTooltipContent ?? ""}
                            active={isDiscoveryTooltipActivated}
                            onClose={onDiscoveryTooltipClosed}
                        >
                            <>
                                {size === "sm" && (
                                    <Button variant="secondary" onClick={onRenderedActionButtonClicked}>
                                        {buttonText}
                                    </Button>
                                )}
                                {size !== "sm" && (
                                    <IconButton
                                        onClick={onRenderedActionButtonClicked}
                                        variant="secondary"
                                        icon={<Edit />}
                                        aria-label="edit-vehicle-info"
                                    />
                                )}
                            </>
                        </DiscoveryToolTip>
                    </div>
                )}
            </div>
        </div>
    );
}
