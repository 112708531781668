import {useCallback, useEffect, useState} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Button, Dialog} from "@bolteu/kalep-react";

import {ServerError} from "../constants";
import {AssignmentTypeRadioGroup} from "./AssignmentTypeRadioGroup";
import {DriverComboBoxForOrderAssignment} from "./DriverComboBoxForOrderAssignment";

import AssignmentType = FleetPortalOrderService.AssignmentType;

interface UpdateForm {
    assignment_type: AssignmentType;
    driver_id?: number | null;
    driver_name?: string;
}
export interface AssignOrderData {
    orderId: number;
    assignment_type: FleetPortalOrderService.AssignmentType;
    driverId?: number;
    driverName?: string;
}

const getAssignRideApiBodyFromFields = (
    data: AssignOrderData,
    fields: UpdateForm,
): FleetPortalOrderService.AssignScheduledRideForm | null => {
    if (
        fields.assignment_type === AssignmentType.AUTOMATIC &&
        fields.assignment_type !== data.assignment_type &&
        data.driverId
    ) {
        return {order_id: data.orderId, assignment_type: AssignmentType.AUTOMATIC, driver_id: data.driverId};
    }
    if (
        fields.assignment_type === AssignmentType.MANUAL &&
        fields.driver_id &&
        (fields.assignment_type !== data.assignment_type || fields.driver_id !== data.driverId)
    ) {
        return {
            order_id: data.orderId,
            assignment_type: AssignmentType.MANUAL,
            driver_id: fields.driver_id,
        };
    }
    return null;
};

const assignFunction = (api: Api, body: FleetPortalOrderService.AssignScheduledRideForm) =>
    api.fleetPortalOrder.assignScheduledRide(body);

interface Props {
    onClose: () => void;
    closeDialogAndRefreshTable: () => void;
    data: AssignOrderData;
}

export const AssignScheduledRideDialog = ({onClose, closeDialogAndRefreshTable, data}: Props) => {
    const {i18n} = useI18n();
    const [fieldsToUpdate, setFieldsToUpdate] = useState<UpdateForm>({
        assignment_type: data.assignment_type,
        driver_id: data.driverId,
        driver_name: data.driverId ? data.driverName : undefined,
    });
    const [driverErrorMessage, setDriverErrorMessage] = useState<string | undefined>(undefined);
    const {fetch: assignFetch, status: updateStatus, error} = useFetch(assignFunction);
    const isMobile = useTailwindViewport() === "sm";

    useEffect(() => {
        if (updateStatus === FetchStatus.Success) {
            closeDialogAndRefreshTable();
        } else if (updateStatus === FetchStatus.Error) {
            if (
                error.message === ServerError.SCHEDULED_RIDE_IS_BOOKED ||
                error.message === ServerError.SCHEDULED_RIDE_IN_WRONG_STATE ||
                error.message === ServerError.ORDER_IS_IN_WRONG_STATE_FOR_THIS_ACTION
            ) {
                closeDialogAndRefreshTable();
            } else if (error.message === ServerError.RIDES_OVERLAPPING) {
                setDriverErrorMessage(i18n("api.error.RIDES_OVERLAPPING"));
            }
        }
    }, [error, closeDialogAndRefreshTable, updateStatus, i18n]);

    const onAssignClick = useCallback(async () => {
        if (!assignFetch) {
            return;
        }
        if (fieldsToUpdate.assignment_type === AssignmentType.MANUAL && !fieldsToUpdate.driver_id) {
            setDriverErrorMessage(i18n("api.error.validation.required"));
            return;
        }
        const updateBody = getAssignRideApiBodyFromFields(data, fieldsToUpdate);
        if (!updateBody) {
            onClose();
            return;
        }
        assignFetch(updateBody);
    }, [assignFetch, fieldsToUpdate, data, i18n, onClose]);

    const onAssignmentTypeChange = useCallback((newAssignmentType: AssignmentType) => {
        setFieldsToUpdate((prev) => ({...prev, assignment_type: newAssignmentType}));
    }, []);

    const onDriverChange = useCallback((newDiverId?: number, newName?: string) => {
        setDriverErrorMessage(undefined);
        setFieldsToUpdate((prev) => ({
            assignment_type: prev.assignment_type,
            driver_id: newDiverId ?? undefined,
            driver_name: newName ?? "",
        }));
    }, []);

    const isLoading = FetchStatus.Loading === updateStatus;

    const buttonClassName = isMobile ? "sm:max-w-[192px]" : "";

    const title = data.driverId
        ? i18n("auth.app.orders.scheduled_rides.change-driver")
        : i18n("auth.app.orders.scheduled_rides.assign-driver");
    const paddingBottom = fieldsToUpdate.assignment_type === AssignmentType.MANUAL ? "" : "pb-16";

    return (
        <ResponsiveDialog title={title} isOpen onRequestClose={onClose}>
            <Dialog.Content>
                <div className={`mt-4 flex flex-col gap-4 ${paddingBottom}`}>
                    <div className="mb-4">
                        <AssignmentTypeRadioGroup
                            value={fieldsToUpdate.assignment_type}
                            onChange={onAssignmentTypeChange}
                        />
                    </div>
                    {fieldsToUpdate.assignment_type === AssignmentType.MANUAL && (
                        <DriverComboBoxForOrderAssignment
                            orderId={data.orderId}
                            selectedDriverId={fieldsToUpdate.driver_id}
                            selectedDriverName={fieldsToUpdate.driver_name}
                            onChange={onDriverChange}
                            validationError={driverErrorMessage}
                        />
                    )}
                    <Alert severity="info">{i18n("auth.app.orders.scheduled_rides.scheduled-ride-info")}</Alert>
                </div>
            </Dialog.Content>
            <Dialog.Footer>
                <Button
                    onClick={onAssignClick}
                    loading={isLoading}
                    fullWidth={isMobile}
                    overrideClassName={buttonClassName}
                >
                    {i18n("common.submit")}
                </Button>
                <Button
                    variant="secondary"
                    onClick={onClose}
                    disabled={isLoading}
                    fullWidth={isMobile}
                    overrideClassName={buttonClassName}
                >
                    {i18n("common.cancel")}
                </Button>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};
