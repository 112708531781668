import {useCallback} from "react";

import {twJoin} from "tailwind-merge";

interface BottomSheetProps {
    isOpen: boolean;
    children: React.ReactNode;
    onClose: () => void;
}

export const BottomSheet = ({isOpen, children, onClose}: BottomSheetProps) => {
    const handleBackDropClick = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <>
            <div
                role="presentation"
                className={twJoin(!isOpen && "hidden", "fixed inset-0 z-20 bg-neutral-secondary-hard animate-fade-in")}
                onClick={handleBackDropClick}
            />
            <div
                className={twJoin(
                    !isOpen && "hidden",
                    "scrolling-touch fixed inset-x-0 bottom-0 z-30 overflow-auto rounded-t-lg bg-layer-floor-1 pt-2 shadow animate-snackbar",
                )}
            >
                {children}
            </div>
        </>
    );
};
