import {FC, useCallback, useEffect} from "react";

import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";

import {LeadToFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Dialog} from "@bolteu/kalep-react";

const fetchFunction = (api: Api, body: LeadToFleetService.AcceptLeadRequest) =>
    api.leadToFleet.leadmatcherAcceptLead(body);

export interface AcceptModalProps {
    show: boolean;
    closeModal: () => void;
    removeMatch: (props: {accepted: true}) => void;
    onLeadAccepted: () => void;
    matcherLeadId?: number;
}

const AcceptDialog: FC<AcceptModalProps> = ({show, closeModal, removeMatch, matcherLeadId, onLeadAccepted}) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const {fetch, status, error} = useFetch(fetchFunction);

    const acceptLead = useCallback(async () => {
        if (fetch && matcherLeadId) {
            fetch({matcher_lead_id: matcherLeadId});
        }
    }, [fetch, matcherLeadId]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            removeMatch({accepted: true});
            onLeadAccepted();
            trackEvent(EventName.L2FLeadAccepted);
        } else if (status === FetchStatus.Error) {
            closeModal();
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.AcceptLead,
                errorMessage: error.message,
            });
        }
    }, [status, error.message, removeMatch, closeModal, trackEvent, onLeadAccepted]);

    return (
        <ResponsiveDialog
            title={i18n("auth.app.fleet.matches.accept_dialog.title")}
            isOpen={show}
            onRequestClose={closeModal}
        >
            <Dialog.Content>
                <div className="text-secondary text-base">
                    {i18n("auth.app.fleet.matches.accept_dialog.description")}
                </div>
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button variant="secondary" onClick={closeModal}>
                        {i18n("auth.app.fleet.matches.reject_dialog.cancel")}
                    </Button>
                    <Button onClick={acceptLead}>{i18n("auth.app.fleet.matches.accept")}</Button>
                </div>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};

export default AcceptDialog;
