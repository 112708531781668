import {FC, useCallback, useContext, useEffect, useState} from "react";

import DeactivatedEntityIsland from "common/components/deactivatedEntityIsland/DeactivatedEntityIsland";
import {DocumentsAlert} from "common/components/document/DocumentsAlert";
import {DocumentsList} from "common/components/document/DocumentsList";
import {DocumentOwnerType, useDocumentsList} from "common/components/document/useDocumentsList";
import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {isCompanyCountryUsingCash} from "common/util/isCompanyCountryUsingCash";
import {AccountContextProvider} from "features/account/accountStateProvider";
import VehicleAccessManager from "features/companies/vehicle-access-management/driver/VehicleAccessManager";
import {FeaturesContextProvider} from "FeaturesProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {useDocumentFeature} from "../../hooks/useDocumentFeature";
import BlockedDriverAlert from "./BlockedDriverAlert";
import DriverDetails from "./DriverDetails";
import DriverRewardsLevelDetails from "./DriverRewards/DriverRewardsLevelDetails";
import {LevelsAndBenefitsGlossary} from "./DriverRewards/LevelsAndBenefitsGlossary";
import {RewardsOverviewGlossary} from "./DriverRewards/RewardsOverviewGlossary";
import DriverTiles from "./DriverTiles";
import PageHeader from "./PageHeader";
import Permissions from "./Permissions";
import ToggleDriverDeactivationButton from "./ToggleDriverDeactivationButton";
import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;
import DriverCategoriesManager from "../../../../driver-categories/driver/DriverCategoriesManager";

interface DriverProfileBodyProps {
    driver: FleetOwnerPortalService.GetDriverResponse;
    toggleDriverDeactivationStatus: (newPortalStatus: EntityPortalStatus) => void;
}

const getDriverBlocksFunction = (api: Api, query: FleetOwnerPortalService.GetDriverBlocksRequest) =>
    api.fleetOwnerPortal.driverGetBlocksHistory(query);
const getDriverRewardsDetailsFunction = (api: Api, query: FleetOwnerPortalService.GetLoyaltyRewardsDetailsRequest) =>
    api.fleetOwnerPortal.driverRewardsGetRewardsDetails(query);
const getDriverLevelsAndBenefitsDetailsFunction = (
    api: Api,
    query: FleetOwnerPortalService.GetLoyaltyLevelsAndBenefitsDetailsRequest,
) => api.fleetOwnerPortal.driverRewardsGetLevelsAndBenefitsDetails(query);

const DriverProfileBody: FC<DriverProfileBodyProps> = ({driver, toggleDriverDeactivationStatus}) => {
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const features = useContext(FeaturesContextProvider);

    const {data: blocks, fetch: fetchDriverBlocks} = useFetch(getDriverBlocksFunction);
    const {data: driverRewardsDetails, fetch: fetchDriverRewardsDetails} = useFetch(getDriverRewardsDetailsFunction);
    const {data: driverLevelsAndBenefitsDetails, fetch: fetchDriverLevelsAndBenefitsDetails} = useFetch(
        getDriverLevelsAndBenefitsDetailsFunction,
    );

    const [hasCashPayments, setHasCashPayments] = useState(false);
    const [isRewardsGlossaryOpen, setIsRewardsGlossaryOpen] = useState(false);
    const [isLevelsAndBenefitsGlossaryOpen, setIsLevelsAndBenefitsGlossaryOpen] = useState(false);

    useEffect(() => {
        if (!fetchDriverBlocks || !features?.driver_blocks) {
            return;
        }
        fetchDriverBlocks({driver_id: driver.id});
    }, [fetchDriverBlocks, driver.id, features?.driver_blocks]);

    useEffect(() => {
        if (!fetchDriverRewardsDetails || !driver.driver_rewards_level_details) {
            return;
        }
        fetchDriverRewardsDetails({driver_id: driver.id});
    }, [fetchDriverRewardsDetails, driver.id, driver.driver_rewards_level_details]);

    useEffect(() => {
        if (!fetchDriverLevelsAndBenefitsDetails || !driver.driver_rewards_level_details) {
            return;
        }
        fetchDriverLevelsAndBenefitsDetails({driver_id: driver.id});
    }, [fetchDriverLevelsAndBenefitsDetails, driver.id, driver.driver_rewards_level_details]);

    const isDriverDeactivated = driver.portal_status === EntityPortalStatus.Deactivated;
    const isCompanyUsingCash = fleet && isCompanyCountryUsingCash(fleet.country);

    const {isFeatureEnabled: isDriverDocumentsEnabled} = useDocumentFeature();
    const {documentsListProps, documentsAlertsProps} = useDocumentsList({
        areDocumentsEnabled: isDriverDocumentsEnabled,
        owner: {id: driver.id, type: DocumentOwnerType.Driver},
        isUploadAllowed: !isDriverDeactivated,
    });

    useEffect(() => {
        setHasCashPayments(driver.has_cash_payment === 1);
    }, [driver.has_cash_payment]);

    const handleRewardsGlossaryOpen = useCallback(() => {
        setIsRewardsGlossaryOpen(true);
    }, []);

    const handleRewardsGlossaryClose = useCallback(() => {
        setIsRewardsGlossaryOpen(false);
    }, []);

    const handleLevelsAndBenefitsGlossaryOpen = useCallback(() => {
        setIsLevelsAndBenefitsGlossaryOpen(true);
    }, []);

    const handleLevelsAndBenefitsGlossaryClose = useCallback(() => {
        setIsLevelsAndBenefitsGlossaryOpen(false);
    }, []);

    const isCashPaymentsToggleButtonEnabled = driver.show_cash_payment_toggle_button;

    const shouldShowCashPaymentsChip = Boolean(isCompanyUsingCash && !hasCashPayments);
    const shouldShowDocumentsList = isDriverDocumentsEnabled && documentsListProps;
    const shouldShowPermissions = isCompanyUsingCash && !isDriverDeactivated;
    const shouldShowDocumentsAlert = isDriverDocumentsEnabled && documentsAlertsProps;
    const shouldShowBlocksAlert = features?.driver_blocks;

    let InfoIsland = null;
    if (driver.portal_status === EntityPortalStatus.Blocked) {
        if (shouldShowDocumentsAlert || shouldShowBlocksAlert) {
            InfoIsland = (
                <div className="flex flex-col gap-4">
                    {shouldShowDocumentsAlert && <DocumentsAlert {...documentsAlertsProps} />}
                    {shouldShowBlocksAlert && <BlockedDriverAlert blocks={blocks?.list || []} />}
                </div>
            );
        }
    } else if (driver.portal_status === EntityPortalStatus.Deactivated) {
        InfoIsland = (
            <DeactivatedEntityIsland
                title_key="auth.app.fleet.driver-profile.deactivation_island.title"
                deactivated_by_fleet_text_key="auth.app.fleet.driver-profile.deactivation_island.deactivated_by_fleet_text"
                deactivated_by_bolt_text_key="auth.app.fleet.driver-profile.deactivation_island.deactivated_by_bolt_text"
                deactivated_by_bolt_date_unknown_text_key="auth.app.fleet.driver-profile.deactivation_island.deactivated_by_bolt_date_unknown_text"
                deactivationDetails={driver.deactivation_details}
            />
        );
    }

    return (
        <div className="mt-6 flex flex-col gap-8">
            <div className="flex items-start justify-between">
                <PageHeader shouldShowCashPaymentsChip={shouldShowCashPaymentsChip} driver={driver} />
                {driver.show_block_button && (
                    <ToggleDriverDeactivationButton
                        key={driver.portal_status}
                        driverId={driver.id}
                        driverPortalStatus={driver.portal_status}
                        isDeactivatedByFleetOwner={Boolean(driver.deactivation_details?.is_deactivated_by_fleet_owner)}
                        onSuccessAction={toggleDriverDeactivationStatus}
                    />
                )}
            </div>
            <div className="grid grid-cols-1 gap-y-4 md:grid-cols-12">
                {InfoIsland && <div className="col-span-7">{InfoIsland}</div>}
                <div className="col-span-7 col-start-auto mb-4">
                    <DriverTiles driver={driver} blocks={blocks?.list || []} />
                    {features?.vehicle_assignment && (
                        <div className="py-12">
                            <VehicleAccessManager driverId={driver.id} />
                        </div>
                    )}
                    {driver?.categories && (
                        <div className="py-12">
                            <DriverCategoriesManager driverId={driver.id} />
                        </div>
                    )}
                    <DriverDetails driverData={driver} carRentalPaymentsEnabled={features?.car_rental_payments} />
                </div>
                <div className="col-span-4 col-end-[-1] flex flex-col gap-4">
                    {driver.driver_rewards_level_details && (
                        <DriverRewardsLevelDetails
                            data={driver.driver_rewards_level_details}
                            handleRewardsGlossaryOpen={handleRewardsGlossaryOpen}
                            handleLevelsAndBenefitsGlossaryOpen={handleLevelsAndBenefitsGlossaryOpen}
                            glossaryTitle={driverRewardsDetails?.details?.details_title}
                        />
                    )}
                    {shouldShowPermissions && (
                        <Permissions
                            driverId={driver.id}
                            setHasCashPayments={setHasCashPayments}
                            isCashPaymentsToggleButtonEnabled={isCashPaymentsToggleButtonEnabled}
                            cashPaymentsEnabled={hasCashPayments}
                        />
                    )}
                    {shouldShowDocumentsList && <DocumentsList {...documentsListProps} />}
                </div>
            </div>
            {driverLevelsAndBenefitsDetails?.details && (
                <LevelsAndBenefitsGlossary
                    isOpen={isLevelsAndBenefitsGlossaryOpen}
                    handleClose={handleLevelsAndBenefitsGlossaryClose}
                    content={driverLevelsAndBenefitsDetails?.details}
                />
            )}
            {driverRewardsDetails?.details && (
                <RewardsOverviewGlossary
                    isOpen={isRewardsGlossaryOpen}
                    handleClose={handleRewardsGlossaryClose}
                    content={driverRewardsDetails?.details}
                />
            )}
        </div>
    );
};

export default DriverProfileBody;
