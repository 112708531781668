export const TOTAL_STEPS = 3;
export const SCHEDULED_RIDES_TABLE_ID = "scheduled-rides-table";
export const ORDER_HISTORY_TABLE_ID = "order-history-table";
export const SCHEDULED_RIDES_TABLE_MAX_LIMIT = 50;
export const ORDER_HISTORY_MAX_PAGE_LIMIT = 25;
export const DATE_RANGE_LIMIT_DAYS = 31;
export const DEFAULT_CATEGORY_ICON_URL = "https://images.bolt.eu/order/category_default_icon_new.png";

export enum ServerError {
    // Driver from another fleet is assigned already (or another fleet driver took it from the driver app)
    SCHEDULED_RIDE_IS_BOOKED = "SCHEDULED_RIDE_IS_BOOKED",
    // The order is already in progress
    SCHEDULED_RIDE_IN_WRONG_STATE = "SCHEDULED_RIDE_IN_WRONG_STATE",
    ORDER_IS_IN_WRONG_STATE_FOR_THIS_ACTION = "ORDER_IS_IN_WRONG_STATE_FOR_THIS_ACTION",
    // Driver has overlapping scheduled or active ride
    RIDES_OVERLAPPING = "RIDES_OVERLAPPING",
    AREA_NOT_SERVICED = "AREA_NOT_SERVICED",
    NO_CATEGORY_MATCHING_PAYMENT_METHOD = "NO_CATEGORY_MATCHING_PAYMENT_METHOD",
    NO_CATEGORY_MATCHING_DRIVERS = "NO_CATEGORY_MATCHING_DRIVERS",
    NO_RESULTS = "NO_RESULTS",
    GEO_PROVIDER_ERROR = "GEO_PROVIDER_ERROR",
    UNABLE_TO_RESOLVE_ADDRESSES = "UNABLE_TO_RESOLVE_ADDRESSES", // Frontend error to combine geo provider errors
    INVALID_REQUEST_DISTANCE_TOO_LONG = "INVALID_REQUEST_DISTANCE_TOO_LONG",
    NO_CATEGORY_MATCHING_TIME = "NO_CATEGORY_MATCHING_TIME",
}
