import {FC, useCallback, useState} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton, Island, List} from "@bolteu/kalep-react";

import {SubFleetListItem} from "./SubFleetListItem";

import SubFleet = FleetOwnerService.SubFleet;

interface Props {
    subFleets: SubFleet[];
}

const MAX_SHOW_ROWS = 5;

export const SubFleetsList: FC<Props> = ({subFleets}) => {
    const {i18n} = useI18n();
    const [shouldShowAll, setShouldShowAll] = useState(false);
    const showAllRows = useCallback(() => setShouldShowAll(true), []);

    const subFleetsList = () => {
        if (shouldShowAll) {
            return subFleets.map((subFleet) => <SubFleetListItem key={subFleet.id} subFleet={subFleet} />);
        }
        return subFleets
            .slice(0, MAX_SHOW_ROWS)
            .map((subFleet) => <SubFleetListItem key={subFleet.id} subFleet={subFleet} />);
    };

    return (
        <div className="w-full max-w-[400px] sm:min-w-[400px]">
            <Island>
                <p className="pb-2 text-lg font-semibold">
                    {subFleets.length} {i18n("common.subsidiaries")}
                </p>
                <List
                    aria-label="Subsidiary fleets list"
                    overrideClassName="w-auto max-h-[62vh] shadow-none overflow-auto"
                >
                    {subFleetsList()}
                </List>
                {!shouldShowAll && subFleets.length > MAX_SHOW_ROWS && (
                    <GhostButton onClick={showAllRows} overrideClassName="text-action-primary mt-1">
                        {i18n("common.view_all")}
                    </GhostButton>
                )}
            </Island>
        </div>
    );
};
