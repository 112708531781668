import {useCallback, useContext, useState} from "react";
import {Outlet} from "react-router-dom";

import {FullScreenLoadingSpinner} from "common/components/spinner";
import {BackDrop} from "common/navigation/components/Backdrop";
import {NonAccountTopBar} from "common/navigation/components/NonAccountTopBar";
import {NonAccountTopBarV2} from "common/navigation/components/NonAccountTopBarV2";
import {Sidebar} from "common/navigation/components/Sidebar";
import {SidebarV2} from "common/navigation/components/SidebarV2";
import {Topbar} from "common/navigation/components/Topbar";
import {TopbarV2} from "common/navigation/components/TopbarV2";
import {useNavigationLinks} from "common/navigation/hooks/useNavigationLinks";
import {useNavigationLinksV2} from "common/navigation/hooks/useNavigationLinksV2";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {BlockNotificationDialog} from "features/companies/block/BlockNotificationDialog";
import {FeaturesContextProvider} from "FeaturesProvider";
import {SupportWidgetContextProvider} from "SupportWidgetProvider";

import {CompanyManagementLayoutVariant} from "./CompanyManagementLayout";

export type TopBarVariant = "ACCOUNT" | "NON_ACCOUNT";

// TODO after new navigation is enabled to 100%, replace variant prop with navigationLinks and dont pass variant from CompanyManagementLayout
interface BaseLayoutProps {
    variant: CompanyManagementLayoutVariant;
    topbarVariant: TopBarVariant;
}

const BaseLayout = ({variant, topbarVariant}: BaseLayoutProps) => {
    const accountState = useContext(AccountContextProvider);
    const features = useContext(FeaturesContextProvider);
    const {isSupportWidgetEnabled, hasUnreadCases, toggleSupportWidget} = useContext(SupportWidgetContextProvider);
    const [isSidebarOpen, setIsSideBarOpen] = useState(false);

    const navigationLinks = useNavigationLinks(variant);
    const newNavigationLinks = useNavigationLinksV2(variant);

    const closeSideBar = useCallback(() => setIsSideBarOpen(false), []);
    const toggleSideBar = useCallback(() => setIsSideBarOpen(!isSidebarOpen), [isSidebarOpen]);

    const toggleSupportWidgetWrapper = useCallback(() => {
        toggleSupportWidget();
    }, [toggleSupportWidget]);

    const isNewNavigationEnabled = accountState.profile?.isNewNavigationEnabled;
    const isLoadingFeaturesApi = features?.is_loading_features;

    const outlet =
        isLoadingFeaturesApi && isNewNavigationEnabled ? <FullScreenLoadingSpinner absoluteFullScreen /> : <Outlet />;

    return (
        <>
            {topbarVariant === "ACCOUNT" &&
                (isNewNavigationEnabled ? (
                    <TopbarV2
                        isSidebarOpen={isSidebarOpen}
                        toggleSidebar={toggleSideBar}
                        navigationLinks={newNavigationLinks}
                    />
                ) : (
                    <Topbar
                        navigationLinks={navigationLinks}
                        isSidebarOpen={isSidebarOpen}
                        toggleSidebar={toggleSideBar}
                        isSupportWidgetEnabled={isSupportWidgetEnabled}
                        hasUnreadSupportCases={hasUnreadCases}
                        toggleSupportWidget={toggleSupportWidgetWrapper}
                    />
                ))}
            {topbarVariant === "NON_ACCOUNT" &&
                (isNewNavigationEnabled ? (
                    <NonAccountTopBarV2 isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSideBar} />
                ) : (
                    <NonAccountTopBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSideBar} />
                ))}
            <div>
                {isNewNavigationEnabled ? (
                    <SidebarV2 isOpen={isSidebarOpen} setOpen={setIsSideBarOpen} navigationLinks={newNavigationLinks} />
                ) : (
                    <Sidebar
                        isOpen={isSidebarOpen}
                        setOpen={setIsSideBarOpen}
                        navigationLinks={navigationLinks}
                        onLinkClick={closeSideBar}
                    />
                )}
                <BackDrop isVisible={isSidebarOpen} onClick={closeSideBar} />
                <div className="flex-1">
                    <div className="flex h-full text-base leading-normal">
                        <div className="flex h-full w-full flex-col xl:items-center">{outlet}</div>
                    </div>
                </div>
                <BlockNotificationDialog />
            </div>
        </>
    );
};

export default BaseLayout;
