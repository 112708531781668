import {useContext} from "react";

import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {FeaturesContextProvider} from "FeaturesProvider";

export enum TabName {
    ShiftPlanning = "shift_planning",
    OrderRequests = "order_requests",
    IncomingOrders = "incoming_orders",
}

export function useManagementTabSelectorOptions(): Array<Tab<TabName>> {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);

    const tabs: Array<Tab<TabName>> = [];
    if (features?.shifts) {
        tabs.push({
            id: TabName.ShiftPlanning,
            title: i18n("auth.app.fleet.shifts.planning.title"),
            trackingEventTabName: TabNameEvent.ShiftPlanning,
        });
    }
    if (features?.order_dispatching) {
        tabs.push({
            id: TabName.OrderRequests,
            title: i18n("auth.app.sidebar.order-requests"),
            trackingEventTabName: TabNameEvent.OrdersPageOrderRequests,
        });
    }
    if (features?.incoming_orders) {
        tabs.push({
            id: TabName.IncomingOrders,
            title: i18n("auth.app.sidebar.incoming-orders"),
            trackingEventTabName: TabNameEvent.OrdersPageIncomingOrders,
        });
    }

    return tabs;
}
