import {FC, useCallback, useEffect} from "react";
import {useIntl} from "react-intl";

import {Buffer} from "buffer";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getDatetimeString} from "@fleet/common/utils/dateFormatUtils";

import {CarRentalPaymentService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Chip} from "@bolteu/kalep-react";
import {Check} from "@bolteu/kalep-react-icons";

import {UploadStatus} from "../index";

const fetchFunction = (api: Api, body: CarRentalPaymentService.GetReconciliationReportRequest) =>
    api.carRentalPayment.carRentalPaymentGetReconciliationReportCsv(body);

export interface TimelineProps {
    currentPeriodUploadTimestamp: number;
    currentPeriodUploadStatus: UploadStatus;
    previousPeriodUploadTimestamp?: number;
    previousPeriodUploadStatus?: UploadStatus;
}

const Timeline: FC<TimelineProps> = ({
    currentPeriodUploadTimestamp,
    currentPeriodUploadStatus,
    previousPeriodUploadTimestamp,
    previousPeriodUploadStatus,
}) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const {status, data, fetch} = useFetch(fetchFunction);

    const getReconciliationReport = useCallback(async () => {
        if (!fetch || !previousPeriodUploadTimestamp) {
            return;
        }

        fetch({upload_timestamp: previousPeriodUploadTimestamp});
    }, [fetch, previousPeriodUploadTimestamp]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            const reconciliationReportTitle = i18n("auth.app.fleet.car-rentals.reconciliation_report");

            const csv = Buffer.from(data.file, "base64").toString();
            const blob = new Blob([csv], {type: "text/csv"});
            const blobURL = window.URL.createObjectURL(blob);

            const anchor = document.createElement("a");
            anchor.download = `${reconciliationReportTitle} - ${previousPeriodUploadTimestamp}.csv`;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv;base64", anchor.download, anchor.href].join(":");
            anchor.click();
        }
    }, [data?.file, i18n, previousPeriodUploadTimestamp, status]);

    function getChipContent(uploadStatus: UploadStatus) {
        switch (uploadStatus) {
            case UploadStatus.UPLOADED:
                return (
                    <Chip
                        label={i18n("auth.app.fleet.car-rentals.upload_status.uploaded")}
                        size="sm"
                        color="action"
                        startIcon={<Check />}
                        variant="outline"
                    />
                );
            case UploadStatus.COMPLETED:
                return (
                    <Chip
                        label={i18n("auth.app.fleet.car-rentals.upload_status.completed")}
                        size="sm"
                        color="action"
                        startIcon={<Check />}
                        variant="outline"
                    />
                );
            case UploadStatus.IN_PROGRESS:
                return (
                    <Chip
                        label={i18n("auth.app.fleet.car-rentals.upload_status.in_progress")}
                        size="sm"
                        variant="outline"
                    />
                );
            default:
                return <></>;
        }
    }

    return (
        <div className="min-w-[400px] max-w-xs flex-col">
            <div className="border-neutral-secondary mb-6 max-w-md rounded-lg border py-4 px-6">
                <p className="text-secondary mb-3 text-sm">
                    {i18n("auth.app.fleet.car-rentals.timeline.current_period_title")}
                </p>
                {currentPeriodUploadStatus === UploadStatus.NOT_UPLOADED && (
                    <div className="flex flex-row justify-between pb-3">
                        <h4 className="text-lg font-semibold">
                            {getDatetimeString(intl, currentPeriodUploadTimestamp)}
                        </h4>
                    </div>
                )}
                {currentPeriodUploadStatus === UploadStatus.UPLOADED && (
                    <>
                        <div className="border-separator flex flex-row justify-between border-b pb-3">
                            <h4 className="text-lg font-semibold">
                                {getDatetimeString(intl, currentPeriodUploadTimestamp)}
                            </h4>
                            {getChipContent(currentPeriodUploadStatus)}
                        </div>
                        <div className="text-secondary my-3 text-sm">
                            <p>{i18n("auth.app.fleet.car-rentals.timeline.current_period_description")}</p>
                            <p>{i18n("auth.app.fleet.car-rentals.timeline.report_description")}</p>
                        </div>
                    </>
                )}
            </div>

            {previousPeriodUploadTimestamp && previousPeriodUploadStatus && (
                <div className="border-neutral-secondary max-w-md rounded-lg border py-4 px-6">
                    <p className="text-secondary mb-3 text-sm">
                        {i18n("auth.app.fleet.car-rentals.timeline.previous_period_title")}
                    </p>
                    <div
                        className={`flex flex-row justify-between pb-3 ${
                            UploadStatus.COMPLETED ? "border-separator border-b" : ""
                        }`}
                    >
                        <h4 className="text-lg font-semibold">
                            {getDatetimeString(intl, previousPeriodUploadTimestamp)}
                        </h4>
                        {getChipContent(previousPeriodUploadStatus)}
                    </div>
                    {previousPeriodUploadStatus === UploadStatus.COMPLETED && (
                        <div className="my-3">
                            <Button variant="secondary" onClick={getReconciliationReport}>
                                {i18n("auth.app.fleet.car-rentals.download_report")}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Timeline;
