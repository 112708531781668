import {FC, useCallback} from "react";
import {NavLink, useNavigate} from "react-router-dom";

import {Menu, MenuItem, MenuItemProps} from "@bolteu/kalep-react";
import {Check, MoreIos as Others} from "@bolteu/kalep-react-icons";

import {NavigationLink} from "../hooks/useNavigationLinks";

interface OthersMenuProps {
    navigationLinks: NavigationLink[];
}

const RenderCheck: FC<MenuItemProps> = ({value: route}) => {
    const linkClassname: (props: {isActive: boolean; isPending: boolean}) => string | undefined = useCallback(
        ({isActive}) => `text-action-secondary ${isActive ? "block" : "hidden"}`,
        [],
    );
    return (
        <NavLink to={route as string} className={linkClassname}>
            <Check size="sm" />
        </NavLink>
    );
};

const OthersMenu: FC<OthersMenuProps> = ({navigationLinks}) => {
    const navigate = useNavigate();

    const getNavigateToRoute = useCallback((route: string) => () => navigate(route), [navigate]);

    return (
        <Menu
            menuButton={
                <button className="flex" type="button">
                    <Others className="text-secondary" />
                </button>
            }
        >
            {navigationLinks.map(({title, route}) => {
                const navigateToRoute = getNavigateToRoute(route);

                return (
                    <MenuItem
                        key={title}
                        value={route}
                        label={title}
                        onClick={navigateToRoute}
                        renderEndSlot={RenderCheck}
                    />
                );
            })}
        </Menu>
    );
};

export {OthersMenu};
