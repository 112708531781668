import {useCallback, useEffect, useMemo, useState} from "react";

import LoadingSpinner from "common/components/spinner";
import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {useI18n} from "common/hooks/useI18n";
import {debounce} from "lodash-es";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {TextField, Typography} from "@bolteu/kalep-react";

import {useTableColumns} from "../../common/hooks/useTableColumns";
import {useTableRows} from "../../common/hooks/useTableRows";
import {getUniqueColumnIndex} from "../../common/utils/getUniqueColumnIndex";
import CardRow from "../components/CardRow";
import GlossarySection from "../components/GlossarySection";
import DownloadCSVButton from "./DownloadCSVButton";
import NoParticipants from "./NoParticipants";

import GetCampaignParticipantsResponse = FleetOwnerPortalService.GetCampaignParticipantsResponse;
import CampaignType = FleetOwnerPortalService.CampaignType;
import ColumnType = FleetOwnerPortalService.ColumnType;
import EntityNameCell = FleetOwnerPortalService.EntityNameCell;
import TextCell = FleetOwnerPortalService.TextCell;

interface CampaignParticipantsProps {
    campaignId: number;
    campaignType: CampaignType;
    data: GetCampaignParticipantsResponse | null;
}

const CampaignParticipants = ({campaignId, campaignType, data}: CampaignParticipantsProps) => {
    const {i18n} = useI18n();

    const apiColumns = useMemo(() => data?.columns || [], [data?.columns]);
    const columns = useTableColumns(apiColumns);
    const {sortedRows, orderBy, handleOrderByChange} = useTableRows(apiColumns, ColumnType.ENTITY_NAME, "ASC");

    const [nameInputValue, setNameInputValue] = useState("");
    const [filteredRows, setFilteredRows] = useState(sortedRows);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setFilteredRows(sortedRows);
    }, [sortedRows]);

    const filterRows = useCallback(
        // TODO: temporary search filter on frontend until decided how to handle it on backend (https://taxify.atlassian.net/browse/DRX-8745)
        (searchTerm: string) => {
            if (!sortedRows.length) {
                return;
            }

            const searchableColumnIndexes = apiColumns
                .filter(
                    (column) => [ColumnType.ENTITY_NAME, ColumnType.TEXT].includes(column.type) && column.searchable,
                )
                .map((column) => getUniqueColumnIndex(column.type, column.title));

            if (!searchableColumnIndexes.length) {
                return;
            }

            const normalisedSearchTerm = searchTerm.trim().toLowerCase();
            const rows = sortedRows.filter((row) =>
                searchableColumnIndexes.some((index) => {
                    const cellValue = row[index] as EntityNameCell | TextCell;
                    return (
                        cellValue?.value?.toLowerCase().includes(normalisedSearchTerm) ||
                        cellValue?.subtext?.toLowerCase().includes(normalisedSearchTerm)
                    );
                }),
            );
            setFilteredRows(rows);
            setIsLoading(false);
        },
        [apiColumns, sortedRows],
    );

    const debouncedFilterRows = useMemo(() => debounce(filterRows, 500), [filterRows]);

    const onSearchChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setNameInputValue(event.target.value);
            setIsLoading(true);
            debouncedFilterRows(event.target.value);
        },
        [debouncedFilterRows],
    );

    const renderEmptyPlaceholder = useCallback(() => <NoParticipants />, []);

    if (!data) {
        return <LoadingSpinner show />;
    }

    const shouldShowDownloadCSVButton = campaignType !== CampaignType.REFERRAL;
    // Empty columns crashes portal, if campaigns for any reason fails to provide columns lets not crash!
    const hasValidColumns = Object.keys(columns).length > 0;

    return (
        <div className="flex flex-col gap-8">
            {data.glossary && <GlossarySection content={data.glossary} />}
            {data.stat_cards.length !== 0 && <CardRow cards={data.stat_cards} isLoading={isLoading} />}
            <div className="flex justify-between gap-4">
                <TextField
                    type="search"
                    value={nameInputValue}
                    onChange={onSearchChange}
                    placeholder={i18n("common.filters.search_by_name")}
                />
                {shouldShowDownloadCSVButton && (
                    <DownloadCSVButton campaignId={campaignId} campaignType={campaignType} campaignName={data.name} />
                )}
            </div>
            {hasValidColumns ? (
                <FixedHeaderTable
                    isLoading={isLoading}
                    columns={columns}
                    items={filteredRows}
                    orderBy={orderBy}
                    onOrderByChange={handleOrderByChange}
                    renderEmptyPlaceholder={renderEmptyPlaceholder}
                    aria-label="Campaign participants table"
                />
            ) : (
                <Typography color="danger-primary">{i18n("api.default_error")}</Typography>
            )}
        </div>
    );
};

export default CampaignParticipants;
