import {useEffect} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import OrderHistory from "features/companies/orders/order-history";
import ActivityLog from "features/companies/shifts/log";

import {TabName, useTrackingTabSelectorOptions} from "./hooks/useTrackingTabSelectorOptions";

export const TrackingPage = () => {
    const tabs = useTrackingTabSelectorOptions();
    const {activeTab, TabSelector} = useTabs(tabs, TabName.OrderHistory);

    useEffect(() => {
        // TODO translate, here and below
        const pageTitle = "Tracking";
        document.title = getDocumentTitle(pageTitle);
    }, []);

    let TabComponent: JSX.Element;
    switch (activeTab) {
        case TabName.ActivityLog:
            TabComponent = <ActivityLog />;
            break;
        case TabName.OrderHistory:
        default:
            TabComponent = <OrderHistory />;
    }

    return (
        <Page className="gap-4">
            <ResponsiveHeader text="Tracking" type={ResponsiveHeaderType.MainPage} />
            <TabSelector />
            {TabComponent}
        </Page>
    );
};
