import {useCallback, useContext, useEffect, useMemo, useState} from "react";

import {ALL_DRIVERS_OPTION_VALUE} from "common/components/DriverFilter";
import Pagination from "common/components/Pagination";
import LoadingSpinner from "common/components/spinner";
import ApiDrivenTable from "common/components/table/ApiDrivenTable";
import {ApiDrivenTableTransforms, CellProps} from "common/components/table/ApiDrivenTable/types";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, OngoingDay, Period} from "common/services/period";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {SelectOption} from "@bolteu/kalep-react";

import NoOrders from "../components/NoOrders";
import {OrderRouteCellApiDriven} from "../components/OrderRouteCell";
import {ORDER_HISTORY_MAX_PAGE_LIMIT, ORDER_HISTORY_TABLE_ID} from "../order-requests/constants";
import {OrderHistorySearch} from "./components/OrderHistorySearch";
import {defaultOrdersFilters, OrderFilters} from "./components/OrdersFilter";

const defaultPeriod: ApiPeriod = {period: Period.ONGOING_DAY} as OngoingDay;

const getOrdersFetchFunction = (api: Api, body: FleetPortalOrderService.GetOrderHistoryTableRequest) =>
    api.fleetPortalOrder.orderHistoryGetTable(body);

const OrderHistory = () => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {setNotification} = useContext(NotificationContext);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>(defaultPeriod);
    const [isInvalidPeriod, setIsInvalidPeriod] = useState<boolean>(false);
    const [orderFilters, setOrderFilters] = useState<OrderFilters>(defaultOrdersFilters);
    const [selectedDriver, setDriver] = useState<SelectOption | null>(null); // TODO add to filter?
    const [pageNum, setPageNum] = useState(0);
    const {data, fetch, status, error} = useFetch(getOrdersFetchFunction);

    const getOrderHistory = useCallback(
        async (newPageNum: number) => {
            if (fetch) {
                const dates =
                    selectedPeriod.period === Period.CUSTOM
                        ? {start: selectedPeriod.startDate, end: selectedPeriod.endDate}
                        : apiPeriodToDates(selectedPeriod);

                const offset = newPageNum * ORDER_HISTORY_MAX_PAGE_LIMIT;
                const driverId = Number(selectedDriver?.value);
                fetch({
                    offset,
                    limit: ORDER_HISTORY_MAX_PAGE_LIMIT,
                    start_date: getIsoDate(dates.start),
                    end_date: getIsoDate(dates.end),
                    driver_id: !Number.isNaN(driverId) && driverId > 0 ? driverId : undefined,
                    order_states: orderFilters.orders_state_statuses,
                    city_id: orderFilters.city_id,
                    search_category_group: orderFilters.search_category_group,
                });
            }
        },
        [fetch, selectedPeriod, selectedDriver, orderFilters],
    );

    useEffect(() => {
        if (fleet) {
            setPageNum(0);
            getOrderHistory(0);
        }
    }, [getOrderHistory, fleet]);

    useEffect(() => {
        if (status === FetchStatus.Error && error.message) {
            const errorMessage =
                error.message === "CLIENT_TIMEOUT"
                    ? i18n("auth.app.orders.history.date-range-too-big")
                    : i18n("api.default_error");

            setNotification({
                type: NotificationType.ERROR,
                text: errorMessage,
                timeout: 6000,
            });
        }
    }, [error.message, status, i18n, setNotification]);

    const onPageChange = useCallback(
        async (newPageNum: number) => {
            if (newPageNum === pageNum) {
                return;
            }

            getOrderHistory(newPageNum);
            setPageNum(newPageNum);
        },
        [getOrderHistory, pageNum],
    );

    const onPeriodErrorStateChange = useCallback((hasError: boolean) => {
        setIsInvalidPeriod(hasError);
    }, []);

    const onPeriodChange = useCallback(
        (newPeriod: ApiPeriod) => {
            setSelectedPeriod(newPeriod);
        },
        [setSelectedPeriod],
    );

    const onDriverChange = useCallback(
        (newDriver: SelectOption | null) => {
            setDriver(newDriver);
            if (newDriver?.value === ALL_DRIVERS_OPTION_VALUE) {
                setSelectedPeriod(defaultPeriod);
            }
        },
        [setDriver, setSelectedPeriod],
    );

    const transforms = useMemo((): ApiDrivenTableTransforms => {
        const transformRoute = (props: CellProps) => {
            const cellValue = props.column.cells[props.valueAtIdx];
            if (typeof cellValue === "object" && typeof cellValue[0] === "string") {
                const stops = (cellValue as string[]).map((stop, index) => ({
                    id: index,
                    address: stop,
                    arrived_at: null,
                    departed_at: null,
                }));
                return <OrderRouteCellApiDriven stops={stops} />;
            }
            return <></>;
        };
        return {route: {renderCellOnMobile: transformRoute, renderCellOnDesktop: transformRoute}};
    }, []);

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(status);

    return (
        <div className="flex flex-col gap-y-6">
            <OrderHistorySearch
                columns={data?.columns ?? []}
                columnsTransforms={transforms}
                isInvalidPeriod={isInvalidPeriod}
                onPeriodChange={onPeriodChange}
                onPeriodErrorStateChange={onPeriodErrorStateChange}
                selectedDriver={selectedDriver}
                selectedPeriod={selectedPeriod}
                setDriver={onDriverChange}
                filters={orderFilters}
                setFilters={setOrderFilters}
            />
            <ApiDrivenTable
                tableId={ORDER_HISTORY_TABLE_ID}
                columns={data?.columns ?? []}
                numberOfRecords={data?.columns[0]?.cells.length ?? 0}
                transforms={transforms}
            >
                {isLoading ? <LoadingSpinner show parentClassName="mt-2" /> : <NoOrders />}
            </ApiDrivenTable>
            <Pagination
                currentPage={pageNum}
                pageSize={ORDER_HISTORY_MAX_PAGE_LIMIT}
                totalCount={data?.total_rows ?? 0}
                onPageChange={onPageChange}
                disabled={isLoading}
                showText
            />
        </div>
    );
};

export default OrderHistory;
