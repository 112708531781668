import React from "react";

import {Locale} from "date-fns";
import {
    arSA,
    az,
    cs,
    de,
    enGB,
    enUS,
    es,
    et,
    fi,
    fr,
    hr,
    hu,
    ka,
    lt,
    lv,
    nl,
    nn,
    pl,
    pt,
    ro,
    ru,
    sk,
    sv,
    uk,
    zhTW,
} from "date-fns/locale";

import {HttpClientLibrary, TranslationNoAuthService} from "@bolteu/bolt-server-api-translation";
import {registerLocale as registerLocaleForDatePicker} from "@bolteu/kalep-react";
import {initializeTranslationsService, TranslationFile} from "@bolteu/translations-service";

export const DefaultLocale = "en-us";

export interface Language {
    regions: string[];
    key: string;
    fallback: string;
    name: string;
}
// Regions: ISO 3166-1 https://en.wikipedia.org/wiki/ISO_3166-1#Officially_assigned_code_elements
// Language: ISO 639-1 (https://en.wikipedia.org/wiki/List_of_ISO_639-2_codes) + ISO 3166-1
export const languages: Language[] = [
    {regions: ["us"], key: "en-us", fallback: "en", name: "English"},
    {regions: ["gb"], key: "en-gb", fallback: "en-gb", name: "English, United Kingdom"},
    {regions: ["az"], key: "az-az", fallback: "az", name: "Azərbaycanca"},
    {regions: ["sa"], key: "ar-sa", fallback: "ar", name: "العَرَبِيَّة"},
    {regions: ["cz"], key: "cs-cz", fallback: "cs", name: "Čeština"},
    {regions: ["de"], key: "de-de", fallback: "de", name: "Deutsch"},
    {regions: ["ee"], key: "et-ee", fallback: "et", name: "Eesti"},
    {regions: ["es"], key: "es-es", fallback: "es", name: "Español"},
    {regions: ["fr"], key: "fr-fr", fallback: "fr", name: "Français"},
    {regions: ["nl"], key: "nl-nl", fallback: "nl", name: "Nederlands"},
    {regions: ["no"], key: "no-no", fallback: "no", name: "Norsk"},
    {regions: ["hr"], key: "hr-hr", fallback: "hr", name: "Hrvatski"},
    {regions: ["lv"], key: "lv-lv", fallback: "lv", name: "Latviešu"},
    {regions: ["lt"], key: "lt-lt", fallback: "lt", name: "Lietuvių"},
    {regions: ["hu"], key: "hu-hu", fallback: "hu", name: "Magyar"},
    {regions: ["pl"], key: "pl-pl", fallback: "pl", name: "Polski"},
    {regions: ["pt"], key: "pt-pt", fallback: "pt", name: "Português"},
    {regions: ["ro"], key: "ro-ro", fallback: "ro", name: "Română"},
    {regions: ["ru"], key: "ru-ru", fallback: "ru", name: "Pусский"},
    {regions: ["sk"], key: "sk-sk", fallback: "sk", name: "Slovenčina"},
    {regions: ["fi"], key: "fi-fi", fallback: "fi", name: "Suomi"},
    {regions: ["se"], key: "sv-se", fallback: "sv", name: "Svenska"},
    {regions: ["ge"], key: "ka-ge", fallback: "ka", name: "ქართული"},
    {regions: ["ua"], key: "uk-ua", fallback: "uk", name: "Українська"},
    {regions: ["tw"], key: "zh-tw", fallback: "zh", name: "Chinese"},
];
//! Add RTL language locales (key above)
const rtlLocales: string[] = ["ar-sa"];

export const intlLocaleToDateFnsLocale: Record<string, Locale> = {
    /* eslint-disable @typescript-eslint/naming-convention -- kebab-case is required (en-us etc.) */
    "ar-sa": arSA,
    "az-az": az,
    "cs-cz": cs,
    "de-de": de,
    "en-gb": enGB,
    "en-us": enUS,
    "es-es": es,
    "et-ee": et,
    "fi-fi": fi,
    "fr-fr": fr,
    "hr-hr": hr,
    "hu-hu": hu,
    "ka-ge": ka,
    "lt-lt": lt,
    "lv-lv": lv,
    "nl-nl": nl,
    "no-no": nn,
    "pl-pl": pl,
    "pt-pt": pt,
    "ro-ro": ro,
    "ru-ru": ru,
    "sk-sk": sk,
    "sv-se": sv,
    "uk-ua": uk,
    "zh-tw": zhTW,
    /* eslint-enable @typescript-eslint/naming-convention */
};

const getLocaleToSet = (localeFallback: string, localeFromQuery?: string | null): string => {
    let localeToSet: string | undefined;
    const isLocaleFromQueryValid = localeFromQuery && localeFromQuery?.length >= 2;
    if (isLocaleFromQueryValid) {
        const isLocaleWithCountryCode = localeFromQuery?.length === 5;
        if (isLocaleWithCountryCode && languages.find((lang) => lang.key === localeFromQuery)) {
            localeToSet = localeFromQuery;
        } else {
            const languageFromLocale = localeFromQuery.substring(0, 2);
            const localeFoundByLanguage = languages.find((lang) => lang.fallback === languageFromLocale);
            localeToSet = localeFoundByLanguage?.key;
        }
    }
    if (!localeToSet && languages.find((lang) => lang.key === localeFallback)) {
        // Can be removed once we dont have users using the removed excessive locales
        localeToSet = localeFallback;
    }
    if (!localeToSet) {
        localeToSet = DefaultLocale;
    }
    return localeToSet;
};

const onLocaleSet = (localeSet: string): void => {
    // Its to make HTML elements direction aware (e.g. can do ellpsis on the start of the text)
    if (typeof window !== "undefined" && window.document) {
        const direction = rtlLocales.includes(localeSet) ? "rtl" : "ltr";
        window.document.dir = direction;
    }
    // Setting DateFns locales
    const dateFnsLocale = intlLocaleToDateFnsLocale[localeSet];
    registerLocaleForDatePicker(localeSet, dateFnsLocale);
};

// Need to set locale for initializeTranslationsService instantly.
// If we set default locale and later setLocale with actual locale then 2 translation API calls are made
const getInitialLocale = (currentSelectedLocale: string): string => {
    const urlParams = new URLSearchParams(window.location.search);
    const languageFromQuery = urlParams.get("lang");
    const initLocale = getLocaleToSet(currentSelectedLocale, languageFromQuery);
    return initLocale;
};

export const TranslationService = (
    baseUrl: string,
    crowdinFolder: string,
    defaultLocale: string,
    currentSelectedLocale: string,
    crowdinBackendProject: string,
    filename: string,
    fallbackTranslations: Record<string, TranslationFile>,
) => {
    const translationHttpClient =
        new HttpClientLibrary.HttpClientBoltServer<TranslationNoAuthService.HttpClientConstraints>({
            baseUrl,
        });
    translationHttpClient.init({});
    const translationApiClient: TranslationNoAuthService.ApiClient = new TranslationNoAuthService.ApiClient({
        httpClient: translationHttpClient,
    });

    const initialeLocale = getInitialLocale(currentSelectedLocale);
    onLocaleSet(initialeLocale);

    const {RemoteIntlProvider, setLocale} = initializeTranslationsService({
        folderName: crowdinFolder,
        locale: initialeLocale,
        fallbackLocale: defaultLocale,
        fallbackTranslations,
        TextComponent: React.Fragment,
        apiClient: translationApiClient,
        project: crowdinBackendProject,
        filename,
    });

    const changeAppLocale = async (localeToSet: string) => {
        await setLocale(localeToSet);
        onLocaleSet(localeToSet);
    };

    return {RemoteIntlProvider, changeAppLocale};
};
