import {FC} from "react";

interface ProgressBarProps {
    progress: number;
    progressColor: string;
}

const ProgressBar: FC<ProgressBarProps> = ({progress, progressColor}) => (
    <div className="relative mb-3 flex h-2 w-full items-center">
        <div className="bg-layer-floor-1 h-full w-full rounded-full" />
        <div
            className="absolute h-full rounded-full"
            style={{
                width: `${progress}%`,
                backgroundColor: progressColor,
            }}
        />
    </div>
);

export default ProgressBar;
