import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";

import Icon from "assets/icons/vehicle.svg?react";
import CloseButton from "common/components/CloseButton";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import LoadingSpinner from "common/components/spinner";
import {EventName} from "common/constants/events";
import {vehicleColors} from "common/constants/vehicleColors";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {TranslationKeys} from "config/translations";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {isProvinceSelectionEnabled} from "features/companies/vehicles/config/isProvinceSelectionEnabled";
import {isVehicleTypeSelectionEnabled} from "features/companies/vehicles/config/isVehicleTypeSelectionEnabled";
import {
    isTransportLicenseExpiresVisible,
    isTransportLicenseVisible,
} from "features/companies/vehicles/config/transportLicenseConfig";
import {getDateFormat, getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {CarApplicationService, FleetRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormChange, FormState, useForm} from "@bolteu/kalep-form-react";
import {DatePicker, Grid, Link, SelectOption} from "@bolteu/kalep-react";

import {useCarGroups} from "../hooks/useCarGroups";
import {useProvinces} from "../hooks/useProvinces";

import VehicleType = FleetRegistrationService.CarModelVehicleType;

export interface AddVehiclePageProps {
    submitButtonLabel: string;
    closePage: () => void;
    onVehicleAdded: (carApplication: CarApplicationService.CarApplicationModel) => Promise<void>;
}

interface CarApplicationForm {
    vehicle_type: VehicleType | null;
    manufacturer?: number;
    model_id?: number;
    vin: string | null;
    year?: number;
    reg_number: string;
    color?: string;
    car_transport_licence_number: string | null;
    car_group_id: number | null;
    applicant_comment: string | null;
    seats: number | null;
    registration_province_id?: number;
}

const getVehicleModelsFetchFunction = (api: Api) => api.fleetRegistration.getCarModels({});
const addVehicleApplicationFunction = (api: Api, body: CarApplicationService.AddCarApplicationRequest) =>
    api.carApplication.v2AddCarApplication({}, body);

const AddVehiclePage: FC<AddVehiclePageProps> = ({closePage, onVehicleAdded, submitButtonLabel}) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState?.getFleet();

    const {
        data: vehicleModelsData,
        fetch: fetchVehicleModels,
        status: fetchVehicleModelsStatus,
    } = useFetch(getVehicleModelsFetchFunction);
    const {
        fetch: fetchAddNewVehicle,
        status: addNewVehicleStatus,
        data: addNewVehicleData,
        error: addNewVehicleError,
    } = useFetch(addVehicleApplicationFunction);

    const {carGroups, fetchStatus: fetchCarGroupsStatus} = useCarGroups();
    const {provinces, fetchStatus: fetchProvincesStatus} = useProvinces();

    const shouldShowLoadingSpinner = [fetchVehicleModelsStatus, fetchCarGroupsStatus, fetchProvincesStatus].includes(
        FetchStatus.Loading,
    );
    const isSubmitting = [addNewVehicleStatus].includes(FetchStatus.Loading);

    const defaultFormValues: CarApplicationForm = {
        reg_number: "",
        vehicle_type: null,
        car_group_id: null,
        car_transport_licence_number: null,
        applicant_comment: null,
        vin: null,
        seats: null,
    };

    const {Form, TextField, SelectField, Button, useFieldValue, setData, AutocompleteField} =
        useForm(defaultFormValues);

    const vehicleType = useFieldValue("vehicle_type");
    const manufacturer = useFieldValue("manufacturer");
    const [licenseExpires, setLicenseExpires] = useState<Date | null>(null);

    const onFormChange = useCallback(
        (change: FormChange<CarApplicationForm>) => {
            if (change.event.name === "vehicle_type") {
                setData({manufacturer: undefined, model_id: undefined});
            }
            if (change.event.name === "manufacturer") {
                setData({model_id: undefined});
            }
        },
        [setData],
    );

    const handleLicenseExpires = useCallback<(value: Date | Date[] | null) => void>(
        (date) => {
            if (!Array.isArray(date)) {
                setLicenseExpires(date);
            }
        },
        [setLicenseExpires],
    );

    const onFormSubmit = useCallback(
        async (state: FormState<CarApplicationForm>) => {
            if (fetchAddNewVehicle) {
                fetchAddNewVehicle({
                    model_id: state.data.model_id || 0,
                    year: state.data.year || 0,
                    color: state.data.color ?? "",
                    reg_number: state.data.reg_number,
                    registration_province_id: state.data.registration_province_id,
                    manager: {
                        first_name: accountState?.profile?.firstName ?? "",
                        last_name: accountState?.profile?.lastName ?? "",
                    },
                    ...(state.data.vehicle_type && {vehicle_type: state.data.vehicle_type}),
                    ...(state.data.car_group_id && {car_group_id: state.data.car_group_id}),
                    ...(state.data.car_transport_licence_number && {
                        car_transport_licence_number: state.data.car_transport_licence_number,
                    }),
                    ...(licenseExpires && {
                        car_transport_licence_expires: getIsoDate(licenseExpires),
                    }),
                    ...(state.data.applicant_comment && {applicant_comment: state.data.applicant_comment}),
                });
            }
        },
        [fetchAddNewVehicle, accountState?.profile?.firstName, accountState?.profile?.lastName, licenseExpires],
    );

    const vehicleTypes = useMemo(
        () =>
            Object.values(VehicleType).map(
                (v) =>
                    ({
                        value: v,
                        title: i18n(`auth.app.fleet.add-vehicle.form.${v}` as TranslationKeys),
                    } as SelectOption),
            ),
        [i18n],
    );

    const vehicleManufacturers = useMemo(() => {
        let vehicles = vehicleModelsData?.list;
        if (vehicleType) {
            vehicles = vehicles?.filter((v) => v.models.some((m) => m.vehicle_type === vehicleType));
        }
        return (
            vehicles?.map(
                (v) =>
                    ({
                        value: v.id,
                        title: v.name,
                    } as SelectOption),
            ) ?? []
        );
    }, [vehicleModelsData?.list, vehicleType]);

    const vehicleModels = useMemo(() => {
        let models = vehicleModelsData?.list.find((v) => v.id === manufacturer)?.models;
        if (vehicleType) {
            models = models?.filter((m) => m.vehicle_type === vehicleType);
        }
        return (
            models?.map(
                (m) =>
                    ({
                        value: m.id,
                        title: m.name,
                    } as SelectOption),
            ) ?? []
        );
    }, [manufacturer, vehicleModelsData?.list, vehicleType]);

    const validationErrors = useMemo(() => {
        const errors = addNewVehicleError?.validationErrors;

        if (errors.some((x) => x.property === "model_id") && !vehicleModels.length) {
            return [...errors, {property: "manufacturer", error: i18n("api.error.validation.model.required")}];
        }

        return errors;
    }, [addNewVehicleError, vehicleModels, i18n]);

    const getVehicleModelHelperText = useCallback(() => {
        const email = `mailto:${i18n("auth.app.fleet.add-vehicle.form.model-not-in-list-email")}`;
        return (
            <>
                <span>{`${i18n("auth.app.fleet.add-vehicle.form.model_helper_text")} `}</span>
                <Link href={email}>{i18n("auth.app.fleet.add-vehicle.form.model-not-in-list-email")}</Link>
            </>
        );
    }, [i18n]);

    const vehicleYears = useMemo(() => {
        const vehicleMaxAge = vehicleModelsData?.car_max_age;
        if (vehicleMaxAge) {
            let currentYear = new Date().getFullYear();
            const years = [];
            const end = currentYear - vehicleMaxAge;

            while (currentYear >= end) {
                years.push({value: currentYear});
                currentYear--;
            }
            return years as SelectOption[];
        }
        return [];
    }, [vehicleModelsData?.car_max_age]);

    const colors = () =>
        vehicleColors.map(
            (c: string) => ({value: c, title: i18n(`vehicle.color.${c}` as TranslationKeys)} as SelectOption),
        );

    useEffect(() => {
        if (fetchVehicleModels && fleet) {
            fetchVehicleModels({});
        }
    }, [fetchVehicleModels, fleet]);

    useEffect(() => {
        if (carGroups.length === 1) {
            setData({car_group_id: Number(carGroups[0].value)});
        }
    }, [setData, carGroups]);

    useEffect(() => {
        const processDone = async () => {
            if (addNewVehicleStatus === FetchStatus.Success) {
                trackEvent(EventName.AddNewVehicleSubmitSuccessful);
                await onVehicleAdded(addNewVehicleData);
            } else if (addNewVehicleStatus === FetchStatus.Error) {
                trackEvent(EventName.AddNewVehicleSubmissionFailed);
            }
        };

        processDone();
    }, [trackEvent, addNewVehicleStatus, onVehicleAdded, addNewVehicleData]);

    if (shouldShowLoadingSpinner) {
        return <LoadingSpinner show={shouldShowLoadingSpinner} size="lg" showLoadingText parentClassName="mt-9" />;
    }

    return (
        <Form onSubmit={onFormSubmit} onChange={onFormChange} validatorResults={validationErrors}>
            <div className="m-auto my-10 flex w-[484px] max-w-3xl flex-col justify-center">
                <div className="flex justify-end">
                    <CloseButton onClick={closePage} />
                </div>
                <Icon />
                <div className="my-6 flex flex-col gap-3">
                    <ResponsiveHeader
                        type={ResponsiveHeaderType.InnerPage}
                        text={i18n("auth.app.fleet.add-vehicle.add_vehicle")}
                    />
                </div>
                <Grid columns={1}>
                    {fleet && isVehicleTypeSelectionEnabled(fleet.country) && (
                        <SelectField
                            name="vehicle_type"
                            label={i18n("auth.app.fleet.add-vehicle.form.vehicle_type")}
                            placeholder={i18n("auth.app.fleet.add-vehicle.form.vehicle_type")}
                            options={vehicleTypes}
                            required
                            fullWidth
                        />
                    )}
                    <AutocompleteField
                        name="manufacturer"
                        label={i18n("auth.app.fleet.add-vehicle.manufacturer")}
                        placeholder={i18n("auth.app.fleet.add-vehicle.manufacturer")}
                        options={vehicleManufacturers}
                        required
                    />
                    {!!manufacturer && (
                        <AutocompleteField
                            name="model_id"
                            label={i18n("auth.app.fleet.add-vehicle.form.model")}
                            placeholder={i18n("auth.app.fleet.add-vehicle.form.model")}
                            options={vehicleModels}
                            helperText={getVehicleModelHelperText()}
                            required
                        />
                    )}
                    <SelectField
                        name="year"
                        label={i18n("auth.app.fleet.add-vehicle.form.year")}
                        placeholder={i18n("auth.app.fleet.add-vehicle.form.year")}
                        options={vehicleYears}
                        required
                        fullWidth
                    />
                    <TextField
                        name="reg_number"
                        type="text"
                        label={i18n("auth.app.fleet.add-vehicle.license_plate")}
                        placeholder={i18n("auth.app.fleet.add-vehicle.license_plate")}
                        required
                        fullWidth
                    />
                    <AutocompleteField
                        name="color"
                        label={i18n("auth.app.fleet.add-vehicle.form.color")}
                        placeholder={i18n("auth.app.fleet.add-vehicle.form.color")}
                        options={colors()}
                        required
                    />
                    {carGroups.length > 1 && (
                        <AutocompleteField
                            name="car_group_id"
                            label={i18n("auth.app.fleet.add-vehicle.form.car_group")}
                            placeholder={i18n("auth.app.fleet.add-vehicle.form.car_group")}
                            options={carGroups}
                            required
                        />
                    )}
                    {fleet && isProvinceSelectionEnabled(fleet.country) && (
                        <SelectField
                            name="registration_province_id"
                            label={i18n("auth.app.fleet.add-vehicle.form.province")}
                            placeholder={i18n("auth.app.fleet.add-vehicle.form.province")}
                            options={provinces}
                            required
                            fullWidth
                        />
                    )}
                    {fleet && isTransportLicenseVisible(fleet) && (
                        <TextField
                            name="car_transport_licence_number"
                            type="text"
                            label={i18n("auth.app.fleet.add-vehicle.transport_license_number")}
                            placeholder={i18n("auth.app.fleet.add-vehicle.transport_license_number")}
                            fullWidth
                        />
                    )}
                    {fleet && isTransportLicenseExpiresVisible(fleet) && (
                        // DateField from kalep-react-form is not working due to ReactIntl
                        <DatePicker
                            name="car_transport_licence_expires"
                            label={i18n("auth.app.fleet.add-vehicle.transport_license_expires")}
                            value={licenseExpires}
                            onChange={handleLicenseExpires}
                            format={getDateFormat(intl)}
                            minDate={new Date()}
                            locale={intl.locale}
                            fullWidth
                        />
                    )}
                    <TextField
                        name="applicant_comment"
                        type="text"
                        label={i18n("auth.app.fleet.add-vehicle.form.comment")}
                        placeholder={i18n("auth.app.fleet.add-vehicle.form.comment")}
                        fullWidth
                    />
                </Grid>
                <div className="mt-6">
                    <Button submit variant="primary" loading={isSubmitting} disabled={isSubmitting}>
                        {submitButtonLabel}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default AddVehiclePage;
