import {useCallback} from "react";
import {NavLink, useLocation} from "react-router-dom";

import {SEARCH_PARAM_TAB_NAME} from "common/components/tab/constants";

import {Typography} from "@bolteu/kalep-react";

export const SubMenuLink = ({title, route, onClick}: {title: string; route: string; onClick?: () => void}) => {
    const {search} = useLocation();
    const tabSearchParam = new URLSearchParams(search).get("tab");

    const linkClassname: (props: {isActive: boolean; isPending: boolean}) => string | undefined = useCallback(
        ({isActive}) => {
            const defaultClassNames = "p-2 rounded-md hover:bg-neutral-secondary";
            const activeBgClassName = "bg-action-secondary";
            // Using tab=SEARCH_PARAM because route could include the same tab name. For example drivers
            if (
                (tabSearchParam && route.includes(`${SEARCH_PARAM_TAB_NAME}=${tabSearchParam}`)) ||
                (isActive && !tabSearchParam)
            ) {
                return `${defaultClassNames} ${activeBgClassName}`;
            }
            return defaultClassNames;
        },
        [route, tabSearchParam],
    );

    return (
        <NavLink to={route} className={linkClassname} onClick={onClick} end>
            <Typography fontSize="text-md">{title}</Typography>
        </NavLink>
    );
};
