import React, {useCallback, useContext, useEffect, useState} from "react";

import {useAuthentication} from "common/hooks/useAuthentication";
import {AccountContextProvider, SelectedCompany} from "features/account/accountStateProvider";
import {ApplicationContextProvider} from "features/app/appStateProvider";

import {FleetOwnerAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {AppApiClient} from "./apiClients/fleetOwnerPortalApi";
import {AppJwtAuthStrategy} from "./apiClients/strategies/AppJwtAuthStrategy";
import {requestNewAccessToken} from "./authService";

import CompanyIdentity = FleetOwnerAuthService.CompanyIdentity;

export type Api = AppApiClient;

const fleetOwnerApi = new AppApiClient();

const ApiContextProvider = React.createContext<AppApiClient | null>(null);

const isAccessTokenForCorrectCompany = (
    selectedCompany: SelectedCompany | null,
    accessTokenCompany: CompanyIdentity | null,
) =>
    (!selectedCompany && !accessTokenCompany) ||
    (selectedCompany?.company?.id === accessTokenCompany?.company_id &&
        selectedCompany?.type === accessTokenCompany?.company_type);

const ApiProvider = ({children}: {children: React.ReactNode}) => {
    const accountState = useContext(AccountContextProvider);
    const appState = useContext(ApplicationContextProvider);

    const {makeLogout} = useAuthentication();

    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [accessTokenCompany, setAccessTokenCompany] = useState<CompanyIdentity | null>(null);
    const [isAccessTokenError, setIsAccessTokenError] = useState(false);

    let apiValue: Api | null = null;

    useEffect(() => {
        async function initAccessToken() {
            if (!appState.refreshToken) {
                setAccessToken(null);
                return;
            }

            const initialAccessTokenPerCompanySelection = await requestNewAccessToken(
                appState.refreshToken,
                accountState.selectedCompany?.company?.id,
                accountState.selectedCompany?.type,
            ).catch(() => {
                setIsAccessTokenError(true);
                return null;
            });
            setAccessToken(initialAccessTokenPerCompanySelection);
            if (accountState.selectedCompany) {
                setAccessTokenCompany({
                    company_id: accountState.selectedCompany?.company?.id,
                    company_type: accountState.selectedCompany?.type,
                });
            } else {
                setAccessTokenCompany(null);
            }
        }
        initAccessToken();
    }, [appState.refreshToken, accountState.selectedCompany]);

    useEffect(() => {
        if (isAccessTokenError) {
            setIsAccessTokenError(false);
            makeLogout(false);
        }
    }, [isAccessTokenError, makeLogout]);

    const getAccessToken = useCallback(() => accessToken, [accessToken]);

    const authTokenParseDidThrow = useCallback(async () => {
        await makeLogout(false);
    }, [makeLogout]);

    const updateAccessToken = useCallback(async () => {
        if (!appState.refreshToken) {
            setIsAccessTokenError(true);
            return Promise.reject(new Error("No refresh token provided"));
        }

        const token = await requestNewAccessToken(
            appState.refreshToken,
            accountState.selectedCompany?.company?.id,
            accountState.selectedCompany?.type,
        ).catch((e) => {
            setIsAccessTokenError(true);
            return Promise.reject(e);
        });

        setAccessToken(token);

        return Promise.resolve(token);
    }, [
        appState.refreshToken,
        setAccessToken,
        accountState.selectedCompany?.company?.id,
        accountState.selectedCompany?.type,
    ]);

    if (accessToken && isAccessTokenForCorrectCompany(accountState.selectedCompany, accessTokenCompany)) {
        const fleet = accountState.getFleet();
        const orderShardId = fleet?.order_shard_id;

        const authStrategy = new AppJwtAuthStrategy(getAccessToken, updateAccessToken, authTokenParseDidThrow);
        fleetOwnerApi.init(authStrategy, fleet?.id, accountState.profile?.id, orderShardId);

        apiValue = fleetOwnerApi;
    }
    return <ApiContextProvider.Provider value={apiValue}>{children}</ApiContextProvider.Provider>;
};

export {ApiProvider, ApiContextProvider};
