import {useContext} from "react";

import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {FeaturesContextProvider} from "FeaturesProvider";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {InvoiceTab} from "..";

import BillingModel = FleetOwnerReportingService.BillingModel;

export function useTabOptions(billingModel?: BillingModel): Array<Tab<InvoiceTab>> {
    const features = useContext(FeaturesContextProvider);
    const {i18n} = useI18n();

    const tabs: Array<Tab<InvoiceTab>> = [];

    if (features?.rider_invoices) {
        tabs.push({
            id: InvoiceTab.FleetToRider,
            title: i18n("auth.app.invoices.fleet-to-rider.tab_title"),
            trackingEventTabName: TabNameEvent.InvoicesPageFleetToRider,
        });
    }

    const boltInvoicesTitle =
        billingModel === BillingModel.INTERMEDIATE
            ? i18n(`auth.app.invoices.bolt-to-fleet.tab_title`)
            : i18n(`auth.app.invoices.bolt-to-fleet.tab_title_resale`);
    tabs.push({
        id: InvoiceTab.BoltInvoices,
        title: boltInvoicesTitle,
        trackingEventTabName: TabNameEvent.InvoicesPageBoltToFleet,
    });

    if (features?.compensation_invoices) {
        tabs.push({
            id: InvoiceTab.Compensations,
            title: i18n("auth.app.invoices.compensations_v2.tab_title"),
            trackingEventTabName: TabNameEvent.InvoicesPageCompensations,
        });
    }

    tabs.push({
        id: InvoiceTab.Payouts,
        title: i18n("auth.app.reports.payouts.title"),
        trackingEventTabName: TabNameEvent.InvoicesPagePayouts,
    });

    return tabs;
}
