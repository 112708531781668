import {FC, useContext} from "react";

import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {AccountContextProvider} from "../../../features/account/accountStateProvider";
import Header, {HeaderSize} from "./Header";

export enum ResponsiveHeaderType {
    MainPage = "MainPage",
    InnerPage = "InnerPage",
}

export interface ResponsiveHeaderProps {
    type: ResponsiveHeaderType;
    text: string;
    disabled?: boolean;
}

const getHeaderSize = (
    type: ResponsiveHeaderType,
    isMobileView: boolean,
    isNewNavigationEnabled: boolean,
): HeaderSize => {
    switch (type) {
        case ResponsiveHeaderType.MainPage:
            return isMobileView || isNewNavigationEnabled ? HeaderSize.Medium : HeaderSize.Large;
        case ResponsiveHeaderType.InnerPage:
            return isMobileView ? HeaderSize.Small : HeaderSize.Medium;
        default:
            return HeaderSize.Large;
    }
};

const ResponsiveHeader: FC<ResponsiveHeaderProps> = ({type, text, disabled}: ResponsiveHeaderProps) => {
    const viewport = useTailwindViewport();
    const accountState = useContext(AccountContextProvider);

    const isNewNavigationEnabled = accountState.profile?.isNewNavigationEnabled ?? false;
    const isMobileView = viewport === "sm";
    const headerSize = getHeaderSize(type, isMobileView, isNewNavigationEnabled);

    return <Header disabled={disabled} size={headerSize} text={text} />;
};

export {ResponsiveHeader};
