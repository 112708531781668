import {useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";
import {FormValidationError} from "@fleet/common/errors/formValidationError";
import {ValidationError} from "@fleet/common/errors/validationError";
import {FormValidatorResult, ValidatorResult} from "@fleet/common/hooks/useFetch";

interface ValidatorMessage {
    property?: string | number;
    message: string;
}

export const useValidationErrorMessages = () => {
    const {i18n} = useI18n();

    const getPropertyValidationMessage = useCallback(
        (message: ValidatorMessage) => {
            if (message.property) {
                return i18n(
                    `api.error.validation.${message.property}.${message.message}` as TranslationKeys,
                    undefined,
                    "api.default_error",
                );
            }
            return null;
        },
        [i18n],
    );

    const getValidationMessage = useCallback(
        (message: ValidatorMessage) =>
            i18n(`api.error.validation.${message.message}` as TranslationKeys, undefined, "api.default_error"),
        [i18n],
    );

    const getValidationErrorMessages = useCallback(
        (error: ValidationError) =>
            error.validatorResults.map((validatorResult: ValidatorResult) => {
                const validationMessage = {property: validatorResult.property, message: "invalid"};

                if (validatorResult.error.includes("required")) {
                    validationMessage.message = "required";
                }

                if (validationMessage.message !== "invalid" && !getPropertyValidationMessage(validationMessage)) {
                    validationMessage.message = "invalid";
                }

                // Current workaround for phone
                if (
                    ["phone", "passenger_phone"].includes(String(validationMessage.property)) &&
                    validationMessage.message === "invalid"
                ) {
                    const property = validationMessage.property === "passenger_phone" ? "passenger_phone" : "phone";
                    return {
                        property,
                        error:
                            getPropertyValidationMessage({property: "phone_number", message: "invalid"}) ||
                            validatorResult.error,
                    };
                }

                return {
                    property: validatorResult.property,
                    error: getPropertyValidationMessage(validationMessage) || validatorResult.error,
                };
            }),
        [getPropertyValidationMessage],
    );

    const getCarFormValidationErrorMessages = useCallback(
        (error: FormValidationError) =>
            error.errors.map((validatorResult: FormValidatorResult) => {
                const validationMessage = {property: validatorResult.attribute, message: "invalid"};

                if (validatorResult.message.includes("required")) {
                    validationMessage.message = "required";
                }

                if (validationMessage.message !== "invalid" && !getPropertyValidationMessage(validationMessage)) {
                    validationMessage.message = "invalid";
                }

                return {
                    property: validatorResult.attribute,
                    error: getPropertyValidationMessage(validationMessage) || validatorResult.message,
                };
            }),
        [getPropertyValidationMessage],
    );

    const getDynamicFormValidationErrorMessages = useCallback(
        (error: FormValidationError) =>
            error.errors.map((validatorResult: FormValidatorResult) => ({
                property: validatorResult.attribute,
                error: getValidationMessage(validatorResult),
            })),
        [getValidationMessage],
    );

    return {getValidationErrorMessages, getCarFormValidationErrorMessages, getDynamicFormValidationErrorMessages};
};
