import {CommonLocalStorageService} from "@fleet/common/services/CommonLocalStorageService";

import {LocaleCode} from "./authService";

const portalPrefix = "FOP_";
const legacyPortalPrefix = "taxifyFleetOwnerPortal_";

class LocalStorageService extends CommonLocalStorageService {
    private TableColumnSettingsKey = "table_column_settings";

    private FixedTooltipSettingsKey = "fixed_tooltip_settings";

    private VehicleAccessSettingsKey = "vehicle_access_settings";

    private DriverCategoriesSettingsKey = "driver_categories_settings";

    constructor(keyPrefix: string) {
        super(keyPrefix);
    }

    // Arrow function is used to avoid binding issues in BrowserInfo
    public setLegacyItem = (key: string, value: string) => localStorage.setItem(`${legacyPortalPrefix}${key}`, value);

    // Arrow function is used to avoid binding issues in BrowserInfo
    public getLegacyItem = (key: string) => localStorage.getItem(`${legacyPortalPrefix}${key}`);

    public removeLegacyItem(key: string) {
        return localStorage.removeItem(`${legacyPortalPrefix}${key}`);
    }

    public removeLegacyItems(keys: string[]) {
        return keys.forEach((key) => localStorage.removeItem(`${legacyPortalPrefix}${key}`));
    }

    public getLanguageWithDefaultLocale(defaultLocale = "en-us"): string {
        return this.getLegacyItem(LocaleCode) ?? defaultLocale;
    }

    public getLanguageWithoutDefaultLocale(): string | null {
        return this.getLegacyItem(LocaleCode);
    }

    public getTableSettingsKey(tableId: string) {
        return `${this.TableColumnSettingsKey}_${tableId}`;
    }

    public getFixedTooltipSettingsKey(featureId: string) {
        return `${this.FixedTooltipSettingsKey}_${featureId}`;
    }

    public getVehicleAccessSettingsKey() {
        return this.VehicleAccessSettingsKey;
    }

    public getDriverCategoriesSettingsKey() {
        return this.DriverCategoriesSettingsKey;
    }
}

export const localStorageService = new LocalStorageService(portalPrefix);
