import {useI18n} from "common/hooks/useI18n";

import {Offer as OfferIcon} from "@bolteu/kalep-react-icons";

const EmptyCampaignsList = () => {
    const {i18n} = useI18n();

    const title = i18n("auth.app.fleet.campaigns.list.no_campaigns");

    return (
        <div className="mt-24 flex flex-col items-center justify-center gap-2 md:mt-20">
            <OfferIcon className="text-tertiary mb-2 h-16 w-16" />
            <h5 className="max-w-xs text-center text-xl font-semibold">{title}</h5>
        </div>
    );
};

export default EmptyCampaignsList;
