import {useCallback, useContext, useEffect, useRef, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {ApiDriverTableSortedBy} from "common/components/table/ApiDrivenTable/types";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, OngoingDay, Period} from "common/services/period";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import CarRentalsPage from "features/companies/car-rental-payment";
import {UseFetch} from "@fleet/common/hooks/useFetch";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {
    CarApplicationService,
    FleetOwnerPortalService,
    VehicleMarketplaceService,
} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {FeaturesContextProvider} from "../../../../FeaturesProvider";
import {AddVehiclePageContextProvider, AddVehiclePageProvider} from "./components/AddVehiclePageProvider";
import ApplicationsComponent from "./components/ApplicationsTable";
import RentingOutTable from "./components/RentingOutTable";
import {defaultVehicleFilters, VehicleFilter} from "./components/VehiclesFilter";
import VehiclesPageHeader from "./components/VehiclesPageHeader";
import VehiclesTableV2 from "./components/VehiclesTableV2";
import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";

const MAX_LIMIT = 25;

export enum VehicleTabs {
    Vehicles = "vehicles",
    Applications = "applications",
    RentingOut = "renting_out",
    RentalPayments = "rental_payments",
}

const getVehicleColumnsFetchFunction = (api: Api, body: FleetOwnerPortalService.GetCarsRequest) =>
    api.fleetOwnerPortal.getVehicles(body);

const getCarApplicationsFetchFunction = (api: Api) => api.carApplication.v2GetCarApplications({});

const getSearchCompanyListingsFunction = (api: Api, body: VehicleMarketplaceService.SearchCompanyListingsRequest) =>
    api.vehicleMarketplace.vehicleListingSearch(body);

interface VehiclesPageProps {
    useCarApplicationsFetch: UseFetch<unknown, CarApplicationService.GetCarApplicationsResponse>;
}

const VehiclesPage = ({useCarApplicationsFetch}: VehiclesPageProps) => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const features = useContext(FeaturesContextProvider);
    const {isAddVehiclePageOpen, openAddVehiclePage} = useContext(AddVehiclePageContextProvider);
    const sorting = useRef<ApiDriverTableSortedBy | undefined>(undefined);

    const [vehiclesPageNum, setVehiclesPageNum] = useState(0);
    const [rentingOutPageNum, setRentingOutPageNum] = useState(0);
    const [vehiclesTableSearchValue, setVehiclesTableSearchValue] = useState("");
    const [rentingOutTableSearchValue, setRentingOutTableSearchValue] = useState("");
    const [vehiclesFilters, setVehiclesFilters] = useState<VehicleFilter>(defaultVehicleFilters);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>({period: Period.ONGOING_DAY} as OngoingDay);
    const [vehicleListingsFilters, setVehicleListingsFilters] = useState<VehicleMarketplaceService.ListingStatus[]>([]);

    const {
        fetch: searchCompanyListings,
        data: searchCompanyListingsData,
        status: searchCompanyListingsStatus,
    } = useFetch(getSearchCompanyListingsFunction);

    const {
        data: vehicleColumns,
        fetch: vehicleColumnsFetch,
        status: getVehicleColumnsStatus,
    } = useFetch(getVehicleColumnsFetchFunction);

    const {data: applications, fetch: carApplicationsFetch, status: applicationsStatus} = useCarApplicationsFetch;

    const vehiclesCount = vehicleColumns?.total_rows ?? 0;
    const applicationsCount = applications?.list.length ?? 0;
    const rentingOutCount = searchCompanyListingsData?.count ?? 0;
    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(getVehicleColumnsStatus);
    const tabs = useTabSelectorOptions(vehiclesCount, applicationsCount, rentingOutCount, features);

    const shouldShowApplications = !vehiclesCount && !vehiclesTableSearchValue && !rentingOutTableSearchValue;
    const {activeTab, TabSelector} = useTabs(
        tabs,
        shouldShowApplications ? VehicleTabs.Applications : VehicleTabs.Vehicles,
    );

    const calculateThirtyDaysAgo = () => {
        const today = new Date();
        today.setDate(today.getDate() - 30);
        return today;
    };

    const getRequestDates = useCallback(
        (period: ApiPeriod) => {
            if (!features?.vehicle_engagement_data) {
                return {start: calculateThirtyDaysAgo(), end: new Date()};
            }

            return period.period === Period.CUSTOM
                ? {start: period.startDate, end: period.endDate}
                : apiPeriodToDates(period);
        },
        [features?.vehicle_engagement_data],
    );

    const getVehiclesColumnData = useCallback(
        ({
            newPageNum,
            newSortingField,
            newSortingDirection,
        }: {
            newPageNum: number;
            newSortingField?: string;
            newSortingDirection?: FleetOwnerPortalService.SortingDirection;
        }) => {
            if (!vehicleColumnsFetch) {
                return;
            }
            const {start, end} = getRequestDates(selectedPeriod);
            const offset = newPageNum * MAX_LIMIT;
            vehicleColumnsFetch({
                offset,
                limit: MAX_LIMIT,
                start_date: getIsoDate(start),
                end_date: getIsoDate(end),
                portal_status: vehiclesFilters.status ?? undefined,
                sorting_field: newSortingField as FleetOwnerPortalService.EngagementDataVehicleKey,
                sorting_direction: newSortingDirection,
                ...(vehiclesTableSearchValue && {search: vehiclesTableSearchValue}),
            });
        },
        [vehicleColumnsFetch, getRequestDates, selectedPeriod, vehiclesFilters, vehiclesTableSearchValue],
    );

    const onVehicleListingsFiltersApplied = useCallback(
        (statuses: VehicleMarketplaceService.ListingStatus[] | undefined) => {
            setVehicleListingsFilters(statuses ?? []);
        },
        [],
    );

    useEffect(() => {
        if (features?.vehicle_engagement_data !== undefined) {
            getVehiclesColumnData({
                newPageNum: 0,
                newSortingDirection: sorting.current?.direction,
                newSortingField: sorting.current?.field,
            });
        }
    }, [features?.vehicle_engagement_data, getVehiclesColumnData]);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.vehicles.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        if (carApplicationsFetch && fleet) {
            carApplicationsFetch({});
        }
    }, [carApplicationsFetch, fleet]);

    useEffect(() => {
        if (searchCompanyListings && features?.vehicle_marketplace) {
            searchCompanyListings({
                query: rentingOutTableSearchValue,
                page: rentingOutPageNum + 1,
                limit: MAX_LIMIT,
                status: vehicleListingsFilters,
            });
        }
    }, [
        searchCompanyListings,
        rentingOutTableSearchValue,
        rentingOutPageNum,
        vehicleListingsFilters,
        features?.vehicle_marketplace,
    ]);

    useEffect(() => {
        sorting.current = vehicleColumns?.sorted_by;
    }, [vehicleColumns?.sorted_by]);

    const onSortingChange = useCallback(
        (newSorting?: ApiDriverTableSortedBy) => {
            sorting.current = newSorting;
            setVehiclesPageNum(0);
            getVehiclesColumnData({
                newPageNum: 0,
                newSortingDirection: newSorting?.direction,
                newSortingField: newSorting?.field,
            });
        },
        [getVehiclesColumnData],
    );

    const onPageChange = useCallback(
        (newPageNum: number) => {
            setVehiclesPageNum(newPageNum);
            getVehiclesColumnData({
                newPageNum,
                newSortingDirection: sorting.current?.direction,
                newSortingField: sorting.current?.field,
            });
        },
        [getVehiclesColumnData],
    );

    if (getVehicleColumnsStatus === FetchStatus.Error || applicationsStatus === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    if (isAddVehiclePageOpen) {
        return null;
    }

    return (
        <Page>
            <VehiclesPageHeader openAddVehiclePage={openAddVehiclePage} tabSelectorComponent={TabSelector} />
            {activeTab === VehicleTabs.Vehicles && (
                <VehiclesTableV2
                    columnsResponse={vehicleColumns}
                    vehiclesCount={vehiclesCount}
                    currentPage={vehiclesPageNum}
                    pageSize={MAX_LIMIT}
                    onPageChange={onPageChange}
                    filters={vehiclesFilters}
                    onPeriodChange={setSelectedPeriod}
                    onSortingChange={onSortingChange}
                    searchValue={vehiclesTableSearchValue}
                    onSearchChange={setVehiclesTableSearchValue}
                    openAddVehiclePage={openAddVehiclePage}
                    selectedPeriod={selectedPeriod}
                    setFilters={setVehiclesFilters}
                    isLoading={isLoading}
                />
            )}
            {activeTab === VehicleTabs.Applications && (
                <ApplicationsComponent applications={applications?.list || []} />
            )}
            {activeTab === VehicleTabs.RentingOut && features?.vehicle_marketplace && (
                <RentingOutTable
                    onFiltersApplied={onVehicleListingsFiltersApplied}
                    searchCompanyListingsData={searchCompanyListingsData}
                    searchValue={rentingOutTableSearchValue}
                    onSearchChange={setRentingOutTableSearchValue}
                    currentPage={rentingOutPageNum}
                    pageSize={MAX_LIMIT}
                    onPageChange={setRentingOutPageNum}
                    isLoading={searchCompanyListingsStatus === FetchStatus.Loading}
                    totalCount={rentingOutCount}
                />
            )}
            {activeTab === VehicleTabs.RentalPayments && <CarRentalsPage />}
        </Page>
    );
};

const VehiclesPageWithProvider = () => {
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const useCarApplicationsFetch = useFetch(getCarApplicationsFetchFunction);

    const {fetch: carApplicationsFetch} = useCarApplicationsFetch;

    const onVehicleAdded = useCallback(async () => {
        if (carApplicationsFetch && fleet) {
            return carApplicationsFetch({});
        }
        return Promise.resolve();
    }, [carApplicationsFetch, fleet]);

    return (
        <AddVehiclePageProvider onVehicleAdded={onVehicleAdded}>
            <VehiclesPage useCarApplicationsFetch={useCarApplicationsFetch} />
        </AddVehiclePageProvider>
    );
};

export default VehiclesPageWithProvider;
