import {useCallback, useEffect, useMemo, useState} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Drawer, IconButton, SelectOption} from "@bolteu/kalep-react";
import {Bin, Filters} from "@bolteu/kalep-react-icons";

import CarStatus = FleetOwnerPortalService.EntityPortalStatus;

export interface VehicleFilter {
    status: CarStatus | null;
}

export const defaultVehicleFilters: VehicleFilter = {
    status: null,
};

export const isSelectedFilterDefault = (selectedStatus: CarStatus | undefined | null) =>
    selectedStatus === defaultVehicleFilters.status;

export interface VehiclesFilterProps {
    filters: VehicleFilter;
    setFilters: (filters: VehicleFilter) => void;
}

const VehiclesFilter = ({filters, setFilters}: VehiclesFilterProps) => {
    const {i18n} = useI18n();
    const [isShowDialog, setShowDialog] = useState(false);

    const {Form, Button, setData, SelectField, useFieldValue} = useForm(filters);

    const selectedStatus = useFieldValue("status");

    useEffect(() => {
        setData(filters);
    }, [setData, filters]);

    const onEditClick = useCallback(() => {
        setShowDialog(true);
    }, [setShowDialog]);

    const onCloseClick = useCallback(() => {
        setShowDialog(false);
    }, [setShowDialog]);

    const handleClearFiltersButtonClick = useCallback(() => {
        setData(defaultVehicleFilters);
    }, [setData]);

    const defaultOption = useMemo(
        () => ({
            value: null,
            title: i18n("common.all"),
        }),
        [i18n],
    );

    const statusSelectOptions: SelectOption[] = useMemo(
        () => [
            defaultOption,
            {value: CarStatus.Active, title: i18n(`auth.app.fleet.engagement-dashboard.tooltip.active`)},
            {value: CarStatus.Blocked, title: i18n(`auth.app.fleet.engagement-dashboard.tooltip.blocked`)},
            {value: CarStatus.Deactivated, title: i18n(`auth.app.fleet.engagement-dashboard.tooltip.deactivated`)},
        ],
        [i18n, defaultOption],
    );

    const handleFormSubmit = useCallback(
        async ({data: formData}: FormState<VehicleFilter>) => {
            setFilters(formData);
            setShowDialog(false);
        },
        [setFilters],
    );

    const isClearFiltersButtonShown = useMemo(() => !isSelectedFilterDefault(selectedStatus), [selectedStatus]);

    return (
        <div>
            <Form onSubmit={handleFormSubmit}>
                <ResponsiveDrawer
                    onRequestClose={onCloseClick}
                    isOpen={isShowDialog}
                    title={i18n("auth.app.fleet.campaigns.filters.title")}
                >
                    <Drawer.Content>
                        <div className="flex flex-col gap-4">
                            <SelectField
                                name="status"
                                label={i18n("common.status")}
                                placeholder={i18n("common.all")}
                                options={statusSelectOptions}
                            />
                        </div>
                    </Drawer.Content>
                    <Drawer.Footer>
                        <Button submit>{i18n("common.apply")}</Button>
                        <Button variant="secondary" onClick={onCloseClick}>
                            {i18n("auth.app.company-settings.cancel")}
                        </Button>
                        {isClearFiltersButtonShown && (
                            <Button variant="secondary" onClick={handleClearFiltersButtonClick} startIcon={<Bin />}>
                                {i18n("common.filters.clear_filters")}
                            </Button>
                        )}
                    </Drawer.Footer>
                </ResponsiveDrawer>
            </Form>
            <IconButton
                icon={<Filters />}
                aria-label="Filter cars"
                onClick={onEditClick}
                variant="filled"
                size="md"
                shape="square"
                overrideClassName="flex"
            />
        </div>
    );
};

export default VehiclesFilter;
