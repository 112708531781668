import {useIntl} from "react-intl";

import {formatNumber} from "@fleet/common/utils/numberFormatUtils";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";
import {ArrowDown, ArrowUp} from "@bolteu/kalep-react-icons";

import {AlignHorizontal} from "../types";

import NumberType = FleetOwnerPortalService.NumberType;

interface Props {
    value: number;
    unit?: string;
    numberType: NumberType;
    isSubCell?: boolean;
    alignHorizontal?: AlignHorizontal;
    trendWithBehavior?: FleetOwnerPortalService.TrendBehavior;
}

export const NumberCell = ({
    unit,
    alignHorizontal,
    numberType,
    value,
    isSubCell,
    trendWithBehavior,
}: Props): React.ReactElement => {
    const intl = useIntl();
    const options = numberType === NumberType.INTEGER ? {minFractionDigits: 0, maxFractionDigits: 0} : {};
    const formattedValue = formatNumber(intl, value, options);

    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;

    const horizontalAlign = alignHorizontal === AlignHorizontal.RIGHT ? "justify-end" : "justify-start";

    if (!value) {
        return (
            <Typography variant="body-tabular-s-regular" color="tertiary" fontSize={fontSize}>
                {formattedValue}
            </Typography>
        );
    }

    const TrendIcon = () => {
        if (!trendWithBehavior || value === 0) {
            return null;
        }

        let trendColorClass = "";
        switch (trendWithBehavior) {
            case FleetOwnerPortalService.TrendBehavior.POSITIVE_IS_SUCCESS:
                trendColorClass = value > 0 ? "text-action-primary" : "text-danger-primary";
                break;
            case FleetOwnerPortalService.TrendBehavior.POSITIVE_IS_DANGER:
                trendColorClass = value < 0 ? "text-action-primary" : "text-danger-primary";
                break;
            default:
                UnreachableCode.never(trendWithBehavior);
        }

        if (value > 0) {
            return (
                <div className={trendColorClass}>
                    <ArrowUp className="pb-1" width={16} height={16} />
                </div>
            );
        }

        return (
            <div className={trendColorClass}>
                <ArrowDown className="pb-1" width={16} height={16} />
            </div>
        );
    };

    return (
        <div className={`flex w-full items-baseline ${horizontalAlign}`}>
            <TrendIcon />
            <div className=" pr-1">
                <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                    {formattedValue}
                </Typography>
            </div>
            <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                {unit}
            </Typography>
        </div>
    );
};
