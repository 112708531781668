import {MouseEvent, useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {TextButton} from "@fleet/common/components/TextButton";

import {MenuItem} from "@bolteu/kalep-react";

const Footer = () => {
    const {i18n} = useI18n();
    const navigate = useNavigate();

    const navigateToNotificationsView = useCallback(
        (onClick: ({detail}: {detail: number}) => void) => (event: MouseEvent) => {
            navigate(FleetRoute.NOTIFICATIONS, {state: {exitToPreviousPage: true}});
            onClick({detail: event.detail});
        },
        [navigate],
    );
    const renderFooter = useCallback(
        (menuItem: {onClick: ({detail}: {detail: number}) => void}) => (
            <div className="mx-6 mt-3 mb-1 flex justify-center">
                <TextButton
                    onClick={navigateToNotificationsView(menuItem.onClick)}
                    text={i18n("auth.app.notifications.view_all_notifications")}
                    className="text-sm font-normal"
                />
            </div>
        ),
        [i18n, navigateToNotificationsView],
    );

    return <MenuItem render={renderFooter} />;
};

export default Footer;
