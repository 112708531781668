import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";

import {SettingsTab} from "..";

export function useTabOptions(isEnterpriseApiEnabled: boolean): Array<Tab<SettingsTab>> {
    const {i18n} = useI18n();

    return [
        {
            id: SettingsTab.General,
            title: i18n("auth.app.settings.general"),
            trackingEventTabName: TabNameEvent.SettingsPageGeneral,
        },
        ...(isEnterpriseApiEnabled
            ? [
                  {
                      id: SettingsTab.API,
                      title: i18n("auth.app.settings.api"),
                      trackingEventTabName: TabNameEvent.SettingsPageApi,
                  },
              ]
            : []),
    ];
}
