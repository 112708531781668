import {useIntl} from "react-intl";

import {Dash} from "@fleet/common/constants/dash";
import {formatNumber} from "@fleet/common/utils/numberFormatUtils";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton, Tooltip} from "@bolteu/kalep-react";
import {ArrowDown, ArrowUp, InfoCircleOutlined} from "@bolteu/kalep-react-icons";

import MetricCard = FleetOwnerPortalService.MetricCard;
import NumberTrendBehavior = FleetOwnerPortalService.NumberTrendBehavior;

interface Props {
    metric: MetricCard;
}

function getPrimaryTextColor(metric: MetricCard) {
    return metric.value === null ? "text-secondary" : "text-primary";
}

function getSecondaryTextColor(metric: MetricCard) {
    if (!metric.secondary?.trend_behavior || metric.secondary.value === null) {
        return "text-secondary";
    }

    if (metric.secondary.value === 0) {
        return "text-primary";
    }

    switch (metric.secondary.trend_behavior) {
        case NumberTrendBehavior.POSITIVE_VALUE_AS_IMPROVEMENT:
            return metric.secondary.value > 0 ? "text-action-secondary" : "text-danger-secondary";
        case NumberTrendBehavior.NEGATIVE_VALUE_AS_IMPROVEMENT:
            return metric.secondary.value < 0 ? "text-action-secondary" : "text-danger-secondary";
        default:
            return UnreachableCode.never(metric.secondary.trend_behavior, "text-primary");
    }
}

const ApiDrivenMetricCard = ({metric}: Props) => {
    const intl = useIntl();

    const valueTextColor = getPrimaryTextColor(metric);
    const secondaryValueTextColor = getSecondaryTextColor(metric);
    const formattedValue = formatNumber(intl, metric.value, {
        emptyValue: Dash.EM,
        zeroValue: "0",
        minFractionDigits: 0,
        maxFractionDigits: metric.decimal_place,
    });
    const formattedSecondaryValue = formatNumber(intl, metric.secondary?.value, {
        emptyValue: Dash.EM,
        zeroValue: "0",
        minFractionDigits: 0,
        maxFractionDigits: metric.secondary?.decimal_place,
    });

    const TrendIcon = () => {
        if (!metric.secondary?.trend_behavior || !metric.secondary.value) {
            return null;
        }

        if (metric.secondary.value > 0) {
            return <ArrowUp className="pb-1" width={16} height={16} />;
        }

        return <ArrowDown className="pb-1" width={16} height={16} />;
    };

    return (
        <div className="border-neutral-secondary flex h-[116px] min-w-[227px] max-w-[288px] flex-1 flex-col justify-between gap-0.5 rounded-lg border p-4">
            <div className="flex flex-col gap-1">
                <div className="flex items-center justify-between gap-2">
                    <div className="text-secondary text-sm">{metric.name}</div>
                    {metric.description && (
                        <Tooltip content={metric.description} placement="top">
                            <IconButton
                                overrideClassName="h-[28px] w-[28px]"
                                aria-label="tooltip"
                                variant="ghost"
                                icon={<InfoCircleOutlined />}
                            />
                        </Tooltip>
                    )}
                </div>
                <div className={`flex gap-0.5 ${valueTextColor}`}>
                    <span className="text-xl font-semibold">{formattedValue}</span>
                    {metric.unit && metric.value !== null && <span>{metric.unit}</span>}
                </div>
            </div>
            <div className="flex gap-0.5 text-xs">
                <div className={`flex gap-0.5 ${secondaryValueTextColor}`}>
                    <TrendIcon />
                    <span>
                        <span>{formattedSecondaryValue}</span>
                        {metric.secondary?.unit && typeof metric.secondary?.value === "number" && (
                            <span>{metric.secondary.unit}</span>
                        )}
                    </span>
                </div>
                {typeof metric.secondary?.value === "number" && metric.secondary?.name && (
                    <span>{metric.secondary.name}</span>
                )}
            </div>
        </div>
    );
};

export default ApiDrivenMetricCard;
