import TransactionCardLegendSection from "./TransactionCardLegendSection";
import {GraphDataSection} from "./types";

interface TransactionCardLegendProps {
    sections: GraphDataSection[];
    colorClasses: string[];
    isMobileView: boolean;
    currencySymbol: string;
}

const TransactionCardLegend: React.FC<TransactionCardLegendProps> = ({
    sections,
    colorClasses,
    isMobileView,
    currencySymbol,
}) => (
    <div className="flex flex-wrap gap-x-4 gap-y-3">
        {sections.map((section, i) => (
            <TransactionCardLegendSection
                key={section.name}
                name={section.name}
                value={section.value}
                colorClass={colorClasses[i]}
                isMobileView={isMobileView}
                currencySymbol={currencySymbol}
            />
        ))}
    </div>
);

export default TransactionCardLegend;
