import {useContext, useEffect} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

import {FleetDriverInvitationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {AccountContextProvider} from "../../../../account/accountStateProvider";
import InvitedDriver = FleetDriverInvitationService.InvitedDriver;

interface DriverInvitationSuccess {
    data: InvitedDriver;
    status: FetchStatus.Success;
}

interface DriverInvitationSuccessElse {
    data: null;
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    status: FetchStatus.Error | FetchStatus.Init | FetchStatus.Loading;
}

const getDriverInvitationsDataFetchFunction = (api: Api) => api.fleetDriverInvitation.invitationsGet();

export function useDriverInvitation(id: string): DriverInvitationSuccess | DriverInvitationSuccessElse {
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {status, data, fetch} = useFetch(getDriverInvitationsDataFetchFunction);

    useEffect(() => {
        if (fleet) {
            if (fetch) {
                fetch({});
            }
        }
    }, [fetch, fleet]);

    if (status === FetchStatus.Success) {
        const invitationData = data.list.find((invitation) => invitation.hash === id);
        if (invitationData) {
            return {
                data: invitationData,
                status,
            };
        }

        return {
            data: null,
            status: FetchStatus.Error,
        };
    }

    return {
        data: null,
        status,
    };
}
