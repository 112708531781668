import {useCallback, useState} from "react";

import {EventName} from "common/constants/events";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {ResponsiveDialog} from "@fleet/common/components/dialog/ResponsiveDialog";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Button, Checkbox, Dialog, Typography} from "@bolteu/kalep-react";

interface ManageVehicleDialogProps {
    onRequestClose: () => void;
    driverId: number;
    driverCategories: FleetOwnerPortalService.InternalSearchCategoriesResponse;
}

const updateDriverCategoriesFn = (api: Api, body: FleetOwnerPortalService.UpdateCategoriesRequest) =>
    api.fleetOwnerPortal.driverUpdateCategoriesConstraints(body);

export default function ManageVehicleDialog({onRequestClose, driverId, driverCategories}: ManageVehicleDialogProps) {
    const {i18n} = useI18n();

    const {fetch: updateDriverCategories} = useFetch(updateDriverCategoriesFn);

    const {trackEvent} = useTracking();

    const [categoriesMap] = useState(new Map(driverCategories.list.map((c) => [c.group, c])));

    const onSubmitted = useCallback(() => {
        const categories = [];
        for (const [group, category] of categoriesMap.entries()) {
            if (category.opt_out_disabled) {
                categories.push(group);
            }
        }
        trackEvent(EventName.DriverMandatoryCategoriesUpdated, {
            categories,
            driver_id: driverId,
        });

        if (!updateDriverCategories) {
            return;
        }

        updateDriverCategories({
            driver_id: driverId,
            disabled_categories_groups_opt_out: categories,
        }).then(onRequestClose);
    }, [trackEvent, driverId, onRequestClose, categoriesMap, updateDriverCategories]);

    return (
        <ResponsiveDialog
            isOpen
            title={i18n("auth.app.fleet.driver-profile.driver_categories.manage_dialog.title")}
            onRequestClose={onRequestClose}
        >
            <Dialog.Content>
                <div className="flex h-full flex-col gap-4 overflow-hidden">
                    <Typography variant="body-m-regular">
                        <span className="text-secondary">
                            {i18n("auth.app.fleet.driver-profile.driver_categories.manage_dialog.subtitle")}
                        </span>
                    </Typography>
                    <Typography variant="body-m-regular">
                        <span className="text-primary font-semibold">
                            {i18n("auth.app.fleet.driver-profile.driver_categories.manage_dialog.required_categories")}
                        </span>
                    </Typography>
                    <div className="border-separator flex h-full flex-col gap-2 overflow-hidden border-b-2 md:h-[500px]">
                        {Array.from(categoriesMap.values()).map((category) => (
                            <DriverCategoryCheckbox
                                key={category.id}
                                category={category}
                                categoriesMap={categoriesMap}
                            />
                        ))}
                    </div>
                </div>
            </Dialog.Content>
            <div className="flex w-full flex-wrap items-center justify-between gap-4 px-6 py-2">
                <Alert severity="info">
                    {i18n("auth.app.fleet.driver-profile.driver_categories.manage_dialog.driver_notification")}
                </Alert>
                <Button variant="secondary" onClick={onRequestClose}>
                    {i18n("auth.app.fleet.driver-profile.driver_categories.manage_dialog.cancel_button")}
                </Button>
                <Button variant="primary" onClick={onSubmitted}>
                    {i18n("auth.app.fleet.driver-profile.driver_categories.manage_dialog.submit_button")}
                </Button>
            </div>
        </ResponsiveDialog>
    );
}

function DriverCategoryCheckbox({
    category,
    categoriesMap,
}: {
    category: FleetOwnerPortalService.InternalSearchCategory;
    categoriesMap: Map<string, FleetOwnerPortalService.InternalSearchCategory>;
}) {
    const handleChange = useCallback(() => {
        const categoryWithGroup = categoriesMap.get(category.group);
        if (!categoryWithGroup) {
            return;
        }
        categoryWithGroup.opt_out_disabled = !categoryWithGroup.opt_out_disabled;
    }, [categoriesMap, category]);

    return (
        <Checkbox
            name={category.group}
            label={category.name}
            defaultChecked={category.opt_out_disabled}
            onChange={handleChange}
        />
    );
}
