import {useContext, useEffect} from "react";
import {useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import CampaignPageComponent from "features/companies/campaigns/details";
import {FeaturesContextProvider} from "FeaturesProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import CampaignType = FleetOwnerPortalService.CampaignType;

export const CampaignPage = () => {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);
    const {id, type} = useParams();

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.campaigns.details.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    if (!features?.campaigns) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    const isFaultyUrl = !Number(id) || !Object.values(CampaignType).includes(type as CampaignType);
    if (isFaultyUrl) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    return <CampaignPageComponent />;
};
