import {ChangeEventHandler, useCallback, useContext, useEffect, useMemo, useState} from "react";

import {FetchStatus} from "common/hooks/useFetch";
import {I18nFn, useI18n} from "common/hooks/useI18n";
import {ApiContextProvider} from "common/services/apiProvider";
import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {
    ComboBox,
    GhostButton,
    ListItemLayout,
    ListItemLayoutSlotProps,
    OptionProps,
    SelectOption,
    Spinner,
    Typography,
} from "@bolteu/kalep-react";
import {ComboBoxTriggerProps} from "@bolteu/kalep-react/build/components/ComboBox/ComboBox.types";
import {Cross, SearchExtra} from "@bolteu/kalep-react-icons";

import {useAddressSuggestions} from "../../hooks/useAddressSuggestions";
import {Address, AddressType} from "../../types";
import {getIconFromImageIdentifier} from "./imageIdentifierHelper";

interface Props {
    type: AddressType;
    address: Address;
    onChange: (address: Address, deleteAddress?: boolean) => void;
    validationError?: string;
    geoLocation: GeolocationPosition | null;
    isDeletable: boolean;
    defaultSuggestions: FleetPortalOrderService.RidehailingSuggestionsResponse | null;
}

const getAddressTexts = (type: AddressType, i18n: I18nFn) => {
    switch (type) {
        case AddressType.Pickup:
            return {
                label: i18n("auth.app.orders.scheduled_rides.pickup-address"),
                placeholder: i18n("auth.app.orders.scheduled_rides.create-dialog.enter-pickup-address"),
            };
        case AddressType.Stop:
            return {
                label: i18n("auth.app.orders.scheduled_rides.stop-address"),
                placeholder: i18n("auth.app.orders.scheduled_rides.create-dialog.enter-stop-address"),
            };
        case AddressType.Dropoff:
            return {
                label: i18n("auth.app.orders.scheduled_rides.dropoff-address"),
                placeholder: i18n("auth.app.orders.scheduled_rides.create-dialog.enter-dropoff-address"),
            };
        default:
            return UnreachableCode.never(type, {label: "", placeholder: ""});
    }
};

export const AddressSuggestion = ({
    type,
    address,
    onChange,
    validationError,
    geoLocation,
    isDeletable,
    defaultSuggestions,
}: Props) => {
    const {i18n} = useI18n();
    const api = useContext(ApiContextProvider);
    const {setNotification} = useContext(NotificationContext);
    const {observability} = useContext(ThirdPartyContextProvider);
    const [searchValue, setSearchValue] = useState("");
    const [isGettingAddressFromPlaceId, setIsGettingAddressFromPlaceId] = useState(false);
    const {getSuggestions, fetchData, recordSelectedSuggestion} = useAddressSuggestions(type, geoLocation);

    useEffect(() => {
        // On address selected or coming back from next step to this need to show the selected address
        setSearchValue(address.address_name);
    }, [address, getSuggestions, type]);

    const onSearchValueChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setSearchValue(event.target.value);
            getSuggestions(event.target.value);
        },
        [getSuggestions],
    );

    const getAddressFromPlaceId = useCallback(
        async (suggestion: FleetPortalOrderService.Suggestion): Promise<Address | null> => {
            if (!api || !suggestion.place_id) {
                observability.reportError(
                    new Error(`API ('${!!api}') or place_id ('${suggestion.place_id}') is not available`),
                );
                setNotification({
                    type: NotificationType.ERROR,
                    text: i18n("api.default_error"),
                });
                return null;
            }
            let newAddress: Address | null = null;
            setIsGettingAddressFromPlaceId(true);
            try {
                const {place_details: details} = await api.fleetPortalOrder.getAddressFromPlace({
                    place_id: suggestion.place_id,
                    source: suggestion.source,
                });
                newAddress = {
                    key: address.key,
                    address_extra: details.address_extra,
                    address_name: details.address,
                    full_address: details.full_address,
                    place_id: details.place_id,
                    lat: details.lat,
                    lng: details.lng,
                };
            } catch (e) {
                observability.reportError(
                    new Error(`Failed to get address from place id ('${suggestion.place_id}')`, {cause: e}),
                );
                setNotification({
                    type: NotificationType.ERROR,
                    text: i18n("api.default_error"),
                });
            } finally {
                setIsGettingAddressFromPlaceId(false);
            }
            return newAddress;
        },
        [address.key, api, observability, i18n, setNotification],
    );

    const onAddressChange = useCallback(
        async (newValue: SelectOption | SelectOption[] | null) => {
            const addressSugesstions = fetchData.data ?? defaultSuggestions;
            if (newValue && !Array.isArray(newValue)) {
                const selectedAddress = addressSugesstions?.suggestions?.find(
                    (ad) => ad.full_address === newValue.value,
                );
                if (selectedAddress) {
                    recordSelectedSuggestion(searchValue, addressSugesstions?.suggestions ?? [], selectedAddress);
                    if (!selectedAddress.lat || !selectedAddress.lng) {
                        const newAddress = await getAddressFromPlaceId(selectedAddress);
                        if (newAddress) {
                            onChange(newAddress);
                        }
                        return;
                    }
                    onChange({
                        key: address.key,
                        address_extra: selectedAddress.address_extra,
                        address_name: selectedAddress.address_name,
                        full_address: selectedAddress.full_address,
                        place_id: selectedAddress.place_id,
                        lat: selectedAddress.lat,
                        lng: selectedAddress.lng,
                    });
                }
            } else {
                onChange({
                    key: address.key,
                    address_extra: "",
                    address_name: "",
                    full_address: "",
                    place_id: "",
                });
            }
        },
        [
            address.key,
            defaultSuggestions,
            fetchData.data,
            getAddressFromPlaceId,
            onChange,
            recordSelectedSuggestion,
            searchValue,
        ],
    );

    const suggestionOptions: SelectOption[] = useMemo(() => {
        const data = fetchData.data?.suggestions ?? defaultSuggestions?.suggestions ?? [];

        return data.map((suggestion) => ({
            title: suggestion.address_name,
            secondary: suggestion.address_extra,
            value: suggestion.full_address,
        }));
    }, [defaultSuggestions?.suggestions, fetchData.data]);

    const renderOptionStartSlot = useCallback(
        (option: SelectOption) => (_props: ListItemLayoutSlotProps) => {
            const data = fetchData.data ?? defaultSuggestions;
            const imageIdentifier = data?.suggestions.find(
                (suggestion) => suggestion.full_address === option.value,
            )?.image_identifier;
            return getIconFromImageIdentifier(imageIdentifier);
        },
        [defaultSuggestions, fetchData.data],
    );

    const renderOption = useCallback(
        ({ref, ...props}: OptionProps) => (
            <li ref={ref} {...props} data-value={props.option.value}>
                <ListItemLayout
                    primary={props.label}
                    selected={props.selected}
                    secondary={props.option.secondary}
                    selectionMode="solo-checkmark"
                    highlighted={props.highlighted}
                    disabled={props.option.disabled}
                    variant={props.size === "sm" ? "sm" : "base"}
                    renderStartSlot={renderOptionStartSlot(props.option)}
                />
            </li>
        ),
        [renderOptionStartSlot],
    );

    // No filtering needed as we are getting suggestions from API
    const filterOptions = useCallback((options: SelectOption[]) => options, []);

    const renderOverlayFooter = useCallback(() => {
        const data = fetchData.data ?? defaultSuggestions;
        if (data?.suggestions.length === 0) {
            // No suggestions found placeholder
            return (
                <div className="mt-4 mb-6 flex w-full flex-col items-center justify-center gap-4">
                    <SearchExtra />
                    <div className="flex flex-col items-center justify-center gap-2">
                        <Typography fontWeight="semibold">
                            {i18n("auth.app.orders.scheduled_rides.create-dialog.address-not-found-title")}
                        </Typography>
                        <Typography fontSize="text-sm" color="secondary">
                            {i18n("auth.app.orders.scheduled_rides.create-dialog.address-not-found-description")}
                        </Typography>
                    </div>
                </div>
            );
        }
        const providerUrl = data?.provider_image_url;
        if (!providerUrl) {
            return null;
        }
        return (
            <div className="flex h-8 w-full items-center justify-center">
                <img
                    className="block h-auto max-h-3 w-auto max-w-[280px]"
                    src={providerUrl}
                    alt="Address suggestions provider"
                />
            </div>
        );
    }, [defaultSuggestions, fetchData.data, i18n]);

    const onClickDeleteStop = useCallback(() => {
        onChange(address, true);
    }, [address, onChange]);

    const renderEndSlot = useCallback(
        (props: ComboBoxTriggerProps) => (
            <>
                {props.loading && <Spinner />}
                {!props.loading && (
                    <GhostButton onClick={onClickDeleteStop} aria-label="Delete stop">
                        <Cross size="lg" />
                    </GhostButton>
                )}
            </>
        ),
        [onClickDeleteStop],
    );

    const {label, placeholder} = getAddressTexts(type, i18n);
    const isLoading = fetchData.status === FetchStatus.Loading || isGettingAddressFromPlaceId;
    const selectedSuggestion = suggestionOptions.find((suggestion) => suggestion.value === address.full_address);
    const renderEndSlotProp = isDeletable ? renderEndSlot : undefined;

    return (
        <ComboBox
            placeholder={placeholder}
            label={label}
            value={selectedSuggestion}
            inputValue={searchValue}
            onInputChange={onSearchValueChange}
            onChange={onAddressChange}
            options={suggestionOptions}
            fullWidth
            error={Boolean(validationError)}
            helperText={validationError}
            loading={isLoading}
            renderOption={renderOption}
            filterOptions={filterOptions}
            renderOverlayFooter={renderOverlayFooter}
            shouldRenderEndSlotIcon={false}
            renderEndSlot={renderEndSlotProp}
            clearable={false}
        />
    );
};
