/**
 *! If you add a new route to the enums below, then also add a new entry in pages and
 *! update routeUtils.getPageNameFromRoute
 */
export enum CommonRoute {
    AUTH = "/login",
    FORGOT_PASSWORD = "/forgotPassword",
    RESET_PASSWORD = "/resetPassword/:token",
    HOME = "/",
    INVOICES = "invoices",
    SETTINGS = "settings",
    VERIFICATION = "/verification/:key",
    VERIFICATION_PASSWORD = "/verification/password/:key",
    VERIFICATION_CONFIRMATION = "/verification/confirmation/:key",
    VERIFICATION_2FA = "/verification/2fa/:key",
    VERIFICATION_2FA_CONFIRMATION = "/verification/2fa/confirmation/:key",
    ADD_COMPANY = "/company/add",
    REGISTER_COMPANY = "/company/add/:hash",
    ONBOARD_COMPANY = "/onboarding/:companyId",
    SMS_NOTIFICATION = "/sms/:id",
}

export enum FleetRoute {
    RENTAL_PAYMENTS = "carRentalPayment",
    COMPANY_SETTINGS = "companySettings",
    COMPANY_SETTINGS_BANKING = "companySettings?tab=banking_details",
    COMPANY_SETTINGS_TAX = "companySettings?tab=tax_info",
    DASHBOARD = "dashboard",
    DRIVER = "drivers/:id",
    DRIVERS = "drivers",
    DRIVER_REGISTRATION = "drivers/registration/:id",
    DRIVER_INVITATION = "drivers/invitation/:id",
    DRIVER_REGISTRATIONS = "drivers?tab=registrations",
    EXPIRING_DOCUMENTS = "expiringDocuments",
    LIVE_MAP = "liveMap",
    ORDERS = "orders",
    REPORTS = "reports",
    VEHICLE = "vehicles/:id",
    VEHICLES = "vehicles",
    VEHICLE_APPLICATIONS = "vehicles?tab=applications",
    VEHICLE_APPLICATION = "vehicles/applications/:id",
    CAMPAIGNS = "campaigns",
    CAMPAIGN_DETAILS = "campaigns/:type/:id",
    BALANCE = "balance",
    NOTIFICATIONS = "notifications",
    SUPPORT = "support",
    SHIFTS = "shifts",
    VEHICLE_LISTING_CREATE = "vehicles/vehicle_listings",
    VEHICLE_LISTING_EDIT = "vehicles/vehicle_listings/:id",
    VEHICLE_LISTING_VIEW = "vehicles/vehicle_listings/details/:id",

    VEHICLE_LISTING_APPLICATION = "vehicles/vehicle_listings/applications",
    VEHICLE_LISTING_VIEW_APPLICATION = "vehicles/vehicle_listings/applications/:applicationId",
}

export enum FleetRouteV2 {
    // Operations -> Tracking
    TRACKING = "operations/tracking",
    LIVE_MAP = "operations/tracking/liveMap",
    ORDER_HISTORY = "operations/tracking?tab=order_history",
    ACTIVITY_LOG = "operations/tracking?tab=activity_log",
    // Operations -> Management
    MANAGEMENT = "operations/management",
    SHIFT_PLANNING = "operations/management?tab=shift_planning",
    ORDER_REQUESTS = "operations/management?tab=order_requests",
    INCOMING_ORDERS = "operations/management?tab=incoming_orders",
    EXPIRING_DOCUMENTS = "operations/management/expiringDocuments",
    // Finances -> Reports
    COMPANY_PERFORMANCE = "finances/reports/companyPerformance",
    COMPANY_BALANCE = "finances/reports/companyBalance",
    DRIVER_EARNINGS = "finances/reports/driverEarnings",
    // Finances -> Invoices
    INVOICES = "finances/invoices",
    PAYOUTS = "finances/invoices?tab=payouts",
    BOLT_INVOICES = "finances/invoices?tab=bolt_invoices",
    FLEET_INVOICES = "finances/invoices?tab=fleet_to_rider",
    COMPENSATIONS = "finances/invoices?tab=compensations",
    // Finance -> Bonus campaigns
    CAMPAIGNS = "finances/campaigns",
    ACTIVE_AND_UPCOMING_CAMPAIGNS = "finances/campaigns?tab=active_and_upcoming",
    FINISHED_CAMPAIGNS = "finances/campaigns?tab=finished",
    CAMPAIGN_DETAILS = "finances/campaigns/:type/:id",
    // Supply -> Drivers
    DRIVERS = "supply/drivers",
    DRIVER = "supply/drivers/:id",
    DRIVER_APPLICATION = "supply/drivers/registration/:id",
    DRIVER_INVITATION = "supply/drivers/invitation/:id",
    DRIVER_PERFORMANCE = "supply/drivers?tab=drivers",
    DRIVER_APPLICATIONS = "supply/drivers?tab=registrations",
    PROPOSED_CANDIDATES = "supply/drivers?tab=proposed_candidates",
    RENTAL_REQUESTS = "supply/drivers?tab=rental_requests",
    // Supply -> Vehicles
    VEHICLES = "supply/vehicles",
    VEHICLE = "supply/vehicles/:id",
    VEHICLE_LISTING_CREATE = "supply/vehicles/vehicle_listings",
    VEHICLE_LISTING_EDIT = "supply/vehicles/vehicle_listings/:id",
    VEHICLE_LISTING_VIEW = "supply/vehicles/vehicle_listings/details/:id",
    VEHICLE_LISTING_APPLICATION = "supply/vehicles/vehicle_listings/applications",
    VEHICLE_LISTING_VIEW_APPLICATION = "supply/vehicles/vehicle_listings/applications/:applicationId",
    VEHICLE_PERFORMANCE = "supply/vehicles?tab=vehicles",
    VEHICLE_APPLICATIONS = "supply/vehicles?tab=applications",
    VEHICLE_APPLICATION = "supply/vehicles/applications/:id",
    RENTAL_LISTINGS = "supply/vehicles?tab=renting_out",
    RENTAL_PAYMENTS = "supply/vehicles?tab=rental_payments",
    // Settings -> Company settings
    COMPANY_SETTINGS = "settings/companySettings",
    COMPANY_CONTACT_DETAILS = "settings/companySettings?tab=company_details",
    COMPANY_BANKING_INFO = "settings/companySettings?tab=banking_details",
    COMPANY_TAX_INFO = "settings/companySettings?tab=tax_info",
    API_CONNECTIONS = "settings/companySettings/apiConnections",
    // Settings -> User preferences
    USER_PREFERENCES = "settings/userPreferences",
    NOTIFICATION_SETTINGS = "settings/userPreferences?tab=notification_settings",
    LANGUAGE_SELECTION = "settings/userPreferences?tab=language_selection",
    NOTIFICATIONS = "notifications",
    SUPPORT = "support",
}

export enum HoldingFleetRoute {}

export const SmsNotificationRoutePrefix = "sms";
export const HoldingFleetRoutePrefix = "enterprise";
export const ResetPasswordPrefix = "resetPassword";
export const VerificationPrefix = "verification";
export const CompanyRegistrationPrefix = "company/add";
export const VerificationKeyMinLength = 40;
export const CompanyOnboardingRoutePrefix = "onboarding";
export const DeepLinkPrefix = "l/";
