import {useContext, useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import DashboardPage from "features/companies/dashboard";
import {FeaturesContextProvider} from "FeaturesProvider";

export const PerformancePage = () => {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.dashboard.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    if (!features?.company_metrics) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    return <DashboardPage />;
};
