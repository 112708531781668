import {AppBrand} from "@fleet/common/constants/appConstants";

const version = import.meta.env.PACKAGE_VERSION as string;
const [major, minor] = (version || "ERR.ERR").split(".");

const config = {
    appName: "FleetOwnerPortal",
    baseUrl: `${import.meta.env.VITE_API_URL_SCHEME}://${import.meta.env.VITE_API_URL_SUFFIX}`,
    baseUrlOld: `${import.meta.env.VITE_API_URL_SCHEME}://${import.meta.env.VITE_API_URL_OLD_SUFFIX}`,
    version: `FO.${major}.${Number(minor) < 10 ? `0${minor}` : minor}`,
    versionNumber: `${version}`,
    versionFile: `${import.meta.env.VITE_ROUTER_BASENAME}/version.txt`,
    compareVersionsInterval: 2 * 60 * 60 * 1000,
    hasUnreadSupportCasesPollInterval: 60 * 1000,
    routerBasename: import.meta.env.VITE_ROUTER_BASENAME as string,
    appStage: import.meta.env.VITE_STAGE as string,
    landingPageUrl: (import.meta.env.VITE_LANDING_PAGE_URL ?? "") as string,
    vehicleMarketplaceUrl: (import.meta.env.VITE_VEHICLE_MARKETPLACE_URL ?? "") as string,
    isStageLive: import.meta.env.VITE_STAGE === "live",
    isStagePrelive: import.meta.env.VITE_STAGE === "prelive",
    isStageLocal: import.meta.env.VITE_STAGE === "local",
    isStageMockoon: import.meta.env.VITE_STAGE === "mockoon",
    appBrand: (import.meta.env.VITE_BRAND as string).toLowerCase() as AppBrand,
    isEnvLocal: import.meta.env.DEV,
    googleMapsApiKey: (import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? "") as string,
    defaultLocale: "en-us",
    crowdinFolder: "Fleet Owner Portal/Website",
    crowdinBackendProject: "fleet-owner-portal-client",
    crowdinFileName: "en.json",
    mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN as string,
    pdfPreviewWorkerFile: `${import.meta.env.VITE_ROUTER_BASENAME}/versions/${version}/pdf.worker.min.js`,
    accessTokenRefreshTimeoutBeforeExpiryInSeconds: 60,
    invoiceServiceUrl: import.meta.env.VITE_INVOICE_SERVICE_URL as string,
    geoServiceUrl: import.meta.env.VITE_GEO_SERVICE_URL as string,
    sentryDataSourceName: import.meta.env.VITE_SENTRY_DATA_SOURCE_NAME as string,
};

export type ConfigType = typeof config;

export default config;
