import {useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton, Menu, MenuItem, Typography} from "@bolteu/kalep-react";
import {MoreIos} from "@bolteu/kalep-react-icons";

interface Props {
    shift: FleetShiftManagementService.ShiftWithAllocationSummary;
    onDeleteClick: (shiftId: number) => void;
    onEditClick: (shift: FleetShiftManagementService.ShiftWithAllocationSummary) => void;
}

export const CardHeader = ({shift, onDeleteClick, onEditClick}: Props) => {
    const {i18n} = useI18n();

    const onEditClickWrapper = useCallback(() => {
        onEditClick(shift);
    }, [onEditClick, shift]);

    const onDeleteClickWrapper = useCallback(() => {
        onDeleteClick(shift.id);
    }, [onDeleteClick, shift.id]);

    return (
        <div className="border-separator flex items-center justify-between gap-3 border-b p-4">
            <div className="flex flex-col">
                <Typography variant="title-primary" lines={2}>
                    {shift.name}
                </Typography>
                <Typography variant="body-secondary" color="secondary">
                    {i18n("auth.app.fleet.shifts.planning.card.description", {
                        driversCount: shift.driver_count,
                    })}
                </Typography>
            </div>
            <Menu
                menuButton={<IconButton icon={<MoreIos />} variant="ghost" aria-label="Shift actions" />}
                overrideClassName="max-w-[160px]"
            >
                <MenuItem onClick={onEditClickWrapper} label={i18n("common.edit")} />
                <MenuItem onClick={onDeleteClickWrapper} label={i18n("common.delete")} />
            </Menu>
        </div>
    );
};
