import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {SpinnerIcon} from "@bolteu/kalep-react";

export interface LoadingSpinnerProps {
    show: boolean;
    size?: "xs" | "sm" | "md" | "lg";
    spinnerColorCss?: string;
    showLoadingText?: boolean;
    parentClassName?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
    show,
    size,
    spinnerColorCss = "text-action-secondary",
    parentClassName,
    showLoadingText,
}) => {
    const {i18n} = useI18n();
    if (!show) {
        return null;
    }

    return (
        <div className={`flex items-end justify-center gap-2 ${parentClassName}`}>
            <SpinnerIcon size={size} className={`animate-spin ${spinnerColorCss}`} />
            {showLoadingText && i18n("api.loading")}
        </div>
    );
};

interface FullScreenLoadingSpinnerProps {
    absoluteFullScreen?: boolean;
}

export const FullScreenLoadingSpinner = ({absoluteFullScreen}: FullScreenLoadingSpinnerProps) => {
    const absoluteFullScreenClasses = absoluteFullScreen ? "absolute top-0 left-0" : "";
    return (
        <div className={`flex h-screen w-full items-center justify-center ${absoluteFullScreenClasses}`}>
            <LoadingSpinner show />
        </div>
    );
};

export default LoadingSpinner;
