import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Page} from "common/components/page/Page";
import LoadingSpinner from "common/components/spinner";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {useAccountState} from "features/account/hooks/useAccountState";
import {
    DriverDialogContextProvider,
    DriverDialogProvider,
} from "features/companies/drivers/list-view/components/DriverDialogProvider";
import {
    AddVehicleWithDocumentsPageContextProvider,
    AddVehicleWithDocumentsPageProvider,
} from "features/companies/vehicles/list-view/components/AddVehicleWithDocumentsPageProvider";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, GhostButton, Typography} from "@bolteu/kalep-react";
import {SparkleOutlined} from "@bolteu/kalep-react-icons";

import ConfirmDeletionDialog from "./ConfirmDeletionDialog";
import {useOnboardingStep} from "./hooks/useOnboardingStep";
import {OnboardingStep, OnboardingStepProps} from "./OnboardingStep";

const getCompanyRegistrationOverviewFunction = (api: Api) =>
    api.fleetOwnerRegistration.companyRegistrationGetOverview();

const deleteCompanyRegistrationFunction = (api: Api) => api.fleetOwnerRegistration.companyRegistrationDelete();

const CompanyOnboarding = () => {
    const {companyId} = useParams();
    const {i18n} = useI18n();
    const {fetch, data, status} = useFetch(getCompanyRegistrationOverviewFunction);
    const {fetch: deleteCompanyRegistration} = useFetch(deleteCompanyRegistrationFunction);
    const {getCompanyRegistrationPath} = useAbsolutePath();
    const navigate = useNavigate();
    const {refreshProfile} = useAccountState();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const {openInviteDriverDialog} = useContext(DriverDialogContextProvider);
    const {isAddVehiclePageOpen, isVehicleDocumentsPageOpen, openAddVehiclePage} = useContext(
        AddVehicleWithDocumentsPageContextProvider,
    );

    const onDeleteApplicationClick = useCallback(async () => {
        setIsDeleteDialogOpen(true);
    }, []);

    const onCloseDeleteApplicationClick = useCallback(async () => {
        setIsDeleteDialogOpen(false);
    }, []);

    const onConfirmDeleteApplication = useCallback(async () => {
        if (deleteCompanyRegistration) {
            try {
                await deleteCompanyRegistration({});
                await refreshProfile();
                navigate("/", {replace: true});
            } catch {
                // Ignore all errors
            }
        }
    }, [deleteCompanyRegistration, navigate, refreshProfile]);

    const [activeOverview, declinedOverview, completedOverview] = useMemo(() => {
        if (!data) {
            return [undefined, undefined, undefined];
        }

        if (data?.type === FleetOwnerRegistrationService.OverviewType.ACTIVE) {
            return [data, undefined, undefined];
        }

        if (data?.type === FleetOwnerRegistrationService.OverviewType.DECLINED) {
            return [undefined, data, undefined];
        }

        if (data?.type === FleetOwnerRegistrationService.OverviewType.COMPLETE) {
            return [undefined, undefined, data];
        }
        return [undefined, undefined, undefined];
    }, [data]);

    const gotoCompanyRegistration = useCallback(() => {
        if (activeOverview?.hash) {
            navigate(getCompanyRegistrationPath(activeOverview?.hash));
        }
    }, [activeOverview?.hash, getCompanyRegistrationPath, navigate]);

    useEffect(() => {
        if (fetch && !isAddVehiclePageOpen && !isVehicleDocumentsPageOpen) {
            fetch({});
        }
    }, [fetch, isAddVehiclePageOpen, isVehicleDocumentsPageOpen]);

    const {getActiveStepProps, getDeclinedStepProps} = useOnboardingStep();

    const isLoading = status === FetchStatus.Init || status === FetchStatus.Loading;

    const fullExperienceCard: OnboardingStepProps = {
        section: "full_experience",
        title: i18n("company_registration.full_experience.title"),
        subTitle: i18n("company_registration.full_experience.sub_title"),
        icon: <SparkleOutlined />,
        state: "NONE",
        elevation: "surface",
        hideLine: true,
    };

    const stepsProps: OnboardingStepProps[] = useMemo(() => {
        let props: Array<OnboardingStepProps | null> = [];
        if (activeOverview) {
            const {company_section, documents_section, vehicles_section, drivers_section} = activeOverview;
            props = [
                getActiveStepProps("details", company_section, gotoCompanyRegistration),
                getActiveStepProps("documents", documents_section, gotoCompanyRegistration),
                getActiveStepProps("vehicles", vehicles_section, openAddVehiclePage),
                getActiveStepProps("drivers", drivers_section, openInviteDriverDialog),
            ];
        }

        if (declinedOverview) {
            const {company_section, documents_section} = declinedOverview;
            props = [
                getDeclinedStepProps("details", company_section),
                getDeclinedStepProps("documents", documents_section),
            ];
        }

        if (completedOverview) {
            const {company_section, documents_section, vehicles_section, drivers_section} = completedOverview;
            props = [
                getActiveStepProps("details", company_section, gotoCompanyRegistration),
                getActiveStepProps("documents", documents_section, gotoCompanyRegistration),
                getActiveStepProps("vehicles", vehicles_section, openAddVehiclePage),
                getActiveStepProps("drivers", drivers_section, openInviteDriverDialog),
            ];
        }
        return props.filter((s) => s !== null);
    }, [
        activeOverview,
        declinedOverview,
        completedOverview,
        getActiveStepProps,
        gotoCompanyRegistration,
        openAddVehiclePage,
        openInviteDriverDialog,
        getDeclinedStepProps,
    ]);

    if (isLoading) {
        return <LoadingSpinner show parentClassName="pt-12 w-full" />;
    }

    if (status === FetchStatus.Error || !companyId) {
        return <ErrorView type={ErrorViewType.NotFound} />;
    }

    if (isAddVehiclePageOpen || isVehicleDocumentsPageOpen) {
        return null;
    }

    return (
        <Page>
            <div className="mx-auto max-w-lg">
                <div className="mb-10">
                    <Typography fontSize="text-3xl" fontWeight="semibold">
                        {i18n("company_registration.company_setup")}
                    </Typography>
                </div>
                {declinedOverview && (
                    <div className="mb-10">
                        <Alert severity="error" title={declinedOverview.declined_title}>
                            <div className="flex flex-col gap-4">
                                {declinedOverview.declined_description}
                                <GhostButton onClick={onDeleteApplicationClick}>
                                    <Typography variant="body-primary" fontWeight="semibold" fontSize="text-base">
                                        <div className="text-danger-primary">
                                            {declinedOverview.declined_action_text}
                                        </div>
                                    </Typography>
                                </GhostButton>
                            </div>
                        </Alert>
                    </div>
                )}
                <div className="flex flex-col">
                    {stepsProps.map((step, index) => (
                        <OnboardingStep key={step.section} {...step} hideLine={index === stepsProps.length - 1} />
                    ))}
                    {activeOverview && <OnboardingStep {...fullExperienceCard} />}
                </div>
                {activeOverview?.can_delete_application && (
                    <div className="border-separator border-t pt-6">
                        <Typography variant="body-primary" fontWeight="regular" fontSize="text-sm">
                            <span className="text-secondary">
                                {i18n("company_registration.delete_application.text", {
                                    company_registration_delete_application_link: (
                                        <GhostButton onClick={onDeleteApplicationClick}>
                                            <span className="text-action-primary">
                                                {i18n("company_registration.delete_application.link")}
                                            </span>
                                        </GhostButton>
                                    ),
                                })}
                            </span>
                        </Typography>
                    </div>
                )}
                <ConfirmDeletionDialog
                    show={isDeleteDialogOpen}
                    closeModal={onCloseDeleteApplicationClick}
                    confirmDelete={onConfirmDeleteApplication}
                />
            </div>
        </Page>
    );
};

const CompanyOnboardingWithProvider = () => {
    const {refreshProfile} = useAccountState();
    const onVehicleAdded = useCallback(async () => {
        await refreshProfile();
    }, [refreshProfile]);

    return (
        <DriverDialogProvider>
            <AddVehicleWithDocumentsPageProvider onVehicleAdded={onVehicleAdded}>
                <CompanyOnboarding />
            </AddVehicleWithDocumentsPageProvider>
        </DriverDialogProvider>
    );
};

export default CompanyOnboardingWithProvider;
