import {useContext, useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import AddVehicleListingPageComponent from "features/companies/vehicleListings/add-listing/AddVehicleListingPage";
import {FeaturesContextProvider} from "FeaturesProvider";

export const AddVehicleListingPage = () => {
    const {i18n} = useI18n();
    const isVehicleMarketplaceDisabled = !useContext(FeaturesContextProvider)?.vehicle_marketplace;

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.vehicle_marketplace.listings.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    if (isVehicleMarketplaceDisabled) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    return <AddVehicleListingPageComponent />;
};
