import {FC, useCallback, useRef, useState} from "react";

import {EventName} from "common/constants/events";
import {useTracking} from "common/hooks/useTracking";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton} from "@bolteu/kalep-react";
import {Cross, List, Map} from "@bolteu/kalep-react-icons";

import {useLiveMap} from "../hooks/useLiveMap";
import DriverDetailsContainer from "./detail-view/DriverDetailsContainer";
import DriverList from "./list/DriverList";
import {ResponsiveMapView} from "./map/ResponsiveMap";
import ShowDriverRoutes from "./show-driver-routes";

export type MobileViewMode = "list" | "map" | "details";

enum MapViewMode {
    MAP = "map",
    LIST = "list",
    DETAILS = "details",
}

export const ResponsiveLiveMap: FC = () => {
    const [previewDriverId, setPreviewDriverId] = useState<number | null>(null);
    const [viewMode, setViewMode] = useState<MapViewMode>(MapViewMode.MAP);
    const fromViewModeToDetail = useRef<MapViewMode>(MapViewMode.MAP);
    const {trackEvent} = useTracking();
    const [showDriverRoutesLocalStorage, setShowDriverRoutesLocalStorage] =
        useLocalStorage<ShowDriverRoutesLocalStorage>("showDriverRoutes", {
            isShowDriverRoutesChecked: false,
        });

    const isDriverRoutesShown = showDriverRoutesLocalStorage.isShowDriverRoutesChecked;

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const setToMapMode = useCallback(() => {
        setPreviewDriverId(null);
        setViewMode(MapViewMode.MAP);
    }, []);
    const setToListMode = useCallback(() => setViewMode(MapViewMode.LIST), []);
    const setToDetailsMode = useCallback(() => setViewMode(MapViewMode.DETAILS), []);

    const resetViewMode = useCallback(() => {
        if (!isMobileView) {
            return;
        }
        if (fromViewModeToDetail.current === MapViewMode.MAP) {
            setToMapMode();
        } else {
            setToListMode();
        }
    }, [isMobileView, setToListMode, setToMapMode]);

    const {
        filteredDrivers,
        selectedDriver,
        driverFilter,
        driverStats,
        onDriverMapSelected,
        onFilterChange,
        onDriverListSelected,
        onDriverDeselected,
        lastDropoffAndWaitingTime,
        isShowDriverRoutesEnabled,
        driverTrailPeriodMs,
        isSendOfflineEnabled,
    } = useLiveMap({
        isMobileMapOrDetailsView: isMobileView && ["map", "details"].includes(viewMode),
        resetOnDriverStateChange: resetViewMode,
    });

    const onListClick = useCallback(
        (driver: FleetOwnerPortalService.DriverForFleetOwner | null) => {
            onDriverListSelected(driver);
            if (isMobileView) {
                fromViewModeToDetail.current = MapViewMode.LIST;
                setToDetailsMode();
            }
        },
        [isMobileView, onDriverListSelected, setToDetailsMode],
    );

    const setPreviewDriver = useCallback(
        (driverId: number | null) => {
            setPreviewDriverId(driverId);
            if (driverId && isMobileView) {
                trackEvent(EventName.LiveMapDriverPreviewClicked);
            }
        },
        [isMobileView, trackEvent],
    );

    const onMapClick = useCallback(
        (driver: FleetOwnerPortalService.DriverForFleetOwner) => {
            onDriverMapSelected(driver);
            if (isMobileView) {
                fromViewModeToDetail.current = MapViewMode.MAP;
                setToDetailsMode();
            }
        },
        [isMobileView, onDriverMapSelected, setToDetailsMode],
    );

    const onDriverDeselect = useCallback(() => {
        onDriverDeselected();
        resetViewMode();
    }, [onDriverDeselected, resetViewMode]);

    const onListFabClick = useCallback(() => {
        setToListMode();
        trackEvent(EventName.LiveMapFabDriverListClicked);
    }, [setToListMode, trackEvent]);

    const onMapFabClick = useCallback(() => {
        setToMapMode();
        trackEvent(EventName.LiveMapFabMapClicked);
    }, [setToMapMode, trackEvent]);

    const showRoute = useCallback(() => {
        setToMapMode();
        trackEvent(EventName.LiveMapShowRouteClicked);
    }, [setToMapMode, trackEvent]);

    let ListAndMapFabButton =
        isMobileView && ["list", "map"].includes(viewMode) ? (
            <IconButton
                icon={viewMode === "list" ? <Map /> : <List />}
                aria-label="Toggle list and map view"
                variant="floating"
                size="lg"
                overrideClassName="[&>span]:shadow-lg"
                onClick={viewMode === "list" ? onMapFabClick : onListFabClick}
            />
        ) : null;
    const CloseRouteDetailsFabButton =
        isMobileView && viewMode === "map" && selectedDriver ? (
            <IconButton
                icon={<Cross />}
                aria-label="Back to drivers details"
                variant="floating"
                size="lg"
                overrideClassName="[&>span]:shadow-lg"
                onClick={setToDetailsMode}
            />
        ) : null;
    if (viewMode === "map" && selectedDriver) {
        ListAndMapFabButton = null;
    }

    function handleShowDriverRoutesToggle() {
        return () => {
            const isToggledOn = !isDriverRoutesShown;
            trackEvent(EventName.LiveMapShowDriverRoutesToggle, {
                toggledOn: isToggledOn,
            });

            setShowDriverRoutesLocalStorage({
                isShowDriverRoutesChecked: isToggledOn,
            });
        };
    }

    return (
        <div className="h-full w-full flex-col">
            <div className="relative h-full w-full">
                <ResponsiveMapView
                    drivers={filteredDrivers}
                    onDriverSelected={onMapClick}
                    selectedDriver={selectedDriver}
                    previewDriverId={previewDriverId}
                    isMobileView={isMobileView}
                    isMapView={viewMode === "map"}
                    lastDropoffAndWaitingTime={lastDropoffAndWaitingTime}
                    onDriverPreviewSelected={setPreviewDriver}
                    isDriverRoutesShown={isDriverRoutesShown}
                    driverTrailPeriodMs={driverTrailPeriodMs}
                />
                <div
                    className={`absolute top-0 flex h-full w-full flex-col gap-3 sm:w-full sm:pl-0 sm:pt-0 md:w-[450px] md:pl-5 md:pt-10 ${
                        isMobileView && viewMode === "map" && "hidden"
                    }`}
                >
                    <div className="bg-layer-floor-1 flex h-full w-full flex-col shadow md:h-5/6 md:rounded-lg">
                        {!selectedDriver ? (
                            <DriverList
                                filteredDrivers={filteredDrivers}
                                filter={driverFilter}
                                onFilterChange={onFilterChange}
                                onDriverSelected={onListClick}
                                driverStats={driverStats}
                            />
                        ) : (
                            <DriverDetailsContainer
                                driverInfo={selectedDriver}
                                lastDropoffAndWaitingTime={lastDropoffAndWaitingTime}
                                onBackPressed={onDriverDeselect}
                                showRoute={isMobileView ? showRoute : undefined}
                                isSendOfflineEnabled={isSendOfflineEnabled}
                            />
                        )}
                    </div>
                    {!isMobileView && (
                        <ShowDriverRoutes
                            onToggle={handleShowDriverRoutesToggle()}
                            isShowDriverRoutesEnabled={isShowDriverRoutesEnabled}
                            isShowDriverRoutesChecked={isDriverRoutesShown}
                        />
                    )}
                </div>
            </div>
            <div className="fixed left-3 top-[68px] z-20">{CloseRouteDetailsFabButton}</div>
            <div
                id="live-map-fab"
                className="fixed right-4 bottom-6 z-20 flex w-full flex-col items-end gap-4 pr-2 pl-6"
            >
                {ListAndMapFabButton}
                <div className="w-full">
                    {isMobileView && viewMode === "map" && (
                        <ShowDriverRoutes
                            onToggle={handleShowDriverRoutesToggle()}
                            isShowDriverRoutesChecked={showDriverRoutesLocalStorage.isShowDriverRoutesChecked}
                            isShowDriverRoutesEnabled={isShowDriverRoutesEnabled}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

interface ShowDriverRoutesLocalStorage {
    isShowDriverRoutesChecked: boolean;
}
