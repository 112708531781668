import {ErrorViewProps, useErrorView} from "./useErrorView";

export enum ErrorViewType {
    ServiceUnavailable,
    NotFound,
    SomethingWentWrong,
}

const ErrorView = (props: ErrorViewProps) => {
    const {title, description, icon, ActionComponent} = useErrorView(props);

    return (
        <div className="grid h-full w-full place-items-center">
            <div className="grid max-w-[550px] place-items-center gap-6 rounded-lg px-6 py-12">
                {icon}
                <div className="grid gap-2 text-center">
                    <div className="text-2xl font-semibold">{title}</div>
                    <div className="text-secondary text-base">{description}</div>
                </div>
                {ActionComponent}
            </div>
        </div>
    );
};

export default ErrorView;
