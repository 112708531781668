import {Navigate, useLocation} from "react-router-dom";

import {useFleetIndexRoute} from "AppRoutes";
import {CommonRoute, CompanyOnboardingRoutePrefix, HoldingFleetRoutePrefix} from "common/constants/routes";
import {Company, isHoldingFleet} from "common/constants/types";
import {useCompanyData} from "common/hooks/useCompanyData";
import {LocationWithState, ViewLocationState} from "common/navigation/types/locationState";
import {getRouteEndingPath} from "common/util/routeUtils";

import {LatestSelectedCompany} from "../services/authService";
import {localStorageService} from "../services/LocalStorageService";

const ChooseCompanyLayout = () => {
    const location = useLocation() as LocationWithState<ViewLocationState>;
    const {
        isZeroCompanies,
        isOnlyCompanyRegistrations,
        availableFleets,
        availableActiveOrBlockedFleets,
        availableHoldingFleets,
        continueCompanyRegistrationHash,
    } = useCompanyData();
    const fleetIndexRoute = useFleetIndexRoute();

    if (continueCompanyRegistrationHash) {
        return <Navigate to={`${CommonRoute.ADD_COMPANY}/${continueCompanyRegistrationHash}`} />;
    }
    if (isZeroCompanies) {
        return <Navigate to={CommonRoute.ADD_COMPANY} />;
    }
    if (isOnlyCompanyRegistrations) {
        const draftCompany = availableFleets[0];
        return <Navigate to={`/${CompanyOnboardingRoutePrefix}/${draftCompany.id}`} />;
    }

    let view = location.state?.view ?? "";
    if (view === getRouteEndingPath(CommonRoute.AUTH)) {
        view = fleetIndexRoute;
    }

    const latestSelectedCompany = Number(localStorageService.getItem(LatestSelectedCompany));

    let company: Company | undefined = latestSelectedCompany
        ? availableActiveOrBlockedFleets.find((c) => c.id === latestSelectedCompany)
        : undefined;
    if (!company) {
        [company] = availableActiveOrBlockedFleets.length ? availableActiveOrBlockedFleets : availableHoldingFleets;
    }

    if (isHoldingFleet(company)) {
        return <Navigate to={`${HoldingFleetRoutePrefix}/${String(company.id)}`} />;
    }

    return <Navigate to={`${String(company.id)}/${view}`} />;
};

export default ChooseCompanyLayout;
