import {useContext, useMemo} from "react";

import {FleetRouteV2} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {CompanyManagementLayoutVariant} from "common/layouts/CompanyManagementLayout";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {FeaturesContextProvider} from "FeaturesProvider";

export interface MenuContent {
    title: string;
    /**
     * If empty subMenuItems array and route exists, it will render a single link
     * If empty subMenuItems array and no route, MenuContent is not rendered at all
     */
    subMenuItems: SubMenuItem[];
    route?: string;
}

export interface SubMenuItem {
    title: string;
    subTitle?: string; // Optional subtitle
    /**
     * If empty array, SubMenuItem will not be rendered
     */
    links: MenuLink[];
}

export interface MenuLink {
    title: string;
    route: string;
    renderDividerAbove?: boolean; // Optional property for showing divider
}

// TODO translations!
export const useNavigationLinksV2 = (variant: CompanyManagementLayoutVariant): MenuContent[] => {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);
    const acccountState = useContext(AccountContextProvider);

    const navigationLinks = useMemo<MenuContent[]>(() => {
        const fleetOwnerEmail = acccountState.profile?.email;
        const holdingFleetLinks: MenuContent[] = [
            {
                title: i18n("common.invoices"),
                route: FleetRouteV2.INVOICES,
                subMenuItems: [],
            },
            {
                title: "Settings",
                subMenuItems: [
                    {
                        title: "User preferences",
                        subTitle: fleetOwnerEmail,
                        links: [{title: "Language selection", route: FleetRouteV2.LANGUAGE_SELECTION}],
                    },
                ],
            },
        ];

        const inactiveFleetLinks: MenuContent[] = [
            {
                title: "Supply",
                subMenuItems: [
                    ...(features?.company_under_review?.add_driver
                        ? [
                              {
                                  title: "Drivers",
                                  links: [
                                      {title: "Driver performance", route: FleetRouteV2.DRIVER_PERFORMANCE},
                                      {title: "Applications", route: FleetRouteV2.DRIVER_APPLICATIONS},
                                  ],
                              },
                          ]
                        : []),
                    ...(features?.company_under_review?.add_vehicle
                        ? [
                              {
                                  title: "Vehicles",
                                  links: [
                                      {title: "Vehicle performance", route: FleetRouteV2.VEHICLE_PERFORMANCE},
                                      {title: "Applications", route: FleetRouteV2.VEHICLE_APPLICATIONS},
                                  ],
                              },
                          ]
                        : []),
                ],
            },
            {
                title: "Settings",
                subMenuItems: [
                    {
                        title: "User preferences",
                        subTitle: fleetOwnerEmail,
                        links: [
                            {title: "Notification settings", route: FleetRouteV2.NOTIFICATION_SETTINGS},
                            {title: "Language selection", route: FleetRouteV2.LANGUAGE_SELECTION},
                        ],
                    },
                ],
            },
        ];

        const fleetLinks = [
            {
                title: "Operations",
                subMenuItems: [
                    {
                        title: "Tracking",
                        links: [
                            ...(features?.live_map ? [{title: "Live map", route: FleetRouteV2.LIVE_MAP}] : []),
                            {title: "Order history", route: FleetRouteV2.ORDER_HISTORY},
                            ...(features?.shifts ? [{title: "Activity log", route: FleetRouteV2.ACTIVITY_LOG}] : []),
                        ],
                    },
                    {
                        title: "Manage",
                        links: [
                            ...(features?.shifts
                                ? [{title: "Shift planning", route: FleetRouteV2.SHIFT_PLANNING}]
                                : []),
                            ...(features?.order_dispatching
                                ? [{title: "Order dispatching", route: FleetRouteV2.ORDER_REQUESTS}]
                                : []),
                            ...(features?.incoming_orders
                                ? [{title: "Incoming orders", route: FleetRouteV2.INCOMING_ORDERS}]
                                : []),
                            {
                                title: "Expiring documents",
                                route: FleetRouteV2.EXPIRING_DOCUMENTS,
                                renderDividerAbove: !!(
                                    features?.shifts ||
                                    features.order_dispatching ||
                                    features.incoming_orders
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                title: "Finances",
                subMenuItems: [
                    {
                        title: "Reports",
                        links: [
                            ...(features?.company_metrics
                                ? [{title: "Company performance", route: FleetRouteV2.COMPANY_PERFORMANCE}]
                                : []),
                            ...(features?.balance
                                ? [{title: "Company balance", route: FleetRouteV2.COMPANY_BALANCE}]
                                : []),
                            ...(features?.earning_reports
                                ? [{title: "Earnings per driver", route: FleetRouteV2.DRIVER_EARNINGS}]
                                : []),
                        ],
                    },
                    {
                        title: "Invoices",
                        links: [
                            {title: "Payouts", route: FleetRouteV2.PAYOUTS},
                            {title: "Bolt to fleet", route: FleetRouteV2.BOLT_INVOICES},
                            ...(features?.rider_invoices
                                ? [{title: "Fleet to rider", route: FleetRouteV2.FLEET_INVOICES}]
                                : []),
                            ...(features?.compensation_invoices
                                ? [{title: "Compensations", route: FleetRouteV2.COMPENSATIONS}]
                                : []),
                        ],
                    },
                    {
                        title: "Bonus campaigns",
                        links: features?.campaigns
                            ? [
                                  {title: "Active and upcoming", route: FleetRouteV2.ACTIVE_AND_UPCOMING_CAMPAIGNS},
                                  {title: "Finished", route: FleetRouteV2.FINISHED_CAMPAIGNS},
                              ]
                            : [],
                    },
                ],
            },
            {
                title: "Supply",
                subMenuItems: [
                    {
                        title: "Drivers",
                        links: [
                            {title: "Driver performance", route: FleetRouteV2.DRIVER_PERFORMANCE},
                            {title: "Applications", route: FleetRouteV2.DRIVER_APPLICATIONS},
                            ...(features?.lead_to_fleet
                                ? [{title: "Proposed candidates", route: FleetRouteV2.PROPOSED_CANDIDATES}]
                                : []),
                        ],
                    },
                    {
                        title: "Vehicles",
                        links: [
                            {title: "Vehicle performance", route: FleetRouteV2.VEHICLE_PERFORMANCE},
                            ...(features?.vehicle_marketplace
                                ? [{title: "Rental listings", route: FleetRouteV2.RENTAL_LISTINGS}]
                                : []),
                            ...(features?.car_rental_payments
                                ? [{title: "Rental payments", route: FleetRouteV2.RENTAL_PAYMENTS}]
                                : []),
                            {title: "Applications", route: FleetRouteV2.VEHICLE_APPLICATIONS},
                        ],
                    },
                ],
            },
            {
                title: "Settings",
                subMenuItems: [
                    {
                        title: "Company settings",
                        links: [
                            {title: "Contact details", route: FleetRouteV2.COMPANY_CONTACT_DETAILS},
                            {title: "Banking info", route: FleetRouteV2.COMPANY_BANKING_INFO},
                            {title: "Tax info", route: FleetRouteV2.COMPANY_TAX_INFO},
                            ...(features?.enterprise_api
                                ? [
                                      {
                                          title: "Data API connections",
                                          route: FleetRouteV2.API_CONNECTIONS,
                                          renderDividerAbove: true,
                                      },
                                  ]
                                : []),
                        ],
                    },
                    {
                        title: "User preferences",
                        subTitle: fleetOwnerEmail,
                        links: [
                            {title: "Notification settings", route: FleetRouteV2.NOTIFICATION_SETTINGS},
                            {title: "Language selection", route: FleetRouteV2.LANGUAGE_SELECTION},
                        ],
                    },
                ],
            },
        ];
        if (variant === "FLEET") {
            return fleetLinks;
        }
        if (variant === "HOLDING_FLEET") {
            return holdingFleetLinks;
        }
        if (variant === "INACTIVE_COMPANY") {
            return inactiveFleetLinks;
        }
        return [];
    }, [acccountState.profile?.email, features, i18n, variant]);

    return navigationLinks;
};
