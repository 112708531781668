import {useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";

import {EventName, EventPropertiesDownloadType} from "common/constants/events";
import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, filePeriod, getPeriodName, Period} from "common/services/period";
import {cleanStringForFileName, downloadBase64File} from "common/util/downloadUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";
import {getIsoDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, SelectOption} from "@bolteu/kalep-react";

import {isDriverSelected} from "../util";
import {OrderFilters} from "./OrdersFilter";

interface Props {
    driver: SelectOption | null;
    period: ApiPeriod;
    filters: OrderFilters;
    isError: boolean;
}

const getOrderHistoryFetchFunction = (api: Api, body: FleetPortalOrderService.GetOrderHistoryCsvRequest) =>
    api.fleetPortalOrder.orderHistoryGetCsv(body, {timeout: 65_000});

const OrderHistoryDownload = ({driver, period, filters, isError}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const account = useContext(AccountContextProvider);
    const {setNotification} = useContext(NotificationContext);
    const [base64File, setBase64File] = useState<string | null>(null);
    const {
        data: downloadedCsv,
        fetch: downloadCsvFetch,
        status: downloadCsvStatus,
        error: downloadCsvFetchError,
    } = useFetch(getOrderHistoryFetchFunction);
    const {trackEvent} = useTracking();

    useEffect(() => {
        if (downloadCsvStatus === FetchStatus.Success && downloadedCsv.file) {
            if (!downloadedCsv.file) {
                setNotification({
                    type: NotificationType.ERROR,
                    text: i18n("auth.app.orders.history.no-data-error"),
                });
            } else {
                setBase64File(downloadedCsv.file);
            }
        }
    }, [downloadCsvStatus, downloadedCsv?.file, i18n, setNotification]);

    useEffect(() => {
        const companyName = account.selectedCompany?.company.name;
        if (base64File && companyName) {
            const periodStr = filePeriod(period, intl.locale);
            const cleanCompanyName = cleanStringForFileName(companyName);
            const driverName = typeof driver?.title === "function" ? driver.title() : driver?.title ?? "";
            const cleanDriverName = isDriverSelected(driver) && cleanStringForFileName(driverName);

            const fileName = !isDriverSelected(driver)
                ? `${i18n("auth.app.orders.history.title")}-${periodStr}-${cleanCompanyName}`
                : `Bolt-${i18n("auth.app.orders.history.title")}-${cleanDriverName}`;
            downloadBase64File(base64File, MimeTypes.CSV, fileName);
            setBase64File(null);
            trackEvent(EventName.FileDownloaded, {
                downloadType: EventPropertiesDownloadType.OrderHistoryCsv,
                periodName: getPeriodName(period),
                period: periodStr,
            });
        }
    }, [account.selectedCompany?.company.name, base64File, intl, i18n, period, driver, trackEvent]);

    useEffect(() => {
        if (downloadCsvStatus === FetchStatus.Error && downloadCsvFetchError.message) {
            const errorMessage =
                downloadCsvFetchError.message === "CLIENT_TIMEOUT"
                    ? i18n("auth.app.orders.history.date-range-too-big")
                    : i18n("api.default_error");

            setNotification({
                type: NotificationType.ERROR,
                text: errorMessage,
                timeout: 6000,
            });
        }
    }, [downloadCsvFetchError.message, downloadCsvStatus, i18n, setNotification]);

    const downloadCsv = useCallback(() => {
        if (!downloadCsvFetch) {
            return;
        }
        const driverId = driver?.value && driver?.value !== -1 ? (driver.value as number) : undefined;
        const dates =
            period.period === Period.CUSTOM ? {start: period.startDate, end: period.endDate} : apiPeriodToDates(period);
        downloadCsvFetch({
            start_date: getIsoDate(dates.start),
            end_date: getIsoDate(dates.end),
            driver_id: driverId,
            order_states: filters.orders_state_statuses,
            search_category_group: filters.search_category_group,
            city_id: filters.city_id,
        });
    }, [
        downloadCsvFetch,
        driver?.value,
        filters.city_id,
        filters.orders_state_statuses,
        filters.search_category_group,
        period,
    ]);

    return (
        <div className="flex justify-end">
            <Button
                variant="secondary"
                onClick={downloadCsv}
                loading={downloadCsvStatus === FetchStatus.Loading}
                disabled={isError}
            >
                {i18n("common.download")}
            </Button>
        </div>
    );
};

export default OrderHistoryDownload;
