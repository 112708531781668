import {useI18n} from "common/hooks/useI18n";

import {FleetCarService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface DriverItemProps {
    driver: FleetCarService.CarDriver;
    listMode?: boolean;
}
function DriverItem({driver, listMode = true}: DriverItemProps) {
    const {i18n} = useI18n();

    const driverIsBlockOrDeactivated =
        driver.status === FleetCarService.EntityPortalStatus.Blocked ||
        driver.status === FleetCarService.EntityPortalStatus.Deactivated;

    const driverHasAccessToCarGroup = driver.access_to_car === FleetCarService.DriverCarAccess.HAS_ACCESS_TO_CAR_GROUP;

    return (
        <div className="flex w-full flex-col items-start">
            <div>{driver.name}</div>
            {listMode && (
                <div className="flex w-full items-center">
                    <div className="flex">
                        {driver.status === FleetCarService.EntityPortalStatus.Blocked && (
                            <div className="text-danger-secondary">
                                {i18n("auth.app.fleet.vehicle_assignment.vehicle.manage_drivers_dialog.blocked")}
                            </div>
                        )}
                        {driver.status === FleetCarService.EntityPortalStatus.Deactivated && (
                            <div className="text-secondary">{i18n("driver.status.deactivated")}</div>
                        )}
                    </div>
                    {driverIsBlockOrDeactivated && driverHasAccessToCarGroup && <span className="px-1">•</span>}
                    {driverHasAccessToCarGroup && (
                        <div className="text-secondary">
                            {i18n("auth.app.fleet.vehicle_assignment.vehicle.manage_drivers_dialog.access_to_all_cars")}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default DriverItem;
