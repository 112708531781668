import {useEffect} from "react";

import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import CompanySettingsPageComponent from "features/account/pages/CompanySettings";

export const CompanySettingsPage = () => {
    const {i18n} = useI18n();

    useEffect(() => {
        const pageTitle = i18n("auth.app.company-settings.title_v2");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    return <CompanySettingsPageComponent />;
};
