import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

export type StepType = 0 | 1 | 2;

export interface Step {
    stepNumber: StepType;
}
export interface Step1 extends Step {
    stepNumber: 0;
    data: Step1Data;
}
export interface Step2 extends Step {
    stepNumber: 1;
    data: Step2Data;
}
export interface Step3 extends Step {
    stepNumber: 2;
    data: Step3Data;
}

export interface Step1Data {
    scheduled_for: Date;
    pickup: Address;
    dropoffs: Address[];
    // Used for price lock per order creation
    create_order_uuid: string;
}
export interface Step2Data {
    payment_method: FleetPortalOrderService.CreateOrderPaymentMethod;
    category_id?: number;
    category_price_str?: string;
    category_upfront_price?: number;
    category_price_lock_hash?: string;
    category_price_lock_hash_manual_price?: string;
    category_ride_duration_seconds?: number;
    category_search_token?: string;
    category_search_token_manual_price?: string;
    manual_price?: string;
    is_manual_price_enabled: boolean;
}
export interface Step3Data {
    assignment_type: FleetPortalOrderService.AssignmentType;
    driver_id?: number;
    driver_name?: string;
    phone_area_code: string;
    phone_number: string;
    passenger_name: string;
    comment: string;
}
export interface CreateOrderData extends Step1Data, Step2Data, Step3Data {}

export interface Address {
    address_name: string;
    address_extra?: string | null;
    full_address?: string | null;
    key: string;
    place_id?: string | null;
    lat?: number;
    lng?: number;
}

export interface AddressWithLatLng {
    address_name: string;
    address_extra?: string | null;
    full_address?: string | null;
    key: string;
    place_id?: string | null;
    lat: number;
    lng: number;
}

export enum CreateDrawerType {
    SCHEDULE = "schedule",
    INSTANT = "instant",
}

export enum AddressType {
    Pickup = "pickup",
    Stop = "stop",
    Dropoff = "dropoff",
}
