import {useCallback, useEffect, useRef, useState} from "react";

import {EventName} from "common/constants/events";
import {useTracking} from "common/hooks/useTracking";

import {FleetOwnerPortalService, FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton} from "@bolteu/kalep-react";
import {File} from "@bolteu/kalep-react-icons";

import CompanyReportStatus = FleetOwnerReportingService.CompanyReportStatus;
import GeneratedFilesBody from "./GeneratedFilesBody";

interface Props {
    drivers: FleetOwnerPortalService.Driver[];
    reports: FleetOwnerReportingService.CompanyReportWithUrl[] | undefined;
    getGeneratedReports: () => void;
}

const GENERATED_FILES_POLL_TIME_MS = 10_000;

const isInProgressOrPending = (report: FleetOwnerReportingService.CompanyReport) =>
    [CompanyReportStatus.IN_PROGRESS, CompanyReportStatus.PENDING].includes(report.status);

const GeneratedFiles = ({drivers, reports, getGeneratedReports}: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const hasPendingOrInProgressReports = useRef(false);
    const {trackEvent} = useTracking();

    const handleExpandClick = useCallback(() => {
        setIsExpanded(true);
        trackEvent(EventName.FleetToRiderGeneratedFilesOpened);
    }, [trackEvent]);

    const handleCollapseClick = useCallback(() => setIsExpanded(false), []);

    useEffect(() => {
        if (!reports || !getGeneratedReports || !reports.some(isInProgressOrPending)) {
            hasPendingOrInProgressReports.current = false;
            return () => {};
        }
        if (!hasPendingOrInProgressReports.current) {
            hasPendingOrInProgressReports.current = true;
            setIsExpanded(true);
        }
        const timeout = window.setTimeout(() => getGeneratedReports(), GENERATED_FILES_POLL_TIME_MS);
        return () => window.clearTimeout(timeout);
    }, [getGeneratedReports, reports]);

    if (!reports?.length) {
        return null;
    }

    let Body = (
        <IconButton
            aria-label="Generated files"
            icon={<File />}
            size="lg"
            variant="floating"
            onClick={handleExpandClick}
        />
    );
    if (isExpanded) {
        Body = <GeneratedFilesBody drivers={drivers} reports={reports} onCollapse={handleCollapseClick} />;
    }
    return (
        <div id="generated-files-fab" className="bg-layer-floor-1 fixed bottom-6 right-4 ml-6">
            {Body}
        </div>
    );
};

export default GeneratedFiles;
