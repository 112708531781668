import {useContext, useEffect} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import BoltToFleetInvoices from "./bolt-to-fleet";
import CompensationsInvoices from "./compensations";
import FleetToRiderInvoices from "./fleet-to-rider";
import {useTabOptions} from "./hooks/useTabOptions";
import PayoutsComponent from "./payouts";

import BillingModel = FleetOwnerReportingService.BillingModel;

export enum InvoiceTab {
    FleetToRider = "fleet_to_rider",
    BoltInvoices = "bolt_invoices",
    Compensations = "compensations",
    Payouts = "payouts",
}

const InvoicesPage = () => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const tabs = useTabOptions(fleet?.billing_model);
    const {activeTab, TabSelector} = useTabs(tabs, InvoiceTab.FleetToRider);

    useEffect(() => {
        const pageTitle = i18n("common.invoices");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const description =
        fleet?.billing_model === BillingModel.RESALE
            ? i18n("auth.app.invoices.taxify-invoices.resale-model")
            : i18n("auth.app.invoices.fleet-to-rider.description");

    return (
        <Page>
            <div className="mb-6 flex max-w-6xl flex-col gap-4">
                <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("common.invoices")} />
                <p className="text-secondary">{description}</p>
            </div>
            <div className="mb-6">
                <TabSelector />
            </div>
            {activeTab === InvoiceTab.FleetToRider && <FleetToRiderInvoices />}
            {activeTab === InvoiceTab.BoltInvoices && <BoltToFleetInvoices />}
            {activeTab === InvoiceTab.Compensations && <CompensationsInvoices />}
            {activeTab === InvoiceTab.Payouts && <PayoutsComponent />}
        </Page>
    );
};

export default InvoicesPage;
