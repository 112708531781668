import {EventAdditionalProperties, EventName} from "common/constants/events";
import {PageName} from "common/constants/pages";
import {CompanyType} from "common/constants/types";
import config from "config";
import * as mixpanelLib from "mixpanel-browser";
import {hasStatisticsConsent} from "@fleet/common/utils/hasStatisticsConsent";

export interface TrackEvent {
    eventName: EventName;
    pageName?: PageName;
    companyId?: number;
    companyType?: CompanyType;
    language?: string;
    additionalProperties?: EventAdditionalProperties;
}

export interface TrackPageLoaded {
    pageName: PageName;
    companyId?: number;
    companyType?: CompanyType;
    language: string;
}

const mixpanel = new Promise<mixpanelLib.Mixpanel>((resolve, reject) => {
    const token = config.mixpanelToken;
    if (!token) {
        return reject();
    }

    return mixpanelLib.init(token, {
        loaded: resolve,
    });
});

async function track(eventName: string, eventData: mixpanelLib.Dict) {
    if (hasStatisticsConsent()) {
        (await mixpanel).track(eventName, {
            ...eventData,
            portalVersion: "v2",
        });
    }
}

export async function trackEvent({
    eventName,
    pageName,
    companyId,
    companyType,
    language,
    additionalProperties,
}: TrackEvent) {
    track(eventName, {
        pageName,
        companyId: companyType === CompanyType.FLEET_COMPANY ? companyId : undefined,
        holdingFleetId: companyType === CompanyType.HOLDING_FLEET ? companyId : undefined,
        language,
        appVersion: config.versionNumber,
        ...additionalProperties,
    });
}

export async function trackPageLoaded({pageName, companyId, companyType, language}: TrackPageLoaded) {
    track(`${pageName} Opened`, {
        pageName,
        companyId: companyType === CompanyType.FLEET_COMPANY ? companyId : undefined,
        holdingFleetId: companyType === CompanyType.HOLDING_FLEET ? companyId : undefined,
        language,
        appVersion: config.versionNumber,
    });
}

export async function identify(fleetOwnerId: number) {
    if (hasStatisticsConsent()) {
        (await mixpanel).identify(`fleetOwner-${fleetOwnerId}`);
    }
}

export async function reset() {
    (await mixpanel).reset();
}
