import {useCallback, useContext} from "react";
import {generatePath} from "react-router-dom";

import {CommonRoute, CompanyOnboardingRoutePrefix, FleetRoute, FleetRouteV2} from "common/constants/routes";
import {isActiveOrBlockedFleet} from "common/constants/types";
import {AccountContextProvider} from "features/account/accountStateProvider";

const VERSION_V2 = "v2/";
export const useAbsolutePath = () => {
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState?.getFleet();
    const fleetId = fleet?.id;
    const isCompanyOnboarding = !isActiveOrBlockedFleet(fleet);
    const isNewNavigationEnabled = accountState.profile?.isNewNavigationEnabled;

    const routes = isNewNavigationEnabled ? FleetRouteV2 : FleetRoute;

    const getHomePath = useCallback(() => {
        if (isCompanyOnboarding) {
            return `/${CompanyOnboardingRoutePrefix}/`;
        }
        return CommonRoute.HOME;
    }, [isCompanyOnboarding]);

    const getRentalPaymentsPath = useCallback(() => `/${fleetId}/${routes.RENTAL_PAYMENTS}`, [fleetId, routes]);

    const getDriverDetailsPath = useCallback(
        (id: number, addVersion = false) =>
            generatePath(`${getHomePath()}${addVersion ? VERSION_V2 : ""}${fleetId}/${routes.DRIVER}`, {
                id: String(id),
            }),
        [fleetId, getHomePath, routes],
    );
    const getVehicleDetailsPath = useCallback(
        (id: number) => generatePath(`${getHomePath()}${fleetId}/${routes.VEHICLE}`, {id: String(id)}),
        [fleetId, getHomePath, routes],
    );
    const getVehicleApplicationPath = useCallback(
        (id: number) => generatePath(`${getHomePath()}${fleetId}/${routes.VEHICLE_APPLICATION}`, {id: String(id)}),
        [fleetId, getHomePath, routes],
    );
    const getCompanyRegistrationPath = useCallback(
        (hash: string) => generatePath(`${CommonRoute.REGISTER_COMPANY}`, {hash: String(hash)}),
        [],
    );
    const getCompanyOnboardingPath = useCallback(
        (companyId: string) => generatePath(`${CommonRoute.ONBOARD_COMPANY}`, {companyId: String(companyId)}),
        [],
    );

    const getVerificationPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION}`, {key: attemptKey}),
        [],
    );

    const getVerificationPasswordPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION_PASSWORD}`, {key: attemptKey}),
        [],
    );

    const getVerificationConfirmationPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION_CONFIRMATION}`, {key: attemptKey}),
        [],
    );

    const getVerification2faPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION_2FA}`, {key: attemptKey}),
        [],
    );

    const getVerification2faConfirmationPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION_2FA_CONFIRMATION}`, {key: attemptKey}),
        [],
    );

    const getVehicleListingEditPath = useCallback(
        (id: number) =>
            generatePath(`${getHomePath()}${fleetId}/${routes.VEHICLE_LISTING_EDIT}`, {
                id: String(id),
            }),
        [fleetId, getHomePath, routes.VEHICLE_LISTING_EDIT],
    );

    const getVehicleListingViewPath = useCallback(
        (id: number) =>
            generatePath(`${getHomePath()}${fleetId}/${routes.VEHICLE_LISTING_VIEW}`, {
                id: String(id),
            }),
        [fleetId, getHomePath, routes.VEHICLE_LISTING_VIEW],
    );

    const getVehicleListingApplicationPath = useCallback(
        (id: number) =>
            generatePath(`${getHomePath()}${fleetId}/${routes.VEHICLE_LISTING_VIEW_APPLICATION}`, {
                applicationId: String(id),
            }),
        [fleetId, getHomePath, routes.VEHICLE_LISTING_VIEW_APPLICATION],
    );

    const getVehicleListingCreatePath = useCallback(
        () => generatePath(`${getHomePath()}${fleetId}/${routes.VEHICLE_LISTING_CREATE}`),
        [fleetId, getHomePath, routes.VEHICLE_LISTING_CREATE],
    );

    const getVehiclesPath = useCallback(
        (data?: {toListingsTab?: boolean; toApplicationsTab?: boolean}) => {
            let queryParams = "";

            switch (true) {
                case data?.toApplicationsTab:
                    queryParams = "?tab=applications";
                    break;
                case data?.toListingsTab:
                    queryParams = "?tab=renting_out";
                    break;
                default:
                    queryParams = "?tab=vehicles";
            }

            return generatePath(`${getHomePath()}${fleetId}/${routes.VEHICLES}${queryParams}`);
        },
        [fleetId, getHomePath, routes.VEHICLES],
    );

    const getDriversPath = useCallback(
        () => generatePath(`${getHomePath()}${fleetId}/${routes.DRIVERS}`),
        [fleetId, getHomePath, routes.DRIVERS],
    );

    const getLiveMapPath = useCallback(
        () => generatePath(`${getHomePath()}${fleetId}/${routes.LIVE_MAP}`),
        [fleetId, getHomePath, routes.LIVE_MAP],
    );

    const getCampaignsPath = useCallback(
        () => generatePath(`${getHomePath()}${fleetId}/${routes.CAMPAIGNS}`),
        [fleetId, getHomePath, routes.CAMPAIGNS],
    );

    return {
        getRentalPaymentsPath,
        getVehiclesPath,
        getDriversPath,
        getDriverDetailsPath,
        getVehicleDetailsPath,
        getVehicleApplicationPath,
        getCompanyRegistrationPath,
        getCompanyOnboardingPath,
        getHomePath,
        getVerificationPath,
        getVerificationPasswordPath,
        getVerificationConfirmationPath,
        getVerification2faPath,
        getVerification2faConfirmationPath,
        getVehicleListingEditPath,
        getVehicleListingViewPath,
        getVehicleListingCreatePath,
        getVehicleListingApplicationPath,
        getLiveMapPath,
        getCampaignsPath,
    };
};
