import {useCallback, useEffect, useMemo, useState} from "react";

import LoadingSpinner from "common/components/spinner";
import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {FetchStatus} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";

import {GhostButton} from "@bolteu/kalep-react";
import {ChevronDown} from "@bolteu/kalep-react-icons";
import {TableProps} from "@bolteu/kalep-table-react";
import {TableItem} from "@bolteu/kalep-table-react/build/types";

import {TABLE_ROWS_PER_PAGE} from "../contants";

interface Props<Entity extends TableItem> extends TableProps<Entity> {
    loadMore: () => void;
    dataFetchStatus: FetchStatus;
    clientSidePagination?: boolean;
}

const LoadMoreTable = <Entity extends TableItem>({
    items,
    dataFetchStatus,
    loadMore,
    clientSidePagination,
    ...props
}: Props<Entity>) => {
    const {i18n} = useI18n();
    const [previousData, setPreviousData] = useState<Entity[]>([]);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    useEffect(() => {
        if ([FetchStatus.Success, FetchStatus.Error].includes(dataFetchStatus)) {
            setIsLoadingMore(false);
        }
    }, [dataFetchStatus]);

    useEffect(() => {
        if (dataFetchStatus === FetchStatus.Loading && !isLoadingMore) {
            setPreviousData([]);
        }
    }, [dataFetchStatus, isLoadingMore]);

    const handleLoadMoreClick = useCallback(() => {
        setPreviousData(previousData.concat(items));
        if (!clientSidePagination) {
            setIsLoadingMore(true);
        }
        loadMore();
    }, [previousData, items, loadMore, clientSidePagination]);

    const combinedData = useMemo(() => {
        if (isLoadingMore) {
            return previousData;
        }
        return previousData.concat(items);
    }, [items, isLoadingMore, previousData]);

    const isLoadingFirstPatch = [FetchStatus.Init, FetchStatus.Loading].includes(dataFetchStatus);
    const shouldShowLoadMore =
        isLoadingMore ||
        (!isLoadingFirstPatch && items.length === TABLE_ROWS_PER_PAGE && dataFetchStatus !== FetchStatus.Error);

    const tableData = dataFetchStatus === FetchStatus.Error ? [] : combinedData;

    return (
        <>
            <FixedHeaderTable items={tableData} isLoading={isLoadingMore ? false : isLoadingFirstPatch} {...props} />
            {shouldShowLoadMore && (
                <GhostButton
                    overrideClassName="py-4 text-action-primary text-sm w-full"
                    onClick={handleLoadMoreClick}
                    disabled={isLoadingMore}
                >
                    <span className="flex items-center justify-center gap-2">
                        {i18n("common.load_more")}
                        {!isLoadingMore && <ChevronDown size="sm" />}
                        {isLoadingMore && <LoadingSpinner show size="sm" spinnerColorCss="text-action-primary" />}
                    </span>
                </GhostButton>
            )}
        </>
    );
};

export default LoadMoreTable;
