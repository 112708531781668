import React, {FC} from "react";

export const InfoRow: FC<{
    title: string;
    value: string;
    icon?: React.ReactElement;
}> = ({title, value, icon = null}) => {
    const Icon = icon;

    return (
        <div className="border-separator flex flex-row items-center justify-between py-3 [&:not(:last-child)]:border-b">
            <div className="flex flex-col">
                <span className="text-secondary text-sm">{title}</span>
                <span className="text-primary">{value}</span>
            </div>
            {Icon}
        </div>
    );
};
