import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetDriverInvitationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton, Tooltip} from "@bolteu/kalep-react";
import {Call, Mail} from "@bolteu/kalep-react-icons";
import InvitedDriver = FleetDriverInvitationService.InvitedDriver;

interface DriverDetailsProps {
    driverInvitation: InvitedDriver;
}

const getCallFieldAction = (phone: string, label: string) => (
    <Tooltip content={label} placement="top">
        <a href={`tel:${phone}`}>
            <IconButton aria-label="Call the driver" size="sm" icon={<Call />} />
        </a>
    </Tooltip>
);

const getMailFieldAction = (mail: string, label: string) => (
    <Tooltip content={label} placement="top">
        <a href={`mailto:${mail}`}>
            <IconButton aria-label="Mail the driver" size="sm" icon={<Mail />} />
        </a>
    </Tooltip>
);

const DriverInvitationDetails: FC<DriverDetailsProps> = ({driverInvitation}) => {
    const {i18n} = useI18n();

    const getProfileField = (title: string, value: string | null, fieldAction?: React.ReactNode) => (
        <div className="border-separator flex h-20 items-center justify-between border-b py-4">
            <div>
                <h4 className="text-secondary">{title}</h4>
                <p>{value ?? ""}</p>
            </div>
            {fieldAction}
        </div>
    );

    return (
        <div className="flex flex-col leading-6">
            {getProfileField(
                i18n("auth.app.fleet.driver.email"),
                driverInvitation.email,
                driverInvitation.email ? getMailFieldAction(driverInvitation.email, i18n("common.email")) : undefined,
            )}
            {getProfileField(
                i18n("auth.app.fleet.driver.phone"),
                driverInvitation.phone,
                getCallFieldAction(driverInvitation.phone, i18n("common.call")),
            )}
        </div>
    );
};

export default DriverInvitationDetails;
