import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import Header, {HeaderSize} from "common/components/header/Header";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {FeaturesContextProvider} from "FeaturesProvider";

import {FleetIntegrationControlService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton, Grid} from "@bolteu/kalep-react";

import CredentialsView from "./CredentialsView/CredentialsView";
import Documentation from "./Documentation";
import NoCredentialsView from "./NoCredentialsView";

type FleetIntegrationCredentials = FleetIntegrationControlService.FleetIntegrationCredentials;
type FleetIntegrationClientInfo = FleetIntegrationControlService.FleetIntegrationClientInfo;

const fetchFunction = (api: Api) => api.fleetIntegrationControl.fleetIntegrationGetClientInfo();

const ApiSettings: FC = () => {
    const features = useContext(FeaturesContextProvider);

    const {i18n} = useI18n();
    const {data, fetch} = useFetch(fetchFunction);

    const [generatedCredentials, setGeneratedCredentials] = useState<FleetIntegrationCredentials | null>(null);
    const [clientInfo, setClientInfo] = useState<FleetIntegrationClientInfo | null>(null);
    const [isDocumentationOpen, setIsDocumentationOpen] = useState(false);

    useEffect(() => {
        const pageTitle = i18n("auth.app.api_credentials.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    useEffect(() => {
        if (data) {
            setClientInfo(data);
        }
    }, [data]);

    const isAllowedCredentialsAccess = generatedCredentials || clientInfo;

    const body = useMemo(() => {
        if (isAllowedCredentialsAccess) {
            return (
                <div className="mt-4">
                    <CredentialsView
                        generatedCredentials={generatedCredentials}
                        clientInfo={clientInfo}
                        setCredentials={setGeneratedCredentials}
                        setClientInfo={setClientInfo}
                    />
                </div>
            );
        }

        return <NoCredentialsView setCredentials={setGeneratedCredentials} />;
    }, [isAllowedCredentialsAccess, generatedCredentials, clientInfo, setGeneratedCredentials]);

    const handleDocumentationOpen = useCallback(() => {
        setIsDocumentationOpen(true);
    }, []);

    const handleDocumentationClose = useCallback(() => {
        setIsDocumentationOpen(false);
    }, []);

    if (!features?.enterprise_api) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    return (
        <Grid columns={12}>
            <Grid.Item colSpan={7}>
                <Header size={HeaderSize.Small} text={i18n("auth.app.api_credentials.title")} />
                <div className="text-secondary mt-2 mb-8">
                    <p className="text-secondary">{i18n("auth.app.api_credentials.introduction")}</p>
                    {i18n("auth.app.api_credentials.description.text", {
                        api_documentation_link: (
                            <GhostButton onClick={handleDocumentationOpen}>
                                <span className="text-action-primary">
                                    {i18n("auth.app.api_credentials.description.link")}
                                </span>
                            </GhostButton>
                        ),
                    })}
                </div>
                <Documentation isOpen={isDocumentationOpen} handleClose={handleDocumentationClose} />
                {body}
            </Grid.Item>
        </Grid>
    );
};

export default ApiSettings;
