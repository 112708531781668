import {useCallback, useMemo} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ComboBox, ListItemLayout, OptionProps, SelectOption, Typography} from "@bolteu/kalep-react";
import {RenderFunction} from "@bolteu/kalep-react/build/helpers";

import {ShiftAssignment} from "../components/ManageShiftAssignmentsPage";
import {ShiftTableData} from "../components/ShiftAssignmentsTable";
import AssigmentVehicle = FleetShiftManagementService.AssigmentVehicle;
import AssigmentShift = FleetShiftManagementService.AssigmentShift;

function makeVehicleOption(vehicle: FleetShiftManagementService.AssigmentVehicle): SelectOption {
    return {
        title: `${vehicle.model}, ${vehicle.year}`,
        secondary: `${vehicle.reg_number} • ${vehicle.color}`,
        value: vehicle.id,
    };
}

function getAccessVehiclesOptions(
    vehicles: FleetShiftManagementService.AssigmentVehicle[],
    carAccessPolicy: FleetShiftManagementService.DriverAccessCarsPolicyLite,
): {
    assigned_vehicles: SelectOption[];
    not_assigned_vehicles: SelectOption[];
} {
    const allVehicleOptions = vehicles.map(makeVehicleOption);
    if (carAccessPolicy.type === FleetShiftManagementService.DriverAccessCarsPolicyType.ALL) {
        return {
            assigned_vehicles: allVehicleOptions,
            not_assigned_vehicles: [],
        };
    }
    const assignedVehicles = new Set<string | number | null>(carAccessPolicy.car_ids);
    return {
        assigned_vehicles: allVehicleOptions.filter((option) => assignedVehicles.has(option.value)),
        not_assigned_vehicles: allVehicleOptions.filter((option) => !assignedVehicles.has(option.value)),
    };
}

export const useTableColumns = (
    tableData: ShiftTableData[],
    vehicles: AssigmentVehicle[] | undefined,
    shifts: AssigmentShift[] | undefined,
    updatedAssignments: Map<number, ShiftAssignment>,
    onAssignmentChange: (id: number, updatedAssignment: Partial<ShiftAssignment>) => void,
) => {
    const {i18n} = useI18n();

    const getVehicleSelectOptions = useCallback(
        (carAccessPolicy: FleetShiftManagementService.DriverAccessCarsPolicyLite | undefined): SelectOption[] => {
            if (!carAccessPolicy) {
                return vehicles?.map(makeVehicleOption) ?? [];
            }
            const {assigned_vehicles: assignedVehiclesOptions, not_assigned_vehicles: notAssignedVehiclesOptions} =
                getAccessVehiclesOptions(vehicles ?? [], carAccessPolicy);
            const options: SelectOption[] = [];
            if (assignedVehiclesOptions.length > 0) {
                options.push({
                    title: i18n("auth.app.fleet.shifts.assign_shift.vehicles_access.access_granted"),
                    disabled: true,
                    value: null,
                });
                options.push(...assignedVehiclesOptions);
            }
            if (notAssignedVehiclesOptions.length > 0) {
                options.push({
                    title: i18n("auth.app.fleet.shifts.assign_shift.vehicles_access.grant_new_access_and_assign"),
                    disabled: true,
                    value: null,
                });
                options.push(...notAssignedVehiclesOptions);
            }
            return options;
        },
        [vehicles, i18n],
    );

    const getShiftSelectOptions = useMemo(
        (): SelectOption[] =>
            shifts?.map((s) => ({
                title: s.name,
                secondary: `${s.start_time} - ${s.end_time}`,
                value: s.id,
            })) || [],
        [shifts],
    );

    const getVehicleSelectValue = useCallback(
        (vehicleId: number | null, options: SelectOption[]) =>
            vehicleId ? options.find((option) => option.value === vehicleId) ?? null : null,
        [],
    );

    const getShiftSelectValue = useCallback(
        (shiftId: number | null) =>
            shiftId ? getShiftSelectOptions.find((option) => option.value === shiftId) ?? null : null,
        [getShiftSelectOptions],
    );

    const handleVehicleChange = useCallback(
        (driverId: number) => (newValue: SelectOption | SelectOption[] | null) => {
            if (!Array.isArray(newValue)) {
                const currentAssignment =
                    updatedAssignments.get(driverId) || tableData.find((data) => data.id === driverId);

                onAssignmentChange(driverId, {
                    vehicle_id: newValue ? Number(newValue.value) : null,
                    shift_id: currentAssignment?.shift_id ?? null,
                });
            }
        },
        [onAssignmentChange, updatedAssignments, tableData],
    );

    const handleShiftChange = useCallback(
        (driverId: number) => (newValue: SelectOption | SelectOption[] | null) => {
            if (!Array.isArray(newValue)) {
                const currentAssignment =
                    updatedAssignments.get(driverId) || tableData.find((data) => data.id === driverId);

                onAssignmentChange(driverId, {
                    shift_id: newValue ? Number(newValue.value) : null,
                    vehicle_id: currentAssignment?.vehicle_id ?? null,
                });
            }
        },
        [onAssignmentChange, updatedAssignments, tableData],
    );

    const filterVehicleOptions = useCallback(
        (options: SelectOption[], {inputValue}: {inputValue: string}): SelectOption[] => {
            const lowerCasedInputValue = inputValue.toLocaleLowerCase();
            return options.filter((option) => {
                const label = typeof option.title === "function" ? option.title() : option.title ?? "";
                // eslint-disable-next-line @typescript-eslint/no-base-to-string -- Secondary is type of ReactNode but we have it here it as string
                const secondaryLabel = String(option.secondary ?? option.value ?? "");
                return (
                    label.toLocaleLowerCase().includes(lowerCasedInputValue) ||
                    secondaryLabel.toLocaleLowerCase().includes(lowerCasedInputValue) ||
                    option.value === null
                );
            });
        },
        [],
    );

    const renderOption = ({ref, ...props}: OptionProps) => (
        <li ref={ref} {...props} data-value={props.option.value}>
            <ListItemLayout
                primary={
                    props.option.value == null ? (
                        <Typography fontSize="text-base" fontWeight="semibold" variant="body-primary">
                            {props.label}
                        </Typography>
                    ) : (
                        props.label
                    )
                }
                selected={props.selected}
                secondary={props.option.secondary}
                selectionMode="solo-checkmark"
                highlighted={props.highlighted}
                disabled={props.option.disabled}
                variant={props.size === "sm" ? "sm" : "base"}
            />
        </li>
    );

    const renderComboBox = useCallback(
        ({
            value,
            options,
            onChange,
            placeholder,
            fullWidth,
            filterOptions,
            renderFunction,
        }: {
            value: SelectOption | null;
            options: SelectOption[];
            onChange: (newValue: SelectOption | SelectOption[] | null) => void;
            placeholder: string;
            fullWidth: boolean;
            filterOptions?: (options: SelectOption[], {inputValue}: {inputValue: string}) => SelectOption[];
            renderFunction?: RenderFunction<OptionProps>;
        }) => (
            <div className="p-3">
                <ComboBox
                    value={value}
                    options={options}
                    onChange={onChange}
                    placeholder={placeholder}
                    fullWidth={fullWidth}
                    clearable
                    filterOptions={filterOptions}
                    renderOption={renderFunction}
                />
            </div>
        ),
        [],
    );

    const tableColumns = useMemo(
        () => ({
            driver_name: {
                label: i18n("auth.app.fleet.shifts.assign_shift.driver"),
                sortable: true,
            },
            vehicle_id: {
                label: i18n("auth.app.fleet.shifts.assign_shift.vehicle"),
                renderCell: (item: ShiftTableData) => {
                    const options = getVehicleSelectOptions(item.car_access_policy);
                    return renderComboBox({
                        value: getVehicleSelectValue(item.vehicle_id, options),
                        options,
                        onChange: handleVehicleChange(item.id),
                        placeholder: i18n("auth.app.fleet.shifts.assign_shift.select_vehicle"),
                        fullWidth: false,
                        filterOptions: filterVehicleOptions,
                        renderFunction: renderOption,
                    });
                },
            },
            shift_id: {
                label: i18n("auth.app.fleet.shifts.assign_shift.shift"),
                renderCell: (item: ShiftTableData) =>
                    renderComboBox({
                        value: getShiftSelectValue(item.shift_id),
                        options: getShiftSelectOptions,
                        onChange: handleShiftChange(item.id),
                        placeholder: i18n("auth.app.fleet.shifts.assign_shift.select_shift"),
                        fullWidth: false,
                    }),
            },
        }),
        [
            i18n,
            renderComboBox,
            getVehicleSelectValue,
            getVehicleSelectOptions,
            handleVehicleChange,
            filterVehicleOptions,
            getShiftSelectValue,
            getShiftSelectOptions,
            handleShiftChange,
        ],
    );

    const accordionTableColumns = useMemo(
        () => ({
            vehicle_id: {
                renderCell: (item: ShiftTableData) => {
                    const options = getVehicleSelectOptions(item.car_access_policy);
                    return renderComboBox({
                        value: getVehicleSelectValue(item.vehicle_id, options),
                        options,
                        onChange: handleVehicleChange(item.id),
                        placeholder: i18n("auth.app.fleet.shifts.assign_shift.select_vehicle"),
                        fullWidth: true,
                        filterOptions: filterVehicleOptions,
                        renderFunction: renderOption,
                    });
                },
            },
            shift_id: {
                renderCell: (item: ShiftTableData) =>
                    renderComboBox({
                        value: getShiftSelectValue(item.shift_id),
                        options: getShiftSelectOptions,
                        onChange: handleShiftChange(item.id),
                        placeholder: i18n("auth.app.fleet.shifts.assign_shift.select_shift"),
                        fullWidth: true,
                    }),
            },
        }),
        [
            renderComboBox,
            getVehicleSelectValue,
            getVehicleSelectOptions,
            handleVehicleChange,
            i18n,
            filterVehicleOptions,
            getShiftSelectValue,
            getShiftSelectOptions,
            handleShiftChange,
        ],
    );

    return {tableColumns, accordionTableColumns};
};
