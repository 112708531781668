import {useEffect} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";

import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";
import IncomingOrdersComponent from "./incoming-orders";
import OrderHistoryComponent from "./order-history";
import {OrderRequests as OrderRequestsTab} from "./order-requests";
import {OrderRequestsProvider} from "./OrderRequestsContextProvider";

export enum OrdersTabs {
    OrderHistory = "order_history",
    IncomingOrders = "incoming_orders",
    OrderRequests = "order_requests",
}

const OrdersPage = () => {
    const {i18n} = useI18n();

    useEffect(() => {
        const pageTitle = i18n("auth.app.orders.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const tabs = useTabSelectorOptions();
    const {activeTab, TabSelector} = useTabs(tabs, OrdersTabs.OrderHistory);

    let titleMessage: string | undefined;
    switch (activeTab) {
        case OrdersTabs.IncomingOrders:
            titleMessage = i18n("auth.app.orders.incoming.title-message");
            break;
        case OrdersTabs.OrderRequests:
        case OrdersTabs.OrderHistory:
            if (tabs.find((tab) => tab.id === OrdersTabs.OrderRequests)) {
                titleMessage = i18n("auth.app.orders.title-message");
                break;
            }
            titleMessage = i18n("auth.app.orders.history.lead");
            break;
        default:
            titleMessage = i18n("auth.app.orders.history.lead");
    }

    return (
        <Page>
            <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("auth.app.orders.title")} />
            <div className="text-secondary font-normal">{titleMessage}</div>
            {tabs.length > 1 && <TabSelector />}
            <div>
                {activeTab === OrdersTabs.OrderHistory && <OrderHistoryComponent />}
                {activeTab === OrdersTabs.IncomingOrders && <IncomingOrdersComponent />}
                {activeTab === OrdersTabs.OrderRequests && <OrderRequestsTab />}
            </div>
        </Page>
    );
};

const OrdersPageWithProviders = () => (
    <OrderRequestsProvider>
        <OrdersPage />
    </OrderRequestsProvider>
);

export default OrdersPageWithProviders;
