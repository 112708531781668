import {useCallback, useContext, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";

import DriverCategoriesManagementRenderer from "../components/DriverCategoriesRenderer";
import ManageDriverCategoriesDialog from "./ManageDriverCategoriesDialog";
import {useDriverCategories} from "./useDriverCategories";

interface DriverCategoriesManagerProps {
    driverId: number;
}

export default function DriverCategoriesManager({driverId}: DriverCategoriesManagerProps) {
    const {i18n} = useI18n();
    const [isManageDriverCategoriesDialogOpen, setIsManageDriverCategoriesDialogOpen] = useState(false);

    const accountState = useContext(AccountContextProvider);

    const {data, refetch} = useDriverCategories(accountState, driverId);

    const onDriverCategoriesManagementBtnClicked = useCallback(() => {
        setIsManageDriverCategoriesDialogOpen(true);
    }, []);

    const onManageDriverCategoriesDialogRequestClose = useCallback(() => {
        setIsManageDriverCategoriesDialogOpen(false);
        refetch();
    }, [refetch]);

    return (
        data?.categories && (
            <>
                <DriverCategoriesManagementRenderer
                    title={i18n("auth.app.fleet.driver-profile.driver_categories.header.title")}
                    buttonText={i18n("auth.app.fleet.driver-profile.driver_categories.manage_button")}
                    onButtonClicked={onDriverCategoriesManagementBtnClicked}
                    discoveryTooltipContent={i18n(
                        "auth.app.fleet.driver-profile.driver_categories.discovery_tooltip.text",
                    )}
                    driverCategories={data.categories}
                />
                {isManageDriverCategoriesDialogOpen && (
                    <ManageDriverCategoriesDialog
                        onRequestClose={onManageDriverCategoriesDialogRequestClose}
                        driverId={driverId}
                        driverCategories={data.categories}
                    />
                )}
            </>
        )
    );
}
