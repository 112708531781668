import {useCallback, useContext, useMemo} from "react";
import {Link, matchPath, NavLink, useLocation} from "react-router-dom";

import Logo from "assets/icons/bw-logo.svg?react";
import InfoBubble from "assets/icons/info-bubble.svg?react";
import {CommonRoute, CompanyOnboardingRoutePrefix, HoldingFleetRoutePrefix} from "common/constants/routes";
import {isActiveOrBlockedFleet} from "common/constants/types";
import SubMenu, {NavBarVariant} from "common/navigation/components/SubMenu";
import {MenuContent, SubMenuItem} from "common/navigation/hooks/useNavigationLinksV2";
import {checkIfCompanyOnboardingRoute} from "common/util/routeUtils";
import CompanySelectorWithApplication from "features/account/components/CompanySelectorWithApplication";
import NotificationQuickView from "features/app/notifications/NotificationQuickView";
import {NavigationBarVisbilityContextProvider} from "NavigationBarVisibilityProvider";
import {SupportWidgetContextProvider} from "SupportWidgetProvider";

import {FleetOwnerAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton, IconButton, Menu, Typography} from "@bolteu/kalep-react";
import {ChevronDown} from "@bolteu/kalep-react-icons";

import {AccountContextProvider} from "../../../features/account/accountStateProvider";
import {NavWrapper} from "./NavWrapper";
import {SidebarButton} from "./Sidebar";

interface TopbarProps {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    navigationLinks: MenuContent[];
}

const useRoutePrefix = () => {
    const accountState = useContext(AccountContextProvider);

    const isHoldingFleet = Boolean(accountState.getHoldingFleet());
    const isCompanyOnboarding = !isActiveOrBlockedFleet(accountState.getFleet());
    let routePrefix = isCompanyOnboarding ? CompanyOnboardingRoutePrefix : "";
    if (isHoldingFleet) {
        routePrefix = HoldingFleetRoutePrefix;
    }
    return routePrefix;
};

const MenuItem = ({title, subMenuItems, route}: MenuContent) => {
    const location = useLocation();
    const routePrefix = useRoutePrefix();

    const stub = useCallback(() => {}, []);

    const renderMenuButton = useCallback(
        (subMenuItems: SubMenuItem[]) =>
            ({open}: {open: boolean}) => {
                const hasActiveNavLinks = subMenuItems.some((item) =>
                    item.links.some(({route}) =>
                        matchPath(`${routePrefix}/:id/${route}`, location.pathname + location.search),
                    ),
                );
                return (
                    <GhostButton
                        onClick={stub}
                        overrideClassName={`hover:border-action-secondary flex h-full items-center justify-center gap-2 border-b-[3px] px-3 text-center ${
                            hasActiveNavLinks ? "border-action-primary" : "border-transparent"
                        }`}
                    >
                        <Typography color="primary">{title}</Typography>
                        <Typography color="secondary">
                            <ChevronDown
                                width={20}
                                height={20}
                                className={`transition-transform duration-200 ${open ? "rotate-180" : "rotate-0"}`}
                            />
                        </Typography>
                    </GhostButton>
                );
            },
        [location.pathname, location.search, routePrefix, stub, title],
    );

    const getNavLinkClassNames = useCallback(({isActive}: {isActive: boolean}) => {
        const defaultClassNames =
            "hover:border-action-secondary flex h-full items-center justify-center border-b-[3px] border-transparent px-3";
        if (isActive) {
            return `${defaultClassNames} border-action-primary`;
        }
        return defaultClassNames;
    }, []);

    if (subMenuItems.length === 0 && route) {
        return (
            <NavLink to={route} className={getNavLinkClassNames}>
                {({isActive}) => (
                    <Typography color="primary" fontWeight={isActive ? "semibold" : "regular"}>
                        {title}
                    </Typography>
                )}
            </NavLink>
        );
    }

    if (subMenuItems.length === 0) {
        return null;
    }

    return (
        <Menu menuButton={renderMenuButton(subMenuItems)} openOnHover overrideClassName="w-auto !shadow-xl">
            <div className="flex flex-row space-x-10 px-6 py-3">
                {subMenuItems.map((section) => (
                    <SubMenu key={section.title} section={section} variant={NavBarVariant.TOPBAR} />
                ))}
            </div>
        </Menu>
    );
};

export const TopbarV2 = ({isSidebarOpen, navigationLinks, toggleSidebar}: TopbarProps) => {
    const location = useLocation();
    const {isNavigationBarVisible} = useContext(NavigationBarVisbilityContextProvider);
    const {isSupportWidgetEnabled, hasUnreadCases, toggleSupportWidget} = useContext(SupportWidgetContextProvider);
    const SupportButton = isSupportWidgetEnabled ? (
        <IconButton
            icon={
                <div className="relative leading-none">
                    <InfoBubble className="h-6 w-6 md:h-5 md:w-5" />
                    {hasUnreadCases && (
                        <span className="bg-warning-primary border-primary-inverted absolute -top-1 -right-1 z-50 h-3 w-3 rounded-full" />
                    )}
                </div>
            }
            variant="ghost"
            size="sm"
            aria-label="Support"
            onClick={toggleSupportWidget}
            value="csBtn"
        />
    ) : null;

    const selectedCompany = useContext(AccountContextProvider)?.selectedCompany;
    const isCompanyOnboardingRoute = checkIfCompanyOnboardingRoute(location.pathname);

    const homePath: string = useMemo(() => {
        if (selectedCompany?.type === FleetOwnerAuthService.CompanyIdentityType.HOLDING_FLEET) {
            return `/${HoldingFleetRoutePrefix}/${selectedCompany.company.id}/`;
        }
        if (isCompanyOnboardingRoute) {
            return `/${CompanyOnboardingRoutePrefix}/${selectedCompany?.company?.id}/`;
        }
        return CommonRoute.HOME;
    }, [isCompanyOnboardingRoute, selectedCompany?.company.id, selectedCompany?.type]);

    if (!isNavigationBarVisible) {
        return null;
    }

    return (
        <NavWrapper>
            <div className="flex items-center xl:ml-4">
                <SidebarButton isSidebarOpen={isSidebarOpen} onClick={toggleSidebar} />
                <Link to={homePath} className="">
                    <Logo className="" />
                </Link>
                <div className="ml-10 hidden h-full items-center xl:flex">
                    {navigationLinks.map((item) => (
                        <MenuItem
                            key={item.title}
                            title={item.title}
                            subMenuItems={item.subMenuItems}
                            route={item.route}
                        />
                    ))}
                </div>
            </div>
            <div className="ml-8 flex min-w-0 items-center gap-2">
                <NotificationQuickView />
                {SupportButton}
                <div className="ml-8 mr-5 hidden md:block">
                    <CompanySelectorWithApplication />
                </div>
            </div>
        </NavWrapper>
    );
};
