import {useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import IncomingOrders from "features/companies/orders/incoming-orders";
import {OrderRequests} from "features/companies/orders/order-requests";
import {OrderRequestsProvider} from "features/companies/orders/OrderRequestsContextProvider";
import {PlanningTab} from "features/companies/shifts/planning";

import {TabName, useManagementTabSelectorOptions} from "./hooks/useManagementTabSelectorOptions";

const ManagementPageComponent = () => {
    const tabs = useManagementTabSelectorOptions();
    const {activeTab, TabSelector} = useTabs(tabs, tabs[0].id);

    useEffect(() => {
        // TODO translate, here and below
        const pageTitle = "Management";
        document.title = getDocumentTitle(pageTitle);
    }, []);

    let TabComponent = null;
    switch (activeTab) {
        case TabName.OrderRequests:
            TabComponent = <OrderRequests />;
            break;
        case TabName.IncomingOrders:
            TabComponent = <IncomingOrders />;
            break;
        case TabName.ShiftPlanning:
            TabComponent = <PlanningTab />;
            break;
        default:
    }

    // As every tab is behind a feature flag, we could have 0 tabs in this page
    if (tabs.length === 0) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    return (
        <Page className="gap-4">
            <ResponsiveHeader text="Management" type={ResponsiveHeaderType.MainPage} />
            <TabSelector />
            {TabComponent}
        </Page>
    );
};

export const ManagementPage = () => (
    <OrderRequestsProvider>
        <ManagementPageComponent />
    </OrderRequestsProvider>
);
