import {useMemo} from "react";
import {Link} from "react-router-dom";

import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import {getPublicApplicationStatus} from "../../application/util/publicApplicationStatus";

export function useTableColumns() {
    const {i18n} = useI18n();

    return useMemo<TableHeaders<CarApplicationService.CarApplicationListModel>>(
        () => ({
            model: {
                label: i18n("auth.app.fleet.vehicles.vehicle"),
                renderCell: (item: CarApplicationService.CarApplicationListModel) => (
                    <DefaultCellContent>
                        <Link to={{pathname: `applications/${item.id}`}}>
                            <span>{`${item.model} (${item.year})`}</span>
                            <p className="text-secondary">{i18n(`vehicle.color.${item.color}` as TranslationKeys)}</p>
                        </Link>
                    </DefaultCellContent>
                ),
            },
            reg_number: {
                label: i18n("auth.app.fleet.vehicles.license_plate"),
                renderCell: (item: CarApplicationService.CarApplicationListModel) => (
                    <DefaultCellContent className="text-base">{item.reg_number}</DefaultCellContent>
                ),
            },
            status: {
                label: i18n("auth.app.fleet.vehicles.status"),
                renderCell: (item: CarApplicationService.CarApplicationListModel) => {
                    const chipData = getPublicApplicationStatus(item.status);

                    return (
                        chipData && (
                            <DefaultCellContent className="text-base">
                                <div>
                                    <Chip
                                        label={i18n(
                                            `auth.app.fleet.vehicle-application.status_badges.${chipData.label}`,
                                        )}
                                        color={chipData.color}
                                        size="sm"
                                    />
                                </div>
                            </DefaultCellContent>
                        )
                    );
                },
            },
        }),
        [i18n],
    );
}
