import {useEffect} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import NotificationPreferences from "features/account/pages/Settings/components/GeneralSettings/NotificationPreferences";
import {PortalLanguage} from "features/account/pages/Settings/components/PortalLanguage";

import {TabName, useUserPreferencesTabSelectorOptions} from "./hooks/useUserPreferencesTabSelectorOptions";

export const UserPreferencesPage = () => {
    const {i18n} = useI18n();

    const tabs = useUserPreferencesTabSelectorOptions();
    const {activeTab, TabSelector} = useTabs(tabs, TabName.NotificationSettings);

    useEffect(() => {
        // TODO translate, here and below
        const pageTitle = "User preferences";
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    let TabComponent = <NotificationPreferences />;
    switch (activeTab) {
        case TabName.LanguageSelection:
            TabComponent = <PortalLanguage />;
            break;
        case TabName.NotificationSettings:
        default:
            TabComponent = <NotificationPreferences />;
    }

    return (
        <Page className="gap-4">
            <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text="User preferences" />
            <TabSelector />
            {TabComponent}
        </Page>
    );
};
