import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";

import {Typography} from "@bolteu/kalep-react";
import {BellOff} from "@bolteu/kalep-react-icons";

const EmptyNotificationList = ({title}: {title: TranslationKeys}) => {
    const {i18n} = useI18n();
    return (
        <div className="my-24 mx-6 flex flex-col items-center text-center">
            <BellOff className="text-tertiary h-14 w-14" />
            <Typography fontWeight="semibold" fontSize="text-xl" color="primary" paddingTop={4}>
                {i18n(title)}
            </Typography>
            <Typography fontSize="text-md" color="secondary" paddingTop={2}>
                <div className="whitespace-pre-wrap">{i18n("auth.app.notifications.no_notifications_explanation")}</div>
            </Typography>
        </div>
    );
};

export default EmptyNotificationList;
