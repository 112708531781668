import {FC, useCallback} from "react";
import {Chart} from "react-chartjs-2";

import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartDataset,
    type ChartOptions,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import Header, {HeaderSize} from "common/components/header/Header";
import {EventName} from "common/constants/events";
import {useTracking} from "common/hooks/useTracking";
import {ApiPeriod, apiPeriodToDates, getDatesStrFromDates, getPeriodName} from "common/services/period";
import {format, parse} from "date-fns";
import {getIsoDate, isoDateToDate} from "@fleet/common/utils/dateFormatUtils";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Island} from "@bolteu/kalep-react";
import ChartData = FleetOwnerPortalService.Chart;
import DataPointType = FleetOwnerPortalService.DataPointType;

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    BarController,
    LineController,
    Title,
    Tooltip,
    Legend,
);

interface DashboardChartsProps {
    charts: {
        earnings: ChartData;
        activity: ChartData;
        utilization?: ChartData;
    };
    selectedPeriod: ApiPeriod;
}

const colorsMain = [
    "#5B68F6", // Main line color
    "#32BB78", // Main positive bar color
    "#EB4755", // Main negative bar color
];

const colorsSecondary = [
    "#B8BEFF", // Secondary line color
    "#A1F7CD", // Secondary positive bar color
    "#FCC5C9", // Secondary negative bar color
];

const dashboardWasHoveredOverSet = new Set<string>();

const transformChartDataWithStyles = (chartData: ChartData) => {
    const {pivot, data_points: dataPoints} = chartData;

    const labelIsoDates = pivot.keys.slice(Math.ceil(pivot.keys.length / 2)); // Pick the last 7 days
    const todayIso = getIsoDate(new Date());
    const filteredPivotKeys = pivot.keys.filter((k) => k <= todayIso);

    const datasets = dataPoints.map((dataset) => {
        const isBar = dataset.type === "discrete";
        const isLine = dataset.type === "connected";
        const isSecondary = dataset.snap_id;

        const roundingFunction = (value: number | null | undefined) => {
            if (value === null || value === undefined) {
                return value;
            }
            return !dataset.decimal_place
                ? Math.round(value)
                : Math.round(value * dataset.decimal_place) / dataset.decimal_place;
        };

        const data = filteredPivotKeys
            .map((k) => roundingFunction(dataset.key_to_values[k]))
            .filter((value): value is number => value !== undefined && value !== null);
        const isSingleDayOfData = data.length === 1;

        let backgroundColor;
        if (isBar) {
            if (!isSecondary) {
                backgroundColor = dataset.name.includes("expenses") ? colorsMain[2] : colorsMain[1];
            } else {
                backgroundColor = dataset.name.includes("expenses") ? colorsSecondary[2] : colorsSecondary[1];
            }
        }

        let borderColor;
        if (isLine) {
            borderColor = isSecondary ? colorsSecondary[0] : colorsMain[0];
        }

        const datasetOptions: ChartDataset<"bar" | "line", number[]> = {
            pointStyle: isSingleDayOfData ? "circle" : false,
            type: isBar ? "bar" : "line",
            yAxisID: isBar ? "y1" : "y2",
            tension: isLine ? 0.3 : undefined,
            borderRadius: isBar ? 8 : undefined,
            borderDash: isLine && isSecondary ? [6, 6] : undefined,
            borderCapStyle: "round",
            barThickness: isBar ? 16 : undefined,
            borderWidth: isLine ? 3 : 3,
            borderColor: isLine ? borderColor : "#FFFFFF00",
            backgroundColor,
            label: `${dataset.name}${dataset.unit ? ` (${dataset.unit})` : ""}`,
            data,
        };
        return datasetOptions;
    });

    return {
        labelIsoDates,
        datasets,
    };
};

const ChartContainer: FC<{
    title: string;
    description?: string;
    chartData: ChartData;
    handleHover: () => void;
}> = ({title, description, chartData, handleHover}) => {
    const {labelIsoDates, datasets} = transformChartDataWithStyles(chartData);

    const mainUnit =
        chartData.data_points.find(
            (dataset) => dataset.snap_id === undefined && dataset.type === DataPointType.DISCRETE,
        )?.unit || "";
    const secondaryUnit =
        chartData.data_points.find((dataset) => dataset.snap_id && dataset.type === DataPointType.CONNECTED)?.unit ||
        "";

    const options: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "bottom",
            },
            tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                    title: (item) =>
                        // Tooltip title uses x label from scale labels that were already transformed, so we need to parse date from formatted labels value
                        format(parse(item[0].label, "E, dd.MM", new Date()), "dd.MM.yyyy"),
                },
            },
        },
        scales: {
            x: {
                offset: true,
                labels: labelIsoDates.map((isoDate) => {
                    const date = isoDateToDate(isoDate);
                    return [format(date, "E"), format(date, " dd.MM")];
                }),
                time: {
                    unit: "week",
                },
            },
            y1: {
                position: "left",
                beginAtZero: true,
                ticks: {
                    callback(val) {
                        return `${val} ${mainUnit}`;
                    },
                },
            },
            y2: {
                position: "right",
                beginAtZero: true,
                ticks: {
                    callback(val) {
                        return `${val} ${secondaryUnit}`;
                    },
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    const data = {labels: labelIsoDates, datasets};

    return (
        <Island>
            <Header size={HeaderSize.ExtraSmall} text={title} />
            {description && <p className="mt-2 text-sm text-gray-500">{description}</p>}
            <div className="mt-6 h-[500px]">
                <Chart type="bar" options={options} data={data} onMouseEnter={handleHover} />
            </div>
        </Island>
    );
};

const DashboardCharts: FC<DashboardChartsProps> = ({charts, selectedPeriod}) => {
    const {trackEvent} = useTracking();

    const getHandleHoverCallback = useCallback(
        (chartName: string) => () => {
            const periodName = getPeriodName(selectedPeriod);
            const dates = apiPeriodToDates(selectedPeriod);
            const period = getDatesStrFromDates(dates.start, dates.end);

            const dashboardWasHoveredOverSetKey = chartName + period;
            if (!dashboardWasHoveredOverSet.has(dashboardWasHoveredOverSetKey)) {
                trackEvent(EventName.PerformanceDashboardChartHover, {
                    chartName,
                    periodName,
                    period,
                });
                dashboardWasHoveredOverSet.add(dashboardWasHoveredOverSetKey);
            }
        },
        [trackEvent, selectedPeriod],
    );

    return (
        <div className="flex-col space-y-6">
            {/* Earnings Chart */}
            <div className="flex-row gap-4">
                <ChartContainer
                    title={charts.earnings.name}
                    description={charts.earnings.description}
                    chartData={charts.earnings}
                    handleHover={getHandleHoverCallback(charts.earnings.name)}
                />
            </div>

            {/* Activity and Utilization Charts */}
            <div className="flex flex-row flex-wrap justify-between gap-4">
                <div className="w-full flex-1 lg:w-0">
                    <ChartContainer
                        title={charts.activity.name}
                        description={charts.activity.description}
                        chartData={charts.activity}
                        handleHover={getHandleHoverCallback(charts.activity.name)}
                    />
                </div>
                <div className="w-full flex-1 lg:w-0">
                    {charts.utilization && (
                        <ChartContainer
                            title={charts.utilization.name}
                            description={charts.utilization.description}
                            chartData={charts.utilization}
                            handleHover={getHandleHoverCallback(charts.utilization.name)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardCharts;
