import {ChangeEventHandler, useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {RadioGroup} from "@bolteu/kalep-react";

import {CustomRadio} from "./CustomRadio";

interface Props {
    value?: FleetPortalOrderService.AssignmentType;
    onChange: (value: FleetPortalOrderService.AssignmentType) => void;
}

export const AssignmentTypeRadioGroup = ({value, onChange}: Props) => {
    const {i18n} = useI18n();

    const onChangeWrapper = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            onChange(event.target.value as FleetPortalOrderService.AssignmentType);
        },
        [onChange],
    );

    return (
        <RadioGroup name="assignment_type" value={value} onChange={onChangeWrapper}>
            <div className="flex flex-col gap-6">
                <CustomRadio
                    label={i18n("auth.app.orders.scheduled_rides.assign-automatically-title")}
                    value={FleetPortalOrderService.AssignmentType.AUTOMATIC}
                    helperText={i18n("auth.app.orders.scheduled_rides.assign-automatically-description")}
                />
                <CustomRadio
                    label={i18n("auth.app.orders.scheduled_rides.assign-manually-title")}
                    value={FleetPortalOrderService.AssignmentType.MANUAL}
                    helperText={i18n("auth.app.orders.scheduled_rides.assign-manually-description")}
                />
            </div>
        </RadioGroup>
    );
};
