import {useI18n} from "common/hooks/useI18n";

import {IconButton} from "@bolteu/kalep-react";
import {Cross} from "@bolteu/kalep-react-icons";

interface Props {
    collapse: () => void;
}

const Header = ({collapse}: Props) => {
    const {i18n} = useI18n();

    return (
        <div className="px-6">
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold">
                    {i18n("auth.app.invoices.fleet-to-rider.generated_files.title")}
                </h2>
                <IconButton icon={<Cross size="lg" />} aria-label="Close" onClick={collapse} size="sm" />
            </div>
            <p className="text-secondary text-sm">
                {i18n("auth.app.invoices.fleet-to-rider.generated_files.description")}{" "}
            </p>
        </div>
    );
};

export default Header;
