import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import CloseButton from "common/components/CloseButton";
import {Page} from "common/components/page/Page";
import {FullScreenLoadingSpinner} from "common/components/spinner";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {useSnackbar} from "@bolteu/kalep-react";

import {LISTING_REVIEW_STEP_ID} from "../utils/constants";
import {PreviewListingStep, Step} from "../utils/types";
import {ListingContextProvider, ListingProvider} from "./components/ListingInfoProvider";
import MobileWizardStepper from "./components/MobileWizardStepper";
import WizardContent, {WizardContentHeader} from "./components/WizardContent";
import WizardReviewContent from "./components/WizardReviewContent";
import WizardStepper from "./components/WizardStepper";
import WizardSummary from "./components/WizardSummary";

import ListingStep = VehicleMarketplaceService.ListingStep;

interface LayoutProps {
    children: React.ReactNode;
    currentStep?: Step;
    allSteps: Step[];
    onClosePageClick: () => void;
    onStepClick: (step: Step) => void;
    isLoadingListingFieldsFirstTime: boolean;
}

const getListingFieldsFetchFunction = (api: Api, request: VehicleMarketplaceService.GetListingRequest) =>
    api.vehicleMarketplace.vehicleListingGetListingWizardSteps(request);
const publishListingFetchFunction = (api: Api, request: VehicleMarketplaceService.PublishListingRequest) =>
    api.vehicleMarketplace.vehicleListingPublish(request);

const DesktopWizardLayout = ({
    children,
    currentStep,
    allSteps,
    isLoadingListingFieldsFirstTime,
    onClosePageClick,
    onStepClick,
}: LayoutProps) => {
    if (isLoadingListingFieldsFirstTime) {
        return <FullScreenLoadingSpinner />;
    }

    return (
        <Page>
            <div className="flex justify-end">
                <CloseButton onClick={onClosePageClick} />
            </div>
            <div className="mb-4 flex gap-10">
                <div className="bg-layer-surface basis-3/12 overflow-y-auto rounded-lg px-6 py-4">
                    <div className="flex min-h-[600px] flex-col gap-6">
                        <WizardSummary />
                        <WizardStepper steps={allSteps} currentStep={currentStep} onClick={onStepClick} />
                    </div>
                </div>
                <div className="basis-9/12">
                    {currentStep && (
                        <WizardContentHeader title={currentStep?.title} subtitle={currentStep?.description} />
                    )}
                    {children}
                </div>
            </div>
        </Page>
    );
};

const MobileWizardLayout = ({
    children,
    currentStep,
    allSteps,
    isLoadingListingFieldsFirstTime,
    onClosePageClick,
    onStepClick: _,
}: LayoutProps) => {
    if (isLoadingListingFieldsFirstTime) {
        return <FullScreenLoadingSpinner />;
    }

    return (
        <Page>
            <div className="flex justify-end">
                <CloseButton onClick={onClosePageClick} />
            </div>
            <div className="flex flex-col gap-4">
                {currentStep && <WizardContentHeader title={currentStep?.title} subtitle={currentStep?.description} />}
                <MobileWizardStepper steps={allSteps} currentStep={currentStep} />
                {children}
            </div>
        </Page>
    );
};

const EditVehicleListingWizardPage = () => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {setNotification} = useContext(NotificationContext);
    const {listing, reloadListing} = useContext(ListingContextProvider);
    const {getVehiclesPath, getVehicleListingViewPath} = useAbsolutePath();
    const viewport = useTailwindViewport();
    const {id: listingId} = useParams();
    const location = useLocation();
    const snackbar = useRef(useSnackbar());

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.vehicle_marketplace.listings.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const isMobileView = viewport === "sm";

    const navigateToListing = useCallback(
        (vehicleListingId: number) => () => {
            navigate(getVehicleListingViewPath(vehicleListingId));
        },
        [getVehicleListingViewPath, navigate],
    );

    const onClosePageClick = useCallback(
        (action?: {published: boolean}) => {
            const returnToListingTable = location.state?.isFromListingDetails;
            const listingIdNum = Number(listingId);
            if (listingId && !Number.isNaN(listingIdNum) && returnToListingTable) {
                navigateToListing(listingIdNum)();
                return;
            }
            if (action?.published) {
                snackbar.current.add(
                    {
                        description: i18n("auth.app.fleet.vehicle_marketplace.listings.publish.published"),
                        dismissible: false,
                        actions: [
                            {
                                label: i18n("auth.app.fleet.vehicle_marketplace.listings.publish.published_action"),
                                onClick: navigateToListing(listingIdNum),
                            },
                        ],
                    },
                    {timeout: 6000},
                );
            }
            navigate(getVehiclesPath({toListingsTab: true}));
        },
        [getVehiclesPath, i18n, listingId, location.state?.isFromListingDetails, navigate, navigateToListing, snackbar],
    );

    const [listingSteps, setListingSteps] = useState<ListingStep[]>([]);
    const [fleetSupportLink, setFleetSupportLink] = useState<string | undefined>(undefined);

    const {
        status: getListingFieldsStatus,
        data: getListingFieldsData,
        fetch: getListingFieldsFetch,
    } = useFetch(getListingFieldsFetchFunction);
    const {
        status: publishListingStatus,
        fetch: publishListingFetch,
        error: publishListingError,
    } = useFetch(publishListingFetchFunction);

    const [currentStepId, setCurrentStepId] = useState<string | undefined>(undefined);

    const allSteps = useMemo(() => {
        const title =
            listing?.data.status === VehicleMarketplaceService.ListingStatus.DRAFT
                ? i18n("auth.app.fleet.vehicle_marketplace.listings.add.publish")
                : i18n("auth.app.fleet.vehicle_marketplace.listings.add.review");
        const finalStep: PreviewListingStep = {
            id: LISTING_REVIEW_STEP_ID,
            title,
            position: Math.max(...listingSteps.map((x) => x.position)) + 1,
            is_completed: false,
        };
        return [...listingSteps, finalStep];
    }, [listingSteps, listing?.data.status, i18n]);

    const currentStep = useMemo(() => allSteps.find((step) => step.id === currentStepId), [currentStepId, allSteps]);
    const prevStep = useCallback(
        (step: Step) => (step ? allSteps.find((st) => st.position === step.position - 1) : null),
        [allSteps],
    );

    useEffect(() => {
        if (getListingFieldsStatus === FetchStatus.Success) {
            const steps = getListingFieldsData.steps.sort((a, b) => a.position - b.position);
            setListingSteps(steps);
            setFleetSupportLink(getListingFieldsData.fleet_support_link);
            const initialCurrentStep = steps.find((step) => !step.is_completed);
            if (!currentStepId) {
                setCurrentStepId(initialCurrentStep?.id || LISTING_REVIEW_STEP_ID);
            }
        }
    }, [getListingFieldsStatus, getListingFieldsData, currentStepId]);

    useEffect(() => {
        if (!getListingFieldsFetch || !listingId) {
            return;
        }

        getListingFieldsFetch({listing_id: Number(listingId)});
    }, [getListingFieldsFetch, listingId]);

    const onPrevStep = useCallback(() => {
        if (!currentStep) {
            return;
        }

        const step = prevStep(currentStep);
        if (!step) {
            return;
        }

        if (getListingFieldsFetch) {
            getListingFieldsFetch({listing_id: Number(listingId)});
        }

        setCurrentStepId(step.id);
    }, [currentStep, prevStep, getListingFieldsFetch, listingId]);

    const onNextStep = useCallback(() => {
        if (!currentStep || !getListingFieldsFetch) {
            return;
        }

        getListingFieldsFetch({listing_id: Number(listingId)});
        const nextStep = allSteps.find((step) => step.position === currentStep.position + 1);
        if (!nextStep) {
            return;
        }
        if (nextStep.id === LISTING_REVIEW_STEP_ID) {
            reloadListing();
        }
        setCurrentStepId(nextStep.id);
    }, [allSteps, currentStep, getListingFieldsFetch, listingId, reloadListing]);

    const onStep = useCallback(
        (step: Step) => {
            const previousStep = prevStep(step);
            if (!previousStep || previousStep.is_completed || step.is_completed) {
                setCurrentStepId(step.id);
            }
        },
        [setCurrentStepId, prevStep],
    );

    const onPublish = useCallback(() => {
        if (publishListingFetch) {
            publishListingFetch({listing_id: Number(listingId)});
        }
    }, [publishListingFetch, listingId]);

    useEffect(() => {
        if (publishListingStatus === FetchStatus.Success) {
            onClosePageClick({published: true});
        }
    }, [publishListingStatus, onClosePageClick]);

    useEffect(() => {
        if (publishListingStatus === FetchStatus.Error && publishListingError.validationErrors.length !== 0) {
            setNotification({
                type: NotificationType.ERROR,
                text: i18n("api.default_error"),
                timeout: 6000,
            });
        }
    }, [publishListingStatus, publishListingError, i18n, setNotification]);

    let WizardRenderContent: React.ReactNode = <></>;
    if (currentStep && currentStep.id !== LISTING_REVIEW_STEP_ID) {
        WizardRenderContent = (
            <WizardContent
                key={currentStepId}
                listingId={Number(listingId)}
                step={currentStep}
                fleetSupportLink={fleetSupportLink}
                onNextStep={onNextStep}
                onPrevStep={onPrevStep}
            />
        );
    }

    if (currentStepId === LISTING_REVIEW_STEP_ID) {
        WizardRenderContent = (
            <WizardReviewContent
                key={currentStepId}
                listingId={Number(listingId)}
                steps={listingSteps}
                onPublishListing={onPublish}
                onPrevStep={onPrevStep}
                fleetSupportLink={fleetSupportLink}
                isPublishLoading={publishListingStatus === FetchStatus.Loading}
            />
        );
    }

    const isLoadingListingFieldsFirstTime =
        !getListingFieldsData && [FetchStatus.Init, FetchStatus.Loading].includes(getListingFieldsStatus);

    const mobileLayout = (
        <MobileWizardLayout
            allSteps={allSteps}
            onClosePageClick={onClosePageClick}
            onStepClick={onStep}
            currentStep={currentStep}
            isLoadingListingFieldsFirstTime={isLoadingListingFieldsFirstTime}
        >
            {WizardRenderContent}
        </MobileWizardLayout>
    );

    return isMobileView ? (
        mobileLayout
    ) : (
        <DesktopWizardLayout
            allSteps={allSteps}
            onClosePageClick={onClosePageClick}
            onStepClick={onStep}
            currentStep={currentStep}
            isLoadingListingFieldsFirstTime={isLoadingListingFieldsFirstTime}
        >
            {WizardRenderContent}
        </DesktopWizardLayout>
    );
};

const EditVehicleListingWizardPageWithProvider = () => {
    const {id: listingId} = useParams();
    return (
        <ListingProvider listingId={Number(listingId)}>
            <EditVehicleListingWizardPage />
        </ListingProvider>
    );
};

export default EditVehicleListingWizardPageWithProvider;
