import {useEffect, useMemo} from "react";
import {useIntl} from "react-intl";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {format} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {TABLE_ROWS_PER_PAGE} from "../../contants";

export interface PayoutInvoiceData {
    id: number;
    amount: string;
    currency: string;
    date: string;
}

const fetchPayouts = (api: Api) => api.fleetOwnerReporting.getPayouts();

export const usePayouts = (offset: number) => {
    const intl = useIntl();
    const {data, fetch, status} = useFetch(fetchPayouts);

    useEffect(() => {
        if (!fetch) {
            return;
        }
        fetch(undefined);
    }, [fetch]);

    const payouts = useMemo<PayoutInvoiceData[]>(() => {
        if (status !== FetchStatus.Success) {
            return [];
        }
        const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
        return data.list.slice(offset, offset + TABLE_ROWS_PER_PAGE).map((d) => ({
            id: d.id,
            amount: String(d.sum),
            currency: d.currency,
            date: format(d.finished * 1000, "P", {locale: dateFnsLocale}),
        }));
    }, [data, intl, status, offset]);

    return {payouts, status};
};
