import {useContext} from "react";

import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {FeaturesContextProvider} from "FeaturesProvider";

import {OrdersTabs} from "..";

export function useTabSelectorOptions(): Array<Tab<OrdersTabs>> {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);

    const tabs: Array<Tab<OrdersTabs>> = [
        ...(features?.incoming_orders
            ? [
                  {
                      id: OrdersTabs.IncomingOrders,
                      title: `${i18n("auth.app.sidebar.incoming-orders")}`,
                      trackingEventTabName: TabNameEvent.OrdersPageIncomingOrders,
                  },
              ]
            : []),
        {
            id: OrdersTabs.OrderHistory,
            title: `${i18n("auth.app.sidebar.order-history")}`,
            trackingEventTabName: TabNameEvent.OrdersPageOrderHistory,
        },
        ...(features?.order_dispatching
            ? [
                  {
                      id: OrdersTabs.OrderRequests,
                      title: `${i18n("auth.app.sidebar.order-requests")}`,
                      trackingEventTabName: TabNameEvent.OrdersPageOrderRequests,
                  },
              ]
            : []),
    ];

    return tabs;
}
