import {useCallback, useContext, useEffect, useRef, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {FeaturesContextProvider} from "FeaturesProvider";
import {NavigationBarVisbilityContextProvider} from "NavigationBarVisibilityProvider";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Spinner, useSnackbar} from "@bolteu/kalep-react";

import AddShiftDialog from "../components/AddShiftDialog";
import {DeleteDialog} from "./components/DeleteDialog";
import {EditShiftDialog} from "./components/EditShiftDialog";
import ManageShiftAssignmentsPage from "./components/ManageShiftAssignmentsPage";
import {NoShifts} from "./components/NoShifts";
import {useShifts} from "./hooks/useShifts";

export interface Props {
    shifts: FleetShiftManagementService.ShiftsResponse | null;
    shiftGetStatus: FetchStatus;
    getShifts: () => void;
}

export const Planning = ({shifts, shiftGetStatus, getShifts}: Props) => {
    const [shiftIdToDelete, setShiftIdToDelete] = useState<number | null>(null);
    const [editShift, setEditShift] = useState<FleetShiftManagementService.ShiftWithAllocationSummary | null>(null);

    const onCloseDeleteDialog = useCallback(() => {
        setShiftIdToDelete(null);
    }, []);

    const onDelete = useCallback(() => {
        setShiftIdToDelete(null);
        getShifts();
    }, [getShifts]);

    const onShiftCardDeleteClick = useCallback((shiftId: number) => {
        setShiftIdToDelete(shiftId);
    }, []);

    const onShiftEdited = useCallback(() => {
        getShifts();
    }, [getShifts]);

    const onEditShiftClick = useCallback((shift: FleetShiftManagementService.ShiftWithAllocationSummary) => {
        setEditShift(shift);
    }, []);

    const closeEditDialog = useCallback(() => {
        setEditShift(null);
    }, []);

    const shiftsList = useShifts({
        shifts: shifts?.list,
        fetchStatus: shiftGetStatus,
        onDeleteClick: onShiftCardDeleteClick,
        onEditClick: onEditShiftClick,
    });

    let Body: JSX.Element | null = null;
    if ([FetchStatus.Init, FetchStatus.Loading].includes(shiftGetStatus)) {
        Body = (
            <div className="m-auto mt-16 flex justify-center">
                <Spinner />
            </div>
        );
    } else if (shiftGetStatus === FetchStatus.Error) {
        Body = <ErrorView type={ErrorViewType.SomethingWentWrong} onActionClick={getShifts} />;
    } else if (!shiftsList) {
        Body = <NoShifts />;
    } else {
        Body = shiftsList;
    }

    return (
        <>
            <DeleteDialog
                show={!!shiftIdToDelete}
                onClose={onCloseDeleteDialog}
                onDelete={onDelete}
                shiftId={shiftIdToDelete}
            />
            {editShift && (
                <EditShiftDialog closeDialog={closeEditDialog} onShiftEdited={onShiftEdited} shift={editShift} />
            )}
            {Body}
        </>
    );
};

const fetchShiftsFunction = (api: Api) => api.fleetShiftManagement.getShifts();

export const PlanningTab = () => {
    const {i18n} = useI18n();
    const snackbar = useRef(useSnackbar());
    const features = useContext(FeaturesContextProvider);
    const {setIsNavigationBarVisible} = useContext(NavigationBarVisbilityContextProvider);
    const [shiftIdToDelete, setShiftIdToDelete] = useState<number | null>(null);
    const [editShift, setEditShift] = useState<FleetShiftManagementService.ShiftWithAllocationSummary | null>(null);
    const [isManageAssignmentsPageOpen, setIsManageAssignmentsPageOpen] = useState(false);
    const [isAddShiftDialogOpen, setIsAddShiftDialogOpen] = useState(false);
    const viewPort = useTailwindViewport();
    const {trackEvent} = useTracking();
    const {data: shifts, fetch: fetchShifts, status: shiftGetStatus} = useFetch(fetchShiftsFunction);

    const getShifts = useCallback(() => {
        if (fetchShifts && features?.shifts) {
            fetchShifts({});
        }
    }, [features?.shifts, fetchShifts]);

    useEffect(() => {
        getShifts();
    }, [getShifts]);

    // Delete dialog
    const onCloseDeleteDialog = useCallback(() => {
        setShiftIdToDelete(null);
    }, []);

    const onDelete = useCallback(() => {
        setShiftIdToDelete(null);
        getShifts();
    }, [getShifts]);

    const onShiftDeleteClick = useCallback((shiftId: number) => {
        setShiftIdToDelete(shiftId);
    }, []);

    // Edit dialog
    const onShiftEdited = useCallback(() => {
        getShifts();
    }, [getShifts]);

    const onEditShiftClick = useCallback((shift: FleetShiftManagementService.ShiftWithAllocationSummary) => {
        setEditShift(shift);
    }, []);

    const onCloseEditDialog = useCallback(() => {
        setEditShift(null);
    }, []);

    // Add dialog
    const onAddShiftClick = useCallback(() => {
        setIsAddShiftDialogOpen(true);
    }, []);

    const onCloseAddShiftDialog = useCallback(() => {
        setIsAddShiftDialogOpen(false);
    }, []);

    // Assign shifts
    const onManageAssignmentsClick = useCallback(() => {
        trackEvent(EventName.ManageShiftAssignmentsButtonClicked);
        setIsNavigationBarVisible(false);
        setIsManageAssignmentsPageOpen(true);
    }, [setIsNavigationBarVisible, trackEvent]);

    const onCloseManageAssignmentsPage = useCallback(() => {
        setIsNavigationBarVisible(true);
        setIsManageAssignmentsPageOpen(false);
    }, [setIsNavigationBarVisible]);

    const onAssignmentsUpdated = useCallback(() => {
        snackbar.current.add(
            {
                description: i18n("auth.app.fleet.shifts.assign_shift.shifts_assigned"),
            },
            {timeout: 3000},
        );
        getShifts();
    }, [getShifts, i18n, snackbar]);

    const shiftsList = useShifts({
        shifts: shifts?.list,
        fetchStatus: shiftGetStatus,
        onDeleteClick: onShiftDeleteClick,
        onEditClick: onEditShiftClick,
    });

    if (isManageAssignmentsPageOpen) {
        return (
            <ManageShiftAssignmentsPage
                onAssignmentsUpdated={onAssignmentsUpdated}
                closePage={onCloseManageAssignmentsPage}
            />
        );
    }

    if (!features?.shifts) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    let Body: JSX.Element | null = null;
    if ([FetchStatus.Init, FetchStatus.Loading].includes(shiftGetStatus)) {
        Body = (
            <div className="m-auto mt-16 flex justify-center">
                <Spinner />
            </div>
        );
    } else if (shiftGetStatus === FetchStatus.Error) {
        Body = <ErrorView type={ErrorViewType.SomethingWentWrong} onActionClick={getShifts} />;
    } else if (!shiftsList) {
        Body = <NoShifts />;
    } else {
        Body = shiftsList;
    }

    const isMobileView = viewPort === "sm";

    return (
        <>
            <DeleteDialog
                show={!!shiftIdToDelete}
                onClose={onCloseDeleteDialog}
                onDelete={onDelete}
                shiftId={shiftIdToDelete}
            />
            {editShift && (
                <EditShiftDialog closeDialog={onCloseEditDialog} onShiftEdited={onShiftEdited} shift={editShift} />
            )}
            {isAddShiftDialogOpen && <AddShiftDialog closeDialog={onCloseAddShiftDialog} onShiftCreated={getShifts} />}
            <div className="flex items-center justify-end gap-4">
                <Button
                    onClick={onManageAssignmentsClick}
                    variant="secondary"
                    fullWidth={isMobileView}
                    disabled={!shifts || shifts.list.length === 0}
                >
                    {i18n("auth.app.fleet.shifts.assign_shift.title")}
                </Button>
                <Button onClick={onAddShiftClick} fullWidth={isMobileView}>
                    {i18n("auth.app.fleet.shifts.add_shift.title")}
                </Button>
            </div>
            {Body}
        </>
    );
};
