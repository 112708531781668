import {useI18n} from "common/hooks/useI18n";

import {LeadToFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import DocumentStatus = LeadToFleetService.DocumentStatus;

type DocumentStatuses = DocumentStatus.VERIFIED | DocumentStatus.UPLOADED | DocumentStatus.DECLINED;

interface DocumentsListProps {
    title: string;
    list: LeadToFleetService.DocumentTitleAndStatus[];
    leadDetailsShown: boolean;
}

const DEFAULT_MAX_DOCUMENTS_SHOWN = 3;

const DocumentsList = ({title, list, leadDetailsShown}: DocumentsListProps) => {
    const {i18n} = useI18n();
    if (!list.length) {
        return null;
    }

    const documentsList = leadDetailsShown ? list : list.slice(0, DEFAULT_MAX_DOCUMENTS_SHOWN);

    return (
        <div>
            <h5 className="text-secondary mb-1 font-semibold">{title}</h5>
            <ul className="list-disc pl-6">
                {documentsList.map((doc) => (
                    <li key={doc.title} className="text-base">
                        {doc.title}
                        {" · "}
                        <span
                            className={`${
                                doc.status === DocumentStatus.DECLINED ? "text-danger-primary" : "text-secondary"
                            }`}
                        >
                            {i18n(`auth.app.fleet.matches.document.status.${doc.status as DocumentStatuses}`)}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DocumentsList;
