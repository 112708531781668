import {useCallback, useMemo, useState} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {useForm} from "@bolteu/kalep-form-react";
import {Button, Drawer, IconButton, SelectOption} from "@bolteu/kalep-react";
import {Bin, Filters} from "@bolteu/kalep-react-icons";

interface RentingOutFilterProps {
    onApply: (filters: VehicleMarketplaceService.ListingStatus[] | undefined) => void;
}

export default function RentingOutFilter({onApply}: RentingOutFilterProps) {
    const [isOpen, setIsOpen] = useState(false);
    const {i18n} = useI18n();

    const allStatuses = useMemo(
        () => [
            VehicleMarketplaceService.ListingStatus.PUBLISHED,
            VehicleMarketplaceService.ListingStatus.DRAFT,
            VehicleMarketplaceService.ListingStatus.INACTIVE,
        ],
        [],
    );

    const {SelectField, useFieldValue, Form, setData} = useForm({
        listing_statuses: allStatuses,
    });

    const selectedStatuses = useFieldValue("listing_statuses");

    const handleFiltersClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleFilterButtonClick = useCallback(() => {
        setIsOpen(true);
    }, []);

    const statusesSelectOptions: SelectOption[] = allStatuses.map((status) => ({
        value: status,
        title: i18n(`auth.app.fleet.vehicle_marketplace.listings.table.listing_statuses.${status}`),
    }));

    const statusesTextList = useMemo(
        () =>
            allStatuses
                ?.map((status) => i18n(`auth.app.fleet.vehicle_marketplace.listings.table.listing_statuses.${status}`))
                .join(", "),
        [allStatuses, i18n],
    );

    const onCloseClick = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onClearFiltersClick = useCallback(() => {
        setData({listing_statuses: allStatuses});
    }, [allStatuses, setData]);

    const onApplyClicked = useCallback(() => {
        onApply(selectedStatuses);
        setIsOpen(false);
    }, [onApply, selectedStatuses]);

    return (
        <div>
            <IconButton
                icon={<Filters />}
                aria-label="Filter cars"
                variant="filled"
                size="md"
                shape="square"
                overrideClassName="flex"
                onClick={handleFilterButtonClick}
            />

            <Form>
                <ResponsiveDrawer
                    isOpen={isOpen}
                    title={i18n("auth.app.fleet.vehicle_marketplace.listings.table.filter")}
                    onRequestClose={handleFiltersClose}
                >
                    <Drawer.Content>
                        <div className="flex flex-col gap-4">
                            <SelectField
                                multiple
                                label={i18n("auth.app.fleet.vehicle_marketplace.listings.table.status")}
                                options={statusesSelectOptions}
                                placeholder={statusesTextList}
                                name="listing_statuses"
                            />
                        </div>
                    </Drawer.Content>
                    <Drawer.Footer>
                        <Button onClick={onApplyClicked}>{i18n("common.apply")}</Button>
                        <Button variant="secondary" onClick={onCloseClick}>
                            {i18n("auth.app.company-settings.cancel")}
                        </Button>
                        <Button variant="secondary" onClick={onClearFiltersClick} startIcon={<Bin />}>
                            {i18n("common.filters.clear_filters")}
                        </Button>
                    </Drawer.Footer>
                </ResponsiveDrawer>
            </Form>
        </div>
    );
}
