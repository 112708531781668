import {FC} from "react";

import Icon from "assets/icons/live-map/empty-list-icon.svg?react";

interface EmptyListPlaceholderProps {
    title?: string;
    subtext?: React.ReactNode;
}

const EmptyListPlaceholder: FC<EmptyListPlaceholderProps> = ({title, subtext}) => (
    <div className="mt-28 w-full p-6">
        <div className="m-auto flex w-80 flex-col items-center text-center">
            <Icon className="mb-5" />
            <div className="text-xl font-semibold">{title}</div>
            <div className="text-secondary font-normal leading-snug">{subtext}</div>
        </div>
    </div>
);

export default EmptyListPlaceholder;
