import {useCallback, useState} from "react";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {useVehicleDataRow} from "../hooks/useVehicleDataRow";
import DataRow from "./DataRow";
import EditRow from "./EditRow";
import {VisibleVehicleField} from "./VehicleData";

interface Props {
    fieldName: VisibleVehicleField;
    vehicle: FleetOwnerPortalService.GetCarResponse;
    editingField: VisibleVehicleField | null;
    startEditing: (fieldName: VisibleVehicleField) => void;
    stopEditing: () => void;
}

const VehicleDataRow = ({fieldName, vehicle, editingField, startEditing, stopEditing}: Props) => {
    const fieldData = useVehicleDataRow({fieldName, vehicle});
    const [fieldText, setFieldText] = useState(fieldData?.value ?? "");

    const handleEditClick = useCallback(() => startEditing(fieldName), [fieldName, startEditing]);

    const handleEditClose = useCallback(
        (newValue?: string) => {
            if (newValue) {
                setFieldText(newValue);
            }
            stopEditing();
        },
        [stopEditing],
    );

    if (!fieldData) {
        return null;
    }

    const isEditFieldShown = editingField === fieldName;

    return (
        <div className="border-separator flex items-center justify-between border-b py-3">
            {!isEditFieldShown && (
                <DataRow fieldData={{...fieldData, value: fieldText}} onEditClick={handleEditClick} />
            )}
            {isEditFieldShown && (
                <EditRow
                    value={fieldText}
                    title={fieldData.title}
                    closeEdit={handleEditClose}
                    fieldName={fieldName}
                    id={vehicle.id}
                />
            )}
        </div>
    );
};

export default VehicleDataRow;
