// Taken from https://github.com/bolteu/ember-commons/blob/develop/addon/data/countries.ts
export class CountryModel {
    public selected = false;

    public areaCode: string[];

    constructor(
        public readonly code: CountryCode,
        areaCodes: string[],
        public readonly name: string,
        public readonly examplePhone?: string,
    ) {
        this.areaCode = areaCodes;
    }

    get primaryAreaCode() {
        return this.areaCode[0];
    }
}

export enum CountryCode {
    Estonia = "ee",
    SouthAfrica = "za",
    Latvia = "lv",
    Nigeria = "ng",
    Georgia = "ge",
    Lithuania = "lt",
    Mexico = "mx",
    Hungary = "hu",
    Azerbaijan = "az",
    Romania = "ro",
    CzechRepublic = "cz",
    Egypt = "eg",
    Slovakia = "sk",
    Poland = "pl",
    Ukraine = "ua",
    Serbia = "rs",
    Finland = "fi",
    Andorra = "ad",
    UnitedArabEmirates = "ae",
    Afghanistan = "af",
    AntiguaAndBarbuda = "ag",
    Anguilla = "ai",
    Albania = "al",
    Armenia = "am",
    NetherlandsAntilles = "an",
    Angola = "ao",
    Antarctica = "aq",
    Argentina = "ar",
    AmericanSamoa = "as",
    Austria = "at",
    Australia = "au",
    Aruba = "aw",
    BosniaAndHerzegovina = "ba",
    Barbados = "bb",
    Bangladesh = "bd",
    Belgium = "be",
    BurkinaFaso = "bf",
    Bulgaria = "bg",
    Bahrain = "bh",
    Burundi = "bi",
    Benin = "bj",
    SaintBarthelemy = "bl",
    Bermuda = "bm",
    Brunei = "bn",
    Bolivia = "bo",
    Brazil = "br",
    Bahamas = "bs",
    Bhutan = "bt",
    Belarus = "by",
    Botswana = "bw",
    Belize = "bz",
    Canada = "ca",
    CocosIslands = "cc",
    DemocraticRepublicOfTheCongo = "cd",
    CentralAfricanRepublic = "cf",
    RepublicOfTheCongo = "cg",
    Switzerland = "ch",
    IvoryCoast = "ci",
    CookIslands = "ck",
    Chile = "cl",
    Cameroon = "cm",
    China = "cn",
    Colombia = "co",
    CostaRica = "cr",
    Cuba = "cu",
    CapeVerde = "cv",
    Curaçao = "cw",
    ChristmasIsland = "cx",
    Cyprus = "cy",
    Germany = "de",
    Djibouti = "dj",
    Denmark = "dk",
    Dominica = "dm",
    DominicanRepublic = "do",
    Algeria = "dz",
    Ecuador = "ec",
    WesternSahara = "eh",
    Eritrea = "er",
    Spain = "es",
    Ethiopia = "et",
    Fiji = "fj",
    FalklandIslands = "fk",
    Micronesia = "fm",
    FaroeIslands = "fo",
    France = "fr",
    Gabon = "ga",
    UnitedKingdom = "gb",
    Grenada = "gd",
    Guernsey = "gg",
    Ghana = "gh",
    Gibraltar = "gi",
    Greenland = "gl",
    Gambia = "gm",
    Guinea = "gn",
    Guadeloupe = "gp",
    EquatorialGuinea = "gq",
    Greece = "gr",
    Guatemala = "gt",
    Guam = "gu",
    GuineaBissau = "gw",
    Guyana = "gy",
    HongKong = "hk",
    Honduras = "hn",
    Croatia = "hr",
    Haiti = "ht",
    Indonesia = "id",
    Ireland = "ie",
    Israel = "il",
    IsleOfMan = "im",
    India = "in",
    BritishIndianOceanTerritory = "io",
    Iraq = "iq",
    Iran = "ir",
    Iceland = "is",
    Italy = "it",
    Jersey = "je",
    Jamaica = "jm",
    Jordan = "jo",
    Japan = "jp",
    Kenya = "ke",
    Kyrgyzstan = "kg",
    Cambodia = "kh",
    Kiribati = "ki",
    Comoros = "km",
    SaintKittsAndNevis = "kn",
    NorthKorea = "kp",
    SouthKorea = "kr",
    Kuwait = "kw",
    CaymanIslands = "ky",
    Kazakhstan = "kz",
    Laos = "la",
    Lebanon = "lb",
    SaintLucia = "lc",
    Liechtenstein = "li",
    SriLanka = "lk",
    Liberia = "lr",
    Lesotho = "ls",
    Luxembourg = "lu",
    Libya = "ly",
    Morocco = "ma",
    Monaco = "mc",
    Moldova = "md",
    Montenegro = "me",
    SaintMartin = "mf",
    Madagascar = "mg",
    MarshallIslands = "mh",
    Macedonia = "mk",
    Mali = "ml",
    Myanmar = "mm",
    Mongolia = "mn",
    Macau = "mo",
    NorthernMarianaIslands = "mp",
    Martinique = "mq",
    Mauritania = "mr",
    Montserrat = "ms",
    Malta = "mt",
    Mauritius = "mu",
    Maldives = "mv",
    Malawi = "mw",
    Malaysia = "my",
    Mozambique = "mz",
    Namibia = "na",
    NewCaledonia = "nc",
    Niger = "ne",
    Nicaragua = "ni",
    Netherlands = "nl",
    Norway = "no",
    Nepal = "np",
    Nauru = "nr",
    Niue = "nu",
    NewZealand = "nz",
    Oman = "om",
    Panama = "pa",
    Peru = "pe",
    FrenchPolynesia = "pf",
    PapuaNewGuinea = "pg",
    Philippines = "ph",
    Pakistan = "pk",
    SaintPierreAndMiquelon = "pm",
    Pitcairn = "pn",
    PuertoRico = "pr",
    Palestine = "ps",
    Portugal = "pt",
    Palau = "pw",
    Paraguay = "py",
    Qatar = "qa",
    Reunion = "re",
    Russia = "ru",
    Rwanda = "rw",
    SaudiArabia = "sa",
    SolomonIslands = "sb",
    Seychelles = "sc",
    Sudan = "sd",
    Sweden = "se",
    Singapore = "sg",
    SaintHelena = "sh",
    Slovenia = "si",
    SvalbardAndJanMayen = "sj",
    SierraLeone = "sl",
    SanMarino = "sm",
    Senegal = "sn",
    Somalia = "so",
    Suriname = "sr",
    SouthSudan = "ss",
    SãoTomeAndPrincipe = "st",
    ElSalvador = "sv",
    SintMaarten = "sx",
    Syria = "sy",
    Swaziland = "sz",
    TurksAndCaicosIslands = "tc",
    Chad = "td",
    Togo = "tg",
    Thailand = "th",
    Tajikistan = "tj",
    Tokelau = "tk",
    EastTimor = "tl",
    Turkmenistan = "tm",
    Tunisia = "tn",
    Tonga = "to",
    Turkey = "tr",
    TrinidadAndTobago = "tt",
    Tuvalu = "tv",
    Taiwan = "tw",
    Tanzania = "tz",
    Uganda = "ug",
    UnitedStates = "us",
    Uruguay = "uy",
    Uzbekistan = "uz",
    Vatican = "va",
    SaintVincentAndTheGrenadines = "vc",
    Venezuela = "ve",
    BritishVirginIslands = "vg",
    USVirginIslands = "vi",
    Vietnam = "vn",
    Vanuatu = "vu",
    WallisAndFutuna = "wf",
    Samoa = "ws",
    Kosovo = "xk",
    Yemen = "ye",
    Mayotte = "yt",
    Zambia = "zm",
    Zimbabwe = "zw",
}

const countries = [
    {code: CountryCode.Estonia, areaCode: ["372"], name: "Estonia", examplePhone: "51234567"},
    {code: CountryCode.SouthAfrica, areaCode: ["27"], name: "South Africa", examplePhone: "711234567"},
    {code: CountryCode.Latvia, areaCode: ["371"], name: "Latvia", examplePhone: "21234567"},
    {code: CountryCode.Nigeria, areaCode: ["234"], name: "Nigeria", examplePhone: "8021234567"},
    {code: CountryCode.Georgia, areaCode: ["995"], name: "Georgia", examplePhone: "555123456"},
    {code: CountryCode.Lithuania, areaCode: ["370"], name: "Lithuania", examplePhone: "61234567"},
    {code: CountryCode.Mexico, areaCode: ["52"], name: "Mexico", examplePhone: "2221234567"},
    {code: CountryCode.Hungary, areaCode: ["36"], name: "Hungary", examplePhone: "201234567"},
    {code: CountryCode.Azerbaijan, areaCode: ["994"], name: "Azerbaijan", examplePhone: "401234567"},
    {code: CountryCode.Romania, areaCode: ["40"], name: "Romania", examplePhone: "712034567"},
    {code: CountryCode.CzechRepublic, areaCode: ["420"], name: "Czech Republic", examplePhone: "601123456"},
    {code: CountryCode.Egypt, areaCode: ["20"], name: "Egypt", examplePhone: "1001234567"},
    {code: CountryCode.Slovakia, areaCode: ["421"], name: "Slovakia", examplePhone: "912123456"},
    {code: CountryCode.Poland, areaCode: ["48"], name: "Poland", examplePhone: "512345678"},
    {code: CountryCode.Ukraine, areaCode: ["380"], name: "Ukraine", examplePhone: "501234567"},
    {code: CountryCode.Serbia, areaCode: ["381"], name: "Serbia", examplePhone: "601234567"},
    {code: CountryCode.Finland, areaCode: ["358"], name: "Finland", examplePhone: "412345678"},
    {code: CountryCode.Andorra, areaCode: ["376"], name: "Andorra", examplePhone: "312345"},
    {code: CountryCode.UnitedArabEmirates, areaCode: ["971"], name: "United Arab Emirates", examplePhone: "501234567"},
    {code: CountryCode.Afghanistan, areaCode: ["93"], name: "Afghanistan", examplePhone: "701234567"},
    {code: CountryCode.AntiguaAndBarbuda, areaCode: ["1268"], name: "Antigua and Barbuda", examplePhone: "4641234"},
    {code: CountryCode.Anguilla, areaCode: ["1264"], name: "Anguilla", examplePhone: "2351234"},
    {code: CountryCode.Albania, areaCode: ["355"], name: "Albania", examplePhone: "672123456"},
    {code: CountryCode.Armenia, areaCode: ["374"], name: "Armenia", examplePhone: "77123456"},
    {code: CountryCode.NetherlandsAntilles, areaCode: ["599"], name: "Netherlands Antilles", examplePhone: undefined},
    {code: CountryCode.Angola, areaCode: ["244"], name: "Angola", examplePhone: "923123456"},
    {code: CountryCode.Antarctica, areaCode: ["672"], name: "Antarctica", examplePhone: undefined},
    {code: CountryCode.Argentina, areaCode: ["54"], name: "Argentina", examplePhone: "91123456789"},
    {code: CountryCode.AmericanSamoa, areaCode: ["1684"], name: "American Samoa", examplePhone: "7331234"},
    {code: CountryCode.Austria, areaCode: ["43"], name: "Austria", examplePhone: "664123456"},
    {code: CountryCode.Australia, areaCode: ["61"], name: "Australia", examplePhone: "412345678"},
    {code: CountryCode.Aruba, areaCode: ["297"], name: "Aruba", examplePhone: "5601234"},
    {
        code: CountryCode.BosniaAndHerzegovina,
        areaCode: ["387"],
        name: "Bosnia and Herzegovina",
        examplePhone: "61123456",
    },
    {code: CountryCode.Barbados, areaCode: ["1246"], name: "Barbados", examplePhone: "2501234"},
    {code: CountryCode.Bangladesh, areaCode: ["880"], name: "Bangladesh", examplePhone: "1812345678"},
    {code: CountryCode.Belgium, areaCode: ["32"], name: "Belgium", examplePhone: "470123456"},
    {code: CountryCode.BurkinaFaso, areaCode: ["226"], name: "Burkina Faso", examplePhone: "70123456"},
    {code: CountryCode.Bulgaria, areaCode: ["359"], name: "Bulgaria", examplePhone: "48123456"},
    {code: CountryCode.Bahrain, areaCode: ["973"], name: "Bahrain", examplePhone: "36001234"},
    {code: CountryCode.Burundi, areaCode: ["257"], name: "Burundi", examplePhone: "79561234"},
    {code: CountryCode.Benin, areaCode: ["229"], name: "Benin", examplePhone: "90011234"},
    {code: CountryCode.SaintBarthelemy, areaCode: ["590"], name: "Saint-Barthélemy", examplePhone: "690001234"},
    {code: CountryCode.Bermuda, areaCode: ["1441"], name: "Bermuda", examplePhone: "3701234"},
    {code: CountryCode.Brunei, areaCode: ["673"], name: "Brunei", examplePhone: "7123456"},
    {code: CountryCode.Bolivia, areaCode: ["591"], name: "Bolivia", examplePhone: "71234567"},
    {code: CountryCode.Brazil, areaCode: ["55"], name: "Brazil", examplePhone: "11961234567"},
    {code: CountryCode.Bahamas, areaCode: ["1242"], name: "Bahamas", examplePhone: "3591234"},
    {code: CountryCode.Bhutan, areaCode: ["975"], name: "Bhutan", examplePhone: "17123456"},
    {code: CountryCode.Belarus, areaCode: ["375"], name: "Belarus", examplePhone: "294911911"},
    {code: CountryCode.Botswana, areaCode: ["267"], name: "Botswana", examplePhone: "71123456"},
    {code: CountryCode.Belize, areaCode: ["501"], name: "Belize", examplePhone: "6221234"},
    {code: CountryCode.Canada, areaCode: ["1"], name: "Canada", examplePhone: "5062345678"},
    {code: CountryCode.CocosIslands, areaCode: ["61"], name: "Cocos Islands", examplePhone: "412345678"},
    {
        code: CountryCode.DemocraticRepublicOfTheCongo,
        areaCode: ["243"],
        name: "Democratic Republic of the Congo",
        examplePhone: "991234567",
    },
    {
        code: CountryCode.CentralAfricanRepublic,
        areaCode: ["236"],
        name: "Central African Republic",
        examplePhone: "70012345",
    },
    {
        code: CountryCode.RepublicOfTheCongo,
        areaCode: ["242"],
        name: "Republic of the Congo",
        examplePhone: "061234567",
    },
    {code: CountryCode.Switzerland, areaCode: ["41"], name: "Switzerland", examplePhone: "781234567"},
    {code: CountryCode.IvoryCoast, areaCode: ["225"], name: "Ivory Coast", examplePhone: "0123456789"},
    {code: CountryCode.CookIslands, areaCode: ["682"], name: "Cook Islands", examplePhone: "71234"},
    {code: CountryCode.Chile, areaCode: ["56"], name: "Chile", examplePhone: "221234567"},
    {code: CountryCode.Cameroon, areaCode: ["237"], name: "Cameroon", examplePhone: "671234567"},
    {code: CountryCode.China, areaCode: ["86"], name: "China", examplePhone: "13123456789"},
    {code: CountryCode.Colombia, areaCode: ["57"], name: "Colombia", examplePhone: "3211234567"},
    {code: CountryCode.CostaRica, areaCode: ["506"], name: "Costa Rica", examplePhone: "83123456"},
    {code: CountryCode.Cuba, areaCode: ["53"], name: "Cuba", examplePhone: "51234567"},
    {code: CountryCode.CapeVerde, areaCode: ["238"], name: "Cape Verde", examplePhone: "9911234"},
    {code: CountryCode.Curaçao, areaCode: ["599"], name: "Curaçao", examplePhone: "95181234"},
    {code: CountryCode.ChristmasIsland, areaCode: ["61"], name: "Christmas Island", examplePhone: "412345678"},
    {code: CountryCode.Cyprus, areaCode: ["357"], name: "Cyprus", examplePhone: "96123456"},
    {code: CountryCode.Germany, areaCode: ["49"], name: "Germany", examplePhone: "15123456789"},
    {code: CountryCode.Djibouti, areaCode: ["253"], name: "Dijbouti", examplePhone: "77831001"},
    {code: CountryCode.Denmark, areaCode: ["45"], name: "Denmark", examplePhone: "32123456"},
    {code: CountryCode.Dominica, areaCode: ["1767"], name: "Dominica", examplePhone: "2251234"},
    {
        code: CountryCode.DominicanRepublic,
        areaCode: ["1809", "1829", "1849"],
        name: "Dominican Republic",
        examplePhone: "2345678",
    },
    {code: CountryCode.Algeria, areaCode: ["213"], name: "Algeria", examplePhone: "551234567"},
    {code: CountryCode.Ecuador, areaCode: ["593"], name: "Ecuador", examplePhone: "991234567"},
    {code: CountryCode.WesternSahara, areaCode: ["212"], name: "Western Sahara", examplePhone: "650123456"},
    {code: CountryCode.Eritrea, areaCode: ["291"], name: "Eritrea", examplePhone: "7123456"},
    {code: CountryCode.Spain, areaCode: ["34"], name: "Spain", examplePhone: "612345678"},
    {code: CountryCode.Ethiopia, areaCode: ["251"], name: "Ethiopia", examplePhone: "911234567"},
    {code: CountryCode.Fiji, areaCode: ["679"], name: "Fiji", examplePhone: "7012345"},
    {code: CountryCode.FalklandIslands, areaCode: ["500"], name: "Falkland Islands", examplePhone: "51234"},
    {code: CountryCode.Micronesia, areaCode: ["691"], name: "Micronesia", examplePhone: "3501234"},
    {code: CountryCode.FaroeIslands, areaCode: ["298"], name: "Faroe Islands", examplePhone: "211234"},
    {code: CountryCode.France, areaCode: ["33"], name: "France", examplePhone: "612345678"},
    {code: CountryCode.Gabon, areaCode: ["241"], name: "Gabon", examplePhone: "06031234"},
    {code: CountryCode.UnitedKingdom, areaCode: ["44"], name: "United Kingdom", examplePhone: "7400123456"},
    {code: CountryCode.Grenada, areaCode: ["1473"], name: "Grenada", examplePhone: "4031234"},
    {code: CountryCode.Guernsey, areaCode: ["441481"], name: "Guernsey", examplePhone: undefined}, // lib gives phone with +44 GB area code, "+44 7781 123456"
    {code: CountryCode.Ghana, areaCode: ["233"], name: "Ghana", examplePhone: "231234567"},
    {code: CountryCode.Gibraltar, areaCode: ["350"], name: "Gibraltar", examplePhone: "57123456"},
    {code: CountryCode.Greenland, areaCode: ["299"], name: "Greenland", examplePhone: "221234"},
    {code: CountryCode.Gambia, areaCode: ["220"], name: "Gambia", examplePhone: "3012345"},
    {code: CountryCode.Guinea, areaCode: ["224"], name: "Guinea", examplePhone: "601123456"},
    {code: CountryCode.Guadeloupe, areaCode: ["590"], name: "Guadeloupe", examplePhone: "690001234"},
    {code: CountryCode.EquatorialGuinea, areaCode: ["240"], name: "Equatorial Guinea", examplePhone: "222123456"},
    {code: CountryCode.Greece, areaCode: ["30"], name: "Greece", examplePhone: "6912345678"},
    {code: CountryCode.Guatemala, areaCode: ["502"], name: "Guatemala", examplePhone: "51234567"},
    {code: CountryCode.Guam, areaCode: ["1671"], name: "Guam", examplePhone: "3001234"},
    {code: CountryCode.GuineaBissau, areaCode: ["245"], name: "Guinea-Bissau", examplePhone: "955012345"},
    {code: CountryCode.Guyana, areaCode: ["592"], name: "Guyana", examplePhone: "6091234"},
    {code: CountryCode.HongKong, areaCode: ["852"], name: "Hong Kong", examplePhone: "51234567"},
    {code: CountryCode.Honduras, areaCode: ["504"], name: "Honduras", examplePhone: "91234567"},
    {code: CountryCode.Croatia, areaCode: ["385"], name: "Croatia", examplePhone: "921234567"},
    {code: CountryCode.Haiti, areaCode: ["509"], name: "Haiti", examplePhone: "34101234"},
    {code: CountryCode.Indonesia, areaCode: ["62"], name: "Indonesia", examplePhone: "812345678"},
    {code: CountryCode.Ireland, areaCode: ["353"], name: "Ireland", examplePhone: "850123456"},
    {code: CountryCode.Israel, areaCode: ["972"], name: "Israel", examplePhone: "502345678"},
    {code: CountryCode.IsleOfMan, areaCode: ["441624"], name: "Isle of Man", examplePhone: undefined}, // lib gives phone with +44 GB area code, "+44 7924 123456"
    {code: CountryCode.India, areaCode: ["91"], name: "India", examplePhone: "8123456789"},
    {
        code: CountryCode.BritishIndianOceanTerritory,
        areaCode: ["246"],
        name: "British Indian Ocean Territory",
        examplePhone: "3801234",
    },
    {code: CountryCode.Iraq, areaCode: ["964"], name: "Iraq", examplePhone: "7912345678"},
    {code: CountryCode.Iran, areaCode: ["98"], name: "Iran", examplePhone: "9123456789"},
    {code: CountryCode.Iceland, areaCode: ["354"], name: "Iceland", examplePhone: "6111234"},
    {code: CountryCode.Italy, areaCode: ["39"], name: "Italy", examplePhone: "3123456789"},
    {code: CountryCode.Jersey, areaCode: ["441534"], name: "Jersey", examplePhone: undefined}, // lib gives phone with +44 GB area code, "+44 7797 712345"
    {code: CountryCode.Jamaica, areaCode: ["1876"], name: "Jamaica", examplePhone: "2101234"},
    {code: CountryCode.Jordan, areaCode: ["962"], name: "Jordan", examplePhone: "790123456"},
    {code: CountryCode.Japan, areaCode: ["81"], name: "Japan", examplePhone: "9012345678"},
    {code: CountryCode.Kenya, areaCode: ["254"], name: "Kenya", examplePhone: "712123456"},
    {code: CountryCode.Kyrgyzstan, areaCode: ["996"], name: "Kyrgyzstan", examplePhone: "700123456"},
    {code: CountryCode.Cambodia, areaCode: ["855"], name: "Cambodia", examplePhone: "91234567"},
    {code: CountryCode.Kiribati, areaCode: ["686"], name: "Kiribati", examplePhone: "72001234"},
    {code: CountryCode.Comoros, areaCode: ["269"], name: "Comoros", examplePhone: "3212345"},
    {code: CountryCode.SaintKittsAndNevis, areaCode: ["1869"], name: "Saint Kitts and Nevis", examplePhone: "7652917"},
    {code: CountryCode.NorthKorea, areaCode: ["850"], name: "North Korea", examplePhone: "1921234567"},
    {code: CountryCode.SouthKorea, areaCode: ["82"], name: "South Korea", examplePhone: "1020000000"},
    {code: CountryCode.Kuwait, areaCode: ["965"], name: "Kuwait", examplePhone: "50012345"},
    {code: CountryCode.CaymanIslands, areaCode: ["1345"], name: "Cayman Islands", examplePhone: "3231234"},
    {code: CountryCode.Kazakhstan, areaCode: ["7"], name: "Kazakhstan", examplePhone: "7710009998"},
    {code: CountryCode.Laos, areaCode: ["856"], name: "Laos", examplePhone: "2023123456"},
    {code: CountryCode.Lebanon, areaCode: ["961"], name: "Lebanon", examplePhone: "71123456"},
    {code: CountryCode.SaintLucia, areaCode: ["1758"], name: "Saint Lucia", examplePhone: "2845678"},
    {code: CountryCode.Liechtenstein, areaCode: ["423"], name: "Liechtenstein", examplePhone: "660234567"},
    {code: CountryCode.SriLanka, areaCode: ["94"], name: "Sri Lanka", examplePhone: "712345678"},
    {code: CountryCode.Liberia, areaCode: ["231"], name: "Liberia", examplePhone: "770123456"},
    {code: CountryCode.Lesotho, areaCode: ["266"], name: "Lesotho", examplePhone: "50123456"},
    {code: CountryCode.Luxembourg, areaCode: ["352"], name: "Luxembourg", examplePhone: "628123456"},
    {code: CountryCode.Libya, areaCode: ["218"], name: "Libya", examplePhone: "912345678"},
    {code: CountryCode.Morocco, areaCode: ["212"], name: "Morocco", examplePhone: "650123456"},
    {code: CountryCode.Monaco, areaCode: ["377"], name: "Monaco", examplePhone: "612345678"},
    {code: CountryCode.Moldova, areaCode: ["373"], name: "Moldova", examplePhone: "62112345"},
    {code: CountryCode.Montenegro, areaCode: ["382"], name: "Montenegro", examplePhone: "67622901"},
    {code: CountryCode.SaintMartin, areaCode: ["590"], name: "Saint Martin", examplePhone: "690001234"},
    {code: CountryCode.Madagascar, areaCode: ["261"], name: "Madagascar", examplePhone: "321234567"},
    {code: CountryCode.MarshallIslands, areaCode: ["692"], name: "Marshall Islands", examplePhone: "2351234"},
    {code: CountryCode.Macedonia, areaCode: ["389"], name: "Macedonia", examplePhone: "72345678"},
    {code: CountryCode.Mali, areaCode: ["223"], name: "Mali", examplePhone: "65012345"},
    {code: CountryCode.Myanmar, areaCode: ["95"], name: "Myanmar", examplePhone: "92123456"},
    {code: CountryCode.Mongolia, areaCode: ["976"], name: "Mongolia", examplePhone: "88123456"},
    {code: CountryCode.Macau, areaCode: ["853"], name: "Macau", examplePhone: "66123456"},
    {
        code: CountryCode.NorthernMarianaIslands,
        areaCode: ["1670"],
        name: "Northern Mariana Islands",
        examplePhone: "2345678",
    },
    {code: CountryCode.Martinique, areaCode: ["596"], name: "Martinique", examplePhone: "696201234"},
    {code: CountryCode.Mauritania, areaCode: ["222"], name: "Mauritania", examplePhone: "22123456"},
    {code: CountryCode.Montserrat, areaCode: ["1664"], name: "Montserrat", examplePhone: "4923456"},
    {code: CountryCode.Malta, areaCode: ["356"], name: "Malta", examplePhone: "96961234"},
    {code: CountryCode.Mauritius, areaCode: ["230"], name: "Mauritius", examplePhone: "52512345"},
    {code: CountryCode.Maldives, areaCode: ["960"], name: "Maldives", examplePhone: "7712345"},
    {code: CountryCode.Malawi, areaCode: ["265"], name: "Malawi", examplePhone: "991234567"},
    {code: CountryCode.Malaysia, areaCode: ["60"], name: "Malaysia", examplePhone: "123456789"},
    {code: CountryCode.Mozambique, areaCode: ["258"], name: "Mozambique", examplePhone: "821234567"},
    {code: CountryCode.Namibia, areaCode: ["264"], name: "Namibia", examplePhone: "811234567"},
    {code: CountryCode.NewCaledonia, areaCode: ["687"], name: "New Caledonia", examplePhone: "751234"},
    {code: CountryCode.Niger, areaCode: ["227"], name: "Niger", examplePhone: "93123456"},
    {code: CountryCode.Nicaragua, areaCode: ["505"], name: "Nicaragua", examplePhone: "81234567"},
    {code: CountryCode.Netherlands, areaCode: ["31"], name: "Netherlands", examplePhone: "612345678"},
    {code: CountryCode.Norway, areaCode: ["47"], name: "Norway", examplePhone: "40612345"},
    {code: CountryCode.Nepal, areaCode: ["977"], name: "Nepal", examplePhone: "9841234567"},
    {code: CountryCode.Nauru, areaCode: ["674"], name: "Nauru", examplePhone: "5551234"},
    {code: CountryCode.Niue, areaCode: ["683"], name: "Niue", examplePhone: "8884012"},
    {code: CountryCode.NewZealand, areaCode: ["64"], name: "New Zealand", examplePhone: "211234567"},
    {code: CountryCode.Oman, areaCode: ["968"], name: "Oman", examplePhone: "92123456"},
    {code: CountryCode.Panama, areaCode: ["507"], name: "Panama", examplePhone: "61234567"},
    {code: CountryCode.Peru, areaCode: ["51"], name: "Peru", examplePhone: "912345678"},
    {code: CountryCode.FrenchPolynesia, areaCode: ["689"], name: "French Polynesia", examplePhone: "87123456"},
    {code: CountryCode.PapuaNewGuinea, areaCode: ["675"], name: "Papua New Guinea", examplePhone: "70123456"},
    {code: CountryCode.Philippines, areaCode: ["63"], name: "Philippines", examplePhone: "9051234567"},
    {code: CountryCode.Pakistan, areaCode: ["92"], name: "Pakistan", examplePhone: "3012345678"},
    {
        code: CountryCode.SaintPierreAndMiquelon,
        areaCode: ["508"],
        name: "Saint Pierre and Miquelon",
        examplePhone: "551234",
    },
    {code: CountryCode.Pitcairn, areaCode: ["64"], name: "Pitcairn", examplePhone: undefined}, // pitcairn calls through new zeland?
    {code: CountryCode.PuertoRico, areaCode: ["1787", "1939"], name: "Puerto Rico", examplePhone: "2345678"},
    {code: CountryCode.Palestine, areaCode: ["970"], name: "Palestine", examplePhone: "599123456"},
    {code: CountryCode.Portugal, areaCode: ["351"], name: "Portugal", examplePhone: "912345678"},
    {code: CountryCode.Palau, areaCode: ["680"], name: "Palau", examplePhone: "6201234"},
    {code: CountryCode.Paraguay, areaCode: ["595"], name: "Paraguay", examplePhone: "961456789"},
    {code: CountryCode.Qatar, areaCode: ["974"], name: "Qatar", examplePhone: "33123456"},
    {code: CountryCode.Reunion, areaCode: ["262"], name: "Reunion", examplePhone: "692123456"},
    {code: CountryCode.Russia, areaCode: ["7"], name: "Russia", examplePhone: "9123456789"},
    {code: CountryCode.Rwanda, areaCode: ["250"], name: "Rwanda", examplePhone: "720123456"},
    {code: CountryCode.SaudiArabia, areaCode: ["966"], name: "Saudi Arabia", examplePhone: "512345678"},
    {code: CountryCode.SolomonIslands, areaCode: ["677"], name: "Solomon Islands", examplePhone: "7421234"},
    {code: CountryCode.Seychelles, areaCode: ["248"], name: "Seychelles", examplePhone: "2510123"},
    {code: CountryCode.Sudan, areaCode: ["249"], name: "Sudan", examplePhone: "911231234"},
    {code: CountryCode.Sweden, areaCode: ["46"], name: "Sweden", examplePhone: "701234567"},
    {code: CountryCode.Singapore, areaCode: ["65"], name: "Singapore", examplePhone: "81234567"},
    {code: CountryCode.SaintHelena, areaCode: ["290"], name: "Saint Helena", examplePhone: "51234"},
    {code: CountryCode.Slovenia, areaCode: ["386"], name: "Slovenia", examplePhone: "31234567"},
    {
        code: CountryCode.SvalbardAndJanMayen,
        areaCode: ["47"],
        name: "Svalbard and Jan Mayen",
        examplePhone: "41234567",
    },
    {code: CountryCode.SierraLeone, areaCode: ["232"], name: "Sierra Leone", examplePhone: "25123456"},
    {code: CountryCode.SanMarino, areaCode: ["378"], name: "San Marino", examplePhone: "66661212"},
    {code: CountryCode.Senegal, areaCode: ["221"], name: "Senegal", examplePhone: "701234567"},
    {code: CountryCode.Somalia, areaCode: ["252"], name: "Somalia", examplePhone: "71123456"},
    {code: CountryCode.Suriname, areaCode: ["597"], name: "Suriname", examplePhone: "7412345"},
    {code: CountryCode.SouthSudan, areaCode: ["211"], name: "South Sudan", examplePhone: "977123456"},
    {code: CountryCode.SãoTomeAndPrincipe, areaCode: ["239"], name: "São Tomé and Príncipe", examplePhone: "9812345"},
    {code: CountryCode.ElSalvador, areaCode: ["503"], name: "El Salvador", examplePhone: "70123456"},
    {code: CountryCode.SintMaarten, areaCode: ["1721"], name: "Sint Maarten", examplePhone: "5205678"},
    {code: CountryCode.Syria, areaCode: ["963"], name: "Syria", examplePhone: "944567890"},
    {code: CountryCode.Swaziland, areaCode: ["268"], name: "Swaziland", examplePhone: "76123456"},
    {
        code: CountryCode.TurksAndCaicosIslands,
        areaCode: ["1649"],
        name: "Turks and Caicos Islands",
        examplePhone: "2311234",
    },
    {code: CountryCode.Chad, areaCode: ["235"], name: "Chad", examplePhone: "63012345"},
    {code: CountryCode.Togo, areaCode: ["228"], name: "Togo", examplePhone: "90112345"},
    {code: CountryCode.Thailand, areaCode: ["66"], name: "Thailand", examplePhone: "812345678"},
    {code: CountryCode.Tajikistan, areaCode: ["992"], name: "Tajikistan", examplePhone: "917123456"},
    {code: CountryCode.Tokelau, areaCode: ["690"], name: "Tokelau", examplePhone: "7290"},
    {code: CountryCode.EastTimor, areaCode: ["670"], name: "East Timor", examplePhone: "77212345"},
    {code: CountryCode.Turkmenistan, areaCode: ["993"], name: "Turkmenistan", examplePhone: "66123456"},
    {code: CountryCode.Tunisia, areaCode: ["216"], name: "Tunisia", examplePhone: "20123456"},
    {code: CountryCode.Tonga, areaCode: ["676"], name: "Tonga", examplePhone: "7715123"},
    {code: CountryCode.Turkey, areaCode: ["90"], name: "Turkey", examplePhone: "5012345678"},
    {code: CountryCode.TrinidadAndTobago, areaCode: ["1868"], name: "Trinidad and Tobago", examplePhone: "2911234"},
    {code: CountryCode.Tuvalu, areaCode: ["688"], name: "Tuvalu", examplePhone: "901234"},
    {code: CountryCode.Taiwan, areaCode: ["886"], name: "Taiwan", examplePhone: "912345678"},
    {code: CountryCode.Tanzania, areaCode: ["255"], name: "Tanzania", examplePhone: "621234567"},
    {code: CountryCode.Uganda, areaCode: ["256"], name: "Uganda", examplePhone: "712345678"},
    {code: CountryCode.UnitedStates, areaCode: ["1"], name: "United States", examplePhone: "2015550123"},
    {code: CountryCode.Uruguay, areaCode: ["598"], name: "Uruguay", examplePhone: "94231234"},
    {code: CountryCode.Uzbekistan, areaCode: ["998"], name: "Uzbekistan", examplePhone: "912345678"},
    {code: CountryCode.Vatican, areaCode: ["379"], name: "Vatican", examplePhone: undefined}, // this +379 is not in use, they use italys country code, like +39 3123456789
    {
        code: CountryCode.SaintVincentAndTheGrenadines,
        areaCode: ["1784"],
        name: "Saint Vincent and the Grenadines",
        examplePhone: "4301234",
    },
    {code: CountryCode.Venezuela, areaCode: ["58"], name: "Venezuela", examplePhone: "4121234567"},
    {
        code: CountryCode.BritishVirginIslands,
        areaCode: ["1284"],
        name: "British Virgin Islands",
        examplePhone: "3001234",
    },
    {code: CountryCode.USVirginIslands, areaCode: ["1340"], name: "U.S. Virgin Islands", examplePhone: "6421234"},
    {code: CountryCode.Vietnam, areaCode: ["84"], name: "Vietnam", examplePhone: "912345678"},
    {code: CountryCode.Vanuatu, areaCode: ["678"], name: "Vanuatu", examplePhone: "5912345"},
    {code: CountryCode.WallisAndFutuna, areaCode: ["681"], name: "Wallis and Futuna", examplePhone: "821234"},
    {code: CountryCode.Samoa, areaCode: ["685"], name: "Samoa", examplePhone: "7212345"},
    {code: CountryCode.Kosovo, areaCode: ["383"], name: "Kosovo", examplePhone: "43201234"},
    {code: CountryCode.Yemen, areaCode: ["967"], name: "Yemen", examplePhone: "712345678"},
    {code: CountryCode.Mayotte, areaCode: ["262"], name: "Mayotte", examplePhone: "639012345"},
    {code: CountryCode.Zambia, areaCode: ["260"], name: "Zambia", examplePhone: "955123456"},
    {code: CountryCode.Zimbabwe, areaCode: ["263"], name: "Zimbabwe", examplePhone: "712345678"},
]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((country) => new CountryModel(country.code, country.areaCode, country.name, country.examplePhone));

export default countries;
